import React, { useEffect, useMemo, useState } from 'react';
import scssVariables from '../../../../../Config.module.scss';
import { Currency, MarketIndicatorFactorDataModel, UnitOfMeasure } from '../../../../../Generated/Raven-Demeter';
import {
    HistoricalParameters,
    leadingIndicatorTypeDefinitions,
} from '../../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { IChartAreaRangeDataSeries, IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { defaultHistoricalParameters } from '../MarketIndicatorChartDefaultParameters';
import marketIndicatorChartService from '../MarketIndicatorChartService';
import { IMarketIndicatorChartColorPalette, IMarketIndicatorUserChartBaseProps } from '../MarketIndicatorUserChartDefinitions';
import MarketIndicatorUserChartRaw from '../MarketIndicatorUserChartRaw';
import styles from './MarketIndicatorHistoricalChart.module.scss';
import marketIndicatorHistoricalChartService from './MarketIndicatorHistoricalChartService';

export const historicalChartPalette: IMarketIndicatorChartColorPalette = {
    dataDecileAlphaColorsRuleSetTwo: [
        scssVariables.ruleSetTwoDataDecileColorAlpha1,
        scssVariables.ruleSetTwoDataDecileColorAlpha2,
        scssVariables.ruleSetTwoDataDecileColorAlpha3,
        scssVariables.ruleSetTwoDataDecileColorAlpha4,
        scssVariables.ruleSetTwoDataDecileColorAlpha5,
    ],

    dataDecileColorsRuleSetTwo: [
        scssVariables.ruleSetTwoDataDecileColor1,
        scssVariables.ruleSetTwoDataDecileColor2,
        scssVariables.ruleSetTwoDataDecileColor3,
        scssVariables.ruleSetTwoDataDecileColor4,
        scssVariables.ruleSetTwoDataDecileColor5,
    ],

    lineChartColorsRuleSetTwo: [scssVariables.ruleSetTwoLineColor1, scssVariables.ruleSetTwoLineColor2, scssVariables.ruleSetTwoLineColor3],
};

export interface IMarketIndicatorHistoricalChartProps extends IMarketIndicatorUserChartBaseProps {
    parameters?: HistoricalParameters;
}

const MarketIndicatorHistoricalChart: React.FC<IMarketIndicatorHistoricalChartProps> = (props: IMarketIndicatorHistoricalChartProps) => {
    const [translations, translate] = useLanguage();
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);
    const [areaRangeSeries, setAreaRangeSeries] = useState<IChartAreaRangeDataSeries[]>([]);

    useEffect(() => {
        const calculationData = marketIndicatorChartService.filterByYears(chartData, numberOfYears + 1);
        const thisYearData = marketIndicatorChartService.filterByYears(calculationData, 1);
        const lastYearData = marketIndicatorChartService.filterByYears(marketIndicatorHistoricalChartService.calculateLastYearData(calculationData), 1);
        const averageData = marketIndicatorChartService.filterByYears(
            marketIndicatorHistoricalChartService.calculateAverageData(calculationData, numberOfYears),
            1,
        );
        const decilesData = marketIndicatorHistoricalChartService.calculateDecilesData(calculationData, numberOfYears);
        const newLinesSeries: IChartDataSeries[] = [
            {
                label: translations.text.thisYear,
                data: thisYearData,
            },
            {
                label: translations.text.lastYear,
                data: lastYearData,
            },
            {
                label: `${numberOfYears} ${translations.text.yearlyAverage}`,
                data: averageData,
            },
        ];

        const newAreaRangeSeries: IChartAreaRangeDataSeries[] = [
            {
                label: `80% - ${translations.words.maximum}`,
                data: marketIndicatorChartService.filterByYears(decilesData[10], 1),
                decileRank: 10,
            },
            {
                label: '60 - 80%',
                data: marketIndicatorChartService.filterByYears(decilesData[8], 1),
                decileRank: 8,
            },
            {
                label: '40 - 60%',
                data: marketIndicatorChartService.filterByYears(decilesData[6], 1),
                decileRank: 6,
            },
            {
                label: '20 - 40%',
                data: marketIndicatorChartService.filterByYears(decilesData[4], 1),
                decileRank: 4,
            },
            {
                label: `${translations.words.minimum} - 20%`,
                data: marketIndicatorChartService.filterByYears(decilesData[2], 1),
                decileRank: 2,
            },
        ];

        setLineSeries(newLinesSeries);
        setAreaRangeSeries(newAreaRangeSeries);
    }, [props.runTestMarketIndicatorFactorResponse, props.parameters]);

    const numberOfYears = useMemo(() => {
        const parameters = props.parameters ?? defaultHistoricalParameters;
        const value = Number(parameters.yearsOfData);
        return value;
    }, [props.parameters]);

    const chartData = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.rows &&
            props.runTestMarketIndicatorFactorResponse.rows.length > 0
        ) {
            return marketIndicatorChartService.convertToChartData((props.runTestMarketIndicatorFactorResponse?.rows as MarketIndicatorFactorDataModel[]) ?? []);
        }
        return [];
    }, [props.runTestMarketIndicatorFactorResponse]);

    const isLoading = useMemo(
        () => props.isLoading || (!props.runTestMarketIndicatorFactorResponse && !props.showOnlyAsPopout),
        [props.runTestMarketIndicatorFactorResponse, props.showOnlyAsPopout, props.isLoading],
    );

    const title = useMemo(() => {
        if (props.title) {
            return props.title;
        }
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.commodityDisplayName) {
            const region = props.runTestMarketIndicatorFactorResponse?.subRegion
                ? translate(props.runTestMarketIndicatorFactorResponse?.subRegion)
                : translate(props.runTestMarketIndicatorFactorResponse?.regionDisplayName!);
            const tableDefinitionType =
                translations.tableDefinitionType[
                    leadingIndicatorTypeDefinitions.find((x) => x.value === props.runTestMarketIndicatorFactorResponse?.leadingIndicatorType)
                        ?.tableDefinitionType!
                ];
            const commdityName = props.runTestMarketIndicatorFactorResponse.commodityDisplayName;
            return [region, commdityName, tableDefinitionType].filter((x) => x !== null && x !== '').join(' ');
        }
        return '';
    }, [props.title, props.runTestMarketIndicatorFactorResponse]);

    const sourceTag = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags.length > 0
        ) {
            return `${props.runTestMarketIndicatorFactorResponse.dataSourceTags[0]}, ${translations.dataSource.StoneXCalculations}`;
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const currency = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.currency) {
            return props.runTestMarketIndicatorFactorResponse.currency;
        }
        return '' as Currency;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const unitOfMeasure = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.unitOfMeasure) {
            return props.runTestMarketIndicatorFactorResponse.unitOfMeasure;
        }
        return '' as UnitOfMeasure;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const rightSide = useMemo(
        () => (
            <div className={styles.historical_market_indicator_chart_secondary_legend}>
                {areaRangeSeries.map((x, index) => {
                    const currentColor = historicalChartPalette.dataDecileColorsRuleSetTwo![index];
                    return (
                        <div key={`areaRangeSeries_${x.label}`} className={styles.historical_market_indicator_chart_right_side}>
                            <div className={styles.historical_market_indicator_chart_right_side_label} style={{ backgroundColor: currentColor }} />
                            {x.label}
                        </div>
                    );
                })}
            </div>
        ),
        [areaRangeSeries],
    );

    return (
        <ChartWrapper
            name="MarketIndicatorHistoricalChart"
            title={title}
            dataSourceTag={sourceTag}
            isLoading={isLoading}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
            rightSide={rightSide}
        >
            <MarketIndicatorUserChartRaw
                lineSeries={lineSeries}
                areaRangeSeries={areaRangeSeries}
                currencies={[currency]}
                unitOfMeasures={[unitOfMeasure]}
                hideAreaRangeSeriesInLegend
                colorPalette={historicalChartPalette}
            />
        </ChartWrapper>
    );
};

export default MarketIndicatorHistoricalChart;
