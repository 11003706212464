import { useCallback, useEffect, useRef, useState } from 'react';
import { demeterUsersApi } from '../../../Apis/Apis';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { DemeterUserStoreType, GetDemeterUserSessionResponse } from '../../../Generated/Raven-Demeter/api';
import browserService from '../../Services/BrowserService';
import useApi from './useApiHook';

const useUserSessionsApi = (
    stopAutoExecute?: boolean,
): [
    isSessionInUse: boolean,
    userSession: GetDemeterUserSessionResponse | undefined,
    userSessionError: string | undefined,
    refreshSession: (hardRefresh?: boolean) => void | (() => void),
] => {
    const hasCalledInitialApi = useRef(false);
    const [isSessionInUse, setIsSessionInUse] = useState(false);
    const [userSession, setUserSession] = useState<GetDemeterUserSessionResponse | undefined>();
    const [, refreshApi, getDemeterUserSessionResponse, getDemeterUserSessionResponseError] = useApi(
        (refreshSession: boolean | undefined) =>
            demeterUsersApi.getDemeterUserSession([DemeterUserStoreType.LanguageSelected, DemeterUserStoreType.LocationData], refreshSession),
        {
            stopGlobalErrorHandling: true,
            stopAutoExecute: true,
        },
    );

    useEffect(() => {
        // This api is called twice in some environments, therefore we need to stop it after the first call.
        // https://stackoverflow.com/a/72238236
        if (stopAutoExecute !== true && hasCalledInitialApi.current === false) {
            refreshApi(false);
            hasCalledInitialApi.current = true;
        }
    }, []);

    useEffect(() => {
        if (!getDemeterUserSessionResponse?.sessionToken) {
            return;
        }

        browserService.setLocalStorageItem(applicationConstants.SessionTokenHeader, getDemeterUserSessionResponse?.sessionToken);
        setUserSession(getDemeterUserSessionResponse);
    }, [getDemeterUserSessionResponse]);

    useEffect(() => {
        if (!getDemeterUserSessionResponseError || getDemeterUserSessionResponseError !== 'You already have an existing session.') {
            return;
        }

        setIsSessionInUse(true);
    }, [getDemeterUserSessionResponseError]);

    const refreshSession = useCallback((hardRefresh?: boolean) => {
        setIsSessionInUse(false);
        refreshApi(hardRefresh);
    }, []);

    return [isSessionInUse, userSession, getDemeterUserSessionResponseError, refreshSession];
};

export default useUserSessionsApi;
