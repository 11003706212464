import { CellClassParams, ColDef } from 'ag-grid-community';
import scssVariables from '../../../../Config.module.scss';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';
import styleService from '../../../Services/Styles/StyleService';
import styles from './SeasonalTables.module.scss';

export type SeasonalTableReferenceType = 'value' | 'percent';
export type SeasonalColumnDefinition = ColDef & { children: ColDef[] } & { isAverage?: boolean } & { isYearly?: boolean };
export type AverageOrTotalsType = 'average' | 'total';

export interface SeasonalModel {
    id: string;
    isPercent: boolean;
    displayDecimalPlacesMinimum?: number | null | undefined;
    displayDecimalPlacesMaximum?: number | null | undefined;
    year: number;
    month1?: number;
    month1IsActualValue?: boolean;
    month2?: number;
    month2IsActualValue?: boolean;
    month3?: number;
    month3IsActualValue?: boolean;
    month4?: number;
    month4IsActualValue?: boolean;
    month5?: number;
    month5IsActualValue?: boolean;
    month6?: number;
    month6IsActualValue?: boolean;
    month7?: number;
    month7IsActualValue?: boolean;
    month8?: number;
    month8IsActualValue?: boolean;
    month9?: number;
    month9IsActualValue?: boolean;
    month10?: number;
    month10IsActualValue?: boolean;
    month11?: number;
    month11IsActualValue?: boolean;
    month12?: number;
    month12IsActualValue?: boolean;
    quarter1?: number;
    quarter1IsActualValue?: boolean;
    quarter2?: number;
    quarter2IsActualValue?: boolean;
    quarter3?: number;
    quarter3IsActualValue?: boolean;
    quarter4?: number;
    quarter4IsActualValue?: boolean;
    yearlyAverageOrTotal?: number;
    yearlyAverageOrTotalIsActualValue?: boolean;
}

export type RendererParameters = {
    data: SeasonalModel;
    value: string;
    column: { colId: keyof SeasonalModel; colDef: { field: keyof SeasonalModel } };
};

const cellClassSetter = (parameters: CellClassParams) => {
    const { data, colDef } = parameters;
    const cellStyles = [styles.seasonal_table_cell];

    if (colDef.field === 'year') {
        return cellStyles;
    }

    if (data.isPercent) {
        cellStyles.push(...styleService.positiveOrNegativeCellClassSetter(parameters));
    }

    if (!data[`${colDef.field}IsActualValue` as keyof SeasonalModel]) {
        cellStyles.push(styles.seasonal_table_cell_forecast_value);
    }

    return cellStyles;
};

const seasonalTableValueFormatter = (parameters: RendererParameters) => {
    if (!parameters.value) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    if (parameters.column.colId === 'year') {
        return +parameters.value;
    }

    if (parameters.data.isPercent) {
        return formattingService.toPercent(+parameters.value);
    }

    return formattingService.toNumberStringWithTrailingZeros(
        +parameters.value,
        parameters.data.displayDecimalPlacesMinimum ?? 0,
        parameters.data.displayDecimalPlacesMaximum ?? 0,
    );
};

export const seasonalTableDefaultColumnOptions = {
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    flex: 1,
    wrapText: true,
    suppressMenu: true,
    valueFormatter: seasonalTableValueFormatter,
    cellClass: cellClassSetter,
    minWidth: 72,
    width: 72,
    enableCellChangeFlash: true,
};

export const seasonalTableBase: SeasonalColumnDefinition[] = [
    {
        headerName: '',
        children: [
            {
                pinned: 'left',
                field: 'year',
                cellStyle: { color: scssVariables.mainTextColor, fontWeight: 700 },
            },
            {
                field: 'month1',
                headerValueGetter: () => formattingService.toMonth(1),
            },
            {
                field: 'month2',
                headerValueGetter: () => formattingService.toMonth(2),
            },
            {
                field: 'month3',
                headerValueGetter: () => formattingService.toMonth(3),
            },
            {
                field: 'month4',
                headerValueGetter: () => formattingService.toMonth(4),
            },
            {
                field: 'month5',
                headerValueGetter: () => formattingService.toMonth(5),
            },
            {
                field: 'month6',
                headerValueGetter: () => formattingService.toMonth(6),
            },
            {
                field: 'month7',
                headerValueGetter: () => formattingService.toMonth(7),
            },
            {
                field: 'month8',
                headerValueGetter: () => formattingService.toMonth(8),
            },
            {
                field: 'month9',
                headerValueGetter: () => formattingService.toMonth(9),
            },
            {
                field: 'month10',
                headerValueGetter: () => formattingService.toMonth(10),
            },
            {
                field: 'month11',
                headerValueGetter: () => formattingService.toMonth(11),
            },
            {
                field: 'month12',
                headerValueGetter: () => formattingService.toMonth(12),
            },
        ],
    },
];

export const yearlyAverageOrTotalColumnDefinitions = [
    {
        headerValueGetter: () => languageService.translate(translationKeys.words.quarterly),
        isAverage: true,
        lockPinned: true,
        children: [
            {
                field: 'quarter1',
                isAverage: true,
                pinned: 'right',
                width: 90,
                hide: true,
            },
            {
                field: 'quarter2',
                isAverage: true,
                pinned: 'right',
                width: 90,
                hide: true,
            },
            {
                field: 'quarter3',
                isAverage: true,
                pinned: 'right',
                width: 90,
                hide: true,
            },
            {
                field: 'quarter4',
                isAverage: true,
                pinned: 'right',
                width: 90,
                hide: true,
            },
        ],
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.words.yearly),
        field: 'yearlyAverageOrTotal',
        isAverage: true,
        isYearly: true,
        children: [
            {
                field: 'yearlyAverageOrTotal',
                isAverage: true,
                lockPinned: true,
                pinned: 'right',
                width: 90,
                hide: true,
            },
        ],
    },
];
