/* eslint-disable class-methods-use-this */
import { IGtmDataLayer } from '@mono/api/lib/widgetApi/MonoWidget';
import { LicenseManager } from 'ag-grid-enterprise';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import applicationSettings from '../../Core/Settings/ApplicationSettings';
import applicationConstants from '../../Core/Utility/ApplicationConstants';
import store, { ReduxStore } from '../../Redux/ReduxStore';
import browserService from './BrowserService';
import loggingService from './Logging/LoggingService';

// Service that will initialize the application with base settings to be able to bootstrap and start it.
class ApplicationInitializationService {
    private registerApiInterceptorsCalled = false;

    private stores: Record<string, ReduxStore> = { default: store };

    private accessToken: string = '';

    getStore = (storeId?: string): ReduxStore => this.stores[storeId ?? 'default'];

    getAccessToken = (): string => this.accessToken;

    setAccessToken = (accessToken: string): void => {
        this.accessToken = accessToken;
    };

    registerAll = (isMono: boolean, newStore: ReduxStore, storeId?: string, dataLayer?: IGtmDataLayer): void => {
        applicationSettings.isMono = isMono;

        this.registerReduxStore(newStore, storeId);
        this.registerAgGridLicense();
        this.registerApiInterceptors();
        if (dataLayer) {
            this.registerDataLayer(dataLayer);
        }
    };

    registerDataLayer = (dataLayer: IGtmDataLayer) => {
        loggingService.setDataLayer(dataLayer);
    };

    unRegisterAll = (storeId: string): void => {
        const storeToRemove = this.stores[storeId];

        delete this.stores[storeId];
        if (storeToRemove === this.stores.default) {
            const existingStores = Object.values(this.stores);
            if (existingStores.length <= 1) {
                delete this.stores.default;
            } else {
                // eslint-disable-next-line prefer-destructuring
                this.stores.default = existingStores[0];
            }
        }
    };

    registerReduxStore = (newStore: ReduxStore, storeId?: string): void => {
        this.stores[storeId ?? 'default'] = newStore;

        // Always set the default store to the first one that gets registered.
        if (!this.stores.default || this.stores.default === store) {
            this.stores.default = newStore;
        }
    };

    registerAgGridLicense = (): void => {
        LicenseManager.setLicenseKey(
            // eslint-disable-next-line max-len
            'CompanyName=Stonex Group Inc.,LicensedGroup=technology-solutions-dev,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-037161,SupportServicesEnd=16_March_2024_[v2]_MTcxMDU0NzIwMDAwMA==af16510f519246a8dfdae6a4fc0cf6ba',
        );
    };

    registerApiInterceptors = (): void => {
        if (this.registerApiInterceptorsCalled) {
            return;
        }

        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Raven-Environment'] = applicationSettings.ravenEnvironment;
        axios.defaults.headers.common['Rift-Environment'] = applicationSettings.ravenEnvironment;
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

        axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
            const accessToken = this.stores.default?.getState().user?.accessToken;
            const sessionToken =
                browserService.getLocalStorageItem(applicationConstants.SessionTokenHeader) ?? this.stores.default?.getState().user?.sessionToken;

            if (accessToken) {
                config!.headers!.Authorization = `Bearer ${accessToken}`;
            }

            if (sessionToken) {
                config!.headers![applicationConstants.SessionTokenHeader] = sessionToken;
            }

            return config;
        });

        axios.interceptors.response.use(
            (response: AxiosResponse<any, any>) => response,
            (error: AxiosError) => {
                loggingService.trackException(error, `HTTP Error Response (${error.response?.status})`);
                if (error.response?.status === applicationConstants.HttpStatus.Unauthorized) {
                    if (error.response?.data?.detail === 'You already have an existing session.') {
                        browserService.navigateToSessionExpiration();
                    } else if (error.response?.data?.detail === 'Your session has expired, you need to renew your session.') {
                        browserService.navigateToDashboard(true);
                    } else {
                        browserService.navigateToLoginPage();
                    }
                }

                return Promise.reject(error);
            },
        );

        this.registerApiInterceptorsCalled = true;
    };
}

// Intercept all ajax calls because we need to re-write the lightstreamer endpoints.
// We cannot modify the lightstreamer code to have separate HTTP and Websockets prefixes.
// Therefore we have to use interception to make this work. This allows it to work with
// the storefront.
// DO NOT MAKE THIS AN ARROW FUNCTION!
if (applicationSettings.environment === 'local' || applicationSettings.environment === 'production') {
    const originalOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function open(method: string, url: any, isAsync?: boolean, username?: string, password?: string) {
        let newUrl = url;
        if (url.indexOf('/raven-lightstreamer/lightstreamer/') > 0) {
            newUrl = url.replace('/lightstreamer/', '/lightstreamer-session/');
        }

        return originalOpen.call(this, method, newUrl, isAsync ?? true, username, password);
    };
}

const applicationInitializationService = new ApplicationInitializationService();

export default applicationInitializationService;
