import { useEffect, useState } from 'react';
import { demeterUsersApi } from '../../../Apis/Apis';
import { DemeterUserStoreType, GetDemeterUserStoreResponse } from '../../../Generated/Raven-Demeter/api';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';

export type UserStoreValueType = { [key: string]: any };

const useUserStoreApi = (
    userStoreType: DemeterUserStoreType,
): [userStoreValue: UserStoreValueType | undefined, updateUserStoreValue: (userStorevalue: UserStoreValueType) => void] => {
    const [userStoreValueResponse, setUserStoreResponse] = useState<GetDemeterUserStoreResponse>();
    const [, , getDemeterUserStoreResponse] = useCacheThenApi(`${CacheKeys.ListDemeterUserPreferences}_${userStoreType}`, () => {
        if (!userStoreType) {
            return null;
        }

        return demeterUsersApi.getUserStore(userStoreType);
    });

    const updateUserStoreValue = (valueToSet: UserStoreValueType) => {
        // Set this user store type right away so we don't have to do a round trip and there's no
        // delay in getting the newest value.
        setUserStoreResponse({ userStore: { userStoreType, value: valueToSet } });
        updateUserStoreValueInternal(valueToSet);
    };

    const updateUserStoreValueInternal = async (valueToSet: UserStoreValueType) => {
        await demeterUsersApi.updateUserStore(userStoreType, {
            userStoreType,
            value: valueToSet,
        });
    };

    useEffect(() => {
        if (!getDemeterUserStoreResponse) {
            return;
        }
        setUserStoreResponse(getDemeterUserStoreResponse);
    }, [getDemeterUserStoreResponse]);

    return [userStoreValueResponse, updateUserStoreValue];
};

export default useUserStoreApi;
