import React, { useMemo } from 'react';
import { DemeterFeatureType, DemeterRegion, DemeterSubRegion, DemeterTableDefinitionRegionModel } from '../../Generated/Raven-Demeter';
import useRegionCommodityNavigation from '../../Refactor/Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useFeatureFlag from '../../Refactor/Services/FeatureFlags/useFeatureFlagHook';
import languageService from '../../Refactor/Services/Language/LanguageService';
import MenuSubRegionOrOthersDropdown from '../MenuSubRegionOrOthersDropdown/MenuSubRegionOrOthersDropdown';
import styles from './MenuFileSelector.module.scss';

interface IMenuRegionsDropdownProps {
    tableDefinitions: DemeterTableDefinitionRegionModel[];
    showMainRegionsAndGroupsOnly?: boolean;
    handleRegionUpdate: (regionDefinition: DemeterTableDefinitionRegionModel, subRegion?: DemeterSubRegion) => void;
}

const MenuRegionsDropdown: React.FC<IMenuRegionsDropdownProps> = (props: IMenuRegionsDropdownProps) => {
    const regionCommoditySelection = useRegionCommodityNavigation();
    const enableUserData = useFeatureFlag(DemeterFeatureType.UserData);

    const regularTabArray = useMemo(
        () => props.tableDefinitions.filter((x) => !x.showInOtherList && (enableUserData || x.region !== DemeterRegion.UserData)),
        [props.tableDefinitions, enableUserData],
    );
    const othersTabArray = useMemo(
        () =>
            props.tableDefinitions
                .filter((x) => x.showInOtherList)
                .sort((region1, region2) => {
                    const displayName1: string = region1?.displayName || '';
                    const displayName2: string = region2?.displayName || '';
                    return displayName1?.localeCompare(displayName2);
                }),
        [props.tableDefinitions],
    );

    const regionBelongsToOthersTab = () => othersTabArray?.some((x) => x.region === regionCommoditySelection.region);

    const getRegionWithSubRegionDisplayName = (tableDefinition: DemeterTableDefinitionRegionModel): string => {
        if (regionCommoditySelection.subRegion && tableDefinition.region === regionCommoditySelection.region) {
            return ` ${languageService.translate(tableDefinition.displayName)} / ${languageService.translate(regionCommoditySelection.subRegion)}`;
        }
        return languageService.translate(tableDefinition.displayName);
    };

    const getOtherRegionDisplayName = (): string => {
        const tableDefinition = othersTabArray?.find((x) => x.region === regionCommoditySelection.region);

        if (!tableDefinition) {
            return languageService.translate('Others');
        }

        return `${languageService.translate('Others')} / ${tableDefinition.displayName}`;
    };

    return (
        <div className={`${styles.master_flex}`}>
            {regularTabArray.length > 0 &&
                regularTabArray.map((tableDefinition: DemeterTableDefinitionRegionModel) => (
                    <React.Fragment key={`region_${tableDefinition.displayName}`}>
                        <button
                            type="button"
                            className={
                                tableDefinition.region === regionCommoditySelection?.region
                                    ? styles.menu_file_selector_exchanges_button_selected
                                    : styles.menu_file_selector_exchanges_button
                            }
                            key={`region_${tableDefinition.displayName}`}
                            onClick={() => tableDefinition.region && props.handleRegionUpdate(tableDefinition)}
                            data-testid={`MenuRegion${tableDefinition.region}`}
                        >
                            {getRegionWithSubRegionDisplayName(tableDefinition)}
                        </button>
                        {!props.showMainRegionsAndGroupsOnly && tableDefinition?.subRegions?.length > 0 && (
                            <MenuSubRegionOrOthersDropdown
                                tableDefinitions={[tableDefinition]}
                                isRegionSelected={tableDefinition.region === regionCommoditySelection.region}
                                handleRegionUpdate={props.handleRegionUpdate}
                            />
                        )}
                    </React.Fragment>
                ))}
            {othersTabArray.length > 0 && (
                <>
                    <button
                        type="button"
                        className={
                            regionBelongsToOthersTab() ? styles.menu_file_selector_exchanges_button_selected : styles.menu_file_selector_exchanges_button
                        }
                    >
                        {getOtherRegionDisplayName()}
                    </button>
                    <MenuSubRegionOrOthersDropdown
                        tableDefinitions={othersTabArray}
                        isRegionSelected={regionBelongsToOthersTab()}
                        handleRegionUpdate={props.handleRegionUpdate}
                    />
                </>
            )}
        </div>
    );
};

export default MenuRegionsDropdown;
