/* eslint-disable no-restricted-globals */
import { ListMarketPricesResponse } from '../../../../Generated/Raven';
import {
    ListCommodityMonthlyOtherResponse,
    ListMarketPricesRollingRangeResponse,
    ListMarketPricesWithConversionsResponse,
    MarketPriceModel,
    MarketPricesModel,
} from '../../../../Generated/Raven-Demeter';
import { IChartAreaRangeDataSeries } from '../../../Components/Charts/ChartDefinitions';
import {
    DecileChartOption,
    DecileRowDefinitions,
    TermClassification,
    TermClassificationOptions,
    ValueMatrixChartRequest,
    ValueMatrixTableResults,
} from './ValueMatrixDefinitions';

type PriceModel = MarketPriceModel & { value: number };

export default () => {
    self.onmessage = (event: MessageEvent) => {
        if (!event) {
            return;
        }

        const {
            messageType,
            valueMatrixTableInputs,
            valueMatrixChartInputs,
            termClassificationOptions,
            decileChartOptions,
            marketPricesResponse,
            listMarketPricesWithContractYearResponse,
            producerPricesIndexResponse,
            availableDatesArray,
            decileRowDefinitions,
        } = event.data;

        const valueMatrixStripTypeIsTwelveMonthStrip = valueMatrixTableInputs?.stripType === 'twelveMonthStrip';
        const valueMatrixStripTypeIsSpot = valueMatrixTableInputs?.stripType === 'spot';
        const valueMatrixStripTypeIsForwardContract = valueMatrixTableInputs?.stripType === 'forwardContract';

        const getCurrentDecileRange = (currentDecileValue: number, settlementPrices: number[], innerSettlementPrices?: number[]) => {
            const lowRangeDecile = getDecileAtValue(currentDecileValue, settlementPrices, innerSettlementPrices);
            const upperRangeDecile = getDecileAtValue(currentDecileValue + 1, settlementPrices, innerSettlementPrices);
            const currentDecileRange = { minimumValue: lowRangeDecile, maximumValue: upperRangeDecile };

            return currentDecileRange;
        };

        const getDateYearsAgo = (date: Date, yearsAgo: number = 1) => new Date(date).setFullYear(date.getFullYear() - yearsAgo);

        const getDecileAtValue = (decileRangeValue: number, settlementPrices: number[], innerSettlementPrices?: number[]) => {
            const lastSettlementPriceIndex = settlementPrices.length - 1;
            const lengthOfInnerData = innerSettlementPrices && innerSettlementPrices.length > 0 ? innerSettlementPrices.length - 1 : 0;

            const rawDecileIndex = (lastSettlementPriceIndex * decileRangeValue) / 10;
            const lowerDecileIndex = Math.floor(rawDecileIndex);

            const rawInnerDecileIndex = (lengthOfInnerData * decileRangeValue) / 10;
            const lowerInnerDecileIndex = Math.floor(rawInnerDecileIndex);

            // First value no matter what settings.
            if (decileRangeValue === 0) {
                return settlementPrices[0];
            }

            // Last value no matter what settings.
            if (decileRangeValue === 10) {
                return settlementPrices[lastSettlementPriceIndex];
            }

            const currentDecile =
                settlementPrices[lowerDecileIndex] +
                (settlementPrices[lowerDecileIndex + 1] - settlementPrices[lowerDecileIndex]) * (rawDecileIndex - lowerDecileIndex);

            const currentInnerDecile = innerSettlementPrices
                ? innerSettlementPrices[lowerInnerDecileIndex] +
                  (innerSettlementPrices[lowerInnerDecileIndex + 1] - innerSettlementPrices[lowerInnerDecileIndex]) *
                      (rawInnerDecileIndex - lowerInnerDecileIndex)
                : null;

            // All weighted values.
            if (innerSettlementPrices && valueMatrixTableInputs?.weighted?.outerWeight && valueMatrixTableInputs.weighted.innerWeight) {
                return (
                    currentInnerDecile! * (valueMatrixTableInputs.weighted.innerWeight / 100) +
                    currentDecile * (valueMatrixTableInputs.weighted.outerWeight / 100)
                );
            }

            return currentDecile;
        };

        const getProducerPriceIndexForMonth = (contract: MarketPriceModel) =>
            (producerPricesIndexResponse as ListCommodityMonthlyOtherResponse)?.rows?.find(
                (z) => z.month === new Date(contract.asOfDate).getMonth() + 1 && z.year === new Date(contract.asOfDate).getFullYear(),
            )?.value ?? producerPricesIndexResponse?.rows![producerPricesIndexResponse.rows!.length! - 1].value;

        const getValueWithProducerPriceAdjustment = (marketPriceModel: PriceModel, latestDateForSettlementPrices: number) => {
            if (!valueMatrixTableInputs?.applyProducerPriceIndex || producerPricesIndexResponse?.rows?.length === 0) {
                return marketPriceModel.settlementPrice ?? marketPriceModel.value;
            }

            const producerPriceIndexRows = (producerPricesIndexResponse as ListCommodityMonthlyOtherResponse)?.rows!;
            const lastValueOfProducerPriceIndex =
                producerPriceIndexRows?.findLast((x) => new Date(x.asOfDate).getTime() <= latestDateForSettlementPrices)?.value ??
                producerPriceIndexRows[producerPriceIndexRows.length - 1].value!;
            const priceForMonth = getProducerPriceIndexForMonth(marketPriceModel);

            return ((marketPriceModel.settlementPrice ?? marketPriceModel.value) * lastValueOfProducerPriceIndex) / priceForMonth!;
        };

        const formatDataForChart = (dateArguement: Date | string, currentDecileRange: { minimumValue: number; maximumValue: number }[]) => {
            const indexOfDate = (availableDatesArray as Date[]).findIndex((date) => date > new Date(dateArguement));

            // There are scenarios where asOfDate is greater than maximum available date.
            if (indexOfDate === -1) {
                return {
                    asOfDate: new Date(dateArguement),
                    isActualValue: true,
                    minimumValue: currentDecileRange[currentDecileRange.length - 1].minimumValue,
                    maximumValue: currentDecileRange[currentDecileRange.length - 1].maximumValue,
                };
            }
            const { maximumValue, minimumValue } = currentDecileRange[indexOfDate];

            // https://stackoverflow.com/questions/38860161/using-typescript-and-object-assign-gives-me-an-error-property-assign-does-no
            return {
                asOfDate: indexOfDate === 0 ? availableDatesArray[0] : new Date(dateArguement),
                isActualValue: true,
                minimumValue,
                maximumValue,
            };
        };

        const handleValueMatrixChartUpdate = () => {
            const numberOfYears = valueMatrixTableInputs!.weighted?.outerRange ?? valueMatrixTableInputs?.timeSpan!;

            const termClassificationContractMonths = (termClassificationOptions as TermClassificationOptions[]).find(
                (y) => y.value.contractGroupName === valueMatrixTableInputs.termClassification[0].contractGroupName,
            )?.value.contractMonths;

            const newAreaSeries = (valueMatrixChartInputs as ValueMatrixChartRequest).chartDeciles.map((areaSeries) => {
                const currentDecileRange = (availableDatesArray as Date[]).map((dateOfDecileMonth) => {
                    let latestDateForSettlementPrices = dateOfDecileMonth.getTime();
                    let earliestDateForSettlementPrices = new Date(dateOfDecileMonth).setFullYear(dateOfDecileMonth.getFullYear() - numberOfYears);

                    if (valueMatrixStripTypeIsSpot && marketPricesResponse[0]?.rows?.length > 0) {
                        latestDateForSettlementPrices = getLatestDateForSettlementPrices();
                        earliestDateForSettlementPrices = getEarliestDateForSettlementPrices(latestDateForSettlementPrices, numberOfYears);
                    }

                    const allSettlementData = (
                        valueMatrixStripTypeIsTwelveMonthStrip
                            ? (marketPricesResponse[0].rows as MarketPricesModel[]).map((x) => x.prices).flat()
                            : (marketPricesResponse[0].rows as PriceModel[])
                    )
                        ?.filter((x) => new Date(x.asOfDate).getTime() > earliestDateForSettlementPrices)
                        ?.filter((x) => new Date(x.asOfDate).getTime() <= latestDateForSettlementPrices)
                        // If spot, look at the asOfDate to filter out correct quarters.
                        .filter((y) => termClassificationContractMonths?.includes(y.contractMonth ?? new Date(y.asOfDate).getMonth() + 1));

                    const allSettlementPrices = allSettlementData
                        .filter((x) => new Date(x.asOfDate) <= dateOfDecileMonth)
                        .map((x) => {
                            if (valueMatrixTableInputs!.applyProducerPriceIndex) {
                                return getValueWithProducerPriceAdjustment(x as PriceModel, dateOfDecileMonth.getTime());
                            }

                            return x.settlementPrice ?? (x as PriceModel).value;
                        })
                        .sort((a, b) => a - b);

                    const innerSettlementPrices = valueMatrixTableInputs!.weighted?.innerRange
                        ? allSettlementData
                              .filter((x) => new Date(x.asOfDate).getTime() > getDateYearsAgo(dateOfDecileMonth, valueMatrixTableInputs.weighted!.innerRange))
                              .map((x) => {
                                  if (valueMatrixTableInputs.applyProducerPriceIndex) {
                                      return getValueWithProducerPriceAdjustment(x as PriceModel, dateOfDecileMonth.getTime());
                                  }

                                  return x.settlementPrice;
                              })
                              .sort((a, b) => a - b)
                        : [];

                    // Get the final decile range and return it.
                    const maximumValue = getDecileAtValue(areaSeries, allSettlementPrices, innerSettlementPrices);
                    const minimumValue = getDecileAtValue(areaSeries - 1, allSettlementPrices, innerSettlementPrices);

                    if (Number.isNaN(maximumValue)) {
                        return { maximumValue: 0, minimumValue };
                    }

                    if (Number.isNaN(minimumValue)) {
                        return { maximumValue, minimumValue: 0 };
                    }

                    return { maximumValue, minimumValue };
                });

                const allSettlementData = valueMatrixStripTypeIsTwelveMonthStrip
                    ? (marketPricesResponse[0].rows as MarketPricesModel[]).map((x) => x.prices).flat()
                    : (marketPricesResponse[0].rows as MarketPriceModel[]);

                // Loop over dates from first date of chart to last date of chart and put all those dates into an array. We can
                // then loop over the dates instead of the settlement data for everything except spot strip type.
                const chartDisplayDates = [];
                const currentDate =
                    valueMatrixChartInputs.chartStartDate < new Date(allSettlementData[0].asOfDate)
                        ? new Date(allSettlementData[0].asOfDate)
                        : new Date(availableDatesArray[0]);

                while (currentDate <= availableDatesArray[availableDatesArray.length - 1]) {
                    chartDisplayDates.push(new Date(currentDate));
                    currentDate.setDate(currentDate.getDate() + 1);
                }

                return {
                    label: (decileChartOptions as DecileChartOption[]).find((y) => y.value === areaSeries)?.label,
                    decileRank: areaSeries,
                    data: valueMatrixStripTypeIsSpot
                        ? allSettlementData
                              .filter((x) => x.settlementPrice ?? (x as PriceModel).value)
                              .map((x) => formatDataForChart(x.asOfDate, currentDecileRange))
                        : chartDisplayDates.map((x) => formatDataForChart(x, currentDecileRange)),
                };
            });

            // Generate the line series in its proper format.
            const newLinesSeries = ((listMarketPricesWithContractYearResponse as ListMarketPricesWithConversionsResponse[]) ?? []).map((x, index) => ({
                label: `${valueMatrixChartInputs.chartTerms[index]}`,
                data: x
                    .rows!.filter((z) => z.settlementPrice)
                    .filter((y) => new Date(y.asOfDate) > valueMatrixChartInputs.chartStartDate!)
                    .filter((y) => new Date(y.asOfDate) <= newAreaSeries[0].data[newAreaSeries[0].data.length - 1].asOfDate)
                    .map((z) => ({ value: z.settlementPrice, asOfDate: new Date(z.asOfDate), isActualValue: true })),
            }));

            postMessage({
                linesSeries: newLinesSeries,
                areasSeries: newAreaSeries as IChartAreaRangeDataSeries[],
                product: valueMatrixTableInputs?.product,
                messageType: 'updateChartResults',
            });
        };

        // For spot prices, we need to go back at least a month from the current month, then go back x years from that date.
        const getLatestDateForSettlementPrices = () => {
            let firstValidDate = new Date(valueMatrixChartInputs.asOfDate ?? new Date()).getTime();
            firstValidDate = new Date(firstValidDate).setDate(0);
            const filteredSpotPrices = (marketPricesResponse[0].rows as PriceModel[]).filter((x) => new Date(x.asOfDate).getTime() < firstValidDate);
            const latestSpotPrice = filteredSpotPrices.reduce((lastPriceItem, priceItemToTest) => {
                if (new Date(lastPriceItem.asOfDate) > new Date(priceItemToTest.asOfDate)) {
                    return lastPriceItem;
                }

                return priceItemToTest;
            });

            return new Date(latestSpotPrice.asOfDate).getTime();
        };

        const getEarliestDateForSettlementPrices = (latestDateForSettlementPrices: number, numberOfYears: number) =>
            new Date(latestDateForSettlementPrices).setFullYear(new Date(latestDateForSettlementPrices).getFullYear() - numberOfYears);

        // Take prices, do calculations, and format data for display. If the apis are loading or input defaults are updating,
        // we wait until they are done.
        const handleCalculateAndUpdateValueMatrixTable = () => {
            const numberOfYears = valueMatrixTableInputs?.timeSpan ?? valueMatrixTableInputs!.weighted?.outerRange!;
            let earliestDateForSettlementPrices = new Date(valueMatrixTableInputs!.asOfDate!).setFullYear(
                valueMatrixTableInputs!.asOfDate!.getFullYear() - numberOfYears,
            );
            let latestDateForSettlementPrices = valueMatrixTableInputs!.asOfDate!.getTime();

            if (valueMatrixStripTypeIsSpot && marketPricesResponse[0]?.rows?.length > 0) {
                latestDateForSettlementPrices = getLatestDateForSettlementPrices();
                earliestDateForSettlementPrices = getEarliestDateForSettlementPrices(latestDateForSettlementPrices, numberOfYears);
            }

            const decileRangeData = (valueMatrixTableInputs?.termClassification as TermClassification[]).map((x, index) => {
                const marketPricesResponseItem = valueMatrixStripTypeIsForwardContract
                    ? (marketPricesResponse as ListMarketPricesResponse[] | ListMarketPricesRollingRangeResponse[])[index].rows
                    : (marketPricesResponse as ListMarketPricesResponse[] | ListMarketPricesRollingRangeResponse[])[0].rows;

                let temporaryAverageValuesArray: MarketPriceModel[] = [];
                const finalAverageValues: MarketPriceModel[][] = [];
                let twelveMonthStripSettlementData: MarketPriceModel[] = [];
                const flatRollingMarketPriceRows = marketPricesResponseItem
                    ?.map((y) => (y as MarketPricesModel)?.prices)
                    .flat()
                    .sort((a, b) => new Date(a.asOfDate).getTime() - new Date(b.asOfDate).getTime());

                const currentMarketPricesRows = flatRollingMarketPriceRows?.every((y) => !y) ? marketPricesResponseItem : flatRollingMarketPriceRows;
                const termClassificationContractMonths = (termClassificationOptions as TermClassificationOptions[]).find(
                    (y) => y.value.contractGroupName === x.contractGroupName,
                )?.value.contractMonths!;

                const allSettlementData = (currentMarketPricesRows as MarketPriceModel[])
                    ?.filter((y) => new Date(y.asOfDate).getTime() > earliestDateForSettlementPrices)
                    ?.filter((y) => new Date(y.asOfDate).getTime() <= latestDateForSettlementPrices)
                    // If spot, look at the asOfDate to filter out correct quarters.
                    .filter((y) => termClassificationContractMonths?.includes(y.contractMonth ?? new Date(y.asOfDate).getMonth() + 1));

                if (valueMatrixStripTypeIsTwelveMonthStrip) {
                    allSettlementData?.forEach((y, allSettlementDataIndex) => {
                        // Using the settlement data (sorted by date), we get all the data with matching dates and average the
                        // settlement prices into a single datum.
                        if (allSettlementDataIndex === 0 || y?.asOfDate === allSettlementData[allSettlementDataIndex - 1].asOfDate) {
                            temporaryAverageValuesArray.push(y);
                            return;
                        }

                        finalAverageValues.push(temporaryAverageValuesArray);
                        temporaryAverageValuesArray = [];
                        temporaryAverageValuesArray.push(y);
                    });

                    twelveMonthStripSettlementData = finalAverageValues.map(
                        (y) =>
                            ({
                                asOfDate: y[0].asOfDate,
                                settlementPrice: y.map((z) => z.settlementPrice).reduce((sum, currentValue) => sum + currentValue, 0) / y.length,
                            } as MarketPriceModel),
                    );
                }

                const allSettlementPrices = (valueMatrixStripTypeIsTwelveMonthStrip ? twelveMonthStripSettlementData : allSettlementData)
                    ?.map((y) => getValueWithProducerPriceAdjustment(y as PriceModel, latestDateForSettlementPrices))
                    .sort((a, b) => a - b);

                const innerSettlementPrices = valueMatrixTableInputs.weighted?.innerRange
                    ? (valueMatrixStripTypeIsTwelveMonthStrip ? twelveMonthStripSettlementData : allSettlementData)
                          .filter(
                              (y) =>
                                  new Date(y.asOfDate).getTime() >
                                  new Date(
                                      new Date(valueMatrixTableInputs.asOfDate).setFullYear(
                                          valueMatrixTableInputs.asOfDate.getFullYear() - valueMatrixTableInputs.weighted!.innerRange,
                                      ),
                                  ).getTime(),
                          )
                          .map((y) => getValueWithProducerPriceAdjustment(y as PriceModel, latestDateForSettlementPrices))
                          .sort((a, b) => a - b)
                    : [];

                const getMeanPrice = () => {
                    const allSettlementPricesMean = +(allSettlementPrices?.length > 0
                        ? allSettlementPrices.reduce((a, b) => a + b) / allSettlementPrices.length
                        : 0);

                    if (innerSettlementPrices && innerSettlementPrices.length > 0) {
                        const innerSettlementPricesMean = +(innerSettlementPrices.reduce((a, b) => a + b) / innerSettlementPrices.length);

                        return (
                            (innerSettlementPricesMean * valueMatrixTableInputs.weighted!.innerWeight! +
                                allSettlementPricesMean * valueMatrixTableInputs.weighted!.outerWeight!) /
                            100
                        );
                    }

                    return allSettlementPricesMean;
                };

                // Define decimalTableData header.
                const decileTableData = {
                    title: (termClassificationOptions as TermClassificationOptions[]).find((y) => y.value.contractGroupName === x.contractGroupName)?.label!,
                    meanPrice: `${getMeanPrice()}`,
                    medianPrice: `${getDecileAtValue(5, allSettlementPrices, innerSettlementPrices)}`,
                } as ValueMatrixTableResults;

                // Assign value (range) for each decile.
                (decileRowDefinitions as DecileRowDefinitions[])
                    .filter((y) => y.fieldName)
                    .reverse()
                    .forEach((y, indexOfDecile) => {
                        decileTableData[y.fieldName!] = getCurrentDecileRange(indexOfDecile, allSettlementPrices, innerSettlementPrices);
                    });

                return decileTableData;
            });

            postMessage({ decileRangeData, messageType: 'updateTableResults' });
        };

        // Take message type, do calculations, respond with message.
        if (messageType === 'updateChartResults') {
            handleValueMatrixChartUpdate();
        } else {
            handleCalculateAndUpdateValueMatrixTable();
        }
    };
};
