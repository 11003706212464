import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { demeterExportsApi, demeterImportsApi } from '../../../Apis/Apis';
import { DemeterRegion, DemeterSubRegion, DemeterTableDefinitionType, UnitOfMeasure } from '../../../Generated/Raven-Demeter';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Components/Navigation/Hooks/useTableDefinitionHook';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';

export type OriginsDesinationsRow = { Country: string; MonthVolume: number; MonthChange: number; YearVolume: number; YearChange: number };

export interface CommodityMonthlyOriginsOrDestinationsModel {
    originOrDestinationRegion?: DemeterRegion;
    originOrDestinationSubRegion?: DemeterSubRegion;
    originRegion?: DemeterRegion;
    originSubRegion?: DemeterSubRegion;
    destinationRegion?: DemeterRegion;
    destinationSubRegion?: DemeterSubRegion;
    year: number;
    month: number;
    value?: number | null;
    normalizedValue: number;
    monthOverMonthChangePercent?: number | null;
    rollingYearChangePercent?: number | null;
    rollingYearValue?: number | null;
    yearToDateValue?: number | null;
    yearToDateChangePercent?: number | null;
    unitOfMeasure?: UnitOfMeasure;
    isActualValue: boolean;
    asOfDate: string;
    order: number;
}

export interface ListCommodityMonthlyOriginsOrDestinationsResponse {
    rows?: CommodityMonthlyOriginsOrDestinationsModel[] | null;
    unitOfMeasure?: UnitOfMeasure;
    dataSourceTag?: string | null;
}

const useOriginsAndDestinationsApiHook = (
    tableDefinitionType: DemeterTableDefinitionType,
    regionCommoditySelection: IRegionCommoditySelection,
    unitOfMeasure?: UnitOfMeasure,
): ListCommodityMonthlyOriginsOrDestinationsResponse | undefined => {
    const [, tableDefinitionCommodity] = useTableDefinition(tableDefinitionType, regionCommoditySelection);

    const cacheKey = useMemo(() => {
        const cacheKeyPrefix = `${CacheKeys.GetDemeterOriginsOrDestinations}_${tableDefinitionType}`;
        if (!tableDefinitionCommodity || !regionCommoditySelection) {
            return cacheKeyPrefix;
        }

        const cacheKeySuffix = JSON.stringify({ ...regionCommoditySelection, unitOfMeasure });
        return `${cacheKeyPrefix}_${cacheKeySuffix}`;
    }, [tableDefinitionCommodity, regionCommoditySelection, unitOfMeasure]);

    const [, , listDemeterOriginsDestinationsResponse] = useCacheThenApi(
        cacheKey,
        (): Promise<AxiosResponse<ListCommodityMonthlyOriginsOrDestinationsResponse>> | null => {
            if (!tableDefinitionCommodity || !regionCommoditySelection) {
                return null;
            }

            const subRegion = regionCommoditySelection.subRegion ? (regionCommoditySelection.subRegion as DemeterSubRegion) : undefined;

            if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyImportsTable) {
                return demeterImportsApi.listCommodityMonthlyImportOrigins(
                    regionCommoditySelection.region,
                    regionCommoditySelection.commodity,
                    subRegion ?? undefined,
                    undefined,
                    undefined,
                    unitOfMeasure,
                );
            }

            if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyExportsTable) {
                return demeterExportsApi.listCommodityMonthlyExportDestinations(
                    regionCommoditySelection.region,
                    regionCommoditySelection.commodity,
                    subRegion ?? undefined,
                    undefined,
                    undefined,
                    unitOfMeasure,
                );
            }

            return null;
        },
    );

    listDemeterOriginsDestinationsResponse?.rows?.forEach((row) => {
        row.originOrDestinationRegion = row.originRegion ?? row.destinationRegion;
    });

    return listDemeterOriginsDestinationsResponse;
};

export default useOriginsAndDestinationsApiHook;
