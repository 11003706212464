import { useEffect, useMemo } from 'react';
import { DemeterCommodity } from '../../../../../Generated/Raven-Demeter';
import useSymbolsApi from '../../../../Apis/Hooks/useSymbolsApiHook';
import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from '../ValueMatrixCalculator.module.scss';
import { ValueMatrixTableRequest } from '../ValueMatrixDefinitions';

interface IValueMatrixProductDropdownProps {
    valueMatrixInputs: ValueMatrixTableRequest;
    setValueMatrixInputs: (value: ValueMatrixTableRequest) => void;
    refreshGetValueMatrixCalculator: () => void;
}

const notAllowedCommodities: DemeterCommodity[] = [DemeterCommodity.FluidMilk];

const ValueMatrixProductDropdown: React.FC<IValueMatrixProductDropdownProps> = (props: IValueMatrixProductDropdownProps) => {
    const [translations] = useLanguage();
    const symbols = useSymbolsApi();

    const productOptions = useMemo(() => {
        if (!symbols) {
            return [];
        }

        return symbols
            ?.filter((x) => x.symbolCategory === props.valueMatrixInputs.market)
            .filter((x) => !notAllowedCommodities.includes(x.commodity as DemeterCommodity))
            .sort((a, b) => `${a.subExchange ?? a.exchange}-${a.displayName}`.localeCompare(`${b.subExchange ?? b.exchange}-${b.displayName}`))
            .map((x) => ({
                label: formattingService.toDisplayName(x),
                value: x,
            }));
    }, [translations, symbols, props.valueMatrixInputs.market]);

    useEffect(() => {
        const currentProduct = productOptions.find((x) => x.value.commodity === props.valueMatrixInputs.product.commodity)?.value;

        if (productOptions.length === 0 || currentProduct) {
            return;
        }

        // Whenever the productOptions change (after re-evaluation from market changing), we will need to reset the product.
        props.setValueMatrixInputs({
            ...props.valueMatrixInputs,
            product: productOptions[0].value,
            updateTriggeredBy: 'productChange',
        });
        props.refreshGetValueMatrixCalculator();
    }, [productOptions]);

    return (
        <div className={styles.value_matrix_dropdown}>
            <Dropdown
                testId="ValueMatrixProductDropdown"
                value={
                    productOptions.find(
                        (x) => x.value.commodity === props.valueMatrixInputs.product.commodity && x.value.region === props.valueMatrixInputs.product.region,
                    )?.value
                }
                options={productOptions}
                handleOptionChange={(value) => {
                    props.refreshGetValueMatrixCalculator();
                    props.setValueMatrixInputs({
                        ...props.valueMatrixInputs,
                        product: value,
                        updateTriggeredBy: 'productChange',
                    });
                }}
                label={translations.words.product}
            />
        </div>
    );
};

export default ValueMatrixProductDropdown;
