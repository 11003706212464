import scssVariables from '../../../../Config.module.scss';
import {
    Correlation,
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterMarket,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionType,
    LeadingIndicatorType,
    MarketIndicatorDataCombinationType,
    MarketIndicatorFactorModel,
    MarketIndicatorOutlook,
    MarketIndicatorTemplateType,
    MarketPricesTimeSpan,
} from '../../../../Generated/Raven-Demeter';

export type ForwardCurveContractInputType = 'contractNumber' | 'contractMonths';

export type MarketIndicatorManualEntryLine = { Value?: string; AsOfDate: string; Outlook: string };

export type AverageSeasonalParameters = {
    yearsOfData: string;
};

export type CombinationParameters = {
    multipliers1: string;
    multipliers2: string;
    multipliers3: string;
    multipliers4: string;
    multipliers5: string;
    multipliers6: string;
    multipliers7: string;
    multipliers8: string;
    multipliers9: string;
    multipliers10: string;
    multipliers11: string;
    multipliers12: string;
};

export type DecileParameters = {
    yearsOfData: string;
    oneYearWeightedPercent: string;
};

export type ForecastParameters = {
    monthsForward: string;
};

export type ForwardCurveParameters = {
    monthsOfData: string;
    shortTermContractNumber: string;
    longTermContractNumber?: string;
    longTermContractMonth?: string;
};

export type HistoricalParameters = {
    yearsOfData: string;
    subFactorCalculationTypes: string;
    thresholdValue1?: string;
    thresholdValue2?: string;
    thresholdValue3?: string;
};

export type ManualEntryParameters = {
    outlook: MarketIndicatorOutlook;
    outlookHistory: string;
    outlookHistoryRaw: string;
    imageUrl: string;
};

export type SeasonalParameters = {
    yearsOfData: string;
    subFactorCalculationTypes: string;
    thresholdValue1?: string;
    thresholdValue2?: string;
    thresholdValue3?: string;
};

export type SlidingWindowSeasonalParameters = {
    monthsInverseCorrelation: string;
    monthsFlatOutlook: string;
    windowStartDay: string;
    windowEndDay: string;
    yearsOfData: string;
    imageUrl: string;
};

export type SpeculativeParameters = {
    yearsOfData: string;
};

export type TechnicalParameters = {
    daysToAverage1: string;
    daysToAverage2: string;
    daysToAverage3: string;
    shortTermDays1: string;
    shortTermDays2: string;
    shortTermDays3: string;
    longTermDays1: string;
    longTermDays2: string;
    longTermDays3: string;
};

export type ThresholdValueKey = 'thresholdValue1' | 'thresholdValue2' | 'thresholdValue3';

export type Parameters =
    | AverageSeasonalParameters
    | CombinationParameters
    | DecileParameters
    | ForecastParameters
    | ForwardCurveParameters
    | HistoricalParameters
    | ManualEntryParameters
    | SeasonalParameters
    | SlidingWindowSeasonalParameters
    | SpeculativeParameters
    | TechnicalParameters;

export type UpsertMarketIndicatorFactorRequest = {
    parentMarketIndicatorFactorGuid?: string;
    commodity?: DemeterCommodity;
    correlation: Correlation;
    dataFrequency: DemeterDataFrequency;
    displayGroupName: string;
    displayName: string;
    extraParameters?: string | null;
    fundamentalCategory: DemeterTableDefinitionType | null;
    leadingIndicatorType: LeadingIndicatorType;
    market: DemeterMarket;
    marketIndicatorFactorGuid: string;
    marketIndicatorGuid: string;
    order: number;
    parameters: Parameters;
    region: DemeterRegion;
    subRegion?: DemeterSubRegion | undefined;
    templateType: MarketIndicatorTemplateType;
    multiplier?: number;
    dataCombinationType?: MarketIndicatorDataCombinationType;
    secondaryMarket?: DemeterMarket;
    secondarySubRegion?: DemeterSubRegion | undefined;
    secondaryRegion?: DemeterRegion;
    secondaryFundamentalCategory?: DemeterTableDefinitionType | null;
    secondaryLeadingIndicatorType?: LeadingIndicatorType;
    secondaryExtraParameters?: string | null;
    secondaryDataFrequency?: DemeterDataFrequency;
    secondaryCommodity?: DemeterCommodity;
    subFactors?: UpsertMarketIndicatorFactorRequest[] | MarketIndicatorFactorModel[];
};

export type LeadingIndicatorTypeOptions = {
    label: string;
    value: LeadingIndicatorType;
    tableDefinitionType: DemeterTableDefinitionType;
};

// Dropdown options.
export const indicatorColorByOutlookDefinitions = [
    {
        marketIndicatorOutlook: MarketIndicatorOutlook.Unavailable,
        indicatorColor: scssVariables.unavailableColor,
        textColor: scssVariables.mainTextColor,
    },
    {
        marketIndicatorOutlook: MarketIndicatorOutlook.VeryBearish,
        indicatorColor: scssVariables.veryBearishColor,
        textColor: scssVariables.plainWhite,
    },
    {
        marketIndicatorOutlook: MarketIndicatorOutlook.Bearish,
        indicatorColor: scssVariables.bearishColor,
        textColor: scssVariables.mainTextColor,
    },
    {
        marketIndicatorOutlook: MarketIndicatorOutlook.Flat,
        indicatorColor: scssVariables.neutralColor,
        textColor: scssVariables.mainTextColor,
    },
    {
        marketIndicatorOutlook: MarketIndicatorOutlook.Bullish,
        indicatorColor: scssVariables.bullishColor,
        textColor: scssVariables.mainTextColor,
    },
    {
        marketIndicatorOutlook: MarketIndicatorOutlook.VeryBullish,
        indicatorColor: scssVariables.veryBullishColor,
        textColor: scssVariables.plainWhite,
    },
];

export const forecastOptions = [
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    },
    {
        label: '12',
        value: '12',
    },
];

export const marketIndicatorsManagementContractOptions = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
] as { label: string; value: number }[];

export const yearsOfDataOptions = [
    {
        label: '3',
        value: '3',
        marketPricesTimeSpan: MarketPricesTimeSpan.ThreeYears,
    },
    {
        label: '4',
        value: '4',
        marketPricesTimeSpan: MarketPricesTimeSpan.FourYears,
    },
    {
        label: '5',
        value: '5',
        marketPricesTimeSpan: MarketPricesTimeSpan.FiveYears,
    },
    {
        label: '10',
        value: '10',
        marketPricesTimeSpan: MarketPricesTimeSpan.TenYears,
    },
    {
        label: '15',
        value: '15',
        marketPricesTimeSpan: MarketPricesTimeSpan.FifteenYears,
    },
];

export const windowStartDayOptions = [
    {
        label: '0',
        value: '0',
    },
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    },
    {
        label: '12',
        value: '12',
    },
    {
        label: '13',
        value: '13',
    },
];

export const windowEndDayOptions = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    },
    {
        label: '12',
        value: '12',
    },
    {
        label: '13',
        value: '13',
    },
    {
        label: '14',
        value: '14',
    },
];

export const monthsOptions = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    },
    {
        label: '12',
        value: '12',
    },
];

export const shortTermContractNumberOptions = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
];

export const longTermContractNumberOptions = [
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    },
    {
        label: '12',
        value: '12',
    },
    {
        label: '13',
        value: '13',
    },
];

export const monthsOfDataOptions = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    },
    {
        label: '12',
        value: '12',
    },
    {
        label: '13',
        value: '13',
    },
];

export const leadingIndicatorTypeDefinitions = [
    {
        value: LeadingIndicatorType.CommodityMonthlyDomestics,
        tableDefinitionType: DemeterTableDefinitionType.CommodityMonthlyDomesticTable,
    },
    {
        value: LeadingIndicatorType.CommodityFuturesPrice,
        tableDefinitionType: DemeterTableDefinitionType.CommodityFuturesTable,
    },
    {
        value: LeadingIndicatorType.CommodityMonthlyExports,
        tableDefinitionType: DemeterTableDefinitionType.CommodityMonthlyExportsTable,
    },
    {
        value: LeadingIndicatorType.CommodityMonthlyImports,
        tableDefinitionType: DemeterTableDefinitionType.CommodityMonthlyImportsTable,
    },
    {
        value: LeadingIndicatorType.CommodityFuturesIndex,
        tableDefinitionType: DemeterTableDefinitionType.CommodityFuturesIndexTable,
    },
    {
        value: LeadingIndicatorType.CommodityMonthlyPrices,
        tableDefinitionType: DemeterTableDefinitionType.CommodityPricesTable,
    },
    {
        value: LeadingIndicatorType.CommodityMonthlyProduction,
        tableDefinitionType: DemeterTableDefinitionType.CommodityMonthlyProductionTable,
    },
    {
        value: LeadingIndicatorType.CommodityProgress,
        tableDefinitionType: DemeterTableDefinitionType.CommodityProgressTable,
    },
    {
        value: LeadingIndicatorType.CommodityMonthlyStocks,
        tableDefinitionType: DemeterTableDefinitionType.CommodityMonthlyStockTable,
    },
    {
        value: LeadingIndicatorType.CommodityDailyOther,
        tableDefinitionType: DemeterTableDefinitionType.CommodityOtherTable,
    },
];
