import React, { useMemo } from 'react';
import { createSearchParams } from 'react-router-dom';
import scssVariables from '../../../Config.module.scss';
import { MarketIndicatorModel, MarketIndicatorOutlook } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import BrandCard from '../../Components/BrandCard/BrandCard';
import GuageChartRaw from '../../Components/Charts/Guage/GuageChartRaw';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import formattingService from '../../Services/Formatting/FormattingService';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './MarketIndicatorFactorsPage.module.scss';

export interface IMarketIndicatorGuageChartProps {
    title?: string;
    marketIndicator: MarketIndicatorModel;
    chartDimensions?: { chartSize?: number; dialRadius?: number };
    isSmallGuage?: boolean;
}

const MarketIndicatorGuageChart: React.FC<IMarketIndicatorGuageChartProps> = (props: IMarketIndicatorGuageChartProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const isLoading = !props.marketIndicator;

    // Chart hooks.
    const guageSeries = useMemo(
        () => ({
            label: translations.calculators.fields.spread,
            data: {
                asOfDate: props.marketIndicator.asOfDate,
                outlookDecile: props.marketIndicator?.outlookDecile ? 100 - props.marketIndicator.outlookDecile : 0,
            },
        }),
        [props.marketIndicator],
    );

    const brandingTitleString = useMemo(() => translations.marketIndicators.brandingTitleString, [translations]);
    const headerWrapperStyle = props.isSmallGuage ? styles.guage_heading_wrapper_small : styles.guage_heading_wrapper;
    const guageValueStyle = props.isSmallGuage ? styles.guage_value_small : styles.guage_value;
    const guageChartDataHighlightsStyle = props.isSmallGuage ? styles.guage_data_highlights_small : styles.guage_data_highlights;
    const numberOfPercentDecimalPlaces = props.isSmallGuage ? 0 : undefined;

    return isLoading ? (
        <PageLoadingSpinner />
    ) : (
        <div className={props.isSmallGuage ? styles.guage_card_small : styles.guage_card}>
            <BrandCard
                brandingTitleString={brandingTitleString}
                title={props.title}
                navigateTo={{
                    pathName: NavigationRoutes.MarketIndicators,
                    search: createSearchParams({ marketIndicatorGuid: props.marketIndicator!.marketIndicatorGuid }).toString(),
                }}
                useNavigationCard={props.isSmallGuage}
                testId="MarketIndicatorGuageBrandCard"
            >
                <>
                    <GuageChartRaw guageSeries={guageSeries} chartDimensions={props.chartDimensions} />
                    <div className={guageChartDataHighlightsStyle}>
                        <div className={!props.isSmallGuage ? styles.guage : styles.guage_small}>
                            <div style={{ color: scssVariables.veryBearishColor }}>
                                {translations.marketIndicatorOutlook[MarketIndicatorOutlook.VeryBearish]}
                            </div>
                            {!props.isSmallGuage && (
                                <div style={{ color: scssVariables.bearishColor }}>{translations.marketIndicatorOutlook[MarketIndicatorOutlook.Bearish]}</div>
                            )}
                            <div style={{ color: scssVariables.alternativeNeutralTextColor }}>
                                {translations.marketIndicatorOutlook[MarketIndicatorOutlook.Flat]}
                            </div>
                            {!props.isSmallGuage && (
                                <div style={{ color: scssVariables.bullishColor }}>{translations.marketIndicatorOutlook[MarketIndicatorOutlook.Bullish]}</div>
                            )}
                            <div style={{ color: scssVariables.veryBullishColor }}>
                                {translations.marketIndicatorOutlook[MarketIndicatorOutlook.VeryBullish]}
                            </div>
                        </div>
                        <div className={styles.guage_table}>
                            <div className={props.isSmallGuage ? styles.guage_table_bar_small : styles.guage_table_bar}>
                                <div className={headerWrapperStyle}>{formattingService.toShortDayMonthYear(props.marketIndicator.asOfDate)}</div>
                                <div className={headerWrapperStyle}>{`% ${translations.text.monthOverMonth}`}</div>
                                <div className={headerWrapperStyle}>{`% ${translations.words.shortYearOverYear}`}</div>
                            </div>
                            <div className={props.isSmallGuage ? styles.guage_table_bar_small : styles.guage_table_bar}>
                                <div className={guageValueStyle}>{`${props.marketIndicator.currentPrice}`}</div>
                                <div className={guageValueStyle}>
                                    <div
                                        className={(props.marketIndicator.monthOverMonthChangePercent ?? 0) < 0 ? styles.guage_negative : styles.guage_positive}
                                    >
                                        <div className={props.isSmallGuage ? styles.guage_month_over_month_small : styles.guage_month_over_month}>
                                            {`${formattingService.toPercentWithoutPlaceholder(
                                                props.marketIndicator.monthOverMonthChangePercent ?? 0,
                                                numberOfPercentDecimalPlaces,
                                                numberOfPercentDecimalPlaces,
                                            )}`}
                                        </div>
                                    </div>
                                </div>
                                <div className={guageValueStyle}>
                                    <div className={(props.marketIndicator.rollingYearChangePercent ?? 0) < 0 ? styles.guage_negative : styles.guage_positive}>
                                        {`${formattingService.toPercentWithoutPlaceholder(
                                            props.marketIndicator.rollingYearChangePercent ?? 0,
                                            numberOfPercentDecimalPlaces,
                                            numberOfPercentDecimalPlaces,
                                        )}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </BrandCard>
        </div>
    );
};

export default MarketIndicatorGuageChart;
