import { useEffect, useMemo, useState } from 'react';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import {
    DemeterCommodity,
    DemeterMarket,
    DemeterRegion,
    DemeterTableDefinitionType,
    DemeterUserStoreType,
    ExchangeType,
    SymbolCategory,
} from '../../../Generated/Raven-Demeter/api';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../Redux/Slices/UserSlice';
import useSymbolsApi from '../../Apis/Hooks/useSymbolsApiHook';
import useTableDefinitionsApi from '../../Apis/Hooks/useTableDefinitionsApiHook';
import useUserStoreApi from '../../Apis/Hooks/useUserStoreApiHook';
import ManageFuturesModal, { CommodityReferenceKey } from '../../Components/Modals/ProductsModal/ManageFuturesModal';
import useExchangeCommodityNavigation from '../../Components/Navigation/Hooks/useExchangeCommodityNavigationHook';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import type { FuturesTabType } from './FuturesPage';
import styles from './FuturesPage.module.scss';

const defaultSymbolCategory = SymbolCategory.Dairy;

interface CommodityModel {
    exchange: ExchangeType;
    region: DemeterRegion;
    commodity: DemeterCommodity;
    symbolCategory: SymbolCategory;
    displayName: string;
    reutersInstrumentCodePrefix?: string;
}

const FuturesExchangeCommodityMenu: React.FC = () => {
    const [, setSearchParameters] = useSearchParameters();
    const [translations, translate] = useLanguage();
    const market = useApplicationSelector(selectUserCurrentMarket);
    const [productsModalOpen, setProductsModalOpen] = useState(false);

    // Exchange/Commodity hooks.
    const tableDefinitionType = DemeterTableDefinitionType.CommodityOtcPricesTable;
    const futuresTabs =
        market === DemeterMarket.Energy
            ? applicationConstants.AvailableEnergyExchanges
            : ([...applicationConstants.AvailableExchanges.filter((x) => x !== ExchangeType.Dce), 'forwardSpreadCalculatorExchange'] as FuturesTabType[]);
    const symbols = useSymbolsApi();
    const tableDefinitions = useTableDefinitionsApi(tableDefinitionType);
    const [exchangeCommoditySelection] = useExchangeCommodityNavigation();
    const [userStoreValue, updateUserStore] = useUserStoreApi(DemeterUserStoreType.CommodityFuturesChart);

    const isFuturesPage = (tab?: FuturesTabType): boolean => {
        const selectedExchange = tab ?? (exchangeCommoditySelection.exchange as FuturesTabType | undefined);

        if (selectedExchange === 'forwardSpreadCalculatorExchange' || selectedExchange === ExchangeType.Otc || !selectedExchange) {
            return false;
        }

        return true;
    };

    const getCommodityModels = (tab?: FuturesTabType): CommodityModel[] => {
        let commodities: CommodityModel[] = [];
        const selectedExchange = tab ?? (exchangeCommoditySelection.exchange as FuturesTabType | undefined);

        if (selectedExchange === 'forwardSpreadCalculatorExchange') {
            commodities = [];
        } else if (selectedExchange !== ExchangeType.Otc) {
            commodities = symbols?.filter((x) => x.exchange === selectedExchange) ?? [];
        } else if (!tableDefinitions || tableDefinitions.length === 0) {
            commodities = [];
        } else {
            // Convert Otc selections.
            const regionDefinition = tableDefinitions[0];

            return tableDefinitions[0].demeterTableDefinitionGroups.map((x) => ({
                exchange: ExchangeType.Otc,
                commodity: x.commodity!,
                region: regionDefinition.region!,
                symbolCategory: defaultSymbolCategory,
                displayName: x.displayName ?? '',
            }));
        }

        if (userStoreValue?.userStore?.value && isFuturesPage(tab) && symbols) {
            const userStoreValueForExchange = userStoreValue?.userStore?.value[selectedExchange!.toLowerCase()]?.commodities;

            if (userStoreValueForExchange) {
                return userStoreValueForExchange
                    .map((x: CommodityReferenceKey) => symbols.find((symbol) => symbol.reutersInstrumentCodePrefix === x.guid))
                    .filter((x: CommodityModel | undefined) => !!x);
            }
        }

        const filteredCommodities = commodities.filter((x) => x.symbolCategory === defaultSymbolCategory);
        return filteredCommodities.length > 0 ? filteredCommodities : commodities;
    };

    // Select the first commodity we can based on defaults or stored preferences.
    useEffect(() => {
        if (!symbols || !tableDefinitions || !userStoreValue) {
            return;
        }

        const newSearchParameters = { ...exchangeCommoditySelection };
        let hasNewSearchParameters = false;
        if (!exchangeCommoditySelection.exchange) {
            // eslint-disable-next-line prefer-destructuring
            newSearchParameters.exchange = futuresTabs[0];
            hasNewSearchParameters = true;
        }

        const commodities = getCommodityModels(newSearchParameters.exchange as ExchangeType);
        if (commodities.length > 0 && !commodities.some((x) => x.commodity === exchangeCommoditySelection.commodity)) {
            newSearchParameters.commodity = commodities[0].commodity;
            hasNewSearchParameters = true;
        }

        if (hasNewSearchParameters) {
            setSearchParameters({ exchange: newSearchParameters.exchange, commodity: newSearchParameters.commodity });
        }
    }, [symbols, tableDefinitions, userStoreValue]);

    const selectedCommodityModels = useMemo(() => getCommodityModels(), [symbols, exchangeCommoditySelection, userStoreValue?.userStore?.value]);

    // Methods for the Manage Modal.
    const selectedCommodityOptions = useMemo<CommodityReferenceKey[]>(
        () => selectedCommodityModels?.map((x) => ({ guid: x.reutersInstrumentCodePrefix ?? '', commodity: x.commodity, displayName: x.displayName })) ?? [],
        [selectedCommodityModels],
    );

    const handleProductsSelected = (selectedOptions: CommodityReferenceKey[]) => {
        updateUserStore({ ...userStoreValue?.userStore?.value, ...{ [exchangeCommoditySelection.exchange.toLowerCase()]: { commodities: selectedOptions } } });
    };

    if (!symbols || !tableDefinitions || !exchangeCommoditySelection.exchange) {
        return null;
    }

    return (
        <>
            <div className={`${styles.master_flex} ${styles.file_selector_button_width}`}>
                {futuresTabs.map((tab) => (
                    <button
                        type="button"
                        onClick={() => {
                            const commodities = getCommodityModels(tab);
                            setSearchParameters({ exchange: tab, commodity: commodities[0] && commodities[0].commodity });
                        }}
                        className={
                            tab === exchangeCommoditySelection.exchange ? styles.file_selector_exchanges_button_selected : styles.file_selector_exchanges_button
                        }
                        key={tab}
                    >
                        {translate('exchange', tab)}
                    </button>
                ))}
            </div>
            <div className={styles.file_selector_exchange_butttons}>
                <div className={styles.file_selector_focus_area}>
                    {selectedCommodityModels.length > 0 &&
                        selectedCommodityModels.map((commodity) => (
                            <button
                                key={commodity?.displayName}
                                type="button"
                                onClick={() => setSearchParameters({ exchange: exchangeCommoditySelection.exchange, commodity: commodity.commodity })}
                                className={
                                    commodity.commodity === exchangeCommoditySelection.commodity
                                        ? `${styles.file_selector_commodity_button_selected} ${styles.file_selector_commodity_button}`
                                        : styles.file_selector_commodity_button
                                }
                            >
                                {translate(commodity?.displayName)}
                            </button>
                        ))}
                    {selectedCommodityModels.length === 0 && <div className={styles.file_selector_empty}>&nbsp;</div>}
                </div>
                {isFuturesPage() && (
                    <div>
                        <div>
                            <button type="button" onClick={() => setProductsModalOpen(true)} className={styles.file_selector_manage_modal}>
                                {`${translations.dashboard.indicators.manage} ${translations.words.products}`}
                            </button>
                        </div>
                    </div>
                )}
                <ManageFuturesModal
                    title={`${translations.dashboard.indicators.manage} ${translations.words.products}`}
                    selected={selectedCommodityOptions}
                    open={productsModalOpen}
                    exchange={exchangeCommoditySelection.exchange as ExchangeType}
                    handleClose={() => setProductsModalOpen(false)}
                    handleChange={handleProductsSelected}
                />
            </div>
        </>
    );
};
export default FuturesExchangeCommodityMenu;
