import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './SessionPage.module.scss';
import TermsAndConditions from './TermsAndConditions';

export interface ICompanyRegistrationAgreementProps {
    handleCancel: () => void;
    handleAccept: () => void;
}

const CompanyRegistrationAgreement: React.FC<ICompanyRegistrationAgreementProps> = (props: ICompanyRegistrationAgreementProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.company_registration_agreement}>
            <div className={styles.company_registration_agreement_content}>
                <TermsAndConditions />
            </div>
            <div className={styles.company_registration_agreement_buttons}>
                <LinkButton title={translations.actions.cancel} type={LinkButtonType.White} onClick={props.handleCancel} />
                <LinkButton title={translations.actions.accept} type={LinkButtonType.Blue} onClick={props.handleAccept} />
            </div>
        </div>
    );
};

export default CompanyRegistrationAgreement;
