import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import {
    AddMarketIndicatorFactorRequest,
    DemeterDataFrequency,
    DemeterRegion,
    GetMarketIndicatorFactorResponse,
    LeadingIndicatorType,
    MarketIndicatorTemplateType,
    UpdateMarketIndicatorFactorRequest,
} from '../../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../../../../Redux/ReduxStore';
import { selectUserMarkets } from '../../../../../Redux/Slices/UserSlice';
import useApi from '../../../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import useSymbolsApi from '../../../../Apis/Hooks/useSymbolsApiHook';
import useTableDefinitionsApi from '../../../../Apis/Hooks/useTableDefinitionsApiHook';
import TextInput from '../../../../Components/Form/Inputs/TextInput';
import ComponentHeader from '../../../../Components/Headers/ComponentHeader';
import PageLoadingSpinner from '../../../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../../../Components/Navigation/Hooks/useSearchParametersHook';
import RegexValidators from '../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import {
    AverageSeasonalParameters,
    CombinationParameters,
    DecileParameters,
    ForecastParameters,
    ForwardCurveContractInputType,
    ForwardCurveParameters,
    HistoricalParameters,
    leadingIndicatorTypeDefinitions,
    ManualEntryParameters,
    Parameters,
    SeasonalParameters,
    SlidingWindowSeasonalParameters,
    TechnicalParameters,
    UpsertMarketIndicatorFactorRequest,
} from '../MarketIndicatorsManagementDefinitions';
import MarketIndicatorFactorCharts from './MarketIndicatorFactorCharts';
import MarketIndicatorFactorDataSourceArea from './MarketIndicatorFactorDataSourceArea';
import styles from './MarketIndicatorFactorPage.module.scss';
import {
    defaultCorrelation,
    defaultDaysToAverage1,
    defaultDaysToAverage2,
    defaultDaysToAverage3,
    defaultHistoricalSubFactorCalculationTypes,
    defaultLeadingIndicatorType,
    defaultLongTermContractNumber,
    defaultLongTermDays1,
    defaultLongTermDays2,
    defaultLongTermDays3,
    defaultMonthsFlatOutlook,
    defaultMonthsForward,
    defaultMonthsInverseCorrelation,
    defaultMonthsOfData,
    defaultMultipliers1,
    defaultMultipliers10,
    defaultMultipliers11,
    defaultMultipliers12,
    defaultMultipliers2,
    defaultMultipliers3,
    defaultMultipliers4,
    defaultMultipliers5,
    defaultMultipliers6,
    defaultMultipliers7,
    defaultMultipliers8,
    defaultMultipliers9,
    defaultNumberOfDataSourceSections,
    defaultOneYearWeightedPercent,
    defaultOutlook,
    defaultPeriodocity,
    defaultSeasonalSubFactorCalculationTypes,
    defaultShortTermContractMonth,
    defaultShortTermDays1,
    defaultShortTermDays2,
    defaultShortTermDays3,
    defaultTableDefinition,
    defaultTemplateType,
    defaultWindowEndDay,
    defaultWindowStartDay,
    defaultYearsOfData,
    subFactorOrderBaseline,
} from './MarketIndicatorFactorPageDefault';
import MarketIndicatorFactorTemplateArea from './MarketIndicatorFactorTemplateArea';

interface IMarketIndicatorFactorSelectionPageProps {
    parentUpsertMarketIndicatorFactorRequest?: UpsertMarketIndicatorFactorRequest;
    setParentUpsertMarketIndicatorFactorRequest: (upsertRequest: UpsertMarketIndicatorFactorRequest) => void;
    getMarketIndicatorParentFactorResponse?: GetMarketIndicatorFactorResponse;
    refreshGetMarketIndicatorParentFactorResponse?: () => void;
    multiplierColumns: JSX.Element[] | null;
}

const MarketIndicatorFactorSelectionPage: React.FC<IMarketIndicatorFactorSelectionPageProps> = (props: IMarketIndicatorFactorSelectionPageProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const symbols = useSymbolsApi();
    const markets = useApplicationSelector(selectUserMarkets);
    const navigate = useNavigate();
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const { marketIndicatorGuid, marketIndicatorFactorGroupName } = useParams();
    const marketIndicatorFactorGuidFromRoute = useParams().marketIndicatorFactorGuid;
    const [numberOfDataSourceSections, setNumberOfDataSourceSections] = useState(defaultNumberOfDataSourceSections);

    // Form requests and page hooks.
    const [upsertMarketIndicatorFactorRequest, setUpsertMarketIndicatorFactorRequest] = useState<UpsertMarketIndicatorFactorRequest>();

    // Display hooks.
    const [forwardCurveContractInput, setForwardCurveContractInput] = useState<ForwardCurveContractInputType>('contractNumber');
    const [navigateAfterApiSuccess, setNavigateAfterApiSuccess] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Api hooks.
    const tableDefinitions = useTableDefinitionsApi(
        upsertMarketIndicatorFactorRequest?.fundamentalCategory ?? defaultTableDefinition,
        true,
        upsertMarketIndicatorFactorRequest?.market,
    );

    const [, , getMarketIndicatorResponse] = useApi(() => demeterMarketIndicatorsApi.getMarketIndicator(marketIndicatorGuid!));

    const [getMarketIndicatorFactorLoading, refreshGetMarketIndicatorFactorResponse, getMarketIndicatorFactorResponse] = useApiWithoutAutoExecute(() => {
        if (isCombinationParent) {
            return null;
        }

        return demeterMarketIndicatorsApi.getMarketIndicatorFactor(
            marketIndicatorGuid!,
            isSubFactorEdit ? searchParameters.tab : marketIndicatorFactorGuidFromRoute ?? addMarketIndicatorResponse?.marketIndicatorFactorGuid!,
        );
    });

    const [updateMarketIndicatorsLoading, handleUpdateMarketIndicatorFactor, updateMarketIndicatorsResponse] = useApiWithoutAutoExecute(
        (marketIndicatorFactorRequest?: UpdateMarketIndicatorFactorRequest) =>
            demeterMarketIndicatorsApi.updateMarketIndicatorFactor(
                marketIndicatorGuid ?? '',
                marketIndicatorFactorRequest?.marketIndicatorFactorGuid ?? '',
                marketIndicatorFactorRequest,
            ),
        {
            successMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorUpdateSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorUpdateFailed,
        },
    );

    const [addMarketIndicatorLoading, handleAddMarketIndicatorFactor, addMarketIndicatorResponse] = useApiWithoutAutoExecute(
        (marketIndicatorFactorRequest?: AddMarketIndicatorFactorRequest) =>
            demeterMarketIndicatorsApi.addMarketIndicatorFactor(marketIndicatorGuid ?? '', marketIndicatorFactorRequest),
        {
            successMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorAddSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorAddFailed,
        },
    );

    // Combination template constants.
    const isCombinationParentNew =
        upsertMarketIndicatorFactorRequest?.templateType === MarketIndicatorTemplateType.Combination &&
        !props.parentUpsertMarketIndicatorFactorRequest?.marketIndicatorFactorGuid;
    const isCombinationParentEdit = searchParameters.tab === 'parent' && !isCombinationParentNew;
    const isCombinationParent = isCombinationParentNew || isCombinationParentEdit;
    const currentMarketIndicatorSubfactors = props.getMarketIndicatorParentFactorResponse?.marketIndicatorFactor?.subFactors;
    const currentSubFactor = currentMarketIndicatorSubfactors?.find((x) => x.marketIndicatorFactorGuid === searchParameters.tab);
    const isSubFactorNew = props.parentUpsertMarketIndicatorFactorRequest && searchParameters.tab.includes('child');
    const isSubFactorEdit = props.parentUpsertMarketIndicatorFactorRequest && !isSubFactorNew && !isCombinationParent;
    const isSubFactor = isSubFactorNew || isSubFactorEdit;
    const showDataSourceSections = upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.ManualEntry && !isCombinationParent;

    const leadingIndicatorTypeOptions = useMemo(
        () =>
            leadingIndicatorTypeDefinitions.map((x) => ({
                ...x,
                label: translations.tableDefinitionType[x.tableDefinitionType],
            })),
        [tableDefinitions, translations],
    );

    // Fires after updates are complete from hitting the 'save' or 'save and return' button.
    useEffect(() => {
        const navigationIsNotReady =
            !navigateAfterApiSuccess && !addMarketIndicatorResponse && !upsertMarketIndicatorFactorRequest?.parentMarketIndicatorFactorGuid;
        const resourcesLoading = addMarketIndicatorLoading || updateMarketIndicatorsLoading;
        const subFactorNotYetAdded = isSubFactorNew && !addMarketIndicatorResponse?.marketIndicatorFactorGuid;

        if (navigationIsNotReady || resourcesLoading || subFactorNotYetAdded) {
            return;
        }

        const navigationString = `${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicatorGuid}`;
        const editFactorNavigationString = `${navigationString}/factorGroups/${upsertMarketIndicatorFactorRequest!.displayGroupName}/factors/${
            upsertMarketIndicatorFactorRequest?.parentMarketIndicatorFactorGuid ?? addMarketIndicatorResponse?.marketIndicatorFactorGuid
        }/edit`;

        if (navigateAfterApiSuccess) {
            // Change this back to navigate(-1) after getting out of mono.
            navigate(`${navigationString}/edit`);
            return;
        }

        let navigationTab = '';

        if (isCombinationParent) {
            navigationTab = 'parent';
        }

        if (isSubFactorNew) {
            navigationTab = addMarketIndicatorResponse?.marketIndicatorFactorGuid!;
        }

        if (isSubFactorEdit) {
            navigationTab = upsertMarketIndicatorFactorRequest?.marketIndicatorFactorGuid!;
        }

        if (isSubFactorNew) {
            setSearchParameters({ tab: navigationTab! });
            return;
        }

        if (addMarketIndicatorResponse?.marketIndicatorFactorGuid && !isSubFactorEdit) {
            setUpsertMarketIndicatorFactorRequest({
                ...upsertMarketIndicatorFactorRequest!,
                marketIndicatorFactorGuid: addMarketIndicatorResponse?.marketIndicatorFactorGuid ?? '',
            });
        }

        if (addMarketIndicatorResponse?.marketIndicatorFactorGuid && isCombinationParentNew) {
            props.setParentUpsertMarketIndicatorFactorRequest({
                ...upsertMarketIndicatorFactorRequest!,
                marketIndicatorFactorGuid: addMarketIndicatorResponse?.marketIndicatorFactorGuid ?? '',
            });
        }

        if (navigationTab) {
            navigate(`${editFactorNavigationString}?tab=${navigationTab}`);
            return;
        }

        navigate(editFactorNavigationString);
    }, [props.getMarketIndicatorParentFactorResponse, updateMarketIndicatorsResponse, addMarketIndicatorResponse, navigateAfterApiSuccess]);

    // After adding/updating a subfactor or non-combination factor.
    useEffect(() => {
        const requestSavingIncomplete = !marketIndicatorFactorGuidFromRoute && !addMarketIndicatorResponse?.marketIndicatorFactorGuid;
        const apisLoading = getMarketIndicatorFactorLoading || addMarketIndicatorLoading || updateMarketIndicatorsLoading;

        if (requestSavingIncomplete || apisLoading) {
            return;
        }

        refreshGetMarketIndicatorFactorResponse();

        if (props.refreshGetMarketIndicatorParentFactorResponse) {
            props.refreshGetMarketIndicatorParentFactorResponse();
        }

        setIsSaving(false);
    }, [addMarketIndicatorResponse, updateMarketIndicatorsResponse]);

    // Basically, run the on page load once we have everything needed to run.
    useEffect(() => {
        const factorResponseNotLoaded = !getMarketIndicatorFactorResponse && (isSubFactorEdit || marketIndicatorFactorGuidFromRoute);
        const apisAreNotReady = !symbols || !getMarketIndicatorResponse || !tableDefinitions || factorResponseNotLoaded;

        if (apisAreNotReady || upsertMarketIndicatorFactorRequest) {
            return;
        }

        const marketIndicatorFactor = isSubFactorNew ? undefined : currentSubFactor ?? getMarketIndicatorFactorResponse?.marketIndicatorFactor;
        const initialUpsertMarketIndicatorFactorRequest = getResetForUpsertRequest();

        if (marketIndicatorFactor?.secondaryCommodity) {
            setNumberOfDataSourceSections(2);
        }

        // Only set the parent with the initial request when on the parent.
        if (!props.parentUpsertMarketIndicatorFactorRequest && marketIndicatorFactor?.templateType === MarketIndicatorTemplateType.Combination) {
            props.setParentUpsertMarketIndicatorFactorRequest(initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest);
        }

        if (!marketIndicatorFactor) {
            updateFactorsByTemplateType(defaultTemplateType, initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest);
        } else {
            setUpsertMarketIndicatorFactorRequest(initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest);
        }
    }, [marketIndicatorFactorGuidFromRoute, getMarketIndicatorResponse, getMarketIndicatorFactorResponse, searchParameters.tab, tableDefinitions, symbols]);

    // When swithing between tabs on the combination template.
    useEffect(() => {
        if (isSubFactorEdit && !getMarketIndicatorFactorResponse) {
            refreshGetMarketIndicatorFactorResponse();
            return;
        }

        if (isCombinationParent || getMarketIndicatorFactorLoading || !searchParameters.tab || !upsertMarketIndicatorFactorRequest?.parameters) {
            return;
        }

        let marketIndicatorFactor = isSubFactorNew ? undefined : currentSubFactor ?? getMarketIndicatorFactorResponse?.marketIndicatorFactor;

        if (isCombinationParentEdit) {
            marketIndicatorFactor = props.getMarketIndicatorParentFactorResponse?.marketIndicatorFactor;
        }

        const resetUpsertRequest = getResetForUpsertRequest();

        let initialUpsertMarketIndicatorFactorRequest = { ...resetUpsertRequest, ...marketIndicatorFactor } as UpsertMarketIndicatorFactorRequest;

        if (upsertMarketIndicatorFactorRequest.marketIndicatorFactorGuid === searchParameters.tab) {
            initialUpsertMarketIndicatorFactorRequest = {
                ...resetUpsertRequest,
                ...marketIndicatorFactor,
                ...upsertMarketIndicatorFactorRequest,
            } as UpsertMarketIndicatorFactorRequest;
        }

        if ((marketIndicatorFactor?.parameters as ForwardCurveParameters)?.longTermContractMonth) {
            setForwardCurveContractInput('contractMonths');
        }

        if ((marketIndicatorFactor?.parameters as ForwardCurveParameters)?.longTermContractNumber) {
            setForwardCurveContractInput('contractNumber');
        }

        if (initialUpsertMarketIndicatorFactorRequest?.secondaryCommodity) {
            setNumberOfDataSourceSections(2);
        } else {
            setNumberOfDataSourceSections(1);
        }

        if (isSubFactor) {
            initialUpsertMarketIndicatorFactorRequest.marketIndicatorFactorGuid = searchParameters.tab ?? '';
            initialUpsertMarketIndicatorFactorRequest.multiplier = undefined;
            initialUpsertMarketIndicatorFactorRequest.parentMarketIndicatorFactorGuid =
                props.parentUpsertMarketIndicatorFactorRequest?.marketIndicatorFactorGuid;
        }

        if (isSubFactorNew) {
            initialUpsertMarketIndicatorFactorRequest = resetUpsertRequest;
            initialUpsertMarketIndicatorFactorRequest.marketIndicatorFactorGuid = '';
            initialUpsertMarketIndicatorFactorRequest.displayName = '';
            initialUpsertMarketIndicatorFactorRequest.templateType = defaultTemplateType;
            initialUpsertMarketIndicatorFactorRequest.order = resetUpsertRequest.order;

            updateFactorsByTemplateType(
                initialUpsertMarketIndicatorFactorRequest.templateType,
                initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest,
            );
        } else if (isCombinationParent) {
            initialUpsertMarketIndicatorFactorRequest = {
                ...resetUpsertRequest,
                ...marketIndicatorFactor,
            } as UpsertMarketIndicatorFactorRequest;

            props.setParentUpsertMarketIndicatorFactorRequest({
                ...(initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest),
                parameters: props.parentUpsertMarketIndicatorFactorRequest?.parameters ?? initialUpsertMarketIndicatorFactorRequest.parameters,
            });
        }

        if (!isSubFactorNew) {
            setUpsertMarketIndicatorFactorRequest(initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest);
        }
    }, [searchParameters.tab, getMarketIndicatorFactorResponse]);

    // When we update multipliers on the parent, we need to make sure to update the upsert request on the page.
    useEffect(() => {
        if (!isCombinationParent) {
            return;
        }

        setUpsertMarketIndicatorFactorRequest(props.parentUpsertMarketIndicatorFactorRequest!);
    }, [props.parentUpsertMarketIndicatorFactorRequest?.parameters]);

    // Functions.
    const getResetForUpsertRequest = () => {
        const marketIndicatorFactor = isSubFactorNew ? undefined : currentSubFactor ?? getMarketIndicatorFactorResponse?.marketIndicatorFactor;
        const leadingIndicatorType = marketIndicatorFactor?.leadingIndicatorType ?? defaultLeadingIndicatorType;
        const secondaryLeadingIndicatorType = marketIndicatorFactor?.secondaryLeadingIndicatorType ?? defaultLeadingIndicatorType;
        const extraParameters =
            isSubFactorNew && leadingIndicatorType === LeadingIndicatorType.CommodityFuturesPrice ? '1' : marketIndicatorFactor?.extraParameters;

        // For subfactors, we will add 100 to the starting point, then will count from there. Their order lives outside
        // of the natural order.
        let currentOrder = +(marketIndicatorFactor?.order ?? searchParameters.order ?? upsertMarketIndicatorFactorRequest?.order ?? 0);
        const currentMaximum = currentMarketIndicatorSubfactors?.length ? Math.max(...currentMarketIndicatorSubfactors.map((x) => x.order)) : currentOrder;
        const newSubfactorOrderIsSet = getMarketIndicatorFactorResponse?.marketIndicatorFactor?.order === currentMaximum;

        if (currentMarketIndicatorSubfactors?.length === 0 && newSubfactorOrderIsSet && isSubFactorNew) {
            currentOrder += subFactorOrderBaseline + 1;
        } else if (isSubFactorNew) {
            const currentMaximumOrder = currentMaximum;
            currentOrder = currentMaximumOrder + 1;
        }

        let marketIndicatorFactorGuid = marketIndicatorFactorGuidFromRoute ?? '';

        if (isSubFactorEdit) {
            marketIndicatorFactorGuid = searchParameters.tab;
        }

        // Either use the api responses (when editing) or set defaults.
        let initialUpsertMarketIndicatorFactorRequest = {
            commodity: marketIndicatorFactor?.commodity ?? getMarketIndicatorResponse?.marketIndicator?.commodity,
            correlation: marketIndicatorFactor?.correlation ?? defaultCorrelation,
            dataFrequency:
                !marketIndicatorFactor?.dataFrequency || marketIndicatorFactor?.leadingIndicatorType === LeadingIndicatorType.CommodityFuturesPrice
                    ? defaultPeriodocity
                    : (marketIndicatorFactor?.dataFrequency as DemeterDataFrequency),
            displayGroupName: marketIndicatorFactorGroupName ?? marketIndicatorFactor?.displayGroupName!,
            displayName: marketIndicatorFactor?.displayName ?? '',
            extraParameters,
            fundamentalCategory: leadingIndicatorTypeOptions.find((x) => x.value === leadingIndicatorType)?.tableDefinitionType!,
            leadingIndicatorType,
            market:
                marketIndicatorFactor?.market ??
                symbols?.find(
                    (x) =>
                        x.commodity === getMarketIndicatorResponse?.marketIndicator?.commodity &&
                        x.region === getMarketIndicatorResponse?.marketIndicator.region,
                )?.symbolCategory ??
                markets[0],
            marketIndicatorFactorGuid,
            marketIndicatorGuid: marketIndicatorGuid ?? '',
            order: currentOrder,
            parameters: marketIndicatorFactor?.parameters ?? {},
            parentMarketIndicatorFactorGuid: !isCombinationParent ? props.parentUpsertMarketIndicatorFactorRequest?.marketIndicatorFactorGuid : undefined,
            subRegion: marketIndicatorFactor?.subRegion,
            region: (marketIndicatorFactor?.region as DemeterRegion) ?? getMarketIndicatorResponse?.marketIndicator?.region,
            templateType: marketIndicatorFactor?.templateType ?? defaultTemplateType,
            multiplier: marketIndicatorFactor?.multiplier,
        } as UpsertMarketIndicatorFactorRequest;

        if (marketIndicatorFactor?.secondaryMarket) {
            initialUpsertMarketIndicatorFactorRequest = {
                ...initialUpsertMarketIndicatorFactorRequest,
                dataCombinationType: marketIndicatorFactor?.dataCombinationType,
                secondaryMarket:
                    marketIndicatorFactor?.secondaryMarket ??
                    symbols?.find(
                        (x) =>
                            x.commodity === getMarketIndicatorResponse?.marketIndicator?.commodity &&
                            x.region === getMarketIndicatorResponse?.marketIndicator.region,
                    )?.symbolCategory ??
                    markets[0],
                secondaryExtraParameters: marketIndicatorFactor?.secondaryExtraParameters,
                secondaryRegion: marketIndicatorFactor?.secondaryRegion,
                secondarySubRegion: marketIndicatorFactor?.secondarySubRegion,
                secondaryCommodity: marketIndicatorFactor?.secondaryCommodity,
                secondaryDataFrequency:
                    !marketIndicatorFactor?.secondaryDataFrequency ||
                    marketIndicatorFactor?.secondaryLeadingIndicatorType === LeadingIndicatorType.CommodityFuturesPrice
                        ? defaultPeriodocity
                        : (marketIndicatorFactor?.secondaryDataFrequency as DemeterDataFrequency),
                secondaryFundamentalCategory: leadingIndicatorTypeOptions.find((x) => x.value === secondaryLeadingIndicatorType)?.tableDefinitionType!,
                secondaryLeadingIndicatorType,
            } as UpsertMarketIndicatorFactorRequest;
        }

        return initialUpsertMarketIndicatorFactorRequest;
    };

    const updateFactorsByTemplateType = (templateType: MarketIndicatorTemplateType, initialRequest?: UpsertMarketIndicatorFactorRequest) => {
        const parameters: Parameters = {} as Parameters;
        switch (templateType) {
            case MarketIndicatorTemplateType.AverageSeasonal:
                (parameters as AverageSeasonalParameters).yearsOfData = `${defaultYearsOfData}`;
                break;
            case MarketIndicatorTemplateType.Forecast:
                (parameters as ForecastParameters).monthsForward = `${defaultMonthsForward}`;
                break;
            case MarketIndicatorTemplateType.Historical:
                (parameters as HistoricalParameters).yearsOfData = `${defaultYearsOfData}`;
                (parameters as HistoricalParameters).subFactorCalculationTypes = defaultHistoricalSubFactorCalculationTypes;
                break;
            case MarketIndicatorTemplateType.Seasonal:
                (parameters as SeasonalParameters).yearsOfData = `${defaultYearsOfData}`;
                (parameters as SeasonalParameters).subFactorCalculationTypes = defaultSeasonalSubFactorCalculationTypes;
                break;
            case MarketIndicatorTemplateType.Combination:
                (parameters as CombinationParameters).multipliers1 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers1 ?? defaultMultipliers1;
                (parameters as CombinationParameters).multipliers2 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers2 ?? defaultMultipliers2;
                (parameters as CombinationParameters).multipliers3 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers3 ?? defaultMultipliers3;
                (parameters as CombinationParameters).multipliers4 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers4 ?? defaultMultipliers4;
                (parameters as CombinationParameters).multipliers5 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers5 ?? defaultMultipliers5;
                (parameters as CombinationParameters).multipliers6 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers6 ?? defaultMultipliers6;
                (parameters as CombinationParameters).multipliers7 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers7 ?? defaultMultipliers7;
                (parameters as CombinationParameters).multipliers8 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers8 ?? defaultMultipliers8;
                (parameters as CombinationParameters).multipliers9 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers9 ?? defaultMultipliers9;
                (parameters as CombinationParameters).multipliers10 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers10 ?? defaultMultipliers10;
                (parameters as CombinationParameters).multipliers11 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers11 ?? defaultMultipliers11;
                (parameters as CombinationParameters).multipliers12 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers12 ?? defaultMultipliers12;

                break;
            case MarketIndicatorTemplateType.Decile:
                (parameters as DecileParameters).yearsOfData = `${defaultYearsOfData}`;
                (parameters as DecileParameters).oneYearWeightedPercent = `${defaultOneYearWeightedPercent}`;
                break;
            case MarketIndicatorTemplateType.SlidingWindowSeasonal:
                (parameters as SlidingWindowSeasonalParameters).monthsFlatOutlook = defaultMonthsFlatOutlook;
                (parameters as SlidingWindowSeasonalParameters).monthsInverseCorrelation = defaultMonthsInverseCorrelation;
                (parameters as SlidingWindowSeasonalParameters).windowStartDay = `${defaultWindowStartDay}`;
                (parameters as SlidingWindowSeasonalParameters).windowEndDay = `${defaultWindowEndDay}`;
                (parameters as SlidingWindowSeasonalParameters).yearsOfData = `${defaultYearsOfData}`;
                break;
            case MarketIndicatorTemplateType.Technical:
                (parameters as TechnicalParameters).daysToAverage1 = `${defaultDaysToAverage1}`;
                (parameters as TechnicalParameters).daysToAverage2 = `${defaultDaysToAverage2}`;
                (parameters as TechnicalParameters).daysToAverage3 = `${defaultDaysToAverage3}`;
                (parameters as TechnicalParameters).longTermDays1 = `${defaultLongTermDays1}`;
                (parameters as TechnicalParameters).longTermDays2 = `${defaultLongTermDays2}`;
                (parameters as TechnicalParameters).longTermDays3 = `${defaultLongTermDays3}`;
                (parameters as TechnicalParameters).shortTermDays1 = `${defaultShortTermDays1}`;
                (parameters as TechnicalParameters).shortTermDays2 = `${defaultShortTermDays2}`;
                (parameters as TechnicalParameters).shortTermDays3 = `${defaultShortTermDays3}`;
                break;
            case MarketIndicatorTemplateType.ForwardCurve:
                (parameters as ForwardCurveParameters).monthsOfData = `${defaultMonthsOfData}`;
                (parameters as ForwardCurveParameters).shortTermContractNumber = `${defaultShortTermContractMonth}`;
                (parameters as ForwardCurveParameters).longTermContractNumber = `${defaultLongTermContractNumber}`;
                break;
            case MarketIndicatorTemplateType.ManualEntry:
                (parameters as ManualEntryParameters).outlook = defaultOutlook;
                break;
            default:
                (parameters as AverageSeasonalParameters).yearsOfData = `${defaultYearsOfData}`;
                break;
        }

        setUpsertMarketIndicatorFactorRequest({
            ...(initialRequest ?? upsertMarketIndicatorFactorRequest)!,
            templateType,
            parameters,
        });
    };

    const handleSave = () => {
        setIsSaving(true);

        if (isSubFactorNew) {
            handleUpdateMarketIndicatorFactor(props.parentUpsertMarketIndicatorFactorRequest);
        }

        if (marketIndicatorFactorGuidFromRoute && !isSubFactorNew) {
            handleUpdateMarketIndicatorFactor(upsertMarketIndicatorFactorRequest);
        } else {
            handleAddMarketIndicatorFactor(upsertMarketIndicatorFactorRequest);
        }
    };

    const loading = !upsertMarketIndicatorFactorRequest;

    return loading ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.indicator_add_and_edit}>
            <div className={styles.indicator_add_and_edit_details}>
                <ComponentHeader title={translations.marketIndicatorsManagement.headers.details} />
                <div className={styles.indicator_add_and_edit_row_factor_name}>
                    <TextInput
                        title={translations.marketIndicatorsManagement.headers.factorName}
                        required
                        value={upsertMarketIndicatorFactorRequest.displayName}
                        handleTextChange={(value) => {
                            setUpsertMarketIndicatorFactorRequest({
                                ...upsertMarketIndicatorFactorRequest,
                                displayName: value,
                            });
                        }}
                        validation={RegexValidators.AlphaNumericMinimumLength2}
                        errorMessage={translations.marketIndicatorsManagement.messages.displayNameInvalid}
                    />
                </div>
            </div>
            <MarketIndicatorFactorTemplateArea
                upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                forwardCurveContractInput={forwardCurveContractInput}
                setForwardCurveContractInput={setForwardCurveContractInput}
                resetSelectionsByTemplateType={updateFactorsByTemplateType}
                multiplierColumns={props.multiplierColumns}
            />
            {showDataSourceSections && (
                <MarketIndicatorFactorDataSourceArea
                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                    numberOfDataSourceSections={numberOfDataSourceSections}
                    setNumberOfDataSourceSections={setNumberOfDataSourceSections}
                />
            )}

            {showDataSourceSections && numberOfDataSourceSections === 2 && (
                <MarketIndicatorFactorDataSourceArea
                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                    numberOfDataSourceSections={numberOfDataSourceSections}
                    setNumberOfDataSourceSections={setNumberOfDataSourceSections}
                    useSecondary
                />
            )}

            <MarketIndicatorFactorCharts
                upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                parentUpsertMarketIndicatorFactorRequest={props.parentUpsertMarketIndicatorFactorRequest}
                handleSave={handleSave}
                handleSaveAndReturn={() => {
                    setNavigateAfterApiSuccess(true);
                    handleSave();
                }}
                savingDisabled={
                    isSaving ||
                    !RegexValidators.AlphaNumericMinimumLength2.test(upsertMarketIndicatorFactorRequest.displayName) ||
                    upsertMarketIndicatorFactorRequest?.displayName?.includes('child')
                }
                getMarketIndicatorResponse={getMarketIndicatorResponse}
            />
        </div>
    );
};

export default MarketIndicatorFactorSelectionPage;
