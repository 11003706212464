import HighchartsReact from 'highcharts-react-official';
import HighStock, { Options } from 'highcharts/highstock';
import React, { useCallback, useEffect, useState } from 'react';
import scssVariables from '../../../../Config.module.scss';
import { defaultChartOptions, IChartProps } from '../ChartDefinitions';

export interface IGuageChartRawProps extends IChartProps {
    chartDimensions?: { chartSize?: number; dialRadius?: number };
    guageSeries: {
        label: string;
        data: {
            asOfDate: string;
            outlookDecile?: number;
        };
    };
}

// Constants.
const chartHeight = 75;
const startAngle = -90;
const endAngle = 90;
const chartCenterX = 50;
const chartCenterY = 65;
const defaultChartSize = 110;
const yAxisMaxValue = 100;
const pivotRadius = 6;

// Dial.
const defaultDialRadius = 80;
const dialLength = 0;
const dialTopWidth = 4;
const dialBaseWidth = 14;

// Plot bands.
const section1From = 0;
const section1To = 9.99;
const section2From = 10;
const section2To = 29.99;
const section3From = 30;
const section3To = 70;
const section4From = 70.01;
const section4To = 90;
const section5From = 90.01;
const section5To = 100;
const bandThickness = 20;

const GuageChartRaw: React.FC<IGuageChartRawProps> = (props: IGuageChartRawProps) => {
    const [highchartOptions, setHighchartOptions] = useState<Options>(() => ({
        ...defaultChartOptions,
        chart: {
            type: 'gauge',
            height: `${chartHeight}%`,
            plotBorderWidth: 0,
        },
        pane: {
            startAngle,
            endAngle,
            background: [{ backgroundColor: scssVariables.plainWhite, borderColor: scssVariables.plainWhite }],
            center: [`${chartCenterX}%`, `${chartCenterY}%`],
            size: `${props.chartDimensions?.chartSize ?? defaultChartSize}%`,
        },
        yAxis: {
            min: 0,
            max: yAxisMaxValue,
            tickAmount: 0,
            minorTicks: false,
            showFirstLabel: false,
            showLastLabel: false,
            showLabels: false,
            lineWidth: 0,
            plotBands: [
                {
                    from: section1From,
                    to: section1To,
                    color: scssVariables.veryBearishColor,
                    thickness: bandThickness,
                },
                {
                    from: section2From,
                    to: section2To,
                    color: scssVariables.bearishColor,
                    thickness: bandThickness,
                },
                {
                    from: section3From,
                    to: section3To,
                    color: scssVariables.alternativeNeutralColor,
                    thickness: bandThickness,
                },
                {
                    from: section4From,
                    to: section4To,
                    color: scssVariables.bullishColor,
                    thickness: bandThickness,
                },
                {
                    from: section5From,
                    to: section5To,
                    color: scssVariables.veryBullishColor,
                    thickness: bandThickness,
                },
            ],
        },
    }));

    const getDataSeriesDefinitions = useCallback(() => {
        const guageSeries = {
            name: 'Outlook',
            type: 'gauge',
            data: [props.guageSeries.data.outlookDecile ?? 0],
            dataLabels: {
                enabled: false,
            },
            dial: {
                radius: `${props.chartDimensions?.dialRadius ?? defaultDialRadius}%`,
                backgroundColor: scssVariables.stonexLightGrayBlue,
                baseWidth: dialBaseWidth,
                topWidth: dialTopWidth,
                baseLength: `${dialLength}%`,
                rearLength: `${dialLength}%`,
            },
            pivot: {
                backgroundColor: scssVariables.stonexLightGrayBlue,
                radius: pivotRadius,
            },
        };

        return guageSeries;
    }, [props.guageSeries]);

    useEffect(() => {
        const dataSeries = getDataSeriesDefinitions();
        const newOptions = {
            ...highchartOptions,
            series: [dataSeries],
            tooltip: {
                enabled: false,
            },
        };

        setHighchartOptions(newOptions as Options);
    }, [props.guageSeries]);

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default GuageChartRaw;
