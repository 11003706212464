import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { Children, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from '../../../Components/Footer/Footer';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import { useApplicationDispatch, useApplicationSelector } from '../../../Redux/ReduxStore';
import { resetUser, selectUserTokens, setUser } from '../../../Redux/Slices/UserSlice';
import useUserSessionPingApiHook from '../../Apis/Hooks/useUserSessionPingApiHook';
import ApplicationHeader from '../../Components/Headers/ApplicationHeader/ApplicationHeader';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import SmallDisclaimerText from '../../Components/Texts/SmallDisclaimerText/SmallDisclaimerText';
import SessionPage from '../../Pages/Session/SessionPage';
import browserService from '../../Services/BrowserService';
import { EventActionsEnum, EventCategoriesEnum } from '../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../Services/Logging/LoggingService';
import ErrorBoundary from '../ErrorHandling/ErrorBoundary';
import styles from './OktaSecureRouteContainer.module.scss';

const SidebarContainer: React.FC<PropsWithChildren> = (props: PropsWithChildren) => (
    <>
        <ApplicationHeader />
        <div className={styles.okta_page_container}>
            <div className={styles.okta_page_sidebar}>
                <Sidebar />
            </div>
            <div className={styles.okta_page_content}>
                {Children.only(props.children)}
                <div className={styles.okta_page_disclaimer}>
                    <SmallDisclaimerText />
                </div>
            </div>
        </div>
    </>
);

const OktaSecureRouteContainer = () => {
    // Authentication hooks.
    const { authState, oktaAuth } = useOktaAuth();
    const dispatch = useApplicationDispatch();
    const userTokens = useApplicationSelector(selectUserTokens);

    // Session hooks.
    const { ready: translationLoaded } = useTranslation(applicationSettings.locize.namespace);
    const [sessionLoaded, setSessionLoaded] = useState(false);
    useUserSessionPingApiHook(sessionLoaded);

    // Navigation hooks.
    const navigate = useNavigate();

    useEffect(() => {
        browserService.setReactNavigate(navigate);
    }, [navigate]);

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState.isAuthenticated) {
            dispatch(resetUser());
        } else {
            dispatch(
                setUser({
                    accessToken: authState.accessToken?.accessToken!,
                    identityToken: authState.idToken?.idToken!,
                }),
            );
            loggingService.trackEventWithAnalytics(EventActionsEnum.UserAuthenticated, EventCategoriesEnum.Success);
        }
    }, [authState]);

    if (!authState) {
        return <PageLoadingSpinner />;
    }

    if (!authState.isAuthenticated) {
        loggingService.trackEventWithAnalytics(EventActionsEnum.Navigation, EventCategoriesEnum.UnauthenticatedUserPageVisit);
        const originalUri = toRelativeUrl(window.location.href, window.location.origin);
        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect();
        return <PageLoadingSpinner />;
    }

    // There is a slight delay in getting the access token into redux for use.
    // Therefore we will wait for it first, then render the authenticated pages.
    if (!userTokens?.accessToken || !translationLoaded) {
        return <PageLoadingSpinner />;
    }

    if (!sessionLoaded) {
        return <SessionPage setSessionLoaded={(isSessionLoaded: boolean) => setSessionLoaded(isSessionLoaded)} />;
    }

    return (
        <>
            <SidebarContainer>
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </SidebarContainer>
            <Footer />
        </>
    );
};

export default OktaSecureRouteContainer;
