import React from 'react';
import { translationKeys } from '../../Services/Language/TranslationDefinitions';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './SessionPage.module.scss';

const AccessPendingPage: React.FC = () => {
    const [, translate] = useLanguage();

    return (
        <div className={styles.access_pending}>
            <div className={styles.access_pending_border}>
                <div className={styles.access_pending_text_container}>
                    <h3 className={styles.access_pending_title}>{translate(translationKeys.accessPending.title)}</h3>
                    <p className={styles.access_pending_text}>{translate(translationKeys.accessPending.text.toRequestALicense)}</p>
                </div>
            </div>
        </div>
    );
};

export default AccessPendingPage;
