/* eslint-disable no-confusing-arrow */
import { CellValueChangedEvent, ColDef, RowValueChangedEvent, ValueParserParams } from 'ag-grid-community';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { Currency, DemeterSymbolContractModel, DemeterSymbolModel, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import { MarketPriceModel } from '../../../../Redux/Slices/MarketPricesSlice';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';
import styles from './ForwardSpreadCalculator.module.scss';

export enum ForwardSpreadDirection {
    Last = 'Last',
    Bid = 'Bid',
    Ask = 'Ask',
    Settle = 'Settle',
}

export interface ForwardSpreadCalculatorRequest {
    symbol1?: DemeterSymbolModel;
    direction1: ForwardSpreadDirection;
    symbol2?: DemeterSymbolModel;
    direction2: ForwardSpreadDirection;
    unitOfMeasure: UnitOfMeasure;
    currency: Currency;
}

export interface ForwardSpreadRow {
    id: string;
    month: number;
    year: number;
    quarter: number;
    symbolContract1: DemeterSymbolContractModel;
    marketPrice1: MarketPriceModel;
    value1?: number;
    overrideValue1?: number;
    currencyConversionRate1?: number;
    overrideCurrencyConversionRate1?: number;
    convertedValue1?: number;
    symbolContract2?: DemeterSymbolContractModel;
    marketPrice2?: MarketPriceModel;
    value2?: number;
    overrideValue2?: number;
    currencyConversionRate2?: number;
    overrideCurrencyConversionRate2?: number;
    convertedValue2?: number;
    spread?: number;
    eurToUsdCurrencyConversionRate?: number;
}

export type RendererParameters = {
    data: ForwardSpreadRow;
    value: number | string;
    node: { rowIndex: number };
};

export const forwardSpreadColumnOptions = {
    suppressMenu: true,
    suppressContextMenu: true,
    headerName: '',
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: false,
    headerClass: 'ag-header-cell-flex-start',
};

export const quarterlyComparisonColumnOptions = {
    suppressMenu: true,
    suppressContextMenu: true,
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: true,
    headerClass: 'ag-header-cell-flex-start',
};
export type ForwardSpreadDefinitionsType = ColDef & { children: ColDef[] };

const contractMonthCellRenderer = (parameters: RendererParameters) =>
    formattingService.toMonthYear(new Date(parameters.data.year, parameters.data.month - 1, 1));

const quarterCellRenderer = (parameters: RendererParameters) => `Q${parameters.data.quarter} ${parameters.data.year}`;

const numberCellRenderer = (parameters: RendererParameters) =>
    !parameters.value ? applicationConstants.TablePlaceholderZeroOrEmpty : formattingService.toFuturesPriceString(+parameters.value);

const currencyCellRenderer = (parameters: RendererParameters) =>
    !parameters.value ? applicationConstants.TablePlaceholderZeroOrEmpty : formattingService.toCurrencyPriceString(+parameters.value);

const numberParser = (parameters: ValueParserParams) =>
    !parameters.newValue || Number.isNaN(+parameters.newValue) ? parameters.oldValue : +parameters.newValue;

const symbolContract1OverrideValueGetter = (parameters: RendererParameters) => {
    if (parameters.data.value1 === undefined) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    return +formattingService.toFuturesPriceString(+parameters.data.value1);
};

const symbolContract2OverrideValueGetter = (parameters: RendererParameters) => {
    if (parameters.data.value2 === undefined) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    return +formattingService.toFuturesPriceString(+parameters.data.value2);
};

export const forwardSpreadColumnDefinitions = [
    {
        headerName: '',
        headerClass: styles.ag_grid_corner,
        children: [
            {
                field: 'id',
                headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.period),
                minWidth: 100,
                cellRenderer: contractMonthCellRenderer,
            },
        ],
    },
    {
        headerName: '',
        headerClass: styles.ag_grid_header_align,
        children: [
            {
                field: 'value1',
                minWidth: 140,
                resizable: false,
                cellClass: styles.ag_grid_input,
                headerName: '',
                singleClickEdit: true,
                editable: true,
                valueParser: numberParser,
                valueGetter: symbolContract1OverrideValueGetter,
                onCellValueChanged: (event: RowValueChangedEvent | CellValueChangedEvent) => event,
            },
            {
                field: 'currencyConversionRate1',
                headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.foreignExchangeRate),
                minWidth: 100,
                maxWidth: 100,
                cellRenderer: currencyCellRenderer,
                type: 'rightAligned',
            },
            {
                field: 'convertedValue1',
                headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.converted),
                minWidth: 100,
                maxWidth: 100,
                cellRenderer: numberCellRenderer,
                type: 'rightAligned',
            },
        ],
    },
    {
        headerName: '',
        headerClass: styles.ag_grid_header_align,
        children: [
            {
                field: 'value2',
                minWidth: 140,
                resizable: false,
                cellClass: styles.ag_grid_input,
                headerName: '',
                singleClickEdit: true,
                editable: true,
                valueParser: numberParser,
                valueGetter: symbolContract2OverrideValueGetter,
                onCellValueChanged: (event: RowValueChangedEvent | CellValueChangedEvent) => event,
            },
            {
                field: 'currencyConversionRate2',
                headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.foreignExchangeRate),
                minWidth: 100,
                maxWidth: 100,
                cellRenderer: currencyCellRenderer,
                type: 'rightAligned',
            },
            {
                field: 'convertedValue2',
                headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.converted),
                minWidth: 100,
                maxWidth: 100,
                cellRenderer: numberCellRenderer,
                type: 'rightAligned',
            },
        ],
    },
    {
        headerName: '',
        headerClass: styles.ag_grid_corner,
        children: [
            {
                field: 'spread',
                headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.spread),
                minWidth: 100,
                cellRenderer: numberCellRenderer,
                type: 'rightAligned',
            },
        ],
    },
];

export const quarterlyComparisonColumnDefinitions = [
    {
        field: 'period',
        headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.period),
        cellRenderer: quarterCellRenderer,
        headerClass: styles.ag_grid_subheader,
        type: 'rightAligned',
    },
    {
        field: 'value1',
        cellRenderer: numberCellRenderer,
        headerClass: styles.ag_grid_subheader,
        type: 'rightAligned',
    },
    {
        field: 'convertedValue1',
        headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.converted),
        cellRenderer: numberCellRenderer,
        headerClass: styles.ag_grid_subheader,
        type: 'rightAligned',
    },
    {
        field: 'value2',
        cellRenderer: numberCellRenderer,
        headerClass: styles.ag_grid_subheader,
        type: 'rightAligned',
    },
    {
        field: 'convertedValue2',
        headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.converted),
        cellRenderer: numberCellRenderer,
        headerClass: styles.ag_grid_subheader,
        type: 'rightAligned',
    },
    {
        field: 'spread',
        headerValueGetter: () => languageService.translate(translationKeys.calculators.fields.spread),
        cellRenderer: numberCellRenderer,
        headerClass: styles.ag_grid_subheader,
        type: 'rightAligned',
    },
];
