import { useState } from 'react';
import { Link } from 'react-router-dom';
import { demeterUsersApi } from '../../../../Apis/Apis';
import { DemeterUserModel, DemeterUserStatus } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import ConfirmModal from '../../../Components/Modals/ConfirmModal/ConfirmModal';
import ActionsCellButton from '../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './UsersPage.module.scss';

interface IUserActionsProps {
    user: DemeterUserModel;
    handleUserDeactivated: () => any;
    handleUserReactivated: () => any;
}

const UserActions: React.FC<IUserActionsProps> = (props: IUserActionsProps) => {
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [showReactivateModal, setShowReactivateModal] = useState(false);
    const [translations] = useLanguage();

    return (
        <div className={styles.users_actions_container}>
            <Link className={styles.users_actions_link_button} to={`${NavigationRoutes.Users}/${props.user.demeterUserTrialGuid}/edit`}>
                {translations.actions.edit}
            </Link>
            {props.user.userStatus !== DemeterUserStatus.Deactivated && (
                <>
                    <ActionsCellButton text={translations.users.actions.deactivate} handleClick={() => setShowDeactivateModal(true)} />
                    <ConfirmModal
                        header={translations.users.text.confirmDeactivateHeader}
                        message={translations.users.text.confirmDeactivateMessage}
                        showModal={showDeactivateModal}
                        handleConfirm={async () => {
                            await demeterUsersApi.deactivateDemeterUser(props.user.demeterUserTrialGuid);
                            props.handleUserDeactivated();
                            setShowDeactivateModal(false);
                        }}
                        handleCancel={() => {
                            setShowDeactivateModal(false);
                        }}
                    />
                </>
            )}
            {props.user.userStatus === DemeterUserStatus.Deactivated && (
                <>
                    <ActionsCellButton text={translations.users.actions.reactivate} handleClick={() => setShowReactivateModal(true)} />
                    <ConfirmModal
                        header={translations.users.text.confirmReactivateHeader}
                        message={translations.users.text.confirmReactivateMessage}
                        showModal={showReactivateModal}
                        handleConfirm={async () => {
                            await demeterUsersApi.reactivateDemeterUser(props.user.demeterUserTrialGuid);
                            props.handleUserReactivated();
                            setShowReactivateModal(false);
                        }}
                        handleCancel={() => {
                            setShowReactivateModal(false);
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default UserActions;
