import { Options, SeriesColumnOptions, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { useCallback, useEffect, useState } from 'react';
import scssVariables from '../../../../Config.module.scss';
import { Currency, DemeterDataFrequency, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import {
    BarChartFormatOptions,
    ChartContext,
    columnChartType,
    defaultChartAxisTickLabelStyle,
    defaultChartAxisTitleStyle,
    defaultChartOptions,
    defaultZoneAxis,
    IChartBarDataSeries,
    IChartProps,
    lineChartType,
} from '../ChartDefinitions';
import chartService from '../ChartService';

export interface IBarChartRawProps extends IChartProps {
    barSeries: IChartBarDataSeries[];
    format: BarChartFormatOptions;
    dataFrequency?: typeof DemeterDataFrequency.Yearly | typeof DemeterDataFrequency.Monthly;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
}

const colorConstants = [
    {
        barColor: scssVariables.ruleSetOneBarColor1,
        forecastColor: scssVariables.plainWhite,
    },
    {
        barColor: scssVariables.ruleSetOneBarColor2,
        forecastColor: scssVariables.plainWhite,
    },
    {
        barColor: scssVariables.ruleSetOneBarColor3,
        forecastColor: scssVariables.plainWhite,
    },
];

const BarChartRaw: React.FC<IBarChartRawProps> = (props: IBarChartRawProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Chart hooks.
    const [forecastDataOn, setForecastDataOn] = useState<boolean>(() => true);
    const [highchartOptions, setHighchartOptions] = useState<Options>(() => ({
        ...defaultChartOptions,
        chart: {
            type: columnChartType,
            ...defaultChartOptions.chart,
        },
        yAxis: [
            {
                title: {
                    text: props.barSeries[0].label,
                    style: defaultChartAxisTitleStyle,
                },
                labels: {
                    format: '',
                    style: defaultChartAxisTickLabelStyle,
                },
            },
        ],
    }));

    const getDataSeriesDefinitions: () => SeriesColumnOptions[] = useCallback(() => {
        const barDataSeries = props.barSeries.map((series, index) => {
            const actualBarSeries = {
                type: columnChartType,
                name: series.label,
                color: colorConstants[index].barColor,
                data: [],
                pointStart: undefined,
                pointIntervalUnit: props.dataFrequency === DemeterDataFrequency.Yearly ? 'year' : 'month',
                zoneAxis: defaultZoneAxis,
                zones: [
                    {
                        value: 0,
                        color: colorConstants[index].barColor,
                    },
                    {
                        color: scssVariables.transparent,
                        borderColor: colorConstants[index].barColor,
                        borderWidth: 3,
                    },
                ],
                visible: true,
                showInLegend: true,
            };

            if (props.barSeries.find((x) => x.data.some((y) => !y.isActualValue))) {
                const forecastBarSeries = {
                    name: series.forecastLabel ?? translations.words.forecast,
                    type: lineChartType, // This allows it to be registered in the legend.
                    data: [],
                    color: scssVariables.ruleSetOneBarColor1,
                    events: {
                        legendItemClick() {
                            handleForecastClicked(this as unknown as ChartContext);
                        },
                    },
                    marker: {
                        symbol: 'square',
                        radius: 12,
                        fillColor: scssVariables.plainWhite,
                        lineWidth: 1,
                        lineColor: scssVariables.ruleSetOneBarColor1,
                    },
                    lineWidth: 0,
                };

                return [actualBarSeries, forecastBarSeries];
            }

            return [actualBarSeries];
        });

        return barDataSeries.flat() as SeriesColumnOptions[];
    }, [props.barSeries, forecastDataOn]);

    // Main useEffect to update chart when props or data changes.
    useEffect(() => {
        const dataSeries = getDataSeriesDefinitions();
        const downloadData = chartService.getDownloadData(props.barSeries, props.dataFrequency, props.displayDecimalPlacesMinimum);

        props.barSeries.forEach((series, index) => {
            const lastActualValueDataIndex = series.data.findLastIndex((x) => x.isActualValue) >= 0 ? series.data.findLastIndex((x) => x.isActualValue) : 0;
            const lastActualAsOfDate = series.data[lastActualValueDataIndex].asOfDate;
            const seriesAsOfMonth = props.dataFrequency === DemeterDataFrequency.Yearly ? 0 : series.data[0].asOfDate.getMonth() - 1;
            const seriesAsOfYear = lastActualAsOfDate.getFullYear() + 1;
            const endIndex = forecastDataOn ? series.data.length : lastActualValueDataIndex;
            // TODO: We should not be using the series index to determine these things. We need to be able to grab the
            // data based on some other attribute.
            const dataItem = dataSeries[index * 2] ?? dataSeries[index];

            if (!dataItem) {
                return;
            }

            dataItem.data = series.data.slice(0, endIndex).map((item) => item.value);
            dataItem.pointStart = new Date(series.data[0].asOfDate.getFullYear(), seriesAsOfMonth).getTime();
            dataItem.zones![0].value = new Date(seriesAsOfYear, seriesAsOfMonth).getTime();
        });

        const newOptions = {
            ...highchartOptions,
            series: dataSeries,
            tooltip: {
                formatter() {
                    const context = this as unknown as ChartContext;
                    const { y, series } = context;

                    return chartService.getTooltipText(context, {
                        nameOverride: dataSeries[series.index + 1].name!,
                        format: props.format,
                        displayDecimalPlacesMinimum:
                            (props.barSeries[context.series.index / 2] || props.barSeries[context.series.index]).displayDecimalPlacesMinimum ??
                            props.displayDecimalPlacesMinimum ??
                            formattingService.getDisplayDecimalPlacesMinimum(y),
                        displayDecimalPlacesMaximum:
                            (props.barSeries[context.series.index / 2] || props.barSeries[context.series.index]).displayDecimalPlacesMaximum ??
                            props.displayDecimalPlacesMaximum ??
                            formattingService.getDisplayDecimalPlacesMinimum(y),
                    });
                },
            },
            downloadData,
        };

        const minimumValue = Math.min(...(newOptions.series.flatMap((x) => x.data?.map((y) => Math.abs(y as number))).filter((x) => !!x) as number[]));
        const yAxisOptions = (newOptions.yAxis as YAxisOptions[])[0]!;
        yAxisOptions.labels!.format = `{value:{point.y: , .${
            props.barSeries[0].displayDecimalPlacesMaximum ??
            props.displayDecimalPlacesMaximum ??
            formattingService.getDisplayDecimalPlacesMinimum(minimumValue)
        }f}`;
        if (props.format === 'percent') {
            yAxisOptions.labels!.format = `${yAxisOptions.labels!.format}%`;
        }

        yAxisOptions.title!.text = chartService.getCurrencyAndUnitOfMeasureText(props.unitOfMeasure, props.currency);

        setHighchartOptions(newOptions);
    }, [forecastDataOn, props.barSeries]);

    // Unique bar chart functions.
    const handleForecastClicked = (context: ChartContext) => {
        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ButtonClick,
            EventCategoriesEnum.ForcastDataToggled,
            forecastDataOn ? 'Off' : 'On',
            EventDataTargetsEnum.BarChart,
        );

        context.chart.series.forEach((item, index) => {
            if (index % 2 === 0 || index === context.index) {
                return;
            }

            if (forecastDataOn) {
                item.hide();
            } else {
                item.show();
            }
        });

        setForecastDataOn(!forecastDataOn);
    };

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default BarChartRaw;
