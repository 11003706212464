import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './BrandCard.module.scss';

interface IBrandCardProps {
    brandingTitleString: string;
    title?: string;
    children: JSX.Element;
    navigateTo?: {
        pathName: string;
        search?: string;
    };
    useNavigationCard?: boolean;
    testId?: string;
}

const BrandCard: React.FC<IBrandCardProps> = (props: IBrandCardProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const navigate = useNavigate();

    // Text hooks.
    const titleString = useMemo(() => {
        if (props.title) {
            return props.title;
        }

        return '';
    }, [translations, props.title]);

    const brandCardNavigation = () => {
        navigate({
            pathname: props.navigateTo!.pathName,
            search: props.navigateTo!.search,
        });
    };

    return (
        <div
            className={props.useNavigationCard ? styles.brand_card_simplified : styles.brand_card_full}
            onClick={props.useNavigationCard ? brandCardNavigation : undefined}
            onKeyPress={() => {}}
            role="button"
            tabIndex={props.useNavigationCard ? 0 : -1}
            data-testid={props.testId}
        >
            <div className={styles.brand_card_center}>
                <div className={props.useNavigationCard ? styles.brand_card_branding_simplified : styles.brand_card_branding}>{props.brandingTitleString}</div>
                <div
                    className={props.useNavigationCard ? styles.brand_card_title_simplified : styles.brand_card_title}
                    data-testid={`${props.testId ?? 'BrandCard'}Title`}
                >
                    {titleString}
                </div>
                {props.children}
            </div>
        </div>
    );
};

export default BrandCard;
