const TermsAndConditions: React.FC = () => (
    <>
        <h2>Software as a Service Agreement</h2>
        <p>
            This Software as a Service agreement (this <b>&quot;Agreement&quot;</b>), is a binding agreement between StoneX Financial Inc., its subsidiaries,
            affiliates and assigns (<b>&quot;StoneX&quot; or &quot;Provider&quot;</b>) and the user of the StoneX Plus (
            <b>&quot;you&quot;, &quot;user&quot;, &quot;Customer&quot; or &quot;Subscriber&quot;</b>). Provider and Customer may be referred to herein
            collectively as the <b>&quot;Parties&quot;</b> or individually as a <b>&quot;Party.&quot;</b>
        </p>
        <p>
            StoneX Plus including all of its features and content (the <b>&quot;Platform&quot;</b>) is a service made available by StoneX Financial Inc. or its
            affiliates, and all content, information, services and software ordered or provided on or through this Platform (&quot;Content&quot;) may be used
            solely under the following terms and conditions, which expressly incorporate by reference the terms of any user agreement required to be accepted by
            any user of StoneX Plus (<b>&quot;Terms of Use&quot;</b>) and on the condition that Subscriber accepts and complies with them. By using or
            subscribing to the Platform you (a) accept this agreement and agree that Subscriber is legally bound by its terms; and (b) represent and warrant
            that: (i) you are of legal age to enter into a binding agreement; and (ii) if Subscriber is a corporation, governmental organization or other legal
            entity, you have the right, power and authority to enter into this agreement on behalf of Subscriber and bind Subscriber to its terms. If Subscriber
            does not agree to the terms of this Agreement, Provider will not and does not license access to the Platform to Subscriber and you must not download
            or install the applicable software or documentation.
        </p>
        <p>
            Notwithstanding anything to the contrary in this Agreement or Subscriber&apos;s acceptance of the terms and conditions of this Agreement, no license
            is granted (whether expressly, by implication or otherwise) under this Agreement, and this Agreement expressly excludes any right, concerning any
            software or Platform access that Subscriber did not acquire lawfully or that is not a legitimate, authorized copy of Provider&apos;s software or
            Content.
        </p>
        <p>YOUR USE OF THE PLATFORM CONSTITUTES YOUR AGREEMENT TO BE BOUND BY THESE TERMS AND CONDITIONS OF USE.</p>
        <p>
            <b>1. Access and Use.</b> Subject to and conditioned on Subscriber’s payment of Fees and compliance with all Terms of Use, Provider hereby grants
            Subscriber a non-exclusive, non-transferable, revocable right to access and use the Platform during the Term, solely for use by Authorized Users in
            accordance with the Terms of Use. Such use is limited to Subscriber&apos;s internal use. Provider shall provide to Customer the necessary passwords
            and network links or connections to allow Customer to access the Platform.
        </p>
        <p>
            <b>2. Limitations on Use.</b> The Content on this Platform is for use by you or your firm only and not for commercial exploitation by retransmission
            or resale. You shall not at any time, directly or indirectly, and shall not permit any employees, consultants, contractors, and agents who are
            authorized by you to access and use the Platform under the rights granted to you pursuant to this Agreement (each of the foregoing, an
            &quot;Authorized User&quot;) to: (i) decompile, reverse engineer, disassemble, rent, lease, loan, sell, sublicense, or create derivative works, in
            whole or in part, from this Platform or the Content; (ii) use any network monitoring or discovery software to determine the site architecture, or
            extract information about usage, individual identities or users; (iii) use any robot, spider, other automatic software or device, or manual process
            to monitor or copy our Platform or the Content without Provider&apos;s prior written permission; (iv) transmit any false, misleading, fraudulent or
            illegal communications; or (v) copy, modify, reproduce, republish, distribute, display, or transmit for commercial, non-profit or public purposes
            all or any portion of this Platform, except to the extent permitted above. Any unauthorized use of this Platform or its Content is prohibited. In
            addition to the reasons set forth above, Provider may, in its sole discretion, remove content, restrict or block user access, or terminate or revoke
            access to the Platform at any time for any reason, and Provider will refund any pre-paid Fees pro rata based on the number of days Subscriber had
            access to the Platform over the full pre-paid term.
        </p>
        <p>
            <b>3. Not Professional Advice.</b> Content is provided to assist the Subscriber in general commodities marketing planning and for informational
            purposes. The materials are not a substitute for obtaining professional advice from a qualified person, firm, or corporation. Inexperienced
            Subscribers should consult with an appropriate professional commodities marketing advisor or broker for more complete information prior to executing
            any marketing plans, commodities trades or the purchase or sale of any futures, options or other derivative contracts. StoneX is not engaged in
            rendering any financial, investment, legal, taxation, or other professional service or advice by granting access to this Platform. The accuracy,
            completeness, adequacy or timeliness of the Content is not warranted or guaranteed. Your use of Content on this Platform or materials linked from
            this Platform is at your own risk.
        </p>
        <p>
            <b>4. Intellectual Property Rights.</b> Except as expressly provided in these Terms of Use, nothing contained herein shall be construed as
            conferring on you or any third party any license or right, by implication, estoppel or otherwise, under any law (whether common law or statutory
            law), rule or regulation including, without limitation those related to copyright or other intellectual property rights. You agree that the Content
            and Platform are protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. Customer hereby grants to
            Provider a non-exclusive, royalty-free, worldwide license to reproduce, distribute, and use and display any information, data, and other content, in
            any form or medium, that is submitted, posted, or otherwise transmitted by or on behalf of Customer or an Authorized User through the Provider
            System (collectively, &quot;Customer Data&quot;) solely to the extent necessary for Provider to provide the Platform to Subscriber.
        </p>
        <p>
            <b>6. Export Regulation.</b> The software supporting the Platform and any documentation may be subject to U.S. export control laws, including the
            U.S. Export Administration Act and its associated regulations. The Subscriber shall not, directly or indirectly, export, re-export or release the
            software or documentation to, or make the software or documentation accessible from, any jurisdiction or country to which export, re-export or
            release is prohibited by law, rule or regulation. The Subscriber shall comply with all applicable federal laws, regulations and rules, and complete
            all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting,
            releasing or otherwise making the software or documentation available outside the U.S.
        </p>
        <p>
            <b>9. Registration.</b>Certain sections of this Platform require you to register. If registration is requested, you agree to provide accurate and
            complete registration information. It is your responsibility to inform Provider of any changes to that information. Each registration is for a
            single individual only, unless specifically designated otherwise on the registration page. Provider does not permit a) anyone other than you to use
            the sections requiring registration by using your name or password; or b) access through a single name being made available to multiple users on a
            network or otherwise. You are responsible for preventing such unauthorized use. If you believe there has been unauthorized use, you must notify
            Provider immediately by emailing DG-RKContracts@stonex.com.
        </p>
        <p>
            <b>10. Fees and Payment.</b> All Platform access and support fees (<b>&quot;Fees&quot;</b>) are payable in advance in the manner set forth in your
            Subscription Form and are non-refundable, except as may be expressly set forth herein. Any renewal of Platform access hereunder shall not be
            effective until the Fees for such renewal have been paid in full.
        </p>
        <p>
            In addition to the standard Platform access Fees, you may also subscribe to certain data services as provided by financial exchanges (&quot;Third
            Party Data&quot;). Third Party Data shall include, but not be limited to, prices and other data obtained from financial exchanges and other
            providers of proprietary databases. While the costs of this Third Party Data will be added to the Platform Fees and paid by Subscriber pursuant to
            the terms of this Agreement, the providers of this Third Party Data may change their fees from time to time. Provider shall pass the fee changes
            instituted by the Third Party Data providers on to Subscriber. If you wish to continue to receive the relevant Third Party Data you will be required
            to pay the fees relating to it at the new rate, or to cancel the relevant Third Party Data.
        </p>
        <p>
            Provider may discontinue the offering of any Third Party Data upon the cancellation of the supply to Provider of said Third Party Data by the Third
            Party Data provider. The cancellation of certain Third Party Data will result in a reduction in the Platform access fees directly attributable to
            the fees related to the Third Party Data being cancelled. Provider may terminate this Agreement, effective immediately, if Subscriber files, or has
            filed against it, a petition for voluntary or involuntary bankruptcy or pursuant to any other insolvency law, makes or seeks to make a general
            assignment for the benefit of its creditors or applies for, or consents to, the appointment of a trustee, receiver or custodian for a substantial
            part of its property.
        </p>
        <p>
            All Fees and other amounts payable by Customer under this Agreement are exclusive of taxes and similar assessments. Customer is responsible for all
            sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed by any federal, state, or local governmental or
            regulatory authority on any amounts payable by Customer hereunder, other than any taxes imposed on Provider&apos;s income.
        </p>
        <p>
            <b>11. Term and Termination.</b> This Agreement and the license granted hereunder shall commence at the moment of Your first access to or use of the
            Platform and shall continue until either You or Provider provide notice of termination (the <b>&quot;Term&quot;</b>). If you intend to terminate
            your service, you must provide no less than thirty (30) days written notification of cancellation. Termination of this Agreement shall not relieve
            you of your obligation to make payment for any unpaid amounts owed.
        </p>
        <p>
            Upon termination of this Agreement, the access granted hereunder shall also terminate, and Subscriber shall cease using and discontinue access to
            the Platform and Documentation. No termination shall affect Subscriber&apos;s obligation to pay all Fees that may have become due before such
            expiration or termination or entitle Subscriber to any refund.
        </p>
        <p>
            <b>12. Errors and Corrections.</b> Provider does not represent or warrant that this Platform or the Content or Postings will be error-free, free of
            viruses or other harmful components, or that defects will be corrected or that it will always be accessible. Provider does not warrant or represent
            that the Content or Postings available on or through this Platform will be correct, accurate, timely, or otherwise reliable. Provider may make
            improvements and/or changes to its features, functionality or Content or Postings at any time.
        </p>
        <p>
            <b>13. Third Party Content.</b> Third party content may appear on this Platform or may be accessible via links from this Platform. Provider shall
            not be responsible for and assumes no liability for any infringement, mistakes, misstatements of law, defamation, slander, libel, omissions,
            falsehood, obscenity, pornography or profanity in the statements, opinions, representations or any other form of content contained in any
            third-party content appearing on this Platform. You understand that the information and opinions in the third-party content is neither endorsed by
            nor does it reflect the belief or opinion of Provider. Further, information and opinions provided by employees and agents of Provider in Interactive
            Areas are not necessarily endorsed by Provider and do not necessarily represent the beliefs and opinions of Provider.
        </p>
        <p>
            <b>14. Commodity Risk Notice.</b> The risk of loss in trading commodities and or buying and selling of options contracts can be substantial and past
            performance is not necessarily indicative of future results. Therefore, all Subscribers should carefully consider whether such trading is suitable
            for Subscriber in light of Subscriber’s financial condition. Any examples provided by Provider are strictly hypothetical and no representation is
            being made that any person will or is likely to achieve profits or losses similar to those examples. Neither the information, nor any opinion
            expressed by Provider shall be construed as an offer to buy or sell any futures or options on futures contracts.
        </p>
        <p>
            <b>15. DISCLAIMER.</b> THIS PLATFORM, THE INTERACTIVE AREAS, THE CONTENT, AND POSTINGS ARE PROVIDED ON AN &quot;AS IS, AS AVAILABLE&quot; BASIS.
            PROVIDER EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
            PROVIDER DISCLAIMS ALL RESPONSIBILITY FOR ANY LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM, ARISING OUT OF OR ANY WAY
            RELATED TO (A) ANY ERRORS IN OR OMISSIONS FROM THIS PLATFORM, THE INTERACTIVE AREAS, THE CONTENT, AND THE POSTINGS INCLUDING, BUT NOT LIMITED TO,
            TECHNICAL INACCURACIES AND TYPOGRAPHICAL ERRORS, (B) ANY THIRD PARTY WEB SITES OR CONTENT THEREIN DIRECTLY OR INDIRECTLY ACCESSED THROUGH LINKS IN
            THIS PLATFORM, INCLUDING BUT NOT LIMITED TO ANY ERRORS IN OR OMISSIONS THEREFROM, (C) THE UNAVAILABILITY OF THIS PLATFORM, THE INTERACTIVE AREAS,
            THE CONTENT, THE POSTINGS, OR ANY PORTION THEREOF, (D) YOUR USE OF THIS PLATFORM, THE INTERACTIVE AREAS, THE CONTENT, OR THE POSTINGS, OR (E) YOUR
            USE OF ANY EQUIPMENT OR SOFTWARE IN CONNECTION WITH THIS PLATFORM, THE INTERACTIVE AREAS, THE CONTENT, OR THE POSTINGS.
        </p>
        <p>
            <b>16. LIMITATION OF LIABILITY.</b> PROVIDER SHALL NOT BE LIABLE FOR ANY LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM YOUR
            USE OF THIS PLATFORM, THE CONTENT, THE POSTINGS, THE INTERACTIVE AREAS, OR ANY FACTS OR OPINIONS APPEARING ON OR THROUGH AN INTERACTIVE AREA.
            PROVIDER SHALL NOT BE LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER (INCLUDING,
            WITHOUT LIMITATION, ATTORNEYS&apos; FEES) IN ANY WAY DUE TO, RESULTING FROM, OR ARISING IN CONNECTION WITH THE USE OF OR INABILITY TO USE THIS
            PLATFORM, THE INTERACTIVE AREAS, THE CONTENT, OR THE POSTINGS. IN NO EVENT WILL PROVIDER&apos;S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO
            THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE
            EXCEED THE TOTAL AMOUNTS PAID TO PROVIDER UNDER THIS AGREEMENT IN THE ONE YEAR PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR $5,000.00,
            WHICHEVER IS GREATER.
        </p>
        <p>
            <b>17. Indemnification.</b> You agree to indemnify, defend and hold Provider, its officers, directors, employees, agents, licensors, suppliers and
            any third party information providers to this Platform harmless from and against all claims, losses, expenses, damages and costs, including
            reasonable attorneys&apos; fees, resulting from any violation of these Terms of Use by you or arising from or related to any Postings uploaded or
            submitted by you.
        </p>
        <p>
            <b>18. Third Party Rights.</b> The provisions of paragraphs 15 (Disclaimer), 16 (Limitation of Liability), and 17 (Indemnification) are for the
            benefit of Provider and its officers, directors, employees, agents, licensors, suppliers, and any third party information providers to this
            Platform. Each of these individuals or entities shall have the right to assert and enforce those provisions directly against you on its own behalf.
        </p>
        <p>
            <b>19. Unlawful Activity.</b> Provider reserves the right to investigate complaints or reported violations of our Terms of Use and to take any
            action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other
            third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage
            history, posted materials, IP addresses and traffic information.
        </p>
        <p>
            <b>20. Remedies for Violations.</b> Provider reserves the right to seek all remedies available at law and in equity for violations of these Terms of
            Use, including but not limited to the right to block access from a particular internet address to this Platform and any other Provider web sites and
            their features.
        </p>
        <p>
            <b>21. Governing Law and Jurisdiction.</b> The Terms of Use are governed by and construed in accordance with the laws of the State of New York and
            any action arising out of or relating to these terms shall be filed only in state or federal courts located in New York and you hereby consent and
            submit to the personal jurisdiction of such courts for the purpose of litigating any such action.
        </p>
        <p>
            <b>22. Additional Terms.</b>
        </p>
        <p>
            22.1. <b>Privacy.</b> Your use of this Platform is subject to Provider&apos;s{' '}
            <a href="https://www.stonex.com/privacy-notice" target="_blank" rel="noopener noreferrer">
                Privacy Policy
            </a>{' '}
            as incorporated by reference herein.
        </p>
        <p>
            22.2. <b>Additional Terms for Third Party Services.</b> Provider shall include within the Content certain Third Party Data, including prices and
            other data obtained from financial exchanges and other information providers, to be provided by various third parties. Your use of the Third Party
            Data is governed by this Agreement, along with any User Agreement and any applicable Addendum required by a Third Party, (collectively the
            <b>&quot;Third Party Data Terms&quot;</b>) which are provided during the registration process for this Third Party Data, all of which are
            incorporated by reference herein. Your completion of any Third Party Data registration process constitutes your acceptance of the Third Party Data
            Terms. If you do not agree with any Third Party Data Terms, you are not permitted to access the Third Party Data. You are hereby bound by and shall
            comply with all Third Party Data Terms. Any breach by you or any of your authorized users of any Third Party Data Terms is also a breach of this
            Agreement.
        </p>
        <p>
            22.3. <b>Severability of Provisions.</b> These Terms of Use incorporate by reference any notices contained on this Platform, the Privacy Policy and
            the Third Party Data Terms and constitute the entire agreement with respect to access to and use of this Platform, the Interactive Areas, and the
            Content and Postings. If any provision of these Terms of Use is unlawful, void or unenforceable, or conflicts with the Third Party Data Terms then
            that provision shall be deemed severable from the remaining provisions and shall not affect their validity and enforceability. Notwithstanding
            anything to the contrary in these Terms of Use, if you have a separate signed written agreement with the Provider that applies to your use of any of
            the Provider&apos;s Content, that agreement constitutes the entire agreement between you and the Provider with respect to the affected Content
            subject thereto (the &quot;Otherwise Covered Content&quot;), and these Terms of Use shall be treated as having no force or effect with respect to
            the Otherwise Covered Content.
        </p>
        <p>
            22.4. <b>No Default.</b> Provider shall not be in default hereunder by reason of any failure or delay in the performance of its obligations
            hereunder where such failure or delay is due to strikes, labor disputes, civil disturbances, riot, rebellion, invasion, epidemic, hostilities, war,
            terrorist attack, embargo, natural disaster, acts of God, flood, fire, sabotage, fluctuations or non-availability of electrical power, heat, light,
            air conditioning or Licensee equipment, loss and destruction of property or any other circumstances or causes beyond Provider&apos;s reasonable
            control.
        </p>
        <p>
            22.5. <b>Notifications.</b> All notices, requests, consents, claims, demands, waivers and other communications hereunder shall be in writing and
            shall be deemed to have been given: (a) when delivered by hand (with written confirmation of receipt); (b) when received by the addressee if sent by
            a nationally recognized overnight courier (receipt requested); (c) on the date sent by facsimile or e-mail of a PDF document (with confirmation of
            transmission) if sent during normal business hours of the recipient, and on the next business day if sent after normal business hours of the
            recipient; or (d) on the day after the date mailed, by certified or registered mail, return receipt requested, postage prepaid. Such communications
            must be sent to the respective parties at the addresses designated by a party from time to time in accordance with this Section 22.5.
        </p>
        <p>
            22.6. <b>Entire Agreement.</b> This Agreement, all schedules and exhibits attached hereto constitutes the sole and entire agreement between Provider
            and Subscriber with respect to the subject matter contained herein, and supersedes all prior and contemporaneous understandings, agreements,
            representations and warranties, both written and oral, with respect to such subject matter.
        </p>
        <p>
            22.7. <b>Assignability.</b> Subscriber shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its
            obligations or performance, under this Agreement, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without
            Provider&apos;s prior written consent, which consent Provider shall not unreasonably withhold. For purposes of the preceding sentence, and without
            limiting its generality, any merger, consolidation or reorganization involving Subscriber (regardless of whether Subscriber is a surviving or
            disappearing entity) will be deemed to be a transfer of rights, obligations or performance under this Agreement for which Provider&apos;s prior
            written consent is required. No delegation or other transfer will relieve Subscriber of any of its obligations or performance under this Agreement.
            Any purported assignment, delegation or transfer in violation of this Section 22.7 is void. Provider may freely assign or otherwise transfer all or
            any of its rights, or delegate or otherwise transfer all or any of its obligations or performance, under this Agreement without Subscriber&apos;s
            consent. This Agreement is binding upon and inures to the benefit of the parties hereto and their respective permitted successors and assigns.
        </p>
        <p>
            22.8. <b>Sole Benefit.</b> This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and
            nothing herein, express or implied, is intended to or shall confer on any other person any legal or equitable right, benefit or remedy of any nature
            whatsoever under or by reason of this Agreement.
        </p>
        <p>
            22.9. <b>Modifications of Agreement.</b> This Agreement may only be amended, modified or supplemented by an agreement in writing signed by each
            party hereto. No waiver by any party of any of the provisions hereof shall be effective unless explicitly set forth in writing and signed by the
            party so waiving. Except as otherwise set forth in this Agreement, no failure to exercise, or delay in exercising, any right, remedy, power or
            privilege arising from this Agreement shall operate or be construed as a waiver thereof; nor shall any single or partial exercise of any right,
            remedy, power or privilege hereunder preclude any other or further exercise thereof or the exercise of any other right, remedy, power or privilege.
        </p>
        <p>
            22.10. <b>Headings.</b> The headings in this Agreement are for reference only and shall not affect the interpretation of this Agreement.
        </p>
    </>
);

export default TermsAndConditions;
