import { Tooltip } from '@mui/material';
import { FC, useMemo } from 'react';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from './ButtonWithTooltip.module.scss';

interface IButtonWithTooltipProps {
    text: string;
    maximumLength: number;
    onClick: () => void;
}

const ButtonWithTooltip: FC<IButtonWithTooltipProps> = (props: IButtonWithTooltipProps) => {
    const [, translate] = useLanguage();

    const truncateText = (text: string, maxLength: number): string | null => {
        if (text && text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    };

    const textString = useMemo(() => translate(props.text), [props.text]);

    const shortTextString = useMemo(() => {
        if (textString && textString.length <= props.maximumLength) {
            return textString;
        }
        return truncateText(textString, props.maximumLength);
    }, [textString]);

    return (
        <Tooltip title={textString !== shortTextString && textString}>
            <button type="button" onClick={props.onClick} className={styles.button_with_tooltip}>
                {shortTextString}
            </button>
        </Tooltip>
    );
};

export default ButtonWithTooltip;
