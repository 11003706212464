import { useEffect } from 'react';
import CheckboxDropdown from '../../../../Components/Form/Inputs/CheckboxDropdown';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from '../ValueMatrixCalculator.module.scss';
import { ContractGroupName, ValueMatrixTableRequest } from '../ValueMatrixDefinitions';

interface IValueMatrixTermClassificationDropdownProps {
    valueMatrixInputs: ValueMatrixTableRequest;
    setValueMatrixInputs: (value: ValueMatrixTableRequest) => void;
    termClassificationOptions: {
        label: string;
        value: {
            contractMonths: number[];
            contractGroupName: ContractGroupName;
        };
    }[];
}

const minimumNumberOfSelections = 1;
const maximumSelections = 5;

const ValueMatrixTermClassificationDropdown: React.FC<IValueMatrixTermClassificationDropdownProps> = (props: IValueMatrixTermClassificationDropdownProps) => {
    const [translations] = useLanguage();
    const valueMatrixStripTypeIsNotTwelveMonthStrip = props.valueMatrixInputs.stripType !== 'twelveMonthStrip';
    const valueMatrixStripTypeIsNotSpot = props.valueMatrixInputs.stripType !== 'spot';

    useEffect(() => {
        const termClassificationAlreadySelected = props.valueMatrixInputs.termClassification.every((x) =>
            props.termClassificationOptions.find((y) => y.value.contractGroupName === x.contractGroupName),
        );

        if ((valueMatrixStripTypeIsNotTwelveMonthStrip && valueMatrixStripTypeIsNotSpot) || termClassificationAlreadySelected) {
            return;
        }

        props.setValueMatrixInputs({
            ...props.valueMatrixInputs,
            termClassification: [props.termClassificationOptions[0].value],
            isCascadeUpdating: false,
        });
    }, [props.termClassificationOptions]);

    return (
        <div className={styles.value_matrix_dropdown}>
            <CheckboxDropdown
                testId="ValueMatrixTermClassificationDropdown"
                placeholder={props.termClassificationOptions
                    .filter((x) => props.valueMatrixInputs.termClassification.map((y) => y.contractGroupName).includes(x.value.contractGroupName))
                    .map((x) => x.label)
                    .join(', ')}
                options={props.termClassificationOptions}
                values={props.valueMatrixInputs.termClassification}
                handleOptionSelect={(value) => {
                    props.setValueMatrixInputs({ ...props.valueMatrixInputs, termClassification: value, updateTriggeredBy: 'generalFieldSelectionOrUpdate' });
                }}
                disabled={props.valueMatrixInputs.stripType === 'spot'}
                minimumSelectionLimit={minimumNumberOfSelections}
                maximumSelectionLimit={maximumSelections}
                limitMessage={translations.calculators.valueMatrix.text.limitMessage}
            />
        </div>
    );
};

export default ValueMatrixTermClassificationDropdown;
