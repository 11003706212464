import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useLocation } from 'react-router-dom';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import useEffectAfterFirstRender from '../Hooks/useEffectAfterFirstRenderHook';
import styles from './ErrorHandling.module.scss';

interface IErrorFallbackProps {
    handleResetErrorState: () => void;
}

const ErrorFallback: React.FC<IErrorFallbackProps> = (props: IErrorFallbackProps) => {
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();
    const location = useLocation();

    useEffectAfterFirstRender(() => {
        props.handleResetErrorState();
    }, [location, searchParameters?.tab]);

    return (
        <div className={styles.error_fallback}>
            <ErrorOutlineIcon />
            <div className={styles.error_fallback_message}>{translations.errors.componentError}</div>
        </div>
    );
};

export default ErrorFallback;
