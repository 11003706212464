import { Options, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import scssVariables from '../../../../Config.module.scss';
import {
    Currency,
    DemeterDataFrequency,
    DemeterFilterTimeSpan,
    DemeterUserType,
    MarketIndicatorOutlook,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserType } from '../../../../Redux/Slices/UserSlice';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import {
    areaRangeChartType,
    chartColors,
    ChartContext,
    ChartOptionsDefinitions,
    defalutLineWidth,
    defaultChartAxisTickLabelStyle,
    defaultChartAxisTitleStyle,
    defaultChartOptions,
    defaultZoneAxis,
    featuredLineWidth,
    IChartAreaRangeDataSeries,
    IChartDataSeries,
    IChartProps,
    lineChartType,
} from '../ChartDefinitions';
import chartService from '../ChartService';
import { getTimestampMap } from '../FilterTimeSpans/FilterTimeSpans';

export type MarketIndicatorColors =
    | typeof scssVariables.veryBullishColor
    | typeof scssVariables.bullishColor
    | typeof scssVariables.plainWhite
    | typeof scssVariables.bearishColor
    | typeof scssVariables.veryBearishColor;

export type PlotBands = {
    fromDate: number;
    toDate: number;
    color: MarketIndicatorColors;
};

export interface IMarketIndicatorChartRawProps extends IChartProps {
    lineSeries: IChartDataSeries[];
    areaRangeSeries?: IChartAreaRangeDataSeries[];
    plotBands: PlotBands[];
    hideNavigator?: boolean;
    currencies?: Currency[];
    unitOfMeasures?: UnitOfMeasure[];
    yAxisLabelSecondary?: string;
    useColorPalletteInLegend?: boolean;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
}

const lineSeriesBufferPercent = 5;

const MarketIndicatorChartRaw: React.FC<IMarketIndicatorChartRawProps> = (props: IMarketIndicatorChartRawProps) => {
    const chartReference = useRef<HighchartsReact.RefObject>();

    // Application hooks.
    const currentUserType = useApplicationSelector(selectUserType);
    const [translations] = useLanguage();

    // Chart hooks.
    const marketsChartDefaultOptions = useMemo<Options>(
        () => ({
            ...defaultChartOptions,
            yAxis: [
                {
                    title: {
                        text: '',
                        style: defaultChartAxisTitleStyle,
                    },
                    labels: {
                        format: '{value:{point.y: , .0f}',
                        style: defaultChartAxisTickLabelStyle,
                    },
                    maxPadding: lineSeriesBufferPercent / 100.0,
                    minPadding: lineSeriesBufferPercent / 100.0,
                },
                {
                    title: {
                        text: '',
                        style: defaultChartAxisTitleStyle,
                    },
                    opposite: true,
                    labels: {
                        format: '{value:{point.y: , .0f}',
                        style: defaultChartAxisTickLabelStyle,
                    },
                    maxPadding: lineSeriesBufferPercent / 100.0,
                    minPadding: lineSeriesBufferPercent / 100.0,
                },
            ],
            series: [],
        }),
        [],
    );

    const [highchartOptions, setHighchartOptions] = useState<Options>(marketsChartDefaultOptions);

    const getDataSeriesDefinitions = useCallback(() => {
        const areasDataSeries = props.areaRangeSeries?.map((areaSeries, index) => {
            const currentColor = chartColors.areaChartColorsRuleSetTwo[index];

            return {
                ...chartService.getDataSeriesBase(areaSeries, areaRangeChartType, currentColor),
                yAxis: 1,
                tooltip: {
                    enabled: currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.BusinessOwner,
                    customTooltipPerSeries() {
                        const context = this as unknown as ChartContext;
                        return chartService.getAreaRangeTooltipText(context);
                    },
                },
                zIndex: 1,
            };
        });

        const linesDataSeries = props.lineSeries.flatMap((lineSeries, index) => {
            const featuredColor = props.plotBands.length ? scssVariables.black : scssVariables.ruleSetTwoLineColor1;
            const currentColor = index === 0 ? featuredColor : chartColors.lineChartColorsRuleSetOne[index - 1];
            const primaryLine = index === 0 || lineSeries.isPrimaryLine;
            return [
                {
                    ...chartService.getDataSeriesBase(lineSeries, lineChartType, currentColor),
                    yAxis: lineSeries.yAxis ?? 0,
                    events: {
                        legendItemClick() {},
                    },
                    zoneAxis: defaultZoneAxis,
                    turboThreshold: 0,
                    tooltip: {
                        enabled: currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.BusinessOwner,
                        customTooltipPerSeries() {
                            const context = this as unknown as ChartContext;
                            const minimumValue = Math.min(...props.lineSeries[0].data.map((x) => Math.abs(x.value)).filter((x) => !!x));
                            const numberOfDecimalPlaces = formattingService.getDisplayDecimalPlacesMinimumForCharts(minimumValue ?? 0);

                            return chartService.getTooltipText(context, {
                                dataFrequency: DemeterDataFrequency.Weekly,
                                displayDecimalPlacesMinimum: props.displayDecimalPlacesMinimum ?? numberOfDecimalPlaces,
                                displayDecimalPlacesMaximum: props.displayDecimalPlacesMaximum ?? numberOfDecimalPlaces,
                            });
                        },
                    },
                    lineWidth: primaryLine ? featuredLineWidth : defalutLineWidth,
                    zIndex: 2,
                },
            ];
        });

        if (props.useColorPalletteInLegend) {
            const additionalFields = [
                chartService.getDataSeriesBase(
                    {
                        label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.Bearish],
                        data: [],
                    },
                    areaRangeChartType,
                    scssVariables.bearishColor,
                    true,
                ),
                chartService.getDataSeriesBase(
                    {
                        label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.Bullish],
                        data: [],
                    },
                    areaRangeChartType,
                    scssVariables.bullishColor,
                    true,
                ),
            ];

            const usesFullColorPlotbands = props.plotBands.some(
                (x) => x.color === scssVariables.veryBearishColor || x.color === scssVariables.veryBullishColor,
            );

            if (usesFullColorPlotbands) {
                additionalFields.unshift(
                    chartService.getDataSeriesBase(
                        {
                            label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.VeryBearish],
                            data: [],
                        },
                        areaRangeChartType,
                        scssVariables.veryBearishColor,
                        true,
                    ),
                );

                additionalFields.push(
                    chartService.getDataSeriesBase(
                        {
                            label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.VeryBullish],
                            data: [],
                        },
                        areaRangeChartType,
                        scssVariables.veryBullishColor,
                        true,
                    ),
                );
            }

            return [...linesDataSeries, ...(areasDataSeries ?? []), ...additionalFields];
        }

        return [...linesDataSeries, ...(areasDataSeries ?? [])];
    }, [props.lineSeries, props.areaRangeSeries]);

    // Main useEffect to update chart when props or data changes.
    useEffect(() => {
        if (props.lineSeries.length === 0) {
            return;
        }

        const dataSeries = getDataSeriesDefinitions();
        const allSeries = [...props.lineSeries, ...(props.areaRangeSeries ?? [])];
        const minimumValue = Math.min(...props.lineSeries[0].data.map((x) => Math.abs(x.value)).filter((x) => !!x));
        const numberOfDecimalPlaces = formattingService.getDisplayDecimalPlacesMinimumForCharts(minimumValue ?? 0);
        const downloadData = chartService.getDownloadDataForAreaRange(allSeries, dataSeries as ChartOptionsDefinitions[], numberOfDecimalPlaces);

        const newOptions = {
            ...marketsChartDefaultOptions,
            ...{ series: dataSeries },
            ...{
                xAxis: {
                    ...marketsChartDefaultOptions.xAxis,
                    plotBands: props.plotBands.map((x) => ({
                        from: x.fromDate,
                        to: x.toDate,
                        color: x.color,
                    })),
                    scrollbar: {
                        enabled: false,
                    },
                },
            },
            ...{ yAxis: [...(marketsChartDefaultOptions.yAxis as YAxisOptions[])!] },
            ...{
                navigator: {
                    ...highchartOptions.navigator,
                    ...{
                        enabled: !props.hideNavigator,
                    },
                    maskFill: scssVariables.ruleSetTwoAreaColor1,
                },
            },
            tooltip: {
                formatter() {
                    return (this as unknown as ChartContext).series.tooltipOptions.customTooltipPerSeries.call(this);
                },
            },
            downloadData,
        };

        props.unitOfMeasures?.forEach((x, index) => {
            (newOptions.yAxis as YAxisOptions[])[index].labels!.format = `{value:{point.y: , .${numberOfDecimalPlaces}f}`;
            (newOptions.yAxis as YAxisOptions[])[index].title!.text = chartService.getCurrencyAndUnitOfMeasureText(x, props.currencies![index]);
        });
        newOptions.yAxis[1]!.title!.text = props.yAxisLabelSecondary;

        setHighchartOptions(newOptions as Options);
    }, [
        props.lineSeries,
        props.areaRangeSeries,
        props.unitOfMeasures,
        props.currencies,
        props.yAxisLabelSecondary,
        props.displayDecimalPlacesMinimum,
        props.displayDecimalPlacesMaximum,
    ]);

    useEffect(() => {
        if (props.hideNavigator || !props.lineSeries.length || !chartReference.current) {
            return;
        }

        const oldestDate = Math.min(...props.lineSeries.map((x) => x.data[0]?.asOfDate.getTime()));
        const latestDataDate = Math.max(...props.lineSeries.map((x) => x.data[x.data.length - 1]?.asOfDate.getTime()));
        const minimumDateForTimeSpan = getTimestampMap(new Date(latestDataDate))['OneYear' as DemeterFilterTimeSpan];

        chartReference.current?.chart.xAxis[0].setExtremes(Math.max(minimumDateForTimeSpan, oldestDate), latestDataDate);
    }, [props.hideNavigator, props.lineSeries, chartReference.current]);

    return (
        <HighchartsReact
            ref={(newChartReference: HighchartsReact.RefObject) => {
                if (chartReference.current || !newChartReference) {
                    return;
                }

                chartReference.current = newChartReference;
                if (props.chartReference) {
                    props.chartReference(newChartReference);
                }
            }}
            highcharts={HighStock}
            options={highchartOptions}
            containerProps={{ style: { height: '100%' } }}
        />
    );
};

export default memo(MarketIndicatorChartRaw);
