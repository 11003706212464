import { useMemo } from 'react';
import {
    DemeterDataSource,
    DemeterMarket,
    DemeterTableDefinitionModel,
    DemeterTableDefinitionRegionModel,
    DemeterTableDefinitionType,
} from '../../../../Generated/Raven-Demeter/api';
import useTableDefinitionsApi from '../../../Apis/Hooks/useTableDefinitionsApiHook';
import { IRegionCommoditySelection } from './useRegionCommodityNavigationHook';

const useTableDefinition = (
    tableDefinitionType: DemeterTableDefinitionType,
    regionCommoditySelection: IRegionCommoditySelection,
    overrideMarket?: DemeterMarket,
): [tableDefinitionRegion?: DemeterTableDefinitionRegionModel, tableDefinitionCommodity?: DemeterTableDefinitionModel] => {
    // We have to use the production table definition when querying for a margin table definition.
    const tableDefinitions = useTableDefinitionsApi(
        tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyMarginTable
            ? DemeterTableDefinitionType.CommodityMonthlyProductionTable
            : tableDefinitionType,
        false,
        overrideMarket,
    );

    const tableDefinitionRegion = useMemo<DemeterTableDefinitionRegionModel | undefined>(
        () => (tableDefinitions ? tableDefinitions.find((x) => x.region === regionCommoditySelection.region) : undefined),
        [tableDefinitions, regionCommoditySelection],
    );

    const tableDefinitionCommodity = useMemo<DemeterTableDefinitionModel | undefined>(() => {
        if (!tableDefinitions) {
            return undefined;
        }

        const tableDefinitionGroup = tableDefinitionRegion?.demeterTableDefinitionGroups.find(
            (group) =>
                group.commodity === regionCommoditySelection.commodity &&
                ((!group.extraParameters && !regionCommoditySelection.extraParameters) ||
                    group.extraParameters === regionCommoditySelection.extraParameters ||
                    (!group.extraParameters && regionCommoditySelection.extraParameters === DemeterDataSource.All)),
        );

        if (tableDefinitionGroup) {
            return {
                displayName: tableDefinitionGroup.displayName,
                commodity: tableDefinitionGroup.commodity,
                demeterCommentaryGuid: tableDefinitionGroup.demeterCommentaryGuid,
                extraParameters: tableDefinitionGroup.extraParameters,
                displayDecimalPlacesMaximum: tableDefinitionGroup.displayDecimalPlacesMaximum,
                displayDecimalPlacesMinimum: tableDefinitionGroup.displayDecimalPlacesMinimum,
                subRegions: [],
                isSubGroupHeader: false,
                hasSubGroupBreakBefore: false,
                order: 1,
                dataFrequencies: tableDefinitionGroup.dataFrequencies,
            };
        }

        return tableDefinitionRegion?.demeterTableDefinitionGroups
            .flatMap((group) => group.demeterTableDefinitions.filter((definition) => definition.commodity === regionCommoditySelection.commodity))
            .find(
                (definition) =>
                    (!definition.extraParameters && !regionCommoditySelection.extraParameters) ||
                    definition.extraParameters === regionCommoditySelection.extraParameters ||
                    (!definition.extraParameters && regionCommoditySelection.extraParameters === DemeterDataSource.All),
            );
    }, [tableDefinitions, regionCommoditySelection]);

    return [tableDefinitionRegion, tableDefinitionCommodity];
};

export default useTableDefinition;
