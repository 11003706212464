/* eslint-disable no-nested-ternary */
import { FC, useEffect, useMemo, useState } from 'react';
import { UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import Carousel from '../../../Components/Carousel/Carousel';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import BasisCalculatorChart from './BasisCalculatorChart';
import { BasisCalculatorChartText, BasisCalculatorProductPair, BasisPeriod } from './BasisCalculatorDefinitions';
import { IBasisCalculatorCalculationResult } from './BasisCalculatorService';

interface IBasisCalculatorChartCarouselProps {
    productPair: BasisCalculatorProductPair;
    calculationResult: IBasisCalculatorCalculationResult | undefined;
    product1ChartText: BasisCalculatorChartText;
    product2ChartText: BasisCalculatorChartText;
}

const BasisCalculatorChartCarousel: FC<IBasisCalculatorChartCarouselProps> = (props: IBasisCalculatorChartCarouselProps) => {
    const [translations] = useLanguage();
    const [dataSourceTag, setDataSourceTag] = useState<string[]>([]);

    useEffect(() => {
        if (!props.productPair) {
            return;
        }

        const pricesDataSource = [...props.product1ChartText.dataSourceTags];
        setDataSourceTag(pricesDataSource.concat(props.product2ChartText.dataSourceTags).sort((a, b) => a.length - b.length));
    }, [props.product1ChartText, props.product2ChartText]);

    const product1Name = useMemo(() => props.product1ChartText.productName ?? '', [translations, props.productPair.product1, props.product1ChartText]);

    const product1NameForwardCurve = useMemo(
        () =>
            `${props.product1ChartText.productNameForwardCurve ?? props.product1ChartText.productName} ${
                props.calculationResult?.prices1HasCalculatedValues ? translations.words.calculated : ''
            } ${translations.text.forwardCurve}`,
        [translations, props.productPair.product1, props.product1ChartText, props.calculationResult],
    );

    const product2Name = useMemo(() => {
        if (props.productPair.useRegression) {
            return translations.calculators.basis.charts.regression;
        }

        return `${props.product2ChartText.productName ?? ''} ${
            props.calculationResult?.basisLagPeriod && translations.calculators.basis.basisLagPeriod[props.calculationResult?.basisLagPeriod]
        }`;
    }, [translations, props.productPair.useRegression, props.productPair.product2, props.product2ChartText, props.calculationResult]);

    const product2NameForwardCurve = useMemo(() => {
        if (props.productPair.useRegression) {
            return `${translations.calculators.basis.charts.regression} ${translations.text.forwardCurve}`;
        }

        return `${props.product2ChartText.productNameForwardCurve ?? props.product2ChartText.productName} ${
            props.calculationResult?.lagsHasCalculatedValues ? translations.words.calculated : ''
        } ${translations.text.forwardCurve}`;
    }, [translations, props.productPair.useRegression, props.productPair.product2, props.product2ChartText, props.calculationResult]);

    const displayDecimalPlacesMinimum = useMemo(() => {
        const basisValues = props.calculationResult?.basis ?? [];

        if (basisValues.length === 0) {
            return 0;
        }

        return formattingService.getDisplayDecimalPlacesMinimumForCharts(Math.max(...basisValues.map((x) => x.value ?? 0)));
    }, [props.calculationResult?.basis]);

    const isLoading = useMemo(
        () => !props.product1ChartText.productName || !props.product2ChartText.productName,
        [props.product1ChartText.productName, props.product2ChartText.productName],
    );

    return (
        <div className={styles.basis_calculator_chart_carousel}>
            <Carousel>
                <BasisCalculatorChart
                    title={
                        props.productPair.useRegression
                            ? translations.calculators.basis.charts.basisAnalysis
                            : translations.calculators.basis.charts.basisHistory
                    }
                    dataSourceTag={dataSourceTag}
                    currency={props.productPair.currency}
                    unitOfMeasure={props.productPair.unitOfMeasure}
                    linesSeries={[
                        {
                            label: product1Name,
                            forecastLabel: product1NameForwardCurve,
                            data: props.calculationResult?.prices1 ?? [],
                        },
                        {
                            label: product2Name,
                            forecastLabel: product2NameForwardCurve,
                            data: props.productPair.useRegression ? props.calculationResult?.regression ?? [] : props.calculationResult?.lags ?? [],
                        },
                    ]}
                    barSeries={{
                        label: translations.calculators.basis.basisPeriod[BasisPeriod.Basis] ?? '',
                        forecastLabel:
                            props.calculationResult?.prices1HasCalculatedValues || props.calculationResult?.lagsHasCalculatedValues
                                ? `${
                                      props.productPair.basisAdjustment ? translations.calculators.basis.basisAdjustment[props.productPair.basisAdjustment] : ''
                                  } ${props.productPair.basisPeriod ? translations.calculators.basis.basisPeriod[props.productPair.basisPeriod] : ''}`
                                : `${translations.calculators.basis.basisPeriod[BasisPeriod.Basis]} ${translations.text.forwardCurve}`,
                        data: props.calculationResult?.basis ?? [],
                        format: 'currency',
                    }}
                    displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                    isLoading={isLoading}
                />
                <BasisCalculatorChart
                    title={translations.calculators.basis.charts.rollingBasisHistory}
                    dataSourceTag={dataSourceTag}
                    linesSeries={[
                        {
                            label: translations.calculators.basis.basisPeriod[BasisPeriod.ThreeMonthBasis],
                            data: props.calculationResult?.threeMonthsBasis ?? [],
                        },
                        {
                            label: translations.calculators.basis.basisPeriod[BasisPeriod.SixMonthBasis],
                            data: props.calculationResult?.sixMonthsBasis ?? [],
                        },
                        {
                            label: translations.calculators.basis.basisPeriod[BasisPeriod.NineMonthBasis],
                            data: props.calculationResult?.nineMonthsBasis ?? [],
                        },
                        {
                            label: translations.calculators.basis.basisPeriod[BasisPeriod.TwelveMonthBasis],
                            data: props.calculationResult?.twelveMonthsBasis ?? [],
                        },
                    ]}
                    barSeries={{
                        label: '',
                        data: [],
                        isHidden: true,
                    }}
                    displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                    isLoading={isLoading}
                />
                <BasisCalculatorChart
                    title={translations.calculators.basis.charts.rollingBasisHistory}
                    dataSourceTag={dataSourceTag}
                    linesSeries={[
                        {
                            label:
                                (props.calculationResult?.basisPeriod && translations.calculators.basis.basisPeriod[props.calculationResult?.basisPeriod]) ??
                                '',
                            data:
                                props.calculationResult?.basisPeriod === BasisPeriod.ThreeMonthBasis
                                    ? props.calculationResult?.threeMonthsBasis
                                    : props.calculationResult?.basisPeriod === BasisPeriod.SixMonthBasis
                                    ? props.calculationResult?.sixMonthsBasis
                                    : props.calculationResult?.basisPeriod === BasisPeriod.NineMonthBasis
                                    ? props.calculationResult?.nineMonthsBasis
                                    : props.calculationResult?.basisPeriod === BasisPeriod.TwelveMonthBasis
                                    ? props.calculationResult?.twelveMonthsBasis
                                    : props.calculationResult?.basis ?? [],
                        },
                        {
                            label:
                                (props.calculationResult?.basisAdjustment &&
                                    translations.calculators.basis.basisAdjustment[props.calculationResult?.basisAdjustment]) ??
                                '',
                            data: props.calculationResult?.metricSeries ?? [],
                        },
                    ]}
                    barSeries={{
                        label: '',
                        data: [],
                        isHidden: true,
                    }}
                    displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                    isLoading={isLoading}
                />
                <BasisCalculatorChart
                    title={translations.calculators.basis.charts.twelveMonthRollingCorrelation}
                    dataSourceTag={dataSourceTag}
                    unitOfMeasure={UnitOfMeasure.Percent}
                    linesSeries={[
                        {
                            label: translations.calculators.basis.charts.correlation,
                            data: props.calculationResult?.twelveMonthRollingCorrelation ?? [],
                        },
                    ]}
                    barSeries={{
                        label: '',
                        data: [],
                        isHidden: true,
                    }}
                    displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                    isLoading={isLoading}
                />
            </Carousel>
        </div>
    );
};

export default BasisCalculatorChartCarousel;
