import scssVariables from '../../../../Config.module.scss';
import { GetMarketIndicatorFactorDataResponse } from '../../../../Generated/Raven-Demeter';

export interface IMarketIndicatorUserChartBaseProps {
    runTestMarketIndicatorFactorResponse?: GetMarketIndicatorFactorDataResponse;
    title?: string;
    showOnlyAsPopout?: boolean;
    showPopout?: boolean;
    setShowPopout?: (showPopout: boolean) => void;
    isLoading?: boolean;
}

export interface IMarketIndicatorChartColorPalette {
    lineChartColorsRuleSetTwo: string[];
    dataDecileAlphaColorsRuleSetTwo?: string[];
    dataDecileColorsRuleSetTwo?: string[];
    priceDecileAlphaColorsRuleSetTwo?: string[];
}

export const defaultChartPalette: IMarketIndicatorChartColorPalette = {
    dataDecileAlphaColorsRuleSetTwo: [
        scssVariables.ruleSetTwoDataDecileColorAlpha1,
        scssVariables.ruleSetTwoDataDecileColorAlpha2,
        scssVariables.ruleSetTwoDataDecileColorAlpha3,
        scssVariables.ruleSetTwoDataDecileColorAlpha4,
        scssVariables.ruleSetTwoDataDecileColorAlpha5,
    ],

    priceDecileAlphaColorsRuleSetTwo: [
        scssVariables.ruleSetTwoPriceDecileAlphaNinetyToOneHundred,
        scssVariables.ruleSetTwoPriceDecileAlphaEightyToNinety,
        scssVariables.ruleSetTwoPriceDecileAlphaSeventyToEighty,
        scssVariables.ruleSetTwoPriceDecileAlphaSixtyToSeventy,
        scssVariables.ruleSetTwoPriceDecileAlphaFiftyToSixty,
        scssVariables.ruleSetTwoPriceDecileAlphaFourtyToFifty,
        scssVariables.ruleSetTwoPriceDecileAlphaThirtyToFourty,
        scssVariables.ruleSetTwoPriceDecileAlphaTwentyToThirty,
        scssVariables.ruleSetTwoPriceDecileAlphaTenToTwenty,
        scssVariables.ruleSetTwoPriceDecileAlphaZeroToTen,
    ],

    lineChartColorsRuleSetTwo: [
        scssVariables.ruleSetTwoLineColor1,
        scssVariables.ruleSetTwoLineColor2,
        scssVariables.ruleSetTwoLineColor3,
        scssVariables.ruleSetTwoLineColor4,
        scssVariables.ruleSetTwoLineColor5,
        scssVariables.ruleSetTwoLineColor6,
        scssVariables.ruleSetTwoLineColor7,
        scssVariables.ruleSetTwoLineColor8,
        scssVariables.ruleSetTwoLineColor9,
        scssVariables.ruleSetTwoLineColor10,
        scssVariables.ruleSetTwoLineColor11,
        scssVariables.ruleSetTwoLineColor12,
        scssVariables.ruleSetTwoLineColor13,
        scssVariables.ruleSetTwoLineColor14,
    ],
};
