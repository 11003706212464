import TextInput from '../../../../../../Components/Form/Inputs/TextInput';
import RegexValidators from '../../../../../../Core/Validation/RegexValidators';
import formattingService from '../../../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { CombinationParameters, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from './CombinationTabs.module.scss';

interface ICombinationMultiplierColumnProps {
    parentUpsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setParentUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
    columnNumber: number;
}

const CombinationMultiplierColumn: React.FC<ICombinationMultiplierColumnProps> = (props: ICombinationMultiplierColumnProps) => {
    const [translations] = useLanguage();
    const combinationParameters = props.parentUpsertMarketIndicatorFactorRequest.parameters as CombinationParameters;

    return (
        <div className={styles.combination_multipliers_column}>
            {Object.values(combinationParameters).map((_, index) => (
                <div className={styles.master_flex}>
                    {props.columnNumber === 0 && <div className={styles.combination_multipliers_month_label}>{formattingService.toMonth(index + 1)}</div>}
                    <div className={styles.combination_multipliers_input_area}>
                        <TextInput
                            key={`${formattingService.toMonth(index + 1)}-${props.columnNumber}`}
                            required
                            value={combinationParameters[`multipliers${index + 1}` as keyof CombinationParameters].split(',')[props.columnNumber] ?? 0}
                            handleTextChange={(value) => {
                                const multiplierAsArray = combinationParameters[`multipliers${index + 1}` as keyof CombinationParameters].split(',');
                                multiplierAsArray[props.columnNumber] = value;

                                const request = {
                                    ...props.parentUpsertMarketIndicatorFactorRequest,
                                    parameters: {
                                        ...props.parentUpsertMarketIndicatorFactorRequest.parameters,
                                        [`multipliers${index + 1}` as keyof CombinationParameters]: multiplierAsArray.join(','),
                                    },
                                };

                                props.setParentUpsertMarketIndicatorFactorRequest(request);
                            }}
                            validation={RegexValidators.Decimal}
                            errorMessage={translations.marketIndicatorsManagement.messages.positiveNumber}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CombinationMultiplierColumn;
