import { useEffect, useMemo, useState } from 'react';
import { CommodityWeeklyPriceSummaryModel, DemeterCommodity } from '../../../../Generated/Raven-Demeter/api';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styleService from '../../../Services/Styles/StyleService';
import PricesSummaryTableWrapper from '../PricesSummaryTableWrapper';
import styles from '../PriceSummaries.module.scss';
import Sparkline from '../Sparkline';

interface IEuropeanUnionWeeklySummaryIndicesProps {
    summaryData?: CommodityWeeklyPriceSummaryModel[];
}

const EuropeanUnionWeeklySummaryIndices: React.FC<IEuropeanUnionWeeklySummaryIndicesProps> = (props: IEuropeanUnionWeeklySummaryIndicesProps) => {
    const [translations] = useLanguage();

    const columnHeaders = useMemo(
        () => [
            translations.dashboard.priceSummary.europeanUnion.headers.weeklyCheeseIndices,
            '',
            formattingService.toDayMonth(props.summaryData ? new Date(props.summaryData[0].previousWeekPrice.asOfDate) : '') ?? '',
            formattingService.toDayMonth(props.summaryData ? new Date(props.summaryData[0].asOfDate) : '') ?? '',
            translations.words.change,
            translations.fields.percentChange,
        ],
        [props.summaryData],
    );

    const [summaryDataFiltered, setSummaryDataFiltered] = useState<CommodityWeeklyPriceSummaryModel[]>([]);

    useEffect(() => {
        if (!props.summaryData) {
            return;
        }

        const subRegionCommodities: DemeterCommodity[] = [];
        props.summaryData?.forEach((row: CommodityWeeklyPriceSummaryModel) => {
            if (row.subRegion && !subRegionCommodities.some((addedCommodity) => addedCommodity === row.commodity)) {
                subRegionCommodities.push(row.commodity);
            }
        });

        setSummaryDataFiltered(props.summaryData?.filter((row) => !subRegionCommodities.includes(row.commodity)));
    }, [props.summaryData]);

    return (
        <PricesSummaryTableWrapper
            className={styles.price_summary_table_cheese_indices}
            loading={!props.summaryData || props.summaryData.length === 0}
            columnHeaders={columnHeaders}
            largeFirstHeader
        >
            <>
                {summaryDataFiltered.map((row: CommodityWeeklyPriceSummaryModel) => {
                    const { historicalSummary, currentWeekPrice, commodity } = row;
                    const changeClass = styleService.getPositiveOrNegativeClass(currentWeekPrice.netChange!);
                    return (
                        <>
                            <p>{translations.commodity[commodity]}</p>
                            <p className={styles.price_summary_sparkline}>
                                <Sparkline data={row.weeklyValues} />
                            </p>
                            <p>{formattingService.toPriceString(historicalSummary.priceOneWeekAgo)}</p>
                            <p>{formattingService.toPriceString(historicalSummary.priceCurrentWeek)}</p>
                            <p className={changeClass}>{formattingService.toPriceString(currentWeekPrice.netChange)}</p>
                            <p className={changeClass}>{formattingService.toPercent(currentWeekPrice.percentChange)}</p>
                        </>
                    );
                })}
            </>
        </PricesSummaryTableWrapper>
    );
};

export default EuropeanUnionWeeklySummaryIndices;
