import React from 'react';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './SmallDisclaimerText.module.scss';

const currentYear = new Date().getFullYear();

const SmallDisclaimerText: React.FC = () => {
    const [translations] = useLanguage();

    return (
        <div className={styles.small_disclaimer_text}>
            {translations.pageFooterDisclaimer} &copy; {`${currentYear} ${translations.footer.text.allRightsReserved}`}
        </div>
    );
};

export default SmallDisclaimerText;
