import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Popover } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import IconButton from '../Buttons/IconButton';
import InputContainer from './InputContainer';
import styles from './Inputs.module.scss';
import TextInput from './TextInput';

interface IDatePickerInputProps {
    title?: string;
    value?: Date | null;
    required?: boolean;
    handleDateChange: (date: Date | undefined) => void;
    oldestAllowedDate?: Date;
    mostRecentAllowedDate?: Date;
    testId?: string;
}

const DatePickerInput: React.FC<IDatePickerInputProps> = (props: IDatePickerInputProps) => {
    const [translations, , language] = useLanguage();
    const anchorRef = useRef(null);
    const acceptedDateFormat = moment.localeData().longDateFormat('L');
    const [textInputValue, setTextInputValue] = useState<string | null>(props.value ? formattingService.toShortDayMonthYear(props.value) : null);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const isMomentAcceptedValue = (value: string) => {
        const valueAsDate = moment(value, acceptedDateFormat, true);
        return valueAsDate.isValid() && (!props.oldestAllowedDate || moment(props.oldestAllowedDate).isSameOrBefore(valueAsDate));
    };

    const handleDateChange = (newValue: Moment | string | null, selectionState?: PickerSelectionState) => {
        const dateTime = newValue ? new Date(newValue as string) : null;
        const momentDate = formattingService.toShortDayMonthYear(dateTime);

        setTextInputValue(momentDate);
        props.handleDateChange(dateTime ?? undefined);

        if (selectionState === 'finish') {
            handlePopoverClose();
        }
    };

    const handleTextChange = (value: string) => {
        const momentFormatIsValid = isMomentAcceptedValue(value);
        const momentConvertedDate = moment(value, acceptedDateFormat).toDate();

        if (momentFormatIsValid && !Number.isNaN(momentConvertedDate.getTime())) {
            props.handleDateChange(momentConvertedDate);
        }

        if (value === '') {
            props.handleDateChange(undefined);
        }

        setTextInputValue(value);
    };

    const handlePopoverOpen = () => {
        setIsCalendarOpen(true);
    };

    const handlePopoverClose = () => {
        setIsCalendarOpen(false);
    };

    // When the page first loads there is sometimes a delay in getting that inital props.value.
    useEffect(() => {
        if (formattingService.toShortDayMonthYear(props.value) === textInputValue || !props.value || props.value === null) {
            return;
        }
        setTextInputValue(formattingService.toShortDayMonthYear(props.value));
    }, [props.value]);

    return (
        <InputContainer title={props.title}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                <div className={styles.date_picker}>
                    <TextInput
                        testId={props.testId}
                        required={props.required}
                        validation={isMomentAcceptedValue}
                        placeholder={acceptedDateFormat}
                        handleTextChange={handleTextChange}
                        value={textInputValue}
                        errorMessage={
                            props.oldestAllowedDate
                                ? `${translations.errors.dateFormatWithOldestDateError} ${formattingService.toShortDayMonthYear(props.oldestAllowedDate)}.`
                                : translations.errors.dateFormatError
                        }
                    />
                    <div className={styles.date_picker_icon_button}>
                        <IconButton reference={anchorRef} handleClick={handlePopoverOpen}>
                            <CalendarTodayIcon />
                        </IconButton>
                        <Popover
                            open={isCalendarOpen}
                            anchorEl={anchorRef.current}
                            onClose={handlePopoverClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <DateCalendar
                                className={styles.date_picker_calendar}
                                value={props.value && props.value !== null ? moment(props.value) : null}
                                onChange={handleDateChange}
                                minDate={moment(props.oldestAllowedDate ?? new Date(0))}
                                maxDate={props.mostRecentAllowedDate ? moment(props.mostRecentAllowedDate) : undefined}
                            />
                        </Popover>
                    </div>
                </div>
            </LocalizationProvider>
        </InputContainer>
    );
};

export default DatePickerInput;
