/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo } from 'react';
import { CommoditySpotPriceSummaryModel } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styleService from '../../../Services/Styles/StyleService';
import PricesSummaryTableWrapper from '../PricesSummaryTableWrapper';
import styles from '../PriceSummaries.module.scss';
import Sparkline from '../Sparkline';

interface ICmeDailySummaryProps {
    summaryData?: CommoditySpotPriceSummaryModel[];
}

const CmeSpotSummaryDaily: React.FC<ICmeDailySummaryProps> = (props: ICmeDailySummaryProps) => {
    const [translations] = useLanguage();

    const columnHeaders = useMemo(
        () => [
            translations.dashboard.priceSummary.cme.headers.daily,
            '',
            translations.words.settlement,
            translations.words.previous,
            translations.words.change,
            translations.fields.percentChange,
            translations.words.trades,
            translations.words.bids,
            translations.words.offers,
        ],
        [],
    );
    return (
        <PricesSummaryTableWrapper
            className={styles.price_summary_table_daily}
            loading={!props.summaryData || props.summaryData.length === 0}
            columnHeaders={columnHeaders}
            largeFirstHeader
        >
            <>
                {props.summaryData?.map((row: CommoditySpotPriceSummaryModel) => {
                    const { currentPrice, commodity } = row;
                    const changeClass = styleService.getPositiveOrNegativeClass(currentPrice.netChange!);
                    return (
                        <>
                            <p>{translations.commodity[commodity]}</p>
                            <p className={styles.price_summary_sparkline}>
                                <Sparkline data={row.dailyValues} />
                            </p>
                            <p>{formattingService.toPriceString(currentPrice.value)}</p>
                            <p>{formattingService.toPriceString(currentPrice.previousValue)}</p>
                            <p className={changeClass}>{formattingService.toPriceString(currentPrice.netChange)}</p>
                            <p className={changeClass}>{formattingService.toPercent(currentPrice.percentChange)}</p>
                            <p>{currentPrice.sales}</p>
                            <p>{currentPrice.bids}</p>
                            <p>{currentPrice.offers}</p>
                        </>
                    );
                })}
            </>
        </PricesSummaryTableWrapper>
    );
};

export default CmeSpotSummaryDaily;
