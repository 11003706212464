import { Clear, Done, Reviews } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import { AddMarketIndicatorReviewRequest, MarketIndicatorReviewStatus } from '../../../Generated/Raven-Demeter';
import useApi from '../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import IconButton from '../../Components/Form/Buttons/IconButton';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import TextArea from '../../Components/Form/Inputs/TextArea';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import formattingService from '../../Services/Formatting/FormattingService';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './MarketIndicatorFactorsPage.module.scss';

type MarketIndicatorReview = {
    approved: boolean;
    reviewedBy: string;
    reviwedAt: Date;
};

interface IMarketIndicatorReviewFormProps {
    marketIndicatorGuid?: string;
    show?: boolean;
}

const MarketIndicatorReviewForm: React.FC<IMarketIndicatorReviewFormProps> = (props: IMarketIndicatorReviewFormProps) => {
    const [translations] = useLanguage();
    const [showForm, setShowForm] = useState(false);
    const [marketIndicatorGuid, setMarketIndicatorGuid] = useState(props.marketIndicatorGuid);
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');
    const [lastReview, setLastReview] = useState<MarketIndicatorReview | null>();

    // Api hooks.
    const [, getMarketIndicatorLastReview, getMarketIndicatorLastReviewResponse] = useApi(() =>
        demeterMarketIndicatorsApi.getMarketIndicatorReviewCurrent(props.marketIndicatorGuid!),
    );
    const [, addMarketIndicatorReview, addMarketIndicatorReviewResponse] = useApiWithoutAutoExecute(
        (request: AddMarketIndicatorReviewRequest | undefined) => demeterMarketIndicatorsApi.addMarketIndicatorReview(props.marketIndicatorGuid!, request!),
        {
            successMessage: translations.marketIndicatorsManagement.messages.marketIndicatorReviewAddSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.marketIndicatorReviewAddFailed,
        },
    );

    useEffect(() => {
        if (!props.marketIndicatorGuid) {
            return;
        }
        setMarketIndicatorGuid(props.marketIndicatorGuid);
        getMarketIndicatorLastReview();
    }, [props.marketIndicatorGuid]);

    useEffect(() => {
        if (!getMarketIndicatorLastReviewResponse || !getMarketIndicatorLastReviewResponse.marketIndicatorReview) {
            return;
        }
        setLastReview({
            approved: getMarketIndicatorLastReviewResponse.marketIndicatorReview.status === MarketIndicatorReviewStatus.Approved,
            reviewedBy: getMarketIndicatorLastReviewResponse.marketIndicatorReview.reviewedBy,
            reviwedAt: new Date(getMarketIndicatorLastReviewResponse.marketIndicatorReview.reviewedAt),
        });
    }, [getMarketIndicatorLastReviewResponse]);

    useEffect(() => {
        setError('');
        setComment('');
        getMarketIndicatorLastReview();
    }, [addMarketIndicatorReviewResponse]);

    const handleShowForm = () => {
        setShowForm((prevState) => !prevState);
    };

    const handleCommentChange = (text: string) => {
        setComment(text);
        if (text) {
            setError('');
        }
    };

    const handleReject = () => {
        if (!comment || comment.length === 0) {
            setError(translations.marketIndicatorsManagement.reviewForm.commentError);
            return;
        }
        addMarketIndicatorReview({
            marketIndicatorGuid: marketIndicatorGuid ?? '',
            status: MarketIndicatorReviewStatus.Rejected,
            comment,
        });
    };

    const handleApprove = () => {
        addMarketIndicatorReview({
            marketIndicatorGuid: marketIndicatorGuid ?? '',
            status: MarketIndicatorReviewStatus.Approved,
            comment,
        });
    };

    if (!props.show) {
        return null;
    }

    return (
        <div className={`${styles.market_indicator_review_form} ${showForm ? styles.visible : ''}`}>
            <div onClick={handleShowForm} className={styles.market_indicator_review_form_show_button} role="button" onKeyPress={() => {}} tabIndex={0}>
                <Reviews className={styles.market_indicator_review_form_show_button_icon} />
                {translations.marketIndicatorsManagement.reviewForm.contentReview}
            </div>
            <div className={styles.market_indicator_review_form_content}>
                <div className={styles.market_indicator_review_form_header}>
                    <ComponentHeader title={translations.marketIndicatorsManagement.reviewForm.title} />
                    <IconButton handleClick={handleShowForm} size="small" color="default">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
                <div className={styles.market_indicator_review_form_description}>{translations.marketIndicatorsManagement.reviewForm.description}</div>
                <TextArea
                    title={translations.marketIndicatorsManagement.reviewForm.comment}
                    className={error ? styles.market_indicator_review_form_content_textarea_error : styles.market_indicator_review_form_content_textarea}
                    value={comment}
                    error={error}
                    handleTextChange={handleCommentChange}
                />
                {lastReview && (
                    <div className={styles.market_indicator_review_form_content_last_review}>
                        <div className={styles.market_indicator_review_form_content_last_review_icon}>
                            {lastReview.approved ? (
                                <Done className={styles.market_indicator_review_form_content_last_review_icon_approved} fontSize="small" />
                            ) : (
                                <Clear className={styles.market_indicator_review_form_content_last_review_icon_rejected} fontSize="small" />
                            )}
                        </div>
                        <div className={styles.market_indicator_review_form_content_last_review_status}>
                            {lastReview.approved
                                ? translations.marketIndicatorsManagement.reviewForm.approvedBy
                                : translations.marketIndicatorsManagement.reviewForm.rejectedBy}
                        </div>
                        <div className={styles.market_indicator_review_form_content_last_review_reviewed_by}>{lastReview.reviewedBy}</div>
                        <div className={styles.market_indicator_review_form_content_last_review_reviewed_at}>
                            {formattingService.toLongDateAndTimeFormat(lastReview.reviwedAt)}
                        </div>
                    </div>
                )}
                <div className={styles.market_indicator_review_form_button_group}>
                    <LinkButton title={translations.marketIndicatorsManagement.reviewForm.reject} type={LinkButtonType.White} onClick={handleReject} />
                    <LinkButton title={translations.marketIndicatorsManagement.reviewForm.approve} type={LinkButtonType.White} onClick={handleApprove} />
                </div>
            </div>
        </div>
    );
};

export default MarketIndicatorReviewForm;
