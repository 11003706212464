import { demeterApi } from '../../../Apis/Apis';
import { DemeterFeatureType, DemeterTableDefinitionType, DemeterUserType, ListDemeterUserFeaturesResponseFeatures } from '../../../Generated/Raven-Demeter';
import { setFeatureFlags } from '../../../Redux/Slices/UserSlice';
import applicationInitializationService from '../ApplicationInitializationService';
import formattingService from '../Formatting/FormattingService';

type FeatureTypeKey = keyof typeof DemeterFeatureType;
type FeaturesObjectType = { [key in FeatureTypeKey]?: boolean };

export type FeatureFlags = {
    features: FeaturesObjectType;
};

class FeatureFlagsService {
    private featureFlags: FeatureFlags = { features: {} };

    initializeFeatureFlags = async (storeId: string, userType?: DemeterUserType): Promise<FeatureFlags> => {
        const response = await demeterApi.listDemeterUserFeatures(userType);
        const newFlags: FeatureFlags = { features: {} };

        newFlags.features = {};
        Object.keys(response.data.features ?? {}).forEach((featureType: string) => {
            const featureTypeKey = formattingService.toPascalCase(featureType) as FeatureTypeKey;

            const isOn = !!(response.data.features ?? {})[featureType as keyof ListDemeterUserFeaturesResponseFeatures];
            newFlags.features[featureTypeKey] = isOn;
        });

        this.featureFlags = newFlags;
        applicationInitializationService.getStore(storeId).dispatch(setFeatureFlags({ featureFlags: this.featureFlags }));

        return this.featureFlags;
    };

    getFeatureFlags = (): FeatureFlags => this.featureFlags;

    getFeatureFlag = (featureType: DemeterFeatureType): boolean => !!this.featureFlags.features[featureType];

    setFeatureFlag = (storeId: string, isOn: boolean, featureType: DemeterFeatureType): void => {
        this.featureFlags = {
            features: {
                ...this.featureFlags.features,
                [featureType]: isOn,
            },
        };

        applicationInitializationService.getStore(storeId).dispatch(setFeatureFlags({ featureFlags: this.featureFlags }));
    };

    // eslint-disable-next-line class-methods-use-this
    getFeatureType = (baseFeatureType: DemeterFeatureType, tableDefinitionType: DemeterTableDefinitionType) => {
        if (baseFeatureType === DemeterFeatureType.DownloadsGeneral) {
            if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyExportsTable) {
                return DemeterFeatureType.DownloadsExports;
            }

            if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyImportsTable) {
                return DemeterFeatureType.DownloadsImports;
            }
        }

        return baseFeatureType;
    };
}

const featureFlagsService = new FeatureFlagsService();

export default featureFlagsService;
