import { useCallback } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import { GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap, UnitOfMeasure } from '../../../Generated/Raven-Demeter';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheOrApi from './useCacheOrApiHook';

const useUnitOfMeasureConversionApi = (
    reutersInstrumentCodePrefix?: string,
): [
    getUnitOfMeasureConversionRate: (unitOfMeasure?: UnitOfMeasure) => number,
    conversionMap: GetUnitOfMeasureConversionChartResponseUnitOfMeasureConversionMap | undefined,
] => {
    const [, , response] = useCacheOrApi(`${CacheKeys.GetUnitOfMeasureConversionChart}_${reutersInstrumentCodePrefix}`, () => {
        if (!reutersInstrumentCodePrefix) {
            return undefined;
        }

        return demeterApi.getUnitOfMeasureConversionChart(reutersInstrumentCodePrefix!);
    });

    const getUnitOfMeasureConversionRate = useCallback(
        (unitOfMeasure?: UnitOfMeasure): number => {
            if (!unitOfMeasure) {
                return 1.0;
            }

            const key = Object.keys(response?.unitOfMeasureConversionMap ?? {}).find((x) => x.toLowerCase() === unitOfMeasure.toLowerCase());
            const conversionRate = key ? response!.unitOfMeasureConversionMap![key as keyof typeof UnitOfMeasure] : undefined;

            if (!conversionRate) {
                return 1.0;
            }

            return conversionRate;
        },
        [response],
    );

    return [getUnitOfMeasureConversionRate, response?.unitOfMeasureConversionMap ?? undefined];
};

export default useUnitOfMeasureConversionApi;
