/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo } from 'react';
import { CommoditySpotPriceSummaryModel } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import PricesSummaryTableWrapper from '../PricesSummaryTableWrapper';
import styles from '../PriceSummaries.module.scss';

interface ICmeHistoricalSummaryProps {
    summaryData?: CommoditySpotPriceSummaryModel[];
}

const CmeSpotSummaryHistorical: React.FC<ICmeHistoricalSummaryProps> = (props: ICmeHistoricalSummaryProps) => {
    const [translations] = useLanguage();

    const columnHeaders = useMemo(
        () => [
            translations.dashboard.priceSummary.cme.headers.historical,
            translations.dashboard.priceSummary.cme.fields.currentPrice,
            translations.dashboard.priceSummary.cme.fields.yearAgo,
            translations.dashboard.priceSummary.cme.fields.monthAgo,
            translations.dashboard.priceSummary.cme.fields.weekAgo,
            translations.dashboard.priceSummary.cme.fields.twoDaysAgo,
            translations.dashboard.priceSummary.cme.fields.oneDayAgo,
        ],
        [],
    );

    return (
        <PricesSummaryTableWrapper
            className={styles.price_summary_table_historical}
            loading={!props.summaryData || props.summaryData.length === 0}
            columnHeaders={columnHeaders}
            largeFirstHeader
        >
            <>
                {props.summaryData?.map((row: CommoditySpotPriceSummaryModel) => {
                    const { historicalSummary, commodity } = row;

                    return (
                        <>
                            <p>{translations.commodity[commodity]}</p>
                            <p>{formattingService.toPriceString(historicalSummary.priceCurrent)}</p>
                            <p>{formattingService.toPriceString(historicalSummary.priceOneYearAgo)}</p>
                            <p>{formattingService.toPriceString(historicalSummary.priceOneMonthAgo)}</p>
                            <p>{formattingService.toPriceString(historicalSummary.priceOneWeekAgo)}</p>
                            <p>{formattingService.toPriceString(historicalSummary.priceTwoDaysAgo)}</p>
                            <p>{formattingService.toPriceString(historicalSummary.priceOneDayAgo)}</p>
                        </>
                    );
                })}
            </>
        </PricesSummaryTableWrapper>
    );
};

export default CmeSpotSummaryHistorical;
