import { ValueFormatterParams } from 'ag-grid-community';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { CommodityOtcPriceModel } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';
import styleService from '../../../Services/Styles/StyleService';

export type CommodityOtcPriceCompositeModel = CommodityOtcPriceModel & { id: string; rowIndex: number; selected: boolean };
export type RendererParameters = {
    data: CommodityOtcPriceCompositeModel;
    value: number | string;
};

const contractMonthValueFormatter = (parameters: RendererParameters) =>
    formattingService.toMonthYear(new Date(parameters.data.contractYear, parameters.data.contractMonth - 1, 1));

const percentValueFormatter = (parameters: ValueFormatterParams) => {
    if (!parameters.value) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }
    return formattingService.toPercent(+parameters.value);
};

const priceValueFormatter = (parameters: ValueFormatterParams) => {
    if (!parameters.value) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }
    return formattingService.toNumberStringWithTrailingZeros(+parameters.value, 0, 5);
};

const lastUpdatevalueFormatter = (parameters: RendererParameters) => {
    if (!parameters.data.releaseTime) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    return formattingService.toTimestampFromUtc(parameters.data.releaseTime);
};

export const otcPricesColumnOptions = {
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    flex: 1,
    rowDragManaged: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: false,
};

export const otcPricesColumnDefinitions = [
    {
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.contractMonth),
        maxWidth: 150,
        width: 100,
        minWidth: 100,
        lockPinned: true,
        pinned: 'left',
        rowDrag: false,
        suppressMovable: true,
        valueFormatter: contractMonthValueFormatter,
    },
    {
        field: 'bidPrice',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.bidPrice),
        minWidth: 75,
        width: 75,
        valueFormatter: priceValueFormatter,
        type: 'rightAligned',
    },
    {
        field: 'askPrice',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.askPrice),
        minWidth: 75,
        width: 75,
        valueFormatter: priceValueFormatter,
        type: 'rightAligned',
    },
    {
        field: 'bidAskAveragePrice',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.todaysMidpoint),
        minWidth: 75,
        width: 75,
        valueFormatter: priceValueFormatter,
        type: 'rightAligned',
    },
    {
        field: 'previousBidAskAveragePrice',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.priorDayMidpoint),
        minWidth: 75,
        width: 75,
        valueFormatter: priceValueFormatter,
        type: 'rightAligned',
    },
    {
        field: 'percentChange',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.percentChange),
        cellClass: styleService.positiveOrNegativeCellClassSetter,
        minWidth: 75,
        width: 75,
        valueFormatter: percentValueFormatter,
        type: 'rightAligned',
    },
    {
        field: 'netChange',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.netChange),
        cellClass: styleService.positiveOrNegativeCellClassSetter,
        minWidth: 75,
        width: 75,
        valueFormatter: priceValueFormatter,
        type: 'rightAligned',
    },
    {
        field: 'releaseTime',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.lastUpdate),
        minWidth: 150,
        width: 150,
        valueFormatter: lastUpdatevalueFormatter,
        type: 'rightAligned',
    },
    {
        field: 'actions',
        headerValueGetter: () => languageService.translate(translationKeys.fields.chartControl),
        lockPinned: true,
        pinned: 'right',
        rowDrag: false,
        suppressMovable: true,
        valueFormatter: null,
        valueFormatterParams: null,
        cellStyle: {
            color: 'blue',
        },
        maxWidth: 150,
        width: 100,
        minWidth: 100,
        type: 'rightAligned',
    },
];
