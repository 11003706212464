import React, { memo, useMemo } from 'react';
import { DemeterDataSource, DemeterSymbolModel } from '../../../../Generated/Raven-Demeter';
import { IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import MultiBarAreaChartRaw from '../../../Components/Charts/MultiBarArea/MultiBarAreaChartRaw';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { MonthlyRiskCompositeModel, RiskRequest } from '../RiskDefinitions';

export interface INetPositionCumulativeBudgetAtRiskChart {
    symbol: DemeterSymbolModel;
    risksRequest: RiskRequest;
    calculatedRisks: MonthlyRiskCompositeModel[];
    displayDecimalPlaces?: number;
}

const NetPositionCumulativeBudgetAtRiskChart: React.FC<INetPositionCumulativeBudgetAtRiskChart> = (props: INetPositionCumulativeBudgetAtRiskChart) => {
    const [translations] = useLanguage();

    const lineSeries = useMemo<IChartDataSeries>(
        () => ({
            label: `${translations.risk.headers.budget} ${translations.words.volume}`,
            data: props.calculatedRisks.map((x) => ({
                value: x.budgetPosition ?? 0,
                asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                isActualValue: true,
            })),
        }),
        [props.calculatedRisks],
    );

    const barsSeries = useMemo<IChartDataSeries[]>(
        () => [
            {
                label: translations.risk.text.netPhysicalPosition,
                data: props.calculatedRisks.map((x) => ({
                    value: x.physicalPosition ?? 0,
                    asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                    isActualValue: true,
                })),
            },
            {
                label: translations.risk.headers.budgetVersesMarket,
                data: props.calculatedRisks.map((x) => ({
                    value: x.budgetVersesMarketVolume ?? 0,
                    asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                    isActualValue: true,
                })),
            },
            {
                label: translations.risk.text.netFuturesSwapPosition,
                data: props.calculatedRisks.map((x) => ({
                    value: x.futuresPosition ?? 0,
                    asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                    isActualValue: true,
                })),
            },
        ],
        [props.calculatedRisks],
    );

    const areaSeries = useMemo<IChartDataSeries>(
        () => ({
            label: translations.risk.text.riskCumulative,
            data: props.calculatedRisks.map((x) => ({
                value: x.riskCumulative ?? 0,
                asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                isActualValue: true,
            })),
        }),
        [props.calculatedRisks],
    );

    return (
        <ChartWrapper
            name="NetPositionCumulativeBudgetAtRiskChart"
            title={translations.risk.headers.netPositionCumulativeRisk}
            dataSourceTag={[translations.exchange[props.symbol.exchange], translations.dataSource[DemeterDataSource.StoneXCalculations]]}
            isLoading={props.calculatedRisks.length === 0}
        >
            <MultiBarAreaChartRaw
                lineSeries={lineSeries}
                barsSeries={barsSeries}
                areaSeries={areaSeries}
                unitOfMeasure={props.risksRequest?.unitOfMeasure}
                currency={props.risksRequest?.currency}
                displayDecimalPlacesMinimum={props.displayDecimalPlaces}
                displayDecimalPlacesMaximum={props.displayDecimalPlaces}
            />
        </ChartWrapper>
    );
};

export default memo(NetPositionCumulativeBudgetAtRiskChart);
