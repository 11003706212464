import React, { useEffect, useMemo, useState } from 'react';
import useLanguage from '../../../Services/Language/useLanguageHook';
import CheckboxDropdown from '../../Form/Inputs/CheckboxDropdown';
import { ChartStudyType } from './StudiesDefinitions';
import styles from './StudiesDropdown.module.scss';

export interface IStudiesDropdownProps {
    studies: ChartStudyType[];
    disabled?: boolean;
    handleStudiesSelected: (studies: ChartStudyType[]) => void;
}

const maximumStudies = 5;
const availableStudies = Object.keys(ChartStudyType).filter((item) => Number.isNaN(Number(item))) as ChartStudyType[];

const StudiesDropdown: React.FC<IStudiesDropdownProps> = (props: IStudiesDropdownProps) => {
    const [translations] = useLanguage();
    const [studies, setStudies] = useState<ChartStudyType[]>(props.studies ?? []);

    const options = useMemo(
        () =>
            availableStudies.map((study) => ({
                label: translations.chartStudy[study],
                value: study,
            })),
        [],
    );

    useEffect(() => {
        setStudies(props.studies);
    }, [props.studies]);

    const handleClick = (selectedStudies: ChartStudyType[]) => {
        setStudies(selectedStudies);
        props.handleStudiesSelected(selectedStudies);
    };

    return (
        <div className={styles.studies_dropdown_container}>
            <CheckboxDropdown
                placeholder={translations.dropdown.studies}
                options={options}
                values={studies}
                handleOptionSelect={handleClick}
                maximumSelectionLimit={maximumStudies}
                limitMessage={translations.dropdown.studiesLimitMessage}
                disabled={props.disabled}
            />
        </div>
    );
};

export default StudiesDropdown;
