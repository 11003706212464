import { Component } from 'react';
import { EventActionsEnum, EventCategoriesEnum } from '../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../Services/Logging/LoggingService';
import ErrorFallback from './ErrorFallback';

interface IErrorBoundaryProps {
    children: JSX.Element;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

// Note: DO NOT try to make this a functional component. This is directly from the react documentation.
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
        this.handleResetErrorState = this.handleResetErrorState.bind(this);
    }

    static getDerivedStateFromError(error: Error) {
        loggingService.trackException(error);
        loggingService.trackEventWithAnalytics(EventActionsEnum.LoadingError, EventCategoriesEnum.ComponentLoadingError);

        return { hasError: true };
    }

    handleResetErrorState() {
        this.setState({ hasError: false });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorFallback handleResetErrorState={this.handleResetErrorState} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
