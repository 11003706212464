import { CommodityPriceModel, DemeterUserDataValue, ListCommodityMonthlyPricesResponse, MarketPriceModel } from '../../../../Generated/Raven-Demeter';
import { BasisAdjustment, BasisLagPeriod, BasisMonthlyDate, BasisPeriod, BasisValueModel } from './BasisCalculatorDefinitions';

export interface IBasisCalculatorCalculationParameters {
    basisLagPeriod?: BasisLagPeriod;
    basisPeriod?: BasisPeriod;
    basisAdjustment?: BasisAdjustment;
    useOptimalLag?: boolean;
    useRegression?: boolean;
    startDate?: BasisMonthlyDate;
    productPrices1: MarketPriceModel[] | BasisValueModel[] | DemeterUserDataValue[] | undefined;
    productPrices2: MarketPriceModel[] | BasisValueModel[] | DemeterUserDataValue[] | undefined;
}

export interface IBasisCalculatorCalculationResult {
    correlation: number;
    lagRSquared: number;
    optimalLagCorrelation: number;
    optimalLagMonth?: number;
    lagCorrelation?: number;
    intercept: number;
    slope: number;
    basisDeciles: number[];
    threeMonthsBasisDeciles: number[];
    sixMonthsBasisDeciles: number[];
    nineMonthsBasisDeciles: number[];
    twelveMonthsBasisDeciles: number[];
    basisAverage: number;
    threeMonthsBasisAverage: number;
    sixMonthsBasisAverage: number;
    nineMonthsBasisAverage: number;
    twelveMonthsBasisAverage: number;
    prices1: BasisValueModel[];
    prices1HasCalculatedValues: boolean;
    basis: BasisValueModel[];
    threeMonthsBasis: BasisValueModel[];
    sixMonthsBasis: BasisValueModel[];
    nineMonthsBasis: BasisValueModel[];
    twelveMonthsBasis: BasisValueModel[];
    lags: BasisValueModel[]; // This is the second price series.
    lagsHasCalculatedValues: boolean;
    metricSeries: BasisValueModel[];
    twelveMonthRollingCorrelation: BasisValueModel[];
    regression: BasisValueModel[];
    metric: number | undefined;
    basisPeriod: BasisPeriod | undefined;
    basisAdjustment: BasisAdjustment | undefined;
    basisLagPeriod: BasisLagPeriod | undefined;
}

const BasisCalculatorService = {
    calculate: (params: IBasisCalculatorCalculationParameters): IBasisCalculatorCalculationResult => {
        const basisLagMapping: Record<BasisLagPeriod, number> = {
            [BasisLagPeriod.MinusSixMonthLag]: -6,
            [BasisLagPeriod.MinusFiveMonthLag]: -5,
            [BasisLagPeriod.MinusFourMonthLag]: -4,
            [BasisLagPeriod.MinusThreeMonthLag]: -3,
            [BasisLagPeriod.MinusTwoMonthLag]: -2,
            [BasisLagPeriod.MinusOneMonthLag]: -1,
            [BasisLagPeriod.NoLag]: 0,
            [BasisLagPeriod.OneMonthLag]: 1,
            [BasisLagPeriod.TwoMonthLag]: 2,
            [BasisLagPeriod.ThreeMonthLag]: 3,
            [BasisLagPeriod.FourMonthLag]: 4,
            [BasisLagPeriod.FiveMonthLag]: 5,
            [BasisLagPeriod.SixMonthLag]: 6,
        };

        const lagMonths = params.basisLagPeriod ? basisLagMapping[params.basisLagPeriod] : 0;

        const unifyPriceModels = (priceModels: MarketPriceModel[] | BasisValueModel[] | DemeterUserDataValue[] | undefined): BasisValueModel[] => {
            if (!priceModels) {
                return [];
            }

            const unifiedPrices = priceModels.map((priceModel) => {
                if ('settlementPrice' in priceModel) {
                    return {
                        asOfDate: new Date(priceModel.asOfDate),
                        value: priceModel.settlementPrice,
                        isForwardCurve: false,
                        isActualValue: true,
                    } as BasisValueModel;
                }

                const { isActualValue, isForwardCurve } = priceModel as { isActualValue?: boolean | undefined; isForwardCurve?: boolean | undefined };

                return {
                    asOfDate: new Date(priceModel.asOfDate),
                    value: priceModel.value,
                    isActualValue: isActualValue !== false,
                    isForwardCurve: isForwardCurve ?? false,
                } as BasisValueModel;
            });

            return unifiedPrices as BasisValueModel[];
        };

        const convertToMonthlyData = (unifiedPrices: BasisValueModel[]): BasisValueModel[] => {
            const monthlyPrices: { [key: string]: BasisValueModel[] } = {};

            unifiedPrices.forEach((priceModel) => {
                const yearMonth = `${priceModel.asOfDate.getFullYear()}-${String(priceModel.asOfDate.getMonth() + 1).padStart(2, '0')}`;
                if (!monthlyPrices[yearMonth]) {
                    monthlyPrices[yearMonth] = [];
                }

                monthlyPrices[yearMonth].push(priceModel);
            });

            const monthlyAveragePrices: BasisValueModel[] = Object.keys(monthlyPrices).map((yearMonth) => {
                const prices = monthlyPrices[yearMonth];
                const averagePrice = prices.reduce((sum, price) => sum + price.value, 0) / prices.length;
                const [year, month] = yearMonth.split('-');

                return {
                    asOfDate: new Date(parseInt(year, 10), parseInt(month, 10) - 1),
                    value: averagePrice,
                    isActualValue: prices.every((x) => x.isActualValue),
                    isForwardCurve: prices.some((x) => x.isForwardCurve ?? false),
                };
            });

            return monthlyAveragePrices;
        };

        const filterByCommonDates = (array1: BasisValueModel[], array2: BasisValueModel[]): [BasisValueModel[], BasisValueModel[]] => {
            const array2Dates = new Set(array2.map((item) => item.asOfDate.getTime()));
            const filteredArray1 = array1.filter((item) => array2Dates.has(item.asOfDate.getTime()));
            const array1Dates = new Set(array1.map((item) => item.asOfDate.getTime()));
            const filteredArray2 = array2.filter((item) => array1Dates.has(item.asOfDate.getTime()));
            return [filteredArray1, filteredArray2];
        };

        const filterFromStartDate = (values: BasisValueModel[]): BasisValueModel[] => {
            const startDate = new Date(Date.parse(params.startDate!));
            return values.filter((item) => item.asOfDate >= startDate);
        };

        const filterActuals = (values: BasisValueModel[]): BasisValueModel[] => values.filter((item) => item.isActualValue !== false);

        const calculateRegression = (array1: BasisValueModel[], slope: number, intercept: number): BasisValueModel[] => {
            const regression = array1.map((item) => ({
                asOfDate: item.asOfDate,
                value: item.value * slope + intercept,
                isActualValue: item.isActualValue,
            }));

            return regression;
        };

        const calculateBasis = (values1: BasisValueModel[], values2: BasisValueModel[], slope: number, intercept: number): BasisValueModel[] => {
            const [array1, array2] = filterByCommonDates(values1, values2);
            if (params.useRegression) {
                const regression = calculateRegression(array2, slope, intercept);
                const basis = array1.flatMap((item, index) => {
                    const regressionItem = regression[index];
                    if (!regressionItem) {
                        return [];
                    }

                    const difference = item.value - regressionItem.value;

                    return [
                        {
                            asOfDate: item.asOfDate,
                            value: difference,
                            isActualValue: item.isActualValue && regressionItem.isActualValue,
                        },
                    ];
                });

                return basis;
            }

            const basis = array1.flatMap((item, index) => {
                const array2Item = array2[index];
                if (!array2Item) {
                    return [];
                }
                const difference = item.value - array2Item.value;

                return [
                    {
                        asOfDate: item.asOfDate,
                        value: difference,
                        isActualValue: item.isActualValue && array2Item.isActualValue,
                    },
                ];
            });

            return basis;
        };

        const calculateBasisAverages = (array: BasisValueModel[], numMonths: number): BasisValueModel[] => {
            const result = array
                .map((item, i, arr) => {
                    if (i + 1 < numMonths) {
                        return null;
                    }
                    const lastNMonths = arr.slice(i + 1 - numMonths, i + 1);
                    const sum = lastNMonths.reduce((accumulator, price) => accumulator + price.value, 0);
                    const average = sum / numMonths;
                    return { value: average, asOfDate: item.asOfDate, isActualValue: lastNMonths.every((x) => x.isActualValue) };
                })
                .filter((avg) => avg !== null) as BasisValueModel[];

            return result;
        };

        const calculateLags = (values: BasisValueModel[], months?: number | undefined): BasisValueModel[] => {
            if (months === 0) {
                return values;
            }
            const numMonths = months || lagMonths;
            const lags = values.flatMap((item, index, array) => {
                const adjustedIndex = index - numMonths;
                if (adjustedIndex < 0 || adjustedIndex >= array.length) {
                    return [];
                }
                return [
                    {
                        asOfDate: item.asOfDate,
                        value: array[adjustedIndex].value,
                        isActualValue: array[adjustedIndex].isActualValue,
                    },
                ];
            });

            return lags as BasisValueModel[];
        };

        const calculateDeciles = (priceArray: BasisValueModel[]): number[] => calculateDecilesExc(priceArray);

        const calculateDecilesExc = (priceArray: BasisValueModel[]): number[] => {
            const sortedArray = priceArray.map((item) => item.value).sort((a, b) => a - b);
            const deciles = [];
            for (let i = 0; i <= 10; i += 1) {
                deciles.push(percentileExc(sortedArray, i * 10));
            }
            return deciles;
        };

        const percentileExc = (sortedArr: number[], percentile: number): number => {
            const rank = (percentile / 100) * (sortedArr.length + 1);
            if (rank < 1) {
                return sortedArr[0];
            }
            if (rank >= sortedArr.length) {
                return sortedArr[sortedArr.length - 1];
            }
            const lowerIndex = Math.floor(rank) - 1;
            const upperIndex = lowerIndex + 1;
            const weight = rank - Math.floor(rank);
            return sortedArr[lowerIndex] * (1 - weight) + sortedArr[upperIndex] * weight;
        };

        const calculateCorrelation = (values1: BasisValueModel[], values2: BasisValueModel[]): number => {
            const [array1, array2] = filterByCommonDates(values1, values2);
            const n = array1.length;
            const mean1 = array1.reduce((sum, item) => sum + item.value, 0) / n;
            const mean2 = array2.reduce((sum, item) => sum + item.value, 0) / n;
            let numerator = 0;
            let denominator1 = 0;
            let denominator2 = 0;

            for (let i = 0; i < n; i += 1) {
                const diff1 = array1[i].value - mean1;
                const diff2 = array2[i].value - mean2;
                numerator += diff1 * diff2;
                denominator1 += diff1 * diff1;
                denominator2 += diff2 * diff2;
            }

            const denominator = Math.sqrt(denominator1) * Math.sqrt(denominator2);
            const correlation = denominator === 0 ? 0 : numerator / denominator;

            return correlation * 100;
        };

        const calculateCorrelationsForLags = (prices1: BasisValueModel[], prices2: BasisValueModel[]): { lag: number; correlation: number }[] => {
            const results: { lag: number; correlation: number }[] = [];
            for (let lag = -6; lag <= 6; lag += 1) {
                const laggedPrices2 = calculateLags(prices2, lag);
                const correlation = calculateCorrelation(prices1, laggedPrices2);
                results.push({ lag, correlation });
            }
            return results;
        };

        const calculateRollingCorrelation = (values1: BasisValueModel[], values2: BasisValueModel[], monthNum: number): BasisValueModel[] => {
            const [array1, array2] = filterByCommonDates(values1, values2);
            const result: BasisValueModel[] = [];
            const n = array1.length;

            for (let i = monthNum; i < n; i += 1) {
                const sliceArray1 = array1.slice(i - monthNum, i);
                const sliceArray2 = array2.slice(i - monthNum, i);
                const correlation = calculateCorrelation(sliceArray1, sliceArray2);
                result.push({ asOfDate: array1[i].asOfDate, value: correlation, isActualValue: true });
            }

            return result;
        };

        const calculateRSquared = (values1: BasisValueModel[], values2: BasisValueModel[]): number => {
            const [array1, array2] = filterByCommonDates(values1, values2);
            const n = array1.length;
            const sumX = array2.reduce((sum, item) => sum + item.value, 0);
            const sumY = array1.reduce((sum, item) => sum + item.value, 0);
            const sumXY = array2.reduce((sum, item, index) => sum + item.value * array1[index].value, 0);
            const sumXX = array2.reduce((sum, item) => sum + item.value * item.value, 0);
            const sumYY = array1.reduce((sum, item) => sum + item.value * item.value, 0);
            const r2 = ((n * sumXY - sumX * sumY) / Math.sqrt((n * sumXX - sumX * sumX) * (n * sumYY - sumY * sumY))) ** 2;
            return r2 * 100;
        };

        const calculateAverage = (values: BasisValueModel[]): number => {
            const sum = values.reduce((accumulator, item) => accumulator + item.value, 0);
            const average = sum / values.length;
            return average;
        };

        const calculateIntercept = (values1: BasisValueModel[], values2: BasisValueModel[]): number => {
            const [array1, array2] = filterByCommonDates(values1, values2);
            const n = array1.length;
            const sumX = array2.reduce((sum, item) => sum + item.value, 0);
            const sumY = array1.reduce((sum, item) => sum + item.value, 0);
            const sumXY = array2.reduce((sum, item, index) => sum + item.value * array1[index].value, 0);
            const sumXX = array2.reduce((sum, item) => sum + item.value * item.value, 0);
            const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
            const intercept = (sumY - slope * sumX) / n;
            return intercept;
        };

        const calculateSlope = (values1: BasisValueModel[], values2: BasisValueModel[]): number => {
            const [array1, array2] = filterByCommonDates(values1, values2);
            const n = array1.length;
            const sumX = array2.reduce((sum, item) => sum + item.value, 0);
            const sumY = array1.reduce((sum, item) => sum + item.value, 0);
            const sumXY = array2.reduce((sum, item, index) => sum + item.value * array1[index].value, 0);
            const sumXX = array2.reduce((sum, item) => sum + item.value * item.value, 0);
            const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
            return slope;
        };

        const unifiedPrices1 = unifyPriceModels(params.productPrices1);
        const unifiedPrices2 = unifyPriceModels(params.productPrices2);
        const monthlyPrices1 = convertToMonthlyData(unifiedPrices1);
        const monthlyPrices2 = convertToMonthlyData(unifiedPrices2);
        const prices1 = filterActuals(monthlyPrices1);
        const prices2 = filterActuals(monthlyPrices2);

        const lags = calculateLags(prices2);

        const lagsSinceStartDate = filterFromStartDate(lags);
        const prices1SinceStartDate = filterFromStartDate(prices1);
        const prices2SinceStartDate = filterFromStartDate(prices2);

        const lagsWithForecast = calculateLags(monthlyPrices2);
        const lagsSinceStartDateWithForecast = filterFromStartDate(lagsWithForecast);
        const prices1SinceStartDateWithForecast = filterFromStartDate(monthlyPrices1);

        const lagsCorrelations = calculateCorrelationsForLags(prices1SinceStartDate, prices2SinceStartDate);
        const maximumLagCorrelation = lagsCorrelations.reduce((max, current) => (current.correlation > max.correlation ? current : max), lagsCorrelations[0]);
        const optimalLagMonth = maximumLagCorrelation.lag;
        const optimalLagCorrelation = maximumLagCorrelation.correlation;
        const lagCorrelation = params.basisLagPeriod ? lagsCorrelations.find((x) => x.lag === basisLagMapping[params.basisLagPeriod!])?.correlation : 0;

        const correlation = calculateCorrelation(prices1SinceStartDate, prices2SinceStartDate);
        const lagRSquared = calculateRSquared(prices1SinceStartDate, lagsSinceStartDate);
        const intercept = calculateIntercept(prices1SinceStartDate, lagsSinceStartDate);
        const slope = calculateSlope(prices1SinceStartDate, lagsSinceStartDate);

        const regressionWithForecast = calculateRegression(lagsSinceStartDateWithForecast, slope, intercept);
        const basisWithForecast = calculateBasis(prices1SinceStartDateWithForecast, lagsSinceStartDateWithForecast, slope, intercept);
        const threeMonthsBasisWithForecast = calculateBasisAverages(basisWithForecast, 3);
        const sixMonthsBasisWithForecast = calculateBasisAverages(basisWithForecast, 6);
        const nineMonthsBasisWithForecast = calculateBasisAverages(basisWithForecast, 9);
        const twelveMonthsBasisWithForecast = calculateBasisAverages(basisWithForecast, 12);

        const regressionSinceStartDate = filterFromStartDate(regressionWithForecast);
        const basisSinceStartDateWithForecast = filterFromStartDate(basisWithForecast);
        const threeMonthsBasisSinceStartDateWithForecast = filterFromStartDate(threeMonthsBasisWithForecast);
        const sixMonthsBasisSinceStartDateWithForecast = filterFromStartDate(sixMonthsBasisWithForecast);
        const nineMonthsBasisSinceStartDateWithForecast = filterFromStartDate(nineMonthsBasisWithForecast);
        const twelveMonthsBasisSinceStartDateWithForecast = filterFromStartDate(twelveMonthsBasisWithForecast);

        const twelveMonthRollingCorrelation = calculateRollingCorrelation(prices1, lags, 12);
        const twelveMonthRollingCorrelationSinceStartDate = filterFromStartDate(twelveMonthRollingCorrelation);

        const basisAverage = calculateAverage(filterActuals(basisSinceStartDateWithForecast));
        const threeMonthsBasisAverage = calculateAverage(filterActuals(threeMonthsBasisSinceStartDateWithForecast));
        const sixMonthsBasisAverage = calculateAverage(filterActuals(sixMonthsBasisSinceStartDateWithForecast));
        const nineMonthsBasisAverage = calculateAverage(filterActuals(nineMonthsBasisSinceStartDateWithForecast));
        const twelveMonthsBasisAverage = calculateAverage(filterActuals(twelveMonthsBasisSinceStartDateWithForecast));

        const basisDeciles = calculateDeciles(filterActuals(basisSinceStartDateWithForecast));
        const threeMonthsBasisDeciles = calculateDeciles(filterActuals(threeMonthsBasisSinceStartDateWithForecast));
        const sixMonthsBasisDeciles = calculateDeciles(filterActuals(sixMonthsBasisSinceStartDateWithForecast));
        const nineMonthsBasisDeciles = calculateDeciles(filterActuals(nineMonthsBasisSinceStartDateWithForecast));
        const twelveMonthsBasisDeciles = calculateDeciles(filterActuals(twelveMonthsBasisSinceStartDateWithForecast));

        const metrics = {
            Average: {
                Basis: basisAverage,
                ThreeMonthBasis: threeMonthsBasisAverage,
                SixMonthBasis: sixMonthsBasisAverage,
                NineMonthBasis: nineMonthsBasisAverage,
                TwelveMonthBasis: twelveMonthsBasisAverage,
            },
            Maximum: {
                Basis: basisDeciles[10],
                ThreeMonthBasis: threeMonthsBasisDeciles[10],
                SixMonthBasis: sixMonthsBasisDeciles[10],
                NineMonthBasis: nineMonthsBasisDeciles[10],
                TwelveMonthBasis: twelveMonthsBasisDeciles[10],
            },
            Top10Percent: {
                Basis: basisDeciles[9],
                ThreeMonthBasis: threeMonthsBasisDeciles[9],
                SixMonthBasis: sixMonthsBasisDeciles[9],
                NineMonthBasis: nineMonthsBasisDeciles[9],
                TwelveMonthBasis: twelveMonthsBasisDeciles[9],
            },
            Top20Percent: {
                Basis: basisDeciles[8],
                ThreeMonthBasis: threeMonthsBasisDeciles[8],
                SixMonthBasis: sixMonthsBasisDeciles[8],
                NineMonthBasis: nineMonthsBasisDeciles[8],
                TwelveMonthBasis: twelveMonthsBasisDeciles[8],
            },
            Top30Percent: {
                Basis: basisDeciles[7],
                ThreeMonthBasis: threeMonthsBasisDeciles[7],
                SixMonthBasis: sixMonthsBasisDeciles[7],
                NineMonthBasis: nineMonthsBasisDeciles[7],
                TwelveMonthBasis: twelveMonthsBasisDeciles[7],
            },
            Top40Percent: {
                Basis: basisDeciles[6],
                ThreeMonthBasis: threeMonthsBasisDeciles[6],
                SixMonthBasis: sixMonthsBasisDeciles[6],
                NineMonthBasis: nineMonthsBasisDeciles[6],
                TwelveMonthBasis: twelveMonthsBasisDeciles[6],
            },
            Top50Percent: {
                Basis: basisDeciles[5],
                ThreeMonthBasis: threeMonthsBasisDeciles[5],
                SixMonthBasis: sixMonthsBasisDeciles[5],
                NineMonthBasis: nineMonthsBasisDeciles[5],
                TwelveMonthBasis: twelveMonthsBasisDeciles[5],
            },
            Bottom40Percent: {
                Basis: basisDeciles[4],
                ThreeMonthBasis: threeMonthsBasisDeciles[4],
                SixMonthBasis: sixMonthsBasisDeciles[4],
                NineMonthBasis: nineMonthsBasisDeciles[4],
                TwelveMonthBasis: twelveMonthsBasisDeciles[4],
            },
            Bottom30Percent: {
                Basis: basisDeciles[3],
                ThreeMonthBasis: threeMonthsBasisDeciles[3],
                SixMonthBasis: sixMonthsBasisDeciles[3],
                NineMonthBasis: nineMonthsBasisDeciles[3],
                TwelveMonthBasis: twelveMonthsBasisDeciles[3],
            },
            Bottom20Percent: {
                Basis: basisDeciles[2],
                ThreeMonthBasis: threeMonthsBasisDeciles[2],
                SixMonthBasis: sixMonthsBasisDeciles[2],
                NineMonthBasis: nineMonthsBasisDeciles[2],
                TwelveMonthBasis: twelveMonthsBasisDeciles[2],
            },
            Bottom10Percent: {
                Basis: basisDeciles[1],
                ThreeMonthBasis: threeMonthsBasisDeciles[1],
                SixMonthBasis: sixMonthsBasisDeciles[1],
                NineMonthBasis: nineMonthsBasisDeciles[1],
                TwelveMonthBasis: twelveMonthsBasisDeciles[1],
            },
            Minimum: {
                Basis: basisDeciles[0],
                ThreeMonthBasis: threeMonthsBasisDeciles[0],
                SixMonthBasis: sixMonthsBasisDeciles[0],
                NineMonthBasis: nineMonthsBasisDeciles[0],
                TwelveMonthBasis: twelveMonthsBasisDeciles[0],
            },
        };

        const metric = params.basisAdjustment && params.basisPeriod && metrics[params.basisAdjustment][params.basisPeriod];
        const metricSeries = basisSinceStartDateWithForecast.map((x) => ({ asOfDate: x.asOfDate!, value: metric, isActualValue: true } as BasisValueModel));

        // If one series has a forward curve and the other one does not, then generate a synthetic forward curve for one.
        let prices1HasCalculatedValues = false;
        let lagsHasCalculatedValues = false;
        const price1HasForwardCurve = monthlyPrices1.some((x) => x.isForwardCurve);
        const price2HasForwardCurve = monthlyPrices2.some((x) => x.isForwardCurve);

        if (price1HasForwardCurve !== price2HasForwardCurve) {
            if (price1HasForwardCurve && lagsSinceStartDateWithForecast.length > 0) {
                lagsHasCalculatedValues = true;
                const lastLagDate = lagsSinceStartDateWithForecast[lagsSinceStartDateWithForecast.length - 1].asOfDate;
                prices1SinceStartDateWithForecast
                    .filter((x) => x.asOfDate >= lastLagDate)
                    .forEach((x) => {
                        lagsSinceStartDateWithForecast.push({
                            asOfDate: x.asOfDate,
                            isActualValue: false,
                            value: x.value - (metric ?? 0),
                        });

                        basisSinceStartDateWithForecast.push({
                            asOfDate: x.asOfDate,
                            isActualValue: false,
                            value: metric ?? 0,
                        });
                    });
            } else if (price2HasForwardCurve && prices1SinceStartDateWithForecast.length > 0) {
                prices1HasCalculatedValues = true;
                const lastPrices1Data = prices1SinceStartDateWithForecast[prices1SinceStartDateWithForecast.length - 1].asOfDate;
                const dataSeriesToUse = params.useRegression ? regressionWithForecast : lagsSinceStartDateWithForecast;

                dataSeriesToUse
                    .filter((x) => x.asOfDate >= lastPrices1Data)
                    .forEach((x) => {
                        prices1SinceStartDateWithForecast.push({
                            asOfDate: x.asOfDate,
                            isActualValue: false,
                            value: x.value + (metric ?? 0),
                        });

                        basisSinceStartDateWithForecast.push({
                            asOfDate: x.asOfDate,
                            isActualValue: false,
                            value: metric ?? 0,
                        });
                    });
            }
        }

        return {
            correlation,
            lagRSquared,
            optimalLagCorrelation,
            optimalLagMonth,
            lagCorrelation,
            intercept,
            slope,
            basisDeciles,
            threeMonthsBasisDeciles,
            sixMonthsBasisDeciles,
            nineMonthsBasisDeciles,
            twelveMonthsBasisDeciles,
            basisAverage,
            threeMonthsBasisAverage,
            sixMonthsBasisAverage,
            nineMonthsBasisAverage,
            twelveMonthsBasisAverage,
            prices1: prices1SinceStartDateWithForecast,
            prices1HasCalculatedValues,
            basis: basisSinceStartDateWithForecast,
            threeMonthsBasis: threeMonthsBasisSinceStartDateWithForecast,
            sixMonthsBasis: sixMonthsBasisSinceStartDateWithForecast,
            nineMonthsBasis: nineMonthsBasisSinceStartDateWithForecast,
            twelveMonthsBasis: twelveMonthsBasisSinceStartDateWithForecast,
            lags: lagsSinceStartDateWithForecast,
            lagsHasCalculatedValues,
            twelveMonthRollingCorrelation: twelveMonthRollingCorrelationSinceStartDate,
            regression: regressionSinceStartDate,
            metric,
            basisPeriod: params.basisPeriod,
            basisAdjustment: params.basisAdjustment,
            basisLagPeriod: params.basisLagPeriod,
            metricSeries,
        };
    },

    convertMonthlyPricesData: (response: ListCommodityMonthlyPricesResponse): BasisValueModel[] => {
        const priceModels = (response.rows as CommodityPriceModel[])
            .filter((x) => x.isActualValue !== false)
            .map(
                (x) =>
                    ({
                        value: x.value ?? 0,
                        asOfDate: new Date(x.asOfDate),
                        isActualValue: true,
                        isForwardCurve: false,
                    } as BasisValueModel),
            );

        if (response.futuresMarketPricesForwardCurve) {
            response.futuresMarketPricesForwardCurve.forEach((x) => {
                priceModels.push({
                    value: x.settlementPrice ?? 0,
                    asOfDate: new Date(x.contractYear, x.contractMonth - 1, 1),
                    isActualValue: false,
                    isForwardCurve: true,
                });
            });
        }

        return priceModels;
    },
};

export default BasisCalculatorService;
