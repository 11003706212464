import { useMemo, useState } from 'react';
import { MarketIndicatorModel } from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../../Redux/Slices/UserSlice';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { ITreeNode } from '../../TreeView/TreeItem';
import ManageProducts from './ManageProducts';
import styles from './ProductsModal.module.scss';
import ProductsModalWrapper from './ProductsModalWrapper';

interface IManageMarketIndicatorsModal {
    title: string;
    selected: MarketIndicatorModel[];
    indicators: MarketIndicatorModel[];
    open: boolean;
    handleChange: (selectedOptions: MarketIndicatorModel[]) => void;
    handleClose: () => void;
}

const ManageMarketIndicatorsModal: React.FC<IManageMarketIndicatorsModal> = (props) => {
    const [translations] = useLanguage();
    const [indicatorNodesSelected, setIndicatorNodesSelected] = useState<ITreeNode[]>([]);
    const market = useApplicationSelector(selectUserCurrentMarket);

    const treeNodes = useMemo<ITreeNode[]>(() => {
        if (!props.indicators) {
            return [];
        }

        return props.indicators
            .filter((x) => x.market === market)
            .map((indicator, index) => {
                const displayName = languageService.translate(indicator.displayName ?? '');
                const item: ITreeNode = {
                    id: indicator.marketIndicatorGuid,
                    value: `root_${indicator.market}_${displayName}`,
                    name: displayName,
                    parentId: `${indicator.market}`,
                    parentValue: translations.market[indicator.market!],
                    column: props.selected.some((option) => option.marketIndicatorGuid === indicator.marketIndicatorGuid) ? 'right' : 'left',
                    displayOrder: props.selected.findIndex((x) => x.marketIndicatorGuid === indicator.marketIndicatorGuid),
                    order: index,
                    expanded: false,
                    selectedProduct: false,
                    children: [],
                };

                return item;
            });
    }, [props.indicators, props.selected, props.open]);

    const setSelectedIndicators = () => {
        const selectedIndicators =
            indicatorNodesSelected
                ?.filter((node: ITreeNode) => props.indicators.some((x) => x.marketIndicatorGuid === node.id))
                .map(
                    (node: ITreeNode) =>
                        ({ marketIndicatorGuid: props.indicators.find((x) => x.marketIndicatorGuid === node.id)!.marketIndicatorGuid } as MarketIndicatorModel),
                ) ?? [];

        props.handleChange(selectedIndicators);
        props.handleClose();
    };

    return (
        <ProductsModalWrapper
            title={props.title}
            open={props.open}
            subTitle={translations.dashboard.indicators.manageModalSubHeading}
            isSetButtonDisabled={false}
            handleClose={props.handleClose}
            handleSave={setSelectedIndicators}
        >
            <div className={styles.products_modal_content}>
                <ManageProducts
                    productTreeNodes={treeNodes}
                    showSingleOrGroupOption
                    handleSelectedUpdate={setIndicatorNodesSelected}
                    searchPlaceholder={translations.dashboard.indicators.searchIndicatorPlaceholder}
                    availableSubHeader={translations.dashboard.indicators.availableIndicators}
                    selectSubHeader={translations.dashboard.indicators.selectedIndicators}
                />
            </div>
        </ProductsModalWrapper>
    );
};

export default ManageMarketIndicatorsModal;
