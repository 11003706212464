/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import HighStock from 'highcharts/highstock';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt-br';
import 'moment/locale/zh-cn';
import { initReactI18next } from 'react-i18next';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import { DemeterSymbolModel } from '../../../Generated/Raven-Demeter/api';
import loggingService from '../Logging/LoggingService';
import TranslationKeyDefinition, { TranslationType } from './TranslationDefinitions';

export enum Language {
    Chinese = 'zh-CN',
    EnglishUnitedStates = 'en-US',
    EnglishUnitedKingdom = 'en-GB',
    French = 'fr-FR',
    German = 'de-DE',
    Portuguese = 'pt-BR',
    Spanish = 'es-CO',
}

const locizeOptions = {
    projectId: applicationSettings.locize.projectId,
    apiKey: applicationSettings.locize.apiKey,
    referenceLng: Language.EnglishUnitedStates,
    version: applicationSettings.locize.version,
};

export const languageOptions = [
    { label: 'US English', value: Language.EnglishUnitedStates },
    { label: 'UK English', value: Language.EnglishUnitedKingdom },
    { label: 'Spanish', value: Language.Spanish },
    { label: 'German', value: Language.German },
    { label: 'French', value: Language.French },
    { label: 'Portuguese', value: Language.Portuguese },
    { label: 'Chinese', value: Language.Chinese },
];

class LanguageService {
    private language: Language = Language.EnglishUnitedStates;

    private hasSetLanguage: boolean = false;

    private tranlationMaps: { [key in Language as string]?: TranslationType } = {};

    public defaultLanguage = Language.EnglishUnitedStates;

    constructor() {
        try {
            if (!i18n.isInitialized) {
                this.initialize();
            }
        } catch (error: any) {
            loggingService.trackException(error);
        }
    }

    initialize = (): void => {
        if (applicationSettings.isDevelopmentLike) {
            i18n.use(LastUsed);
        }

        i18n.use(detector)
            .use(Backend)
            .use(initReactI18next)
            .init({
                react: {
                    useSuspense: false,
                },
                debug: applicationSettings.isDevelopmentLike,
                ns: applicationSettings.locize.namespace,
                defaultNS: applicationSettings.locize.namespace,
                keySeparator: false,
                fallbackLng: this.defaultLanguage,
                interpolation: {
                    escapeValue: false,
                },
                backend: locizeOptions,
                locizeLastUsed: locizeOptions,
                saveMissing: applicationSettings.isDevelopmentLike, // You should not use saveMissing in production.
            });
    };

    translate = (...keys: string[]): string => {
        const keyString = keys.join('.');
        return i18n.t(keyString, { ns: applicationSettings.locize.namespace });
    };

    toDisplayName = (symbol: DemeterSymbolModel): string => {
        if (!symbol) {
            return '';
        }

        return `${languageService.translate(symbol.displayName)}`;
    };

    multiWordTranslate = (keyString: string | string[]): string => {
        // check for possible empty strings for dataSources (falsey value)
        if (!keyString) {
            return keyString;
        }
        // this check is to prevent application from erroring should this function recieve an improper type of Array
        let currentKeyString: string = keyString as string;
        if (Array.isArray(keyString)) {
            currentKeyString = keyString.join(' ');
        }
        const translatedArray: string[] = [];
        currentKeyString.split(' ')!.forEach((element: string) => {
            const key = element.replace(',', '').replace(':', '');

            translatedArray.push(i18n.t(key, { ns: applicationSettings.locize.namespace }));
            if (element.includes(':')) {
                translatedArray.push(':');
            }

            if (element.includes(',')) {
                translatedArray.push(',');
            }
        });

        return translatedArray.join(' ').replaceAll(' ,', ',');
    };

    setLanguage = async (language: Language): Promise<void> => {
        this.language = language;
        moment.locale(language);
        HighStock.setOptions({
            lang: {
                months: moment.months(),
                weekdays: moment.weekdays(),
                shortMonths: moment.monthsShort(),
                shortWeekdays: moment.weekdaysShort(),
            },
        });

        await i18n.changeLanguage(this.language);
        this.hasSetLanguage = true;
    };

    getLanguage = (): Language => this.language;

    getTranslations = (): TranslationType => {
        // There is a race condition where another widget will load and get the translations
        // before we can set the language and download the translations.
        if (this.tranlationMaps[this.language] !== undefined || !this.hasSetLanguage) {
            return this.tranlationMaps[this.language]!;
        }

        const destinationRoot = {};
        const missingTranslations: string[] = [];

        const convertObjectToTranslation = (namespace: string[], source: any, destination: any): void => {
            Object.keys(source).forEach((key) => {
                const newNamespace = [...namespace, key];
                const value = source[key];

                if (typeof value === 'string') {
                    const languageKey = newNamespace.join('.');
                    destination[key] = languageService.translate(languageKey) ?? languageKey;
                    if (destination[key] === languageKey) {
                        missingTranslations.push(languageKey);
                    }

                    return;
                }

                destination[key] = {};
                convertObjectToTranslation(newNamespace, value, destination[key]);
            });
        };

        convertObjectToTranslation([], TranslationKeyDefinition, destinationRoot);
        this.tranlationMaps[this.language] = destinationRoot as unknown as TranslationType;
        loggingService.logLocal('MISSING TRANSLATIONS:', this.language, missingTranslations);

        return this.tranlationMaps[this.language]!;
    };
}

const languageService = new LanguageService();

export default languageService;
