import { AccessTime, Clear, Done } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';
import styles from './Badge.module.scss';

interface IBadgeProps {
    text?: string;
    icon?: 'approved' | 'rejected' | 'expireSoon' | 'expired';
    title?: string;
}

const iconMap = {
    approved: <Done className={styles.badge_icon_approved} fontSize="small" />,
    rejected: <Clear className={styles.badge_icon_rejected} fontSize="small" />,
    expireSoon: <AccessTime className={styles.badge_icon_expireSoon} fontSize="small" />,
    expired: <AccessTime className={styles.badge_icon_expired} fontSize="small" />,
};

const Badge: React.FC<IBadgeProps> = (props: IBadgeProps) => {
    const iconElement = props.icon ? iconMap[props.icon] : null;

    return (
        <Tooltip title={props.title}>
            <div className={styles.badge}>
                {iconElement}
                {props.text && <span className={`${styles.badge_text} ${!iconElement ? 'no-icon' : ''}`}>{props.text}</span>}
            </div>
        </Tooltip>
    );
};

export default Badge;
