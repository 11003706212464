import React, { memo, useMemo } from 'react';
import { DemeterDataSource, DemeterSymbolModel } from '../../../../Generated/Raven-Demeter';
import { IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import ProjectionChartRaw from '../../../Components/Charts/Projection/ProjectionChartRaw';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { MonthlyRiskCompositeModel, RiskRequest } from '../RiskDefinitions';

export interface IValueAtRiskAssumptionsChart {
    symbol: DemeterSymbolModel;
    risksRequest: RiskRequest;
    calculatedRisks: MonthlyRiskCompositeModel[];
    displayDecimalPlaces: number;
}

const ValueAtRiskAssumptionsChart: React.FC<IValueAtRiskAssumptionsChart> = (props: IValueAtRiskAssumptionsChart) => {
    const [translations] = useLanguage();

    const linesSeries = useMemo<IChartDataSeries[]>(
        () => [
            {
                label: translations.risk.text.markToMarketReference,
                data: props.calculatedRisks.map((x) => ({
                    value: x.priceConvertedWithBasis ?? 0,
                    asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                    isActualValue: true,
                })),
            },
            {
                label: translations.words.upper,
                data: props.calculatedRisks.map((x) => ({
                    value: (x.priceConvertedWithBasis ?? 0) + (x.riskRange ?? 0),
                    asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                    isActualValue: true,
                })),
            },
            {
                label: translations.words.lower,
                data: props.calculatedRisks.map((x) => ({
                    value: (x.priceConvertedWithBasis ?? 0) - (x.riskRange ?? 0),
                    asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                    isActualValue: true,
                })),
            },
        ],
        [props.calculatedRisks],
    );

    return (
        <ChartWrapper
            name="ValueAtRiskAssumptionsChart"
            title={translations.risk.headers.valueAtRiskAssumptions}
            dataSourceTag={[translations.exchange[props.symbol.exchange], translations.dataSource[DemeterDataSource.StoneXCalculations]]}
            isLoading={props.calculatedRisks.length === 0}
        >
            <ProjectionChartRaw
                linesSeries={linesSeries}
                barSeries={{
                    label: '',
                    data: [],
                    isHidden: true,
                }}
                unitOfMeasure={props.risksRequest.unitOfMeasure}
                currency={props.risksRequest.currency}
                displayDecimalPlacesMaximum={props.displayDecimalPlaces}
                displayDecimalPlacesMinimum={props.displayDecimalPlaces}
            />
        </ChartWrapper>
    );
};

export default memo(ValueAtRiskAssumptionsChart);
