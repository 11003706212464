import React from 'react';
import { DemeterDataSource } from '../../../../Generated/Raven-Demeter';
import { chartColors } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import MultiAreaLineChartRaw from '../../../Components/Charts/MultiAreaLine/MultiAreaLineChartRaw';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValueMatrixCalculator.module.scss';
import { ValueMatrixChartRequest, ValueMatrixChartResults, ValueMatrixTableRequest } from './ValueMatrixDefinitions';

export interface IMarketIndicatorChartProps {
    title: string;
    valueMatrixTableRequest: ValueMatrixTableRequest;
    valueMatrixChartRequest: ValueMatrixChartRequest;
    valueMatrixChartResults: ValueMatrixChartResults;
    isCalculating: boolean;
}

const ValueMatrixChart: React.FC<IMarketIndicatorChartProps> = (props: IMarketIndicatorChartProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    const isLoading = !props.valueMatrixChartRequest || !props.valueMatrixChartResults || props.isCalculating;

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <ChartWrapper
            name="ValueMatrixChart"
            title={props.title}
            dataSourceTag={[
                translations.exchange[props.valueMatrixTableRequest.product.exchange],
                translations.dataSource[DemeterDataSource.StoneXCalculations],
            ]}
            isLoading={isLoading}
            rightSide={
                <div className={styles.value_matrix_chart_secondary_legend}>
                    {props.valueMatrixChartResults.areasSeries.map((x) => {
                        const currentColor = chartColors.priceDecileAlphaColorsRuleSetTwo.find((y) => y.decileRank === x.decileRank!)?.color;
                        return (
                            <div className={styles.value_matrix_chart_right_side}>
                                <div className={styles.value_matrix_chart_right_side_label} style={{ backgroundColor: currentColor }} />
                                {x.label}
                            </div>
                        );
                    })}
                </div>
            }
        >
            <MultiAreaLineChartRaw
                lineSeries={
                    props.valueMatrixTableRequest?.stripType === 'spot'
                        ? [{ ...props.valueMatrixChartResults?.linesSeries[0], label: formattingService.toDisplayName(props.valueMatrixTableRequest?.product) }]
                        : props.valueMatrixChartResults.linesSeries
                }
                areaSeries={props.valueMatrixChartResults.areasSeries}
                focusOnLine
                startDate={props.valueMatrixChartRequest.chartStartDate}
                endDate={props.valueMatrixChartRequest.chartEndDate}
                currency={props.valueMatrixTableRequest.product.currency ?? ''}
                unitOfMeasure={props.valueMatrixTableRequest.product.unitOfMeasure ?? ''}
            />
        </ChartWrapper>
    );
};

export default ValueMatrixChart;
