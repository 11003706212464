import { ITreeNode } from '../../TreeView/TreeItem';

/* eslint-disable class-methods-use-this */
class ProductsModalService {
    buildBranch = (rawBranches: string[], node: ITreeNode, defaultExpandedDepth?: number) => {
        const branchNodes: ITreeNode[] = [];
        const originalLength = rawBranches.length;
        if (node.value === 'root') {
            return [node];
        }

        while (rawBranches.length > 0) {
            const nodeName = rawBranches[rawBranches.length - 1];
            const nodeId = originalLength === rawBranches.length ? node.id : nodeName; // Preserve the original id of the product while in a tree
            const selectedProductValue = originalLength === rawBranches.length ? node.selectedProduct : false;
            const nodeJoinedValue = `${rawBranches.join('_')}`;
            rawBranches.pop();
            const nodeParentId = rawBranches[rawBranches.length - 1];
            const parentJoinedValue = `${rawBranches.join('_')}`;
            branchNodes.push({
                id: nodeId,
                value: nodeJoinedValue,
                name: nodeName,
                parentId: nodeParentId,
                parentValue: parentJoinedValue, // Parent node for reference keyed children to point to.
                column: 'left',
                order: node.order,
                displayOrder: node.displayOrder,
                expanded: (defaultExpandedDepth && rawBranches.length <= defaultExpandedDepth) || node.selectedProduct,
                selectedProduct: selectedProductValue,
                children: [],
            });
        }

        return branchNodes;
    };

    // Filter the branches to only keep unique path values without discarding branches that should be expanded.
    filterBranchs = (rawBranches: ITreeNode[]) => {
        const branchMap = new Map<string, ITreeNode>();

        rawBranches.forEach((branch) => {
            const existing = branchMap.get(branch.value);

            if (!existing || (!existing.expanded && branch.expanded)) {
                branchMap.set(branch.value, branch);
            }
        });

        return Array.from(branchMap.values());
    };

    buildTree = (treeNodes: ITreeNode[], defaultExpandedDepth?: number): ITreeNode => {
        const rawBranchNodes: ITreeNode[] = [];
        treeNodes.forEach((node) => {
            rawBranchNodes.push(...this.buildBranch(node.value.split('_'), node, defaultExpandedDepth));
        });

        const branchNodes = this.filterBranchs(rawBranchNodes);

        const referenceMapping = branchNodes.reduce((referenceMap, node, index) => {
            referenceMap[node.value] = index;
            return referenceMap;
        }, {} as { [key: string]: number });

        const treeRootNode = branchNodes.find((x) => x.value === 'root');
        branchNodes.forEach((element: ITreeNode) => {
            if (element.value !== 'root') {
                const parentElement = branchNodes[referenceMapping[element.parentValue]]; // value is parent id
                parentElement.children = [...(parentElement.children || []), element];
            }
        });

        return treeRootNode!;
    };
}

const productsModalService = new ProductsModalService();

export default productsModalService;
