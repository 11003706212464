/* eslint-disable no-confusing-arrow */
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { demeterApi, demeterCalculatorsApi, demeterOtherApi } from '../../../../Apis/Apis';
import scssVariables from '../../../../Config.module.scss';
import {
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterFilterTimeSpan,
    DemeterMarket,
    DemeterRegion,
    DemeterUserType,
    MarketPriceModel,
    MarketPricesModel,
    MarketPricesTimeSpan,
    PriceType,
} from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserCurrentMarket, selectUserType } from '../../../../Redux/Slices/UserSlice';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import useCacheOrApi from '../../../Apis/Hooks/useCacheOrApiHook';
import useMarketPricesRollingApi, { ListMarketPricesResponse } from '../../../Apis/Hooks/useMarketPricesRollingApiHook';
import useMultipleApis from '../../../Apis/Hooks/useMultipleApisHook';
import usePricesApi from '../../../Apis/Hooks/usePricesApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import checkSvg from '../../../Assets/Icons/checkCircle.svg';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Switch from '../../../Components/Form/Buttons/Switch';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import LabelWithTooltip from '../../../Components/Form/Inputs/LabelWithTooltip';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import { IRegionCommoditySelection } from '../../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useStateMachine from '../../../Core/Hooks/useStateMachine';
import RegexValidators from '../../../Core/Validation/RegexValidators';
import WebWorker from '../../../Core/WebWorker';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValueMatrixCalculator.module.scss';
import ValueMatrixChart from './ValueMatrixChart';
import ValueMatrixChartControls from './ValueMatrixChartControls';
import {
    ChartDisplayVisability,
    DecileChartOption,
    DecileRowDefinitions,
    defaultNumberOfYearsToLookBackGeneral,
    TermClassificationOptions,
    ValueMatrixChartRequest,
    ValueMatrixChartResults,
    ValueMatrixStripType,
    ValueMatrixTableRequest,
    ValueMatrixTableResults,
} from './ValueMatrixDefinitions';
import ValueMatrixDownload from './ValueMatrixDownload';
import ValueMatrixMarketDropdown from './ValueMatrixDropdowns/ValueMatrixMarketDropdown';
import ValueMatrixOuterRangeDropdown from './ValueMatrixDropdowns/ValueMatrixOuterRangeDropdown';
import ValueMatrixProductDropdown from './ValueMatrixDropdowns/ValueMatrixProductDropdown';
import ValueMatrixStripTypeDropdown from './ValueMatrixDropdowns/ValueMatrixStripTypeDropdown';
import ValueMatrixTermClassificationDropdown from './ValueMatrixDropdowns/ValueMatrixTermClassificationDropdown';
import ValueMatrixYearsToLookBackDropdown from './ValueMatrixDropdowns/ValueMatrixYearsToLookBackDropdown';
import ValueMatrixResultsTableRow from './ValueMatrixResultsTableRow';
import ValueMatrixStickyColumn from './ValueMatrixStickyColumn';
import valueMatrixWorker from './ValueMatrixWorker';
// Defaults.
const defaultInnerRange = 1;
const defaultInnerWeight = 20;
const defaultOuterWeight = 80;
const defaultDairyStripType = 'spot';
const defaultstripType = 'firstContractMonth';
const defaultApiYearsToLookBack = MarketPricesTimeSpan.TenYears;
const defaultApiYearsToLookBackSpot = DemeterFilterTimeSpan.TenYears;
const defaultDataFrequency = DemeterDataFrequency.Monthly;

// Default chart controls.
const defaultChartDeciles = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
const defaultChartStartDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
const defaultChartEndDate = new Date();
const defaultChartYears = [new Date().getFullYear()];

const ValueMatrixCalculator = () => {
    // Application hooks.
    const [translations] = useLanguage();
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const currentUserType = useApplicationSelector(selectUserType);
    const defaultMarket = useApplicationSelector(selectUserCurrentMarket);
    const symbols = useSymbolsApi();
    const valueMatrixTableWebworker = useRef(new WebWorker(valueMatrixWorker));
    const valueMatrixChartWebworker = useRef(new WebWorker(valueMatrixWorker));
    const valueMatrixResultsReference = useRef<HTMLDivElement>(null);

    // Table hooks.
    const [valueMatrixTableInputs, setValueMatrixTableInputs] = useState<ValueMatrixTableRequest>();
    const [valueMatrixTableResults, setValueMatrixTableResults] = useState<ValueMatrixTableResults[]>([]);
    const [lastGeneratedTime, setLastGeneratedTime] = useState(new Date());
    const [showLoadedMessage, setShowLoadedMessage] = useState<boolean>();
    const [isTableWorkerCalculating, setIsTableWorkerCalculating] = useState<boolean>();
    const valueMatrixStripTypeIsNotTwelveMonthStrip = valueMatrixTableInputs?.stripType !== 'twelveMonthStrip';
    const valueMatrixStripTypeIsNotSpot = valueMatrixTableInputs?.stripType !== 'spot';

    // Chart hooks.
    const [valueMatrixChartInputs, setValueMatrixChartInputs] = useState<ValueMatrixChartRequest>({
        chartStartDate: defaultChartStartDate,
        chartEndDate: defaultChartEndDate,
        chartDeciles: defaultChartDeciles,
        chartTerms: defaultChartYears,
    });
    const [valueMatrixChartResults, setValueMatrixChartResults] = useState<ValueMatrixChartResults>();
    const [chartDisplayVisibility, setChartDisplayVisibility] = useState<ChartDisplayVisability>({
        chartControlsActive: false,
        chartIsVisible: false,
    });
    const [isChartWorkerCalculating, setIsChartWorkerCalculating] = useState(true);
    const [chartChangesDetected, setChartChangesDetected] = useState<boolean>(true);

    // Use this for the initial get response so we only set the valueMatrixTableInputs one time.
    const initialProductSelection = useMemo(() => {
        if (!symbols) {
            return null;
        }

        return (
            symbols?.filter((x) => x.symbolCategory === searchParameters.market).find((x) => x.commodity === searchParameters.commodity) ??
            symbols
                ?.filter((x) => x.symbolCategory === defaultMarket)
                .sort((a, b) => `${a.exchange}-${a.displayName}`.localeCompare(`${b.exchange}-${b.displayName}`))
                .find((x) => x.symbolCategory === defaultMarket)!
        );
    }, [symbols]);

    // Api hooks.
    const [marketPricesLoading, refreshMarketPricesResponse, marketPricesResponse] = useMarketPricesRollingApi(
        valueMatrixTableInputs?.product?.reutersInstrumentCodePrefix!,
        defaultApiYearsToLookBack,
        valueMatrixTableInputs?.stripType,
        valueMatrixTableInputs?.product?.contractMonths!.length,
        valueMatrixTableInputs?.termClassification,
    );

    // TODO - multiple caches?
    const [, refreshListMarketPricesWithContractYear, listMarketPricesWithContractYearResponse] = useMultipleApis(
        () =>
            valueMatrixChartInputs.chartTerms.map((contractYear) =>
                demeterApi.listMarketPricesByContractYearWithConversions(
                    valueMatrixTableInputs?.product?.reutersInstrumentCodePrefix!,
                    `${contractYear}`,
                    defaultApiYearsToLookBack,
                ),
            ),
        { stopAutoExecute: true },
    );

    // Energy products are showing null for a pricesRegion and pricesCommodity.
    const regionCommoditySelection: IRegionCommoditySelection = {
        market: valueMatrixTableInputs?.market ?? defaultMarket,
        region: valueMatrixTableInputs?.product?.pricesRegion ?? DemeterRegion.All,
        commodity: valueMatrixTableInputs?.product?.pricesCommodity ?? DemeterCommodity.All,
        subRegion: '',
        extraParameters: valueMatrixTableInputs?.product?.pricesDataSource ?? '',
        dataFrequency: defaultDataFrequency,
    };

    const pricesResponseRaw = usePricesApi(regionCommoditySelection, undefined, undefined, defaultApiYearsToLookBackSpot);

    const spotPricesResponse = useMemo(() => {
        if (!pricesResponseRaw?.rows) {
            return undefined;
        }

        return { ...pricesResponseRaw, rows: pricesResponseRaw.rows.filter((x) => !!x.isActualValue) };
    }, [pricesResponseRaw]);

    const [, , producerPricesIndexResponse] = useCacheOrApi(CacheKeys.ListProducerPriceIndex, () =>
        demeterOtherApi.listCommodityMonthlyOther(DemeterRegion.UnitedStates, DemeterCommodity.ProducerPriceIndex),
    );

    const [, updateValueMatrixCalculator] = useApiWithoutAutoExecute(
        () =>
            demeterCalculatorsApi.updateValueMatrixCalculator(valueMatrixTableInputs?.product.region!, valueMatrixTableInputs?.product.commodity!, {
                region: valueMatrixTableInputs!.product.region!,
                commodity: valueMatrixTableInputs!.product.commodity!,
                asOfDate: formattingService.toApiDate(valueMatrixTableInputs!.asOfDate!),
                applyProducerPriceIndex: valueMatrixTableInputs!.applyProducerPriceIndex,
                weighted: showWeightedFields!,
                numberOfYearsInner: valueMatrixTableInputs?.weighted?.innerRange,
                weightPercentInner: valueMatrixTableInputs?.weighted?.innerWeight,
                numberOfYearsOuter: valueMatrixTableInputs?.weighted?.outerRange,
                weightPercentOuter: valueMatrixTableInputs?.weighted?.outerWeight ?? valueMatrixTableInputs?.timeSpan,
                stripType: valueMatrixTableInputs?.stripType!,
                termClassifications: valueMatrixTableInputs?.termClassification.map((x) => x.contractGroupName) as string[],
                showChart: chartDisplayVisibility.chartControlsActive,
                chartStartDate: formattingService.toApiDate(valueMatrixChartInputs?.chartStartDate!),
                chartEndDate: formattingService.toApiDate(valueMatrixChartInputs?.chartEndDate!),
                chartDeciles: valueMatrixChartInputs.chartDeciles,
                chartTerms: valueMatrixChartInputs.chartTerms.map((x) => `${x}`),
            }),
        {
            successMessage: translations.calculators.valueMatrix.messages.successfulCalculatorSave,
            errorMessage: translations.calculators.valueMatrix.messages.failedCalculatorSave,
        },
    );

    const [, refreshGetValueMatrixCalculator, getValueMatrixCalculatorResponse] = useApiWithoutAutoExecute(() =>
        demeterCalculatorsApi.getValueMatrixCalculator(
            valueMatrixTableInputs?.product?.region ?? initialProductSelection?.region!,
            valueMatrixTableInputs?.product?.commodity ?? initialProductSelection?.commodity!,
        ),
    );

    // Display hooks.
    const [showWeightedFields, setShowWeightedFields] = useState<boolean>(false);

    // Dropdown options.
    const decileRowDefinitions: DecileRowDefinitions[] = useMemo(
        () => [
            {
                text: translations.words.mean,
                backgroundColor: scssVariables.deactivatedBlockColor,
            },
            {
                text: translations.words.median,
                backgroundColor: scssVariables.deactivatedBlockColor,
            },
            {
                text: `90% - ${translations.words.maximum}`,
                backgroundColor: scssVariables.ninetyToOneHundredDecile,
                textColor: scssVariables.plainWhite,
                fieldName: 'ninetyToOneHundredDecile',
            },
            {
                text: '80% - 90%',
                backgroundColor: scssVariables.ninetyToOneHundredDecile,
                textColor: scssVariables.plainWhite,
                fieldName: 'eightyToNinetyDecile',
            },
            {
                text: '70% - 80%',
                backgroundColor: scssVariables.seventyToEightyDecile,
                fieldName: 'seventyToEightyDecile',
            },
            {
                text: '60% - 70%',
                backgroundColor: scssVariables.sixtyToSeventyDecile,
                fieldName: 'sixtyToSeventyDecile',
            },
            {
                text: '50% - 60%',
                backgroundColor: scssVariables.fiftyToSixtyDecile,
                fieldName: 'fiftyToSixtyDecile',
            },
            {
                text: '40% - 50%',
                backgroundColor: scssVariables.fourtyToFiftyDecile,
                fieldName: 'fourtyToFiftyDecile',
            },
            {
                text: '30% - 40%',
                backgroundColor: scssVariables.thirtyToFourtyDecile,
                fieldName: 'thirtyToFourtyDecile',
            },
            {
                text: '20% - 30%',
                backgroundColor: scssVariables.twentyToThirtyDecile,
                fieldName: 'twentyToThirtyDecile',
            },
            {
                text: '10% - 20%',
                backgroundColor: scssVariables.zeroToTenDecile,
                textColor: scssVariables.plainWhite,
                fieldName: 'tenToTwentyDecile',
            },
            {
                text: `${translations.words.minimum} - 10%`,
                backgroundColor: scssVariables.zeroToTenDecile,
                textColor: scssVariables.plainWhite,
                fieldName: 'zeroToTenDecile',
            },
        ],
        [translations],
    );

    const decileChartOptions: DecileChartOption[] = useMemo(
        () => [
            {
                label: `90% - ${translations.words.maximum}`,
                value: 10,
            },
            {
                label: '80% - 90%',
                value: 9,
            },
            {
                label: '70% - 80%',
                value: 8,
            },
            {
                label: '60% - 70%',
                value: 7,
            },
            {
                label: '50% - 60%',
                value: 6,
            },
            {
                label: '40% - 50%',
                value: 5,
            },
            {
                label: '30% - 40%',
                value: 4,
            },
            {
                label: '20% - 30%',
                value: 3,
            },
            {
                label: '10% - 20%',
                value: 2,
            },
            {
                label: `${translations.words.minimum} - 10%`,
                value: 1,
            },
        ],
        [translations],
    );

    const termClassificationOptions = useMemo(() => {
        const options: TermClassificationOptions[] = [
            {
                label: translations.calculators.valueMatrix.text.annual,
                value: {
                    contractMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    contractGroupName: 'annual',
                },
            },
        ];

        if (valueMatrixStripTypeIsNotTwelveMonthStrip && valueMatrixStripTypeIsNotSpot) {
            options.push(
                {
                    label: translations.calculators.valueMatrix.text.quarter1,
                    value: {
                        contractMonths: [1, 2, 3],
                        contractGroupName: 'quarter1',
                    },
                },
                {
                    label: translations.calculators.valueMatrix.text.quarter2,
                    value: {
                        contractMonths: [4, 5, 6],
                        contractGroupName: 'quarter2',
                    },
                },
                {
                    label: translations.calculators.valueMatrix.text.quarter3,
                    value: {
                        contractMonths: [7, 8, 9],
                        contractGroupName: 'quarter3',
                    },
                },
                {
                    label: translations.calculators.valueMatrix.text.quarter4,
                    value: {
                        contractMonths: [10, 11, 12],
                        contractGroupName: 'quarter4',
                    },
                },
            );
        }

        if (valueMatrixTableInputs?.market === DemeterMarket.Energy && valueMatrixStripTypeIsNotTwelveMonthStrip && valueMatrixStripTypeIsNotSpot) {
            options.push(
                {
                    label: translations.calculators.valueMatrix.text.summerMonths,
                    value: {
                        contractMonths: [4, 5, 6, 7, 8, 9, 10],
                        contractGroupName: 'summerMonths',
                    },
                },
                {
                    label: translations.calculators.valueMatrix.text.winterMonths,
                    value: {
                        contractMonths: [11, 12, 1, 2, 3],
                        contractGroupName: 'winterMonths',
                    },
                },
            );
        }

        return options;
    }, [translations, valueMatrixTableInputs?.product, valueMatrixTableInputs?.stripType]);

    const savedCommodityLoaded = useMemo(
        () =>
            !!getValueMatrixCalculatorResponse &&
            (getValueMatrixCalculatorResponse?.valueMatrixCalculator?.commodity === valueMatrixTableInputs?.product?.commodity ||
                (getValueMatrixCalculatorResponse?.valueMatrixCalculator?.commodity === initialProductSelection?.commodity && !valueMatrixTableInputs)),
        [getValueMatrixCalculatorResponse?.valueMatrixCalculator?.commodity, valueMatrixTableInputs?.product],
    );

    const apisLoaded = useMemo(
        () => (valueMatrixStripTypeIsNotSpot && marketPricesResponse) || (valueMatrixTableInputs?.stripType === 'spot' && spotPricesResponse),
        [marketPricesResponse, spotPricesResponse, valueMatrixTableInputs?.stripType],
    );

    const stateMachineOptions = useMemo(
        () => [
            // Apply state when setting defaults from search parameters. Only apply on navigation.
            {
                priority: 1,
                state: {
                    ...valueMatrixTableInputs,
                    asOfDate: moment(searchParameters.asOfDate).toDate(),
                    market: searchParameters.market,
                    product: initialProductSelection,
                    stripType: searchParameters.stripType,
                    termClassification: [
                        termClassificationOptions.find((x) => x.value.contractMonths.join() === searchParameters.monthSets)?.value ??
                            termClassificationOptions[0].value,
                    ],
                    timeSpan: Number(searchParameters.outerYears),
                    applyProducerPriceIndex: !!searchParameters.useProducerPriceIndex!,
                    weighted: searchParameters.useWeightedCalculation
                        ? {
                              innerRange: searchParameters.innerYears!,
                              innerWeight: searchParameters.innerWeightPercent!,
                              outerRange: Number(searchParameters.outerYears),
                              outerWeight: searchParameters.outerWeightPercent!,
                          }
                        : {},
                } as ValueMatrixTableRequest,
                condition: !valueMatrixTableInputs && !!searchParameters.market,
            },
            // Apply state when setting a saved commodity. Can apply after product change.
            {
                priority: 2,
                state: {
                    ...valueMatrixTableInputs,
                    asOfDate: moment().toDate(),
                    market: valueMatrixTableInputs?.market ?? defaultMarket,
                    product: valueMatrixTableInputs?.product ?? initialProductSelection,
                    stripType: getValueMatrixCalculatorResponse?.valueMatrixCalculator?.stripType as ValueMatrixStripType,
                    termClassification: termClassificationOptions
                        .filter((x) => getValueMatrixCalculatorResponse?.valueMatrixCalculator?.termClassifications.includes(x.value.contractGroupName!))
                        .map((x) => x.value),
                    timeSpan:
                        getValueMatrixCalculatorResponse?.valueMatrixCalculator?.numberOfYearsOuter ??
                        valueMatrixTableInputs?.timeSpan ??
                        defaultNumberOfYearsToLookBackGeneral,
                    applyProducerPriceIndex: getValueMatrixCalculatorResponse?.valueMatrixCalculator?.applyProducerPriceIndex!,
                    weighted: getValueMatrixCalculatorResponse?.valueMatrixCalculator?.weighted
                        ? {
                              ...valueMatrixTableInputs?.weighted,
                              innerRange: getValueMatrixCalculatorResponse?.valueMatrixCalculator?.numberOfYearsInner!,
                              innerWeight: getValueMatrixCalculatorResponse?.valueMatrixCalculator?.weightPercentInner!,
                              outerRange: getValueMatrixCalculatorResponse?.valueMatrixCalculator?.numberOfYearsOuter!,
                              outerWeight: getValueMatrixCalculatorResponse?.valueMatrixCalculator?.weightPercentOuter!,
                          }
                        : {},
                    updateTriggeredBy: 'apiReset',
                } as ValueMatrixTableRequest,
                condition:
                    savedCommodityLoaded &&
                    !!getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt &&
                    valueMatrixTableInputs?.updateTriggeredBy !== 'stripTypeSelection' &&
                    valueMatrixTableInputs?.updateTriggeredBy !== 'generalFieldSelectionOrUpdate',
            },
            // Apply state when setting defaults for a non-saved commodity and user didn't navigate here.  Can apply after product change.
            {
                priority: 3,
                state: {
                    ...valueMatrixTableInputs,
                    asOfDate: moment().toDate(),
                    market: valueMatrixTableInputs?.market ?? defaultMarket,
                    product: valueMatrixTableInputs?.product ?? initialProductSelection,
                    stripType: valueMatrixTableInputs?.market === DemeterMarket.Dairy ? defaultDairyStripType : defaultstripType,
                    termClassification: [termClassificationOptions[0].value],
                    timeSpan: defaultNumberOfYearsToLookBackGeneral,
                    applyProducerPriceIndex: false,
                    weighted: {},
                    updateTriggeredBy: 'apiReset',
                } as ValueMatrixTableRequest,
                condition: !valueMatrixTableInputs || valueMatrixTableInputs?.updateTriggeredBy === 'productChange',
            },
        ],
        [initialProductSelection, valueMatrixTableInputs, getValueMatrixCalculatorResponse],
    );

    useStateMachine(
        stateMachineOptions,
        !!symbols && savedCommodityLoaded,
        [savedCommodityLoaded, marketPricesResponse, spotPricesResponse, valueMatrixTableInputs?.isCascadeUpdating],
        valueMatrixTableInputs!,
        setValueMatrixTableInputs as () => void,
    );

    // On page load, add event listeners for messages from the Worker.
    useEffect(() => {
        if (!defaultMarket || !symbols) {
            return () => null;
        }

        setShowWeightedFields(!!searchParameters.useWeightedCalculation);

        (valueMatrixTableWebworker.current as Worker).onmessage = (event: MessageEvent) => {
            setValueMatrixTableResults(event.data.decileRangeData);
            setIsTableWorkerCalculating(false);
        };

        (valueMatrixChartWebworker.current as Worker).onmessage = (event: MessageEvent) => {
            setValueMatrixChartResults(event.data);
            setIsChartWorkerCalculating(false);
        };

        return () => {
            (valueMatrixTableWebworker.current as Worker).terminate();
            (valueMatrixChartWebworker.current as Worker).terminate();
        };
    }, [defaultMarket, symbols]);

    // On first load, once we have the defaults. Also, detect changes for disabling the render chart button.
    useEffect(() => {
        setChartChangesDetected(true);
    }, [valueMatrixTableInputs, valueMatrixChartInputs]);

    // Whenever he have the new responses from the apis, update the table data.
    useEffect(() => {
        const valueMatrixTableCalculatorNotReady = !valueMatrixTableInputs?.product || isTableWorkerCalculating;
        const triggeredByProductChange = valueMatrixTableInputs?.updateTriggeredBy === 'productChange';

        if (!savedCommodityLoaded && initialProductSelection) {
            refreshGetValueMatrixCalculator();
            return;
        }

        // If we don't have the correct saved data element loaded in, we cancel this call. Whenever a product changes,
        // don't update. There will be an api call to trigger the update once later.
        if (!apisLoaded || triggeredByProductChange || valueMatrixTableCalculatorNotReady) {
            return;
        }

        // If we dont have marketPricesResponse data, reload the api.
        const marketPricesExists = marketPricesResponse && !Array.isArray(marketPricesResponse) && marketPricesResponse?.rows![0];
        if (marketPricesExists) {
            const marketPricesResponseModel = marketPricesResponse?.rows![0] as MarketPricesModel;
            const contractMonthRequiresLoad =
                !marketPricesResponseModel.prices ||
                !marketPricesResponseModel.prices[0].reutersInstrumentCode?.startsWith(valueMatrixTableInputs?.product.reutersInstrumentCodePrefix);
            const twelveMonthStripModelRequiresLoad = !(marketPricesResponse?.rows![0] as MarketPriceModel).reutersInstrumentCode?.startsWith(
                valueMatrixTableInputs?.product.reutersInstrumentCodePrefix,
            );

            if (valueMatrixStripTypeIsNotSpot && contractMonthRequiresLoad && twelveMonthStripModelRequiresLoad) {
                refreshMarketPricesResponse();
                return;
            }
        }

        setIsTableWorkerCalculating(true);
        handleCalculateAndUpdateValueMatrixTable();
    }, [apisLoaded, savedCommodityLoaded, initialProductSelection, valueMatrixTableInputs]);

    // We need to trigger the chart update with a useEffect only if we do not have our first set of chart results.
    useEffect(() => {
        if (!listMarketPricesWithContractYearResponse || valueMatrixChartResults || !chartDisplayVisibility.chartControlsActive) {
            return;
        }

        handleValueMatrixChartUpdate();
    }, [listMarketPricesWithContractYearResponse]);

    // Refresh market prices api when inputs change.
    useEffect(() => {
        if (!valueMatrixTableInputs?.product) {
            return;
        }

        if (searchParameters.market) {
            setSearchParameters({ tab: 'ValueMatrix' });
        }

        refreshListMarketPricesWithContractYear();
    }, [valueMatrixTableInputs?.product, valueMatrixChartInputs.chartTerms]);

    // When we change products and get a new response, update the defaults for that product.
    useEffect(() => {
        if (!symbols || !getValueMatrixCalculatorResponse || valueMatrixTableInputs?.updateTriggeredBy === 'stripTypeSelection') {
            return;
        }

        if (!getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt) {
            setShowWeightedFields(!!searchParameters.useWeightedCalculation);
            setChartDefaults();
            return;
        }

        handleValueMatrixReset();
    }, [getValueMatrixCalculatorResponse, symbols, termClassificationOptions]);

    useEffect(() => {
        setLastGeneratedTime(new Date());

        if (valueMatrixTableInputs?.updateTriggeredBy === 'apiReset') {
            return;
        }

        setShowLoadedMessage(false);
    }, [valueMatrixTableResults, valueMatrixTableInputs?.updateTriggeredBy]);

    const setChartDefaults = () => {
        setValueMatrixChartInputs({
            chartStartDate: defaultChartStartDate,
            chartEndDate: defaultChartEndDate,
            chartDeciles: defaultChartDeciles,
            chartTerms: defaultChartYears,
        });
    };

    const handleCalculateAndUpdateValueMatrixTable = () => {
        if (!valueMatrixTableInputs || !apisLoaded) {
            return;
        }

        let marketPricesForWorker = valueMatrixStripTypeIsNotSpot ? [marketPricesResponse] : [spotPricesResponse];

        if (valueMatrixTableInputs?.stripType === 'forwardContract') {
            marketPricesForWorker = marketPricesResponse as ListMarketPricesResponse[];
        }

        (valueMatrixTableWebworker.current as Worker).postMessage({
            messageType: 'updateTableResults',
            valueMatrixTableInputs,
            valueMatrixChartInputs,
            producerPricesIndexResponse,
            termClassificationOptions,
            decileChartOptions,
            marketPricesResponse: marketPricesForWorker,
            decileRowDefinitions,
        });
    };

    const handleValueMatrixChartUpdate = () => {
        if ((!marketPricesResponse && !spotPricesResponse) || !listMarketPricesWithContractYearResponse || !valueMatrixTableInputs) {
            return;
        }

        const availableDatesArray: Date[] = [];
        const { chartStartDate, chartEndDate } = valueMatrixChartInputs;
        const currentAvailableDate = new Date(chartStartDate!);

        availableDatesArray.push(chartStartDate!);

        while (currentAvailableDate!.getTime() < chartEndDate!.getTime()) {
            const newAvailableDate = new Date(currentAvailableDate?.setMonth(currentAvailableDate.getMonth() + 1));
            availableDatesArray.push(newAvailableDate);
        }

        let marketPricesForWorker = valueMatrixStripTypeIsNotSpot ? [marketPricesResponse] : [spotPricesResponse];

        if (valueMatrixTableInputs?.stripType === 'forwardContract') {
            marketPricesForWorker = marketPricesResponse as ListMarketPricesResponse[];
        }

        (valueMatrixChartWebworker.current as Worker).postMessage({
            messageType: 'updateChartResults',
            valueMatrixTableInputs,
            valueMatrixChartInputs,
            producerPricesIndexResponse,
            termClassificationOptions,
            availableDatesArray,
            decileChartOptions,
            marketPricesResponse: marketPricesForWorker,
            listMarketPricesWithContractYearResponse,
        });
    };

    const handleValueMatrixReset = () => {
        const { weighted, showChart, chartDeciles, chartTerms } = getValueMatrixCalculatorResponse!.valueMatrixCalculator!;

        // Update table and chart inputs. We always reset the dates.
        setValueMatrixChartInputs({
            ...valueMatrixChartInputs,
            chartStartDate: new Date(valueMatrixChartInputs.chartStartDate!),
            chartEndDate: new Date(valueMatrixChartInputs.chartEndDate!),
            chartDeciles: chartDeciles ?? defaultChartDeciles,
            chartTerms: chartTerms ? chartTerms?.map((x) => +x) : valueMatrixChartInputs.chartTerms,
        });

        // Set switches to display properly.
        setShowWeightedFields(weighted);
        setChartDisplayVisibility({ chartIsVisible: false, chartControlsActive: showChart });
    };

    const updateValueMatrixChart = () => {
        setIsChartWorkerCalculating(true);
        handleValueMatrixChartUpdate();
    };

    const titleString = useMemo(() => formattingService.toDisplayName(valueMatrixTableInputs?.product), [valueMatrixTableResults]);

    const isLoading = !valueMatrixTableInputs || valueMatrixTableResults?.length === 0;

    const pricesLoading = (!spotPricesResponse && valueMatrixTableInputs?.stripType === 'spot') || (marketPricesLoading && valueMatrixStripTypeIsNotSpot);

    const valueMatrixStripTypeIsTwelveMonth = valueMatrixTableInputs?.stripType === 'twelveMonthStrip';

    const valueMatrixStripTypeIsContract2 = valueMatrixTableInputs?.stripType === 'secondContractMonth';

    const valueMatrixStripTypeIsForwardContract = valueMatrixTableInputs?.stripType === 'forwardContract';

    const showValueMatrixDownload = currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.BusinessOwner;

    return isLoading ? (
        <PageLoadingSpinner />
    ) : (
        <>
            <div className={styles.value_matrix_title_row}>
                <ComponentHeader title={translations.calculators.text.valueMatrix} />
                <div className={styles.value_matrix_chart_button_set}>
                    {getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt && (
                        <p>
                            {`${translations.words.saved} ${formattingService.toLongDateAndTimeFormat(
                                new Date(getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt),
                            )}`}
                        </p>
                    )}

                    <LinkButton
                        disabled={!getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt}
                        title={translations.actions.loadLastSave}
                        type={LinkButtonType.White}
                        onClick={() => {
                            const savedTableValues = stateMachineOptions.find((x) => x.priority === 2)!.state;
                            setValueMatrixTableInputs(savedTableValues as ValueMatrixTableRequest);
                            handleValueMatrixReset();
                            setShowLoadedMessage(true);
                        }}
                    />
                    <LinkButton
                        title={translations.actions.save}
                        type={LinkButtonType.White}
                        onClick={() => {
                            updateValueMatrixCalculator();
                        }}
                    />
                </div>
            </div>
            <div className={styles.value_matrix_wrapper}>
                <div className={styles.value_matrix_inputs}>
                    {showLoadedMessage && (
                        <div className={styles.value_matrix_saved_version_loaded}>
                            <img className={styles.registration_self_list_checkmark} src={checkSvg} alt="#" />
                            {translations.calculators.valueMatrix.text.savedVersionLoaded}
                        </div>
                    )}

                    <div className={styles.value_matrix_section_header}>{translations.words.table}</div>

                    <div className={styles.value_matrix_inputs_row}>
                        <ValueMatrixMarketDropdown valueMatrixInputs={valueMatrixTableInputs} setValueMatrixInputs={setValueMatrixTableInputs} />
                    </div>
                    <div className={styles.value_matrix_inputs_row}>
                        <ValueMatrixProductDropdown
                            refreshGetValueMatrixCalculator={refreshGetValueMatrixCalculator}
                            valueMatrixInputs={valueMatrixTableInputs}
                            setValueMatrixInputs={setValueMatrixTableInputs}
                        />
                    </div>
                    <div className={styles.value_matrix_inputs_row}>
                        <div className={styles.value_matrix_inputs_datepicker}>
                            <DatePickerInput
                                testId="ValueMatrixDatePicker"
                                title={translations.text.asOfDate}
                                required
                                value={valueMatrixTableInputs?.asOfDate}
                                handleDateChange={(value) => {
                                    if (value?.getTime()! >= new Date().setHours(0, 0, 0, 0)) {
                                        return;
                                    }

                                    setValueMatrixTableInputs({
                                        ...valueMatrixTableInputs,
                                        asOfDate: value,
                                        updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                                    });
                                }}
                                oldestAllowedDate={moment().subtract(defaultNumberOfYearsToLookBackGeneral, 'years').toDate()}
                                mostRecentAllowedDate={new Date()}
                            />
                        </div>
                        <div>
                            <div className={styles.value_matrix_apply_producer_price_index}>
                                <Switch
                                    checked={valueMatrixTableInputs.applyProducerPriceIndex}
                                    handleChange={() => {
                                        if (valueMatrixTableInputs.applyProducerPriceIndex) {
                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                applyProducerPriceIndex: false,
                                                updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                                            });
                                        } else {
                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                applyProducerPriceIndex: true,
                                                updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                                            });
                                        }
                                    }}
                                />
                                <LabelWithTooltip
                                    title={translations.calculators.valueMatrix.fields.applyProducerPriceIndex}
                                    tooltip={translations.calculators.valueMatrix.text.producerPriceIndexTooltipInformation}
                                />
                            </div>
                            <div className={styles.value_matrix_apply_producer_price_index}>
                                <Switch
                                    checked={showWeightedFields}
                                    handleChange={() => {
                                        const defaultNumberOfYears = defaultNumberOfYearsToLookBackGeneral;

                                        if (showWeightedFields) {
                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                timeSpan: defaultNumberOfYears,
                                                weighted: {},
                                                updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                                            });
                                            setShowWeightedFields(false);
                                        } else {
                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                timeSpan: null,
                                                weighted: {
                                                    ...valueMatrixTableInputs.weighted,
                                                    innerRange: defaultInnerRange,
                                                    innerWeight: defaultInnerWeight,
                                                    outerRange: defaultNumberOfYears,
                                                    outerWeight: defaultOuterWeight,
                                                },
                                                updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                                            });
                                            setShowWeightedFields(true);
                                        }
                                    }}
                                />
                                {translations.calculators.valueMatrix.fields.weighted}
                            </div>
                        </div>
                    </div>

                    {!showWeightedFields ? (
                        <div className={styles.value_matrix_inputs_row}>
                            <ValueMatrixYearsToLookBackDropdown valueMatrixInputs={valueMatrixTableInputs} setValueMatrixInputs={setValueMatrixTableInputs} />
                        </div>
                    ) : (
                        <>
                            <div className={styles.value_matrix_inputs_row}>
                                <div className={styles.value_matrix_half_first_input}>
                                    <TextInput
                                        title={
                                            <LabelWithTooltip
                                                title={translations.calculators.valueMatrix.fields.innerRange}
                                                tooltip={translations.calculators.valueMatrix.text.innerRangeTooltipInformation}
                                            />
                                        }
                                        type="number"
                                        required
                                        value={`${valueMatrixTableInputs?.weighted?.innerRange}`}
                                        handleTextChange={(value) => {
                                            if (valueMatrixTableInputs?.weighted?.innerRange === +value || Number.isNaN(+value)) {
                                                return;
                                            }

                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                weighted: {
                                                    ...valueMatrixTableInputs.weighted,
                                                    innerRange: +value,
                                                },
                                                updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                                            });
                                        }}
                                        validation={RegexValidators.PositiveNumber}
                                        errorMessage={translations.marketIndicatorsManagement.messages.positiveNumber}
                                    />
                                </div>

                                <TextInput
                                    title={translations.calculators.valueMatrix.fields.innerWeight}
                                    type="number"
                                    required
                                    value={`${valueMatrixTableInputs.weighted?.innerWeight}`}
                                    handleTextChange={(value) => {
                                        if (+value > 100 || +value < 0 || Number.isNaN(+value) || valueMatrixTableInputs?.weighted?.innerWeight === +value) {
                                            return;
                                        }

                                        setValueMatrixTableInputs({
                                            ...valueMatrixTableInputs,
                                            weighted: {
                                                ...valueMatrixTableInputs.weighted,
                                                innerWeight: +value,
                                                outerWeight: 100 - +value,
                                            },
                                            updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                                        });
                                    }}
                                    validation={RegexValidators.PositiveNumber}
                                    errorMessage={translations.marketIndicatorsManagement.messages.positiveNumber}
                                />
                            </div>
                            <div className={styles.value_matrix_inputs_row}>
                                <div className={styles.value_matrix_half_first_input}>
                                    <ValueMatrixOuterRangeDropdown
                                        valueMatrixInputs={valueMatrixTableInputs}
                                        setValueMatrixInputs={setValueMatrixTableInputs}
                                    />
                                </div>
                                <TextInput
                                    title={translations.calculators.valueMatrix.fields.outerWeight}
                                    type="number"
                                    required
                                    value={`${valueMatrixTableInputs.weighted?.outerWeight}`}
                                    handleTextChange={(value) => {
                                        if (+value > 100 || +value < 0 || Number.isNaN(+value) || valueMatrixTableInputs?.weighted?.outerWeight === +value) {
                                            return;
                                        }

                                        setValueMatrixTableInputs({
                                            ...valueMatrixTableInputs,
                                            weighted: {
                                                ...valueMatrixTableInputs.weighted,
                                                outerWeight: +value,
                                                innerWeight: 100 - +value,
                                            },
                                            updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                                        });
                                    }}
                                    validation={RegexValidators.PositiveNumber}
                                    errorMessage={translations.marketIndicatorsManagement.messages.positiveNumber}
                                />
                            </div>
                        </>
                    )}

                    <div className={styles.value_matrix_inputs_row}>
                        <ValueMatrixStripTypeDropdown valueMatrixInputs={valueMatrixTableInputs} setValueMatrixInputs={setValueMatrixTableInputs} />
                    </div>
                    <p className={styles.value_matrix_checkbox_dropdown_label}>{translations.calculators.valueMatrix.fields.termClassification}</p>
                    <div className={styles.value_matrix_inputs_row}>
                        <ValueMatrixTermClassificationDropdown
                            valueMatrixInputs={valueMatrixTableInputs}
                            setValueMatrixInputs={setValueMatrixTableInputs}
                            termClassificationOptions={termClassificationOptions}
                        />
                    </div>

                    <div className={styles.value_matrix_section_header}>{translations.words.chart}</div>

                    <div className={styles.value_matrix_note}>{translations.calculators.valueMatrix.text.chartSettingsMessage}</div>

                    <ValueMatrixChartControls
                        valueMatrixTableInputs={valueMatrixTableInputs}
                        valueMatrixChartRequest={valueMatrixChartInputs}
                        setValueMatrixChartInputs={setValueMatrixChartInputs}
                        decileChartOptions={decileChartOptions}
                        chartDisplayVisibility={chartDisplayVisibility}
                        setChartDisplayVisibility={setChartDisplayVisibility}
                    />
                    {chartDisplayVisibility.chartControlsActive && (
                        <div className={styles.value_matrix_go_button}>
                            {pricesLoading && <LoadingSpinner />}

                            <LinkButton
                                title={translations.actions.renderChart}
                                type={LinkButtonType.Blue}
                                onClick={() => {
                                    if (chartDisplayVisibility.chartControlsActive) {
                                        setChartDisplayVisibility({ ...chartDisplayVisibility, chartIsVisible: true });
                                    }

                                    updateValueMatrixChart();
                                    setChartChangesDetected(false);
                                }}
                                disabled={pricesLoading || !chartChangesDetected}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.value_matrix_results}>
                    <div ref={valueMatrixResultsReference} className={styles.value_matrix_results_reference_container}>
                        <ComponentHeader title={titleString} containerClassName={styles.value_matrix_results_header} />
                        <div className={styles.value_matrix_primary_header}>
                            <div className={styles.value_matrix_results_table_header_term_classifications_row}>
                                {valueMatrixTableInputs.product?.currency &&
                                    valueMatrixTableResults?.map((x) => (
                                        <div key={x.title} className={styles.value_matrix_results_header_cell}>
                                            {`${x?.title} (${translations.currency[valueMatrixTableInputs.product.currency]})`}
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div className={styles.value_matrix_results_table}>
                            <ValueMatrixStickyColumn decileRowDefinitions={decileRowDefinitions} />
                            <div className={styles.value_matrix_results_scrollable}>
                                <div className={styles.value_matrix_results_table_header_row}>
                                    {valueMatrixTableResults?.map((x) => (
                                        <div key={x.title} className={styles.value_matrix_results_header_cell}>
                                            {formattingService.toNumberStringWithTrailingZeros(+x.meanPrice)}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.value_matrix_results_table_header_row}>
                                    {valueMatrixTableResults?.map((x) => (
                                        <div key={x.title} className={styles.value_matrix_results_header_cell}>
                                            {formattingService.toNumberStringWithTrailingZeros(+x.medianPrice)}
                                        </div>
                                    ))}
                                </div>

                                {decileRowDefinitions
                                    .filter((x) => x.fieldName)
                                    .map((x) => (
                                        <ValueMatrixResultsTableRow key={x.fieldName} valueMatrixResults={valueMatrixTableResults} fieldName={x.fieldName!} />
                                    ))}
                            </div>
                        </div>
                        <p className={styles.value_matrix_generated_text}>
                            {`${translations.words.generated} ${formattingService.toLongDateAndTimeFormat(lastGeneratedTime)}`}
                        </p>
                    </div>
                    <div className={styles.value_matrix_download}>
                        <ValueMatrixDownload
                            resultsReference={valueMatrixResultsReference}
                            valueMatrixTableInputs={valueMatrixTableInputs}
                            priceType={valueMatrixStripTypeIsNotSpot ? PriceType.Futures : PriceType.Physical}
                            contractNumberStart={valueMatrixStripTypeIsContract2 ? 2 : 1}
                            contractNumberEnd={
                                valueMatrixStripTypeIsTwelveMonth || valueMatrixStripTypeIsForwardContract
                                    ? valueMatrixTableInputs.product.contractMonths?.length
                                    : null
                            }
                            useWeightedCalculation={showWeightedFields}
                        />
                    </div>
                </div>
            </div>
            {chartDisplayVisibility?.chartIsVisible && (
                <div className={styles.value_matrix_chart_section}>
                    <ValueMatrixChart
                        title={`${formattingService.toDisplayName(valueMatrixChartResults?.product)} - ${
                            termClassificationOptions.find((x) => x.value.contractGroupName === valueMatrixTableInputs.termClassification[0].contractGroupName)
                                ?.label ?? ''
                        }`}
                        valueMatrixTableRequest={valueMatrixTableInputs}
                        valueMatrixChartRequest={valueMatrixChartInputs}
                        valueMatrixChartResults={valueMatrixChartResults!}
                        isCalculating={isChartWorkerCalculating}
                    />
                </div>
            )}
        </>
    );
};

export default ValueMatrixCalculator;
