import React from 'react';
import styles from './AlertPage.module.scss';

interface AlertPageInterface {
    message: string;
}

const AlertPage: React.FC<AlertPageInterface> = (props: AlertPageInterface) => (
    <div className={styles.alertPage_container}>
        <div className={styles.AlertModal}>
            <h3>{props.message}</h3>
        </div>
    </div>
);

export default AlertPage;
