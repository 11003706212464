/* eslint-disable no-confusing-arrow */
import { Tooltip } from '@mui/material';
import React from 'react';
import infoSvg from '../../../Assets/Icons/info.svg';
import styles from './Inputs.module.scss';

interface ILabelWithTooltipProps {
    title: string;
    tooltip: string | JSX.Element;
    position?: 'top' | 'middle';
}

const LabelWithTooltip: React.FC<ILabelWithTooltipProps> = (props: ILabelWithTooltipProps) => (
    <>
        {props.title}
        <Tooltip className={props.position === 'middle' ? styles.input_tooltip_middle : styles.input_tooltip} title={props.tooltip} placement="top-start">
            <img src={infoSvg} alt="#" />
        </Tooltip>
    </>
);

export default LabelWithTooltip;
