import { useMemo } from 'react';
import languageService from '../../Refactor/Services/Language/LanguageService';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import styles from './DataSourceTag.module.scss';

interface IDataSourceTagProps {
    value?: string | string[] | null;
}

const DataSourceTag: React.FC<IDataSourceTagProps> = (props: IDataSourceTagProps) => {
    const [translations, translate] = useLanguage();

    const dataSourceString = useMemo(() => {
        if (!props.value) {
            return ' ';
        }

        if (Array.isArray(props.value)) {
            return props.value.filter((value, index, array) => array.indexOf(value) === index).join(', ');
        }

        return props.value;
    }, [props.value]);

    const source = useMemo(() => translate('Source'), [translations]);
    const dataSourceTranslated = useMemo(() => languageService.multiWordTranslate(dataSourceString), [translations, dataSourceString]);

    return (
        <p data-testid="dataSourceTag" className={styles.data_source_tag}>
            {dataSourceString !== ' ' && `${source}: ${dataSourceTranslated}`}
            {/* We need to take up some space so we can auto-size any parent elements. */}
            {dataSourceString === ' ' && <>&nbsp;</>}
        </p>
    );
};

export default DataSourceTag;
