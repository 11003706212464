import React, { useMemo } from 'react';
import TreeItem, { ITreeNode } from './TreeItem';

interface ITreeViewProps {
    data: ITreeNode[];
    searchTerm: string;
    handleSelect: (event: React.MouseEvent<HTMLElement>) => void;
    disabledNodes?: (ITreeNode | null | undefined)[];
    noData?: React.ReactNode;
}

const TreeView: React.FC<ITreeViewProps> = (props: ITreeViewProps) => {
    const isNodeDisabled = (node: ITreeNode) => {
        if (props.disabledNodes) {
            return props.disabledNodes.some((disabledNode) => disabledNode && disabledNode.id === node.id);
        }

        return false;
    };

    const expandNodesMatchingSearch = (nodes: ITreeNode[], searchTerm: string): ITreeNode[] =>
        nodes.reduce<ITreeNode[]>((acc, node) => {
            const matchesSearch = node.name.toLowerCase().includes(searchTerm.toLowerCase());
            const children = node.children.length ? expandNodesMatchingSearch(node.children, searchTerm) : [];

            if (matchesSearch || children.length > 0) {
                acc.push({
                    ...node,
                    expanded: true,
                    children,
                });
            }

            return acc;
        }, []);

    const getFilteredData = useMemo(() => {
        if (props.searchTerm) {
            return expandNodesMatchingSearch(props.data, props.searchTerm);
        }

        return props.data;
    }, [props.searchTerm, props.data]);

    const setSelectedNodes = (event: React.MouseEvent<HTMLElement>) => {
        props.handleSelect(event);
    };

    return (
        <div>
            {getFilteredData &&
                getFilteredData.map((node) => (
                    <TreeItem
                        key={node.id}
                        node={node}
                        searchTerm={props.searchTerm}
                        setSelectedNode={setSelectedNodes}
                        disabled={isNodeDisabled(node)}
                        disabledNodes={props.disabledNodes}
                        expanded={node.expanded || false}
                        level={0}
                    />
                ))}
            {!getFilteredData.length && props.noData}
        </div>
    );
};

export default TreeView;
