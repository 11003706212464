import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DemeterFeatureType, DemeterMarket, DemeterRegion, ExchangeType } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import { BasisCalculatorProduct, BasisCalculatorProductPair } from '../../../Pages/Calculators/BasisCalculator/BasisCalculatorDefinitions';
import useFeatureFlag from '../../../Services/FeatureFlags/useFeatureFlagHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { ITreeNode } from '../../TreeView/TreeItem';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import styles from './ProductsModal.module.scss';
import ProductsModalWrapper from './ProductsModalWrapper';
import SelectProduct from './SelectProduct';

interface IBasisCalculatorProductSelectModal {
    productOptions: BasisCalculatorProduct[];
    userProductOptions?: BasisCalculatorProduct[];
    open: boolean;
    productPair?: BasisCalculatorProductPair;
    savedProductPairs?: BasisCalculatorProductPair[];
    activeTab: number;
    handleChange: (productPair: BasisCalculatorProductPair) => void;
    handleDelete?: (productPair: BasisCalculatorProductPair) => void;
    handleClose: () => void;
}

const BasisCalculatorProductSelectModal: React.FC<IBasisCalculatorProductSelectModal> = (props) => {
    const [translations, translate] = useLanguage();
    const [product1Node, setProduct1Node] = useState<ITreeNode | null | undefined>(null);
    const [product2Node, setProduct2Node] = useState<ITreeNode | null | undefined>(null);
    const [activeTab, setActiveTab] = useState<number>(props.activeTab);
    const [selectedSavedProductPair, setSelectedSavedProductPair] = useState<BasisCalculatorProductPair | null>(null);
    const [savedProductPairs, setSavedProductPairs] = useState<BasisCalculatorProductPair[] | undefined>(props.savedProductPairs);
    const [productPairToDelete, setProductPairToDelete] = useState<BasisCalculatorProductPair | null>(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [isEnergyProduct1, setIsEnergyProduct1] = useState(false);
    const enableUserData = useFeatureFlag(DemeterFeatureType.UserData);

    const handleTabChange = (tab: number) => {
        setActiveTab(tab);
    };

    const isSetButtonDisabled = (activeTab === 0 && (!product1Node || !product2Node)) || (activeTab === 1 && !selectedSavedProductPair);

    const setProductPair = () => {
        if (activeTab === 0) {
            props.handleChange({
                ...props.productPair,
                product1: props.productOptions.find((x) => x.id === product1Node?.id) || props.userProductOptions?.find((x) => x.id === product1Node?.id),
                product2: props.productOptions.find((x) => x.id === product2Node?.id) || props.userProductOptions?.find((x) => x.id === product2Node?.id),
            });
            props.handleClose();
        }

        if (activeTab === 1 && selectedSavedProductPair) {
            props.handleChange(selectedSavedProductPair);
            props.handleClose();
            setSelectedSavedProductPair(null);
        }
    };

    useEffect(() => {
        setSavedProductPairs(props.savedProductPairs);
    }, [props.savedProductPairs]);

    const deleteProductPair = (pair: BasisCalculatorProductPair) => {
        setProductPairToDelete(pair);
        setOpenConfirmModal(true);
    };

    const confirmDeleteProductPair = () => {
        if (!productPairToDelete || !props.handleDelete) {
            return;
        }

        props.handleDelete(productPairToDelete);
        setProductPairToDelete(null);
        setSelectedSavedProductPair(null);
        closeConfirmModal();
    };

    const closeConfirmModal = () => {
        setOpenConfirmModal(false);
        setProductPairToDelete(null);
    };

    const buildValuePath = (product: BasisCalculatorProduct) => {
        const base =
            product.category === 'futures' ? `root_${translations.calculators.basis.futures}` : `root_${translations.calculators.basis.physicalPrices}`;
        const market = product.market ? translate(product.market) : product.market;
        const group =
            product.category === 'futures' ? translations.exchange[product.group as ExchangeType] : translations.region[product.group as DemeterRegion];
        const subGroup = product.category === 'futures' ? '' : translations.region[product.subGroup as DemeterRegion] ?? product.subGroup;
        const name = translate(product.name);
        const groupPath = product.category === 'futures' ? `${group}_${name}` : `${group}_${subGroup}_${name}`;
        return `${base}_${market}_${groupPath}`;
    };

    const mapToTreeNodes = (products: BasisCalculatorProduct[] | undefined): ITreeNode[] => {
        if (!products) {
            return [];
        }

        return products.map((product, index) => {
            const name = translate(product.name);
            const group =
                product.category === 'futures' ? translations.exchange[product.group as ExchangeType] : translations.region[product.group as DemeterRegion];
            const subGroup = product.category === 'futures' ? '' : translations.region[product.subGroup as DemeterRegion] ?? product.subGroup;

            const item: ITreeNode = {
                id: product.id,
                value: buildValuePath(product), // Value field is path of this node realitive to the root.
                name,
                parentValue: product.category === 'futures' ? group : subGroup, // Parent node for this node to point to.
                parentId: product.category === 'futures' ? group : subGroup,
                order: index,
                displayOrder: -1,
                expanded: false,
                selectedProduct: false,
                children: [],
            };

            return item;
        });
    };

    const treeNodes = useMemo(() => mapToTreeNodes(props.productOptions), [props.productOptions]);

    const energyTreeNodes = useMemo(() => {
        const energyProducts = props.productOptions.filter((x) => x.market === DemeterMarket.Energy);

        return mapToTreeNodes(energyProducts);
    }, [props.productOptions]);

    const nonEnergyTreeNodes = useMemo(() => {
        const nonEnergyProducts = props.productOptions.filter((x) => x.market !== DemeterMarket.Energy);

        return mapToTreeNodes(nonEnergyProducts);
    }, [props.productOptions]);

    const userTreeNodes = useMemo(() => mapToTreeNodes(props.userProductOptions), [props.userProductOptions]);

    useEffect(() => {
        setActiveTab(props.activeTab);
    }, [props.activeTab, props.open]);

    useEffect(() => {}, [treeNodes]);

    useEffect(() => {
        if (product1Node) {
            const selectedProduct1 = props.productOptions.find((x) => x.id === product1Node.id);
            if (selectedProduct1?.market === DemeterMarket.Energy) {
                if (product2Node) {
                    const selectedProduct2 = props.productOptions.find((x) => x.id === product2Node.id);
                    if (selectedProduct2?.market !== DemeterMarket.Energy) {
                        setProduct2Node(undefined);
                    }
                }
                setIsEnergyProduct1(true);
            } else {
                setIsEnergyProduct1(false);
            }
        }
    }, [product1Node]);

    useEffect(() => {
        if (props.productPair?.product1) {
            const selectedProduct1 = props.productOptions.find((x) => x.id === props.productPair?.product1!.id);
            if (selectedProduct1?.market === DemeterMarket.Energy) {
                setIsEnergyProduct1(true);
            } else {
                setIsEnergyProduct1(false);
            }
        }
    }, [props.productPair?.product1]);

    return (
        <div>
            <ProductsModalWrapper
                title={translations.calculators.basis.productSelector.header}
                open={props.open}
                isSetButtonDisabled={isSetButtonDisabled}
                handleClose={props.handleClose}
                handleSave={setProductPair}
                leftFooter={
                    enableUserData && (
                        <Link className={styles.basis_calculator_link} to={NavigationRoutes.UserData}>
                            {translations.calculators.basis.productSelector.actions.uploadMyData}
                        </Link>
                    )
                }
            >
                <div className={styles.products_modal_content}>
                    <div className={styles.products_modal_tabs_container}>
                        <button
                            type="button"
                            className={activeTab === 0 ? styles.products_modal_tabs_tab_selected : styles.products_modal_tabs_tab}
                            onClick={() => handleTabChange(0)}
                        >
                            {translations.calculators.basis.productSelector.tabs.productsTab.name}
                        </button>
                        <button
                            type="button"
                            className={activeTab === 1 ? styles.products_modal_tabs_tab_selected : styles.products_modal_tabs_tab}
                            onClick={() => handleTabChange(1)}
                        >
                            {translations.calculators.basis.productSelector.tabs.savedProductPairsTab.name}
                        </button>
                        <div className={styles.products_modal_tabs_empty_underline_space} />
                    </div>
                    <div className={styles.products_modal_body}>
                        {activeTab === 0 && (
                            <div className={styles.products_modal_tree_selection_row}>
                                <SelectProduct
                                    title={translations.calculators.basis.productSelector.tabs.productsTab.product1}
                                    productTreeNodes={treeNodes}
                                    userProductTreeNodes={userTreeNodes}
                                    expandedDepth={2}
                                    productPairProductId={props.productPair?.product1?.id}
                                    enableUserData={enableUserData}
                                    setProduct={setProduct1Node}
                                />
                                <SelectProduct
                                    title={translations.calculators.basis.productSelector.tabs.productsTab.product2}
                                    productTreeNodes={isEnergyProduct1 ? energyTreeNodes : nonEnergyTreeNodes}
                                    userProductTreeNodes={userTreeNodes}
                                    expandedDepth={2}
                                    productPairProductId={props.productPair?.product2?.id}
                                    enableUserData={enableUserData}
                                    setProduct={setProduct2Node}
                                />
                            </div>
                        )}
                        {activeTab === 1 && (
                            <div className={styles.products_modal_body}>
                                <div className={styles.products_modal_product_list_table}>
                                    <div className={styles.products_modal_product_list_table_row}>
                                        <div className={styles.products_modal_product_list_table_header_cell}>
                                            {translations.calculators.basis.productSelector.tabs.savedProductPairsTab.product1}
                                        </div>
                                        <div className={styles.products_modal_product_list_table_header_cell}>
                                            {translations.calculators.basis.productSelector.tabs.savedProductPairsTab.product2}
                                        </div>
                                        <div className={styles.products_modal_product_list_table_header_cell}>
                                            {translations.calculators.basis.productSelector.tabs.savedProductPairsTab.dateSaved}
                                        </div>
                                        <div className={styles.products_modal_product_list_table_header_cell}> </div>
                                    </div>

                                    {savedProductPairs
                                        ?.slice()
                                        .reverse()
                                        .map((productPair) => (
                                            <div
                                                key={`${productPair.product1?.name}_${productPair.product2?.name}`}
                                                className={
                                                    productPair === selectedSavedProductPair
                                                        ? styles.products_modal_product_list_table_row_selected
                                                        : styles.products_modal_product_list_table_row
                                                }
                                                onClick={() => setSelectedSavedProductPair(productPair)}
                                                role="presentation"
                                            >
                                                <div className={styles.products_modal_product_list_table_cell}>{productPair.product1?.name}</div>
                                                <div className={styles.products_modal_product_list_table_cell}>{productPair.product2?.name}</div>
                                                <div className={styles.products_modal_product_list_table_cell}>
                                                    {productPair.saved && formattingService.toLongDateAndTimeFormat(productPair.saved)}
                                                </div>
                                                <div className={styles.products_modal_product_list_table_cell}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            deleteProductPair(productPair);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ProductsModalWrapper>
            <ConfirmModal
                header={translations.calculators.basis.productSelector.tabs.savedProductPairsTab.deleteProductPair}
                message={translations.calculators.basis.productSelector.tabs.savedProductPairsTab.confirmDeleteProductPair}
                showModal={openConfirmModal}
                handleConfirm={confirmDeleteProductPair}
                handleCancel={closeConfirmModal}
            />
        </div>
    );
};

export default BasisCalculatorProductSelectModal;
