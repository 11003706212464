import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import LabelWithTooltip from '../../../../Components/Form/Inputs/LabelWithTooltip';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from '../ValueMatrixCalculator.module.scss';
import { timeSpanOptions, ValueMatrixTableRequest } from '../ValueMatrixDefinitions';

interface IValueMatrixValueMatrixOuterRangeDropdownProps {
    valueMatrixInputs: ValueMatrixTableRequest;
    setValueMatrixInputs: (value: ValueMatrixTableRequest) => void;
}

const ValueMatrixOuterRangeDropdown: React.FC<IValueMatrixValueMatrixOuterRangeDropdownProps> = (props: IValueMatrixValueMatrixOuterRangeDropdownProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.value_matrix_dropdown}>
            <Dropdown
                testId="ValueMatrixOuterRangeDropdown"
                value={props.valueMatrixInputs.weighted?.outerRange}
                options={timeSpanOptions}
                handleOptionChange={(value: number) => {
                    props.setValueMatrixInputs({
                        ...props.valueMatrixInputs,
                        weighted: {
                            ...props.valueMatrixInputs.weighted,
                            outerRange: value,
                        },
                        updateTriggeredBy: 'generalFieldSelectionOrUpdate',
                    });
                }}
                label={
                    <LabelWithTooltip
                        title={translations.calculators.valueMatrix.fields.outerRange}
                        tooltip={translations.calculators.valueMatrix.text.outerRangeTooltipInformation}
                    />
                }
            />
        </div>
    );
};

export default ValueMatrixOuterRangeDropdown;
