import CheckIcon from '@mui/icons-material/Check';
import { DemeterMarket, DemeterMarketLicenseModel, DemeterUserModel } from '../../../../Generated/Raven-Demeter';
import styles from './LicensesPage.module.scss';

interface ILicenseTableRowProps {
    user: DemeterUserModel;
    availableMarkets: DemeterMarketLicenseModel[];
    hasUnlimitedLicenses: boolean;
    handleLicenseChange: (user: DemeterUserModel, market: DemeterMarket, isLicensed: boolean) => void;
}

const LicensesTableRow: React.FC<ILicenseTableRowProps> = (props) => {
    const handleClick = (market: DemeterMarket) => {
        props.handleLicenseChange(props.user, market, props.user.markets.includes(market));
    };

    return (
        <div className={styles.licenses_page_row_container}>
            <div className={styles.licenses_page_username_cell}>
                <div className={styles.licenses_page_username_text}>
                    {props.user.fullName} <span>{props.user.isCompanyAdministrator ? ' *' : ''}</span>
                </div>
            </div>
            {props.availableMarkets.map((marketLicense) => (
                <div key={marketLicense.market} className={styles.licenses_page_cell_container}>
                    <div className={props.user.markets.includes(marketLicense.market) ? styles.licenses_page_active_cell : styles.licenses_page_inactive_cell}>
                        <button type="button" onClick={() => handleClick(marketLicense.market)} className={styles.licenses_page_button_wrapper}>
                            {props.user.markets.includes(marketLicense.market) && <CheckIcon fontSize="small" className={styles.licenses_page_icon_check} />}
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LicensesTableRow;
