import TextInput from '../../../../../../Components/Form/Inputs/TextInput';
import RegexValidators from '../../../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { Parameters, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorParameterTextInputProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
    title: string;
    field: string;
    disabled?: boolean;
    validation?: RegExp;
    errorMessage?: string;
}

const MarketFactorParameterTextInput: React.FC<IMarketFactorParameterTextInputProps> = (props: IMarketFactorParameterTextInputProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.indicator_add_and_edit_input_wrapper}>
            <TextInput
                title={props.title}
                required
                value={props.upsertMarketIndicatorFactorRequest.parameters[props.field as keyof Parameters]}
                handleTextChange={(value: string) =>
                    props.setUpsertMarketIndicatorFactorRequest({
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            [props.field]: value,
                        },
                    })
                }
                disabled={props.disabled}
                validation={props.validation ?? RegexValidators.PositiveNumber}
                errorMessage={props.errorMessage ?? translations.marketIndicatorsManagement.messages.positiveNumber}
            />
        </div>
    );
};

export default MarketFactorParameterTextInput;
