import React, { useEffect, useState } from 'react';
import { demeterUsersApi } from '../../../Apis/Apis';
import AlertPage from '../../../Containers/Alert/AlertPage';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import { DemeterRegistrationSource, DemeterUserStatus, DemeterUserStoreType } from '../../../Generated/Raven-Demeter';
import { useApplicationDispatch, useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectStoreId, setLocation } from '../../../Redux/Slices/SystemSlice';
import { selectUserTokens, setUser, setUserCurrentMarket, setUserType } from '../../../Redux/Slices/UserSlice';
import useApi from '../../Apis/Hooks/useApiHook';
import useUserSessionsApi from '../../Apis/Hooks/useUserSessionApiHook';
import { UserStoreValueType } from '../../Apis/Hooks/useUserStoreApiHook';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import browserService from '../../Services/BrowserService';
import featureFlagsService from '../../Services/FeatureFlags/FeatureFlagsService';
import { Language } from '../../Services/Language/LanguageService';
import useLanguage from '../../Services/Language/useLanguageHook';
import locationService from '../../Services/Location/LocationService';
import permissionsService from '../../Services/Permissions/PermissionsService';
import AccessPendingPage from './AccessPendingPage';
import AccessDenied from './AcessDenied/AcessDenied';
import CompanyRegistration from './CompanyRegistration';
import TrialAccessAgreement from './TrialAccessAgreement';

export interface ISessionPageProps {
    setSessionLoaded: (sessionLoad: boolean) => void;
}

// This page will appear when the user's session is loading up, after we recieve the access token.
const SessionPage: React.FC<ISessionPageProps> = (props: ISessionPageProps) => {
    // System hooks.
    const dispatch = useApplicationDispatch();
    const storeId = useApplicationSelector(selectStoreId);
    const userTokens = useApplicationSelector(selectUserTokens);

    const [, , , setLanguage] = useLanguage();
    const accessDeniedErrorMessage = 'You do not have access to this product.';

    // State hooks.
    const [acceptedAgreement, setAcceptedAgreement] = useState(false);
    const [companyTermsAndConditionsNeedsAccepting, setCompanyTermsAndConditionsNeedsAccepting] = useState(false);
    const [languageLoaded, setLanguageLoaded] = useState(false);
    const [locationLoaded, setLocationLoaded] = useState(false);
    const [userLoaded, setUserLoaded] = useState(false);
    const [userExpired, setUserExpired] = useState(false);
    const [noUserExists, setNoUserExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [responseError, setResponseError] = useState<string>();

    // Api hooks.
    const [isSessionInUse, userSession, userSessionError] = useUserSessionsApi();
    const [, callAgreeTermsApi] = useApi(
        () => demeterUsersApi.agreeToDemeterTermsAndConditions({ agree: true, version: applicationSettings.trialAccessAgreementVersion }),
        {
            stopAutoExecute: true,
        },
    );
    const [, callDeclineTermsApi] = useApi(
        () => demeterUsersApi.agreeToDemeterTermsAndConditions({ agree: false, version: applicationSettings.trialAccessAgreementVersion }),
        { stopAutoExecute: true },
    );

    const handleAccept = (accept: boolean): void => {
        if (!accept) {
            callDeclineTermsApi();
            browserService.navigateToMarketIntelligence();
            return;
        }

        setAcceptedAgreement(accept);
        callAgreeTermsApi();
    };

    const handleCompanyFirstTimeAccessCancel = (): void => {
        browserService.navigateToMarketIntelligence();
    };

    const handleCompanyFirstTimeAccessSubmit = (): void => {
        setCompanyTermsAndConditionsNeedsAccepting(false);
    };

    useEffect(() => {
        const loadUserSession = async () => {
            if (!userSession) {
                if (userSessionError === accessDeniedErrorMessage) {
                    setIsLoading(false);
                    setNoUserExists(true);
                }
                return;
            }

            if (userSession.demeterUser?.userStatus === DemeterUserStatus.Expired) {
                setIsLoading(false);
                setUserExpired(true);
                return;
            }

            dispatch(
                setUser({
                    ...userTokens,
                    sessionToken: userSession?.sessionToken ?? '',
                    markets: userSession.demeterUser?.markets ?? [],
                    demeterCompanyGuid: userSession.demeterUser?.demeterCompanyGuid ?? '',
                    registrationSource: userSession.demeterUser?.registrationSource ?? DemeterRegistrationSource.Broker,
                }),
            );

            // We must check if the user is expired and set that before checking if the feature flags are being returned,
            // else we will recieve a 403 forbidden.
            const featureFlags = await featureFlagsService.initializeFeatureFlags(storeId);

            if (!featureFlags) {
                return;
            }

            dispatch(setUserType({ userType: userSession.demeterUser!.userType }));
            dispatch(setUserCurrentMarket({ userCurrentMarket: userSession.demeterUser!.markets[0] }));
            permissionsService.setPermissions(storeId, userSession.getDemeterPermissionResponse?.permissions ?? [], userSession.demeterUser?.userType);

            loadLanguage(userSession.userStores![0]);
            loadLocation(userSession.userStores![1]);
            setUserLoaded(true);

            if (userSessionError) {
                setResponseError(userSessionError);
            } else {
                if (
                    userSession.getTermsAndConditionsResponse?.agreed &&
                    userSession.getTermsAndConditionsResponse?.version === applicationSettings.trialAccessAgreementVersion
                ) {
                    setAcceptedAgreement(true);
                }
                if (userSession.companyTermsAndConditionsNeedsAccepting) {
                    setCompanyTermsAndConditionsNeedsAccepting(true);
                }
            }

            setIsLoading(false);
        };

        loadUserSession();
    }, [userSession, userSessionError]);

    useEffect(() => {
        if (
            acceptedAgreement &&
            languageLoaded &&
            locationLoaded &&
            userLoaded &&
            !userExpired &&
            !companyTermsAndConditionsNeedsAccepting &&
            userSession?.demeterUser?.markets &&
            userSession.demeterUser.markets.length > 0
        ) {
            props.setSessionLoaded(true);
            return;
        }

        props.setSessionLoaded(false);
    }, [acceptedAgreement, languageLoaded, locationLoaded, userLoaded, userExpired, userSessionError, noUserExists, companyTermsAndConditionsNeedsAccepting]);

    const loadLanguage = async (userStoreLanguage: UserStoreValueType) => {
        if (!userStoreLanguage?.value?.language) {
            const browserLanguageCode = window?.navigator?.language;
            if (browserLanguageCode) {
                const browserLanguage = Language[browserLanguageCode as keyof typeof Language];
                await setLanguage(browserLanguage);
            }
        } else {
            await setLanguage(userStoreLanguage?.value?.language);
        }

        setLanguageLoaded(true);
    };

    const loadLocation = async (userStoreLocation: UserStoreValueType) => {
        if (!userStoreLocation?.value?.location?.location) {
            const location = await locationService.getUserLocationFromBrowser();
            // Only save the location if we can figure it out.
            if (location) {
                await demeterUsersApi.updateUserStore(DemeterUserStoreType.LocationData, {
                    userStoreType: DemeterUserStoreType.LocationData,
                    value: { location },
                });
            }

            dispatch(setLocation({ location: location?.location ?? locationService.defaultLocation }));
        } else {
            dispatch(setLocation(userStoreLocation.value.location));
        }

        setLocationLoaded(true);
    };

    if (isSessionInUse) {
        props.setSessionLoaded(true);
    }

    if (responseError) {
        return <AlertPage message={responseError} />;
    }

    if (!isLoading && noUserExists) {
        return <AccessDenied denialType="Forbidden" />;
    }

    if (!isLoading && userExpired) {
        return <AccessDenied denialType="Expired" />;
    }

    if (!isLoading && (!userSession?.demeterUser?.markets || userSession.demeterUser.markets.length === 0)) {
        return <AccessPendingPage />;
    }

    if (!isLoading && !acceptedAgreement) {
        return <TrialAccessAgreement handleAccept={handleAccept} />;
    }

    if (!isLoading && companyTermsAndConditionsNeedsAccepting) {
        return (
            <CompanyRegistration
                user={userSession?.demeterUser}
                handleSubmit={handleCompanyFirstTimeAccessSubmit}
                handleCancel={handleCompanyFirstTimeAccessCancel}
            />
        );
    }

    return <PageLoadingSpinner />;
};

export default SessionPage;
