import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { forecastOptions, ForecastParameters, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorForecastDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorForecastDropdown: React.FC<IMarketFactorForecastDropdownProps> = (props: IMarketFactorForecastDropdownProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={(props.upsertMarketIndicatorFactorRequest.parameters as ForecastParameters).monthsForward}
                handleOptionChange={(value) => {
                    props.setUpsertMarketIndicatorFactorRequest({
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            monthsForward: value,
                        },
                    });
                }}
                options={forecastOptions}
                label={translations.marketIndicatorsManagement.fields.monthsForward}
            />
        </div>
    );
};
export default MarketFactorForecastDropdown;
