import React, { ReactNode, useState } from 'react';
import chartArrowLeftSvg from '../../Assets/Icons/chartArrowLeft.svg';
import chartArrowRightSvg from '../../Assets/Icons/chartArrowRight.svg';
import styles from './Carousel.module.scss';

interface ICarouselProps {
    children?: ReactNode[];
}

const Carousel = (props: ICarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const validChildren = React.Children.toArray(props.children).filter(Boolean);
    const numberOfItems = validChildren.length;

    const handleNext = () => {
        const nextIndex = (currentIndex + 1) % numberOfItems;
        setCurrentIndex(nextIndex);
    };

    const handlePrevious = () => {
        const prevIndex = (currentIndex - 1 + numberOfItems) % numberOfItems;
        setCurrentIndex(prevIndex);
    };

    return (
        <div className={styles.carousel}>
            <div>{validChildren[currentIndex]}</div>
            <div className={styles.carousel_buttons}>
                <button type="button" className={styles.carousel_button} onClick={handlePrevious}>
                    <img src={chartArrowLeftSvg} alt="" />
                </button>
                <button type="button" className={styles.carousel_button} onClick={handleNext}>
                    <img src={chartArrowRightSvg} alt="" />
                </button>
            </div>
        </div>
    );
};

export default Carousel;
