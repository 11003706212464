import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AgGridBuilder from '../../../Components/AgGridBuilder/AgGridBuilder';
import {
    CommodityPriceModel,
    Currency,
    DemeterDataFrequency,
    DemeterFilterTimeSpan,
    DemeterTableDefinitionType,
    UnitOfMeasure,
} from '../../../Generated/Raven-Demeter';
import usePricesByFrequencyApi from '../../Apis/Hooks/usePricesByFrequencyApiHook';
import Switch from '../../Components/Form/Buttons/Switch';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Components/Navigation/Hooks/useTableDefinitionHook';
import SeasonalTables from '../../Components/Tables/Seasonal/SeasonalTables';
import formattingService from '../../Services/Formatting/FormattingService';
import useLanguage from '../../Services/Language/useLanguageHook';
import PhysicalPricesChart from './PhysicalPricesChart';
import styles from './PhysicalPricesPage.module.scss';
import { physicalPriceColumnOptions, physicalPricesColumnDefinitions, PhysicalPricesCompositeModel, RendererParameters } from './PhysicalPricesPageDefinitions';

export interface IPhysicalPricesTableProps {
    regionCommoditySelection: IRegionCommoditySelection;
    tableDefinitionType: DemeterTableDefinitionType;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
}

const defaultSelectedRowIndex = 0;
const selectionLimit = 5;
const defaultFilterTimeSpan = DemeterFilterTimeSpan.TwoYears;

const PhysicalPricesTable: React.FC<IPhysicalPricesTableProps> = (props: IPhysicalPricesTableProps) => {
    const [translations, translate] = useLanguage();
    const gridReference = useRef<AgGridReact>(null);
    const [onGridReady, setOnGridReady] = useState<GridReadyEvent>();
    const physicalPricesCompositeModelsReference = useRef<PhysicalPricesCompositeModel[]>([]);
    const [physicalPricesCompositeModels, setPhysicalPricesCompositeModels] = useState<PhysicalPricesCompositeModel[]>(
        physicalPricesCompositeModelsReference.current,
    );
    const physicalPricesFrequencyResponseData = usePricesByFrequencyApi(
        props.regionCommoditySelection,
        props.currency,
        props.unitOfMeasure,
        defaultFilterTimeSpan,
    );
    const [physicalPricesSelections, setPhysicalPricesSelections] = useState<PhysicalPricesCompositeModel[]>([]);
    const regionCommoditySelections = useMemo<IRegionCommoditySelection[]>(
        () =>
            physicalPricesSelections.map((row) => ({
                region: row.region!,
                subRegion: row.subRegion ?? '',
                commodity: row.commodity ?? '',
                currency: row.currency ?? '',
                displayName: row.displayName ?? '',
                extraParameters: row.extraParameters ?? row.dataSource,
                dataFrequency: row.dataFrequency ?? DemeterDataFrequency.Monthly,
            })),
        [physicalPricesSelections],
    );

    const [, tableDefinitionCommodity] = useTableDefinition(DemeterTableDefinitionType.CommodityPricesTable, {
        region: props.regionCommoditySelection.region,
        commodity: props.regionCommoditySelection.commodity,
        subRegion: '',
        extraParameters: props.regionCommoditySelection.extraParameters,
        dataFrequency: '',
    });
    const title = useMemo(() => translate(tableDefinitionCommodity?.displayName || ''), [tableDefinitionCommodity]);

    const actionsCellRenderer = (parameters: RendererParameters) => (
        <Switch
            disabled={physicalPricesSelections?.length === selectionLimit && !parameters.data.selected}
            checked={parameters.data.selected}
            handleChange={() => selectRow(parameters.data.rowIndex, true)}
        />
    );

    const physicalPricesColumnDefinitionsWithRenderer: ColDef[] = useMemo(() => {
        const actionField = {
            ...physicalPricesColumnDefinitions[physicalPricesColumnDefinitions.length - 1],
            cellRenderer: actionsCellRenderer,
        };
        const newPhysicalPricesColumnDefinition = [...physicalPricesColumnDefinitions].slice(0, physicalPricesColumnDefinitions.length - 1);

        return [...newPhysicalPricesColumnDefinition, actionField] as ColDef[];
    }, [physicalPricesColumnDefinitions, physicalPricesSelections]);

    useEffect(() => {
        // If only the currency or unit of measure triggered the change, we don't want to update the
        // 'selected' status.
        const unitOfMeasureOrCurrencyChanged =
            physicalPricesFrequencyResponseData?.rows?.length === physicalPricesCompositeModels.length &&
            (physicalPricesFrequencyResponseData?.rows as CommodityPriceModel[])?.every(
                (x, index) =>
                    x.region === physicalPricesCompositeModels[index]?.region &&
                    x.commodity === physicalPricesCompositeModels[index]?.commodity &&
                    x.subRegion === physicalPricesCompositeModels[index]?.subRegion &&
                    x.dataSource === physicalPricesCompositeModels[index]?.dataSource,
            );

        if (!physicalPricesFrequencyResponseData?.rows) {
            return;
        }

        const rows = physicalPricesFrequencyResponseData.rows.map((row, index) => ({
            ...row,
            id: `${index}`,
            rowIndex: index,
            asOfDate:
                props.regionCommoditySelection.dataFrequency === DemeterDataFrequency.Monthly
                    ? formattingService.toMonthYear(row.asOfDate)
                    : formattingService.toShortDayMonthYear(new Date(row.asOfDate))!,
            displayName: translate(row.displayName),
            unitOfMeasureDisplayName: translations.unitOfMeasure[row.unitOfMeasure],
            currencyDisplayName: translations.currency[row.currency],
            commodity: row.commodity,
            region: row.region,
            selected: unitOfMeasureOrCurrencyChanged && physicalPricesCompositeModels[index].selected,
        }));
        physicalPricesCompositeModelsReference.current = rows;

        if (unitOfMeasureOrCurrencyChanged) {
            setPhysicalPricesCompositeModels(rows);
            setPhysicalPricesSelections(rows.filter((x) => x.selected));
        } else {
            selectRow(defaultSelectedRowIndex);
        }
    }, [physicalPricesFrequencyResponseData]);

    useEffect(() => {
        if (!onGridReady && !gridReference?.current?.columnApi && !gridReference?.current?.api) {
            return;
        }

        selectRow(defaultSelectedRowIndex, false, true);
    }, [gridReference?.current?.columnApi, onGridReady]);

    const selectRow = (rowIndex: number, allowMultipleSelections?: boolean, setSelected?: boolean) => {
        const actionFieldName = physicalPricesColumnDefinitions[physicalPricesColumnDefinitions.length - 1].field ?? '';
        const actionColumn = gridReference.current?.columnApi?.getColumn(actionFieldName);
        const selectedRows = gridReference?.current?.api?.getSelectedRows();

        if (!allowMultipleSelections && selectedRows) {
            (physicalPricesCompositeModelsReference?.current ?? []).forEach((priceModel) => {
                if (priceModel.rowIndex !== rowIndex) {
                    priceModel.selected = false;
                    const rowNode = gridReference?.current?.api.getRowNode(priceModel.id);
                    rowNode?.setSelected(false);
                    if (actionColumn) {
                        rowNode?.setDataValue(actionFieldName, false);
                    }
                }
            });
        }

        const row = physicalPricesCompositeModelsReference?.current[rowIndex];
        if (row) {
            row.selected = !row.selected || (!allowMultipleSelections && (selectedRows?.length ?? 0) > 1) || !!setSelected;
            const selectedRow = gridReference?.current?.api?.getRowNode(row.id);
            selectedRow?.setSelected(row.selected);
            if (actionColumn) {
                selectedRow?.setDataValue(actionFieldName, !!row.selected || (!allowMultipleSelections && (selectedRows?.length ?? 0) > 1));
            }
        }

        physicalPricesCompositeModelsReference.current = [...physicalPricesCompositeModelsReference.current];
        setPhysicalPricesCompositeModels(physicalPricesCompositeModelsReference.current);
        setPhysicalPricesSelections(physicalPricesCompositeModelsReference.current.filter((x) => x.selected));
    };

    return (
        <div className={styles.physical_prices_table_container}>
            {!physicalPricesFrequencyResponseData ? (
                <PageLoadingSpinner />
            ) : (
                <div className={styles.physical_prices_selection_wrapper}>
                    <AgGridBuilder
                        gridRef={gridReference}
                        rowData={physicalPricesCompositeModels}
                        hasSaveColumnsState
                        columnDefinitions={physicalPricesColumnDefinitionsWithRenderer}
                        defaultColumnDefinition={physicalPriceColumnOptions}
                        domLayout="autoHeight"
                        cellClickedHandler={(event: { colDef: { field: string }; data: PhysicalPricesCompositeModel }) => {
                            const actionField = physicalPricesColumnDefinitionsWithRenderer[physicalPricesColumnDefinitionsWithRenderer.length - 1].field ?? '';
                            if (event.colDef.field !== actionField) {
                                selectRow(event.data.rowIndex);
                            }
                        }}
                        onGridReady={setOnGridReady}
                        gridHeightFull={false}
                        testId="PhysicalPricesSelectionTable"
                    />
                    {physicalPricesCompositeModels.length > 0 && (
                        <div className={styles.physical_prices_chart_container}>
                            <PhysicalPricesChart
                                title={title}
                                dataFrequency={props.regionCommoditySelection.dataFrequency as DemeterDataFrequency}
                                regionCommoditySelections={regionCommoditySelections}
                                tableDefinitionType={props.tableDefinitionType}
                                displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                                displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 4}
                                currency={props.currency}
                                unitOfMeasure={props.unitOfMeasure}
                                testId="PhysicalPricesChart"
                            />
                        </div>
                    )}
                    {physicalPricesSelections.map((element) => (
                        <SeasonalTables
                            key={element.id}
                            title={translate(element.displayName)}
                            tableDefinitionType={props.tableDefinitionType}
                            regionCommoditySelection={{
                                region: element.region,
                                subRegion: element.subRegion ?? '',
                                commodity: element.commodity,
                                extraParameters: element.dataSource,
                                dataFrequency: element.dataFrequency,
                            }}
                            unitOfMeasure={element.unitOfMeasure}
                            currency={element.currency}
                            isNativeUnitOfMeasure={props.unitOfMeasure === undefined}
                            isNativeCurrency={props.currency === undefined}
                            testId="PhysicalPricesSeasonalTables"
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default PhysicalPricesTable;
