import React, { useEffect } from 'react';

const useStateMachine = <T extends { isCascadeUpdating?: boolean }>(
    states: { priority: number; state: T; condition: boolean }[],
    sharedRequirementsMet: boolean,
    dependencies: React.DependencyList,
    externalState: T,
    setExternalState: (state: T) => void,
) => {
    // When any dependancies change, we update with the appropriate default/reset.
    useEffect(() => {
        const validState = states.sort((a, b) => a.priority - b.priority).find((x) => x.condition);

        if (!sharedRequirementsMet || externalState?.isCascadeUpdating || !validState) {
            return;
        }

        setExternalState(validState.state);
    }, dependencies);
};

export default useStateMachine;
