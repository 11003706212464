import { ColumnState, ColumnVisibleEvent, DragStoppedEvent, FirstDataRenderedEvent, GetRowIdParams, MenuItemDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useRef } from 'react';
import { DemeterUserStoreType, DemeterUserType } from '../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../Redux/ReduxStore';
import { selectUserType } from '../../Redux/Slices/UserSlice';
import useUserStoreApi from '../../Refactor/Apis/Hooks/useUserStoreApiHook';
import styles from './AgGridBuilder.module.scss';
import './AgGridThemeHelper.scss';
import { AgGridContextMenuItem, IAgGridProps } from './InterfaceAgGrid';
import useContainerWidth from './useContainerWidth';
import useWindowSize from './useWindowSize';

const userStoreColumnStateKey = 'columnState';

const AgGridBuilder: React.FC<IAgGridProps> = ({
    gridRef,
    rowData,
    cellClickedHandler,
    columnDefinitions,
    defaultColumnDefinition,
    onSelectionChanged,
    onRowClicked,
    userStoreType,
    theme = 'ag-theme-demeter-alpine',
    debounce = 0,
    getRowStyle = (params: any) => (params.node.rowIndex % 2 === 0 ? { background: '#F3F4F4' } : { background: 'white' }),
    domLayout,
    disableExport = false,
    suppressMovableColumns = false,
    groupIncludeTotalFooter = undefined,
    pinnedBottomRowData = undefined,
    gridDataKey,
    onGridReady,
    testId,
}) => {
    const internalGridReference = useRef<AgGridReact>(null);
    const [windowWidth] = useWindowSize(debounce);
    const { width: containerWidth, ref } = useContainerWidth(debounce);
    const currentUserType = useApplicationSelector(selectUserType);
    const [userStoreValueResponse, updateUserStoreValue] = useUserStoreApi(userStoreType as DemeterUserStoreType);

    useEffect(() => {
        if (!internalGridReference?.current || !gridRef) {
            return;
        }

        gridRef.current = internalGridReference.current;
    }, [internalGridReference?.current, gridRef?.current]);

    useEffect(() => {
        if (internalGridReference?.current?.api) {
            internalGridReference.current.api.sizeColumnsToFit();
        }
    }, [windowWidth, containerWidth, internalGridReference?.current]);

    useEffect(() => {
        if (internalGridReference?.current?.columnApi && userStoreValueResponse && userStoreType) {
            const savedColumnState: ColumnState[] = userStoreValueResponse.userStore.value[userStoreColumnStateKey];
            if (savedColumnState) {
                const mergeColumnState = savedColumnState.map((column) => {
                    const matchColumn = columnDefinitions.find((x) => x.colId === column.colId);
                    return {
                        colId: column.colId,
                        width: column.width,
                        flex: null,
                        hide: matchColumn?.lockVisible ? matchColumn.hide : column.hide,
                    };
                });
                internalGridReference.current!.columnApi.applyColumnState({ state: mergeColumnState, applyOrder: true });
            }
        }
    }, [internalGridReference?.current?.columnApi, userStoreValueResponse, gridDataKey, columnDefinitions]);

    const getRowId = useCallback((params: GetRowIdParams) => params.data.id, []);

    const firstDataRenderedEvent = useCallback(
        (event: FirstDataRenderedEvent) => {
            event.api.setColumnDefs(columnDefinitions);
        },
        [columnDefinitions],
    );

    const getContextMenuItems = useCallback(() => {
        const result: Array<string | MenuItemDef> = [AgGridContextMenuItem.paste, AgGridContextMenuItem.separator];
        if (currentUserType === DemeterUserType.Regular || disableExport) {
            result.push({ name: AgGridContextMenuItem.customExport, disabled: true });
        } else {
            result.push(AgGridContextMenuItem.export);
        }
        return result;
    }, []);

    const dragStoppedEvent = useCallback(
        (event: DragStoppedEvent) => {
            if (userStoreType && userStoreValueResponse) {
                const draggedColumnState = event.columnApi.getColumnState();
                const userSetFields: ColumnState[] = draggedColumnState.map((x) => ({
                    colId: x.colId,
                    width: x.width,
                    hide: x.hide,
                }));
                updateUserStoreValue({ [userStoreColumnStateKey]: userSetFields });
            }
        },
        [userStoreValueResponse],
    );

    const columnVisibleEvent = useCallback(
        (event: ColumnVisibleEvent) => {
            if (userStoreType && userStoreValueResponse) {
                const columnVisibleState = event.columnApi.getColumnState();
                const userSetFields: ColumnState[] = columnVisibleState.map((x) => ({
                    colId: x.colId,
                    width: x.width,
                    hide: x.hide,
                }));
                updateUserStoreValue({ [userStoreColumnStateKey]: userSetFields });
            }
        },
        [userStoreValueResponse],
    );

    return (
        <div data-testid={testId} className={`${theme} ${styles.ag_grid_component}`} ref={ref}>
            <AgGridReact
                ref={internalGridReference}
                onGridReady={onGridReady}
                onFirstDataRendered={firstDataRenderedEvent}
                onDragStopped={dragStoppedEvent}
                onColumnVisible={columnVisibleEvent}
                animateRows
                getRowId={getRowId}
                rowSelection="multiple"
                groupSelectsChildren
                rowData={rowData}
                defaultColDef={defaultColumnDefinition}
                getRowStyle={getRowStyle}
                onSelectionChanged={onSelectionChanged}
                onCellClicked={cellClickedHandler}
                suppressRowClickSelection
                onRowClicked={onRowClicked ?? (() => 0)}
                getContextMenuItems={getContextMenuItems}
                suppressContextMenu
                domLayout={domLayout || undefined}
                suppressMovableColumns={suppressMovableColumns}
                suppressMultiSort
                groupIncludeTotalFooter={groupIncludeTotalFooter}
                pinnedBottomRowData={pinnedBottomRowData}
            />
        </div>
    );
};

export default AgGridBuilder;
