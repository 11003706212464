import { useMemo } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import { DemeterRegion, ListCommodityWeeklyPricesSummaryResponse } from '../../../Generated/Raven-Demeter/api';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';

const useWeeklyPricesSummaryApi = (region: DemeterRegion): ListCommodityWeeklyPricesSummaryResponse | undefined => {
    const cacheKey = useMemo(() => `${CacheKeys.ListCommodityWeeklyPricesSummary}_${region}`, [region]);

    const [, , summaryResponse] = useCacheThenApi(cacheKey, () => demeterApi.listCommodityWeeklyPricesSummary(region));

    return summaryResponse;
};

export default useWeeklyPricesSummaryApi;
