import React, { Children, ReactNode, useEffect, useMemo, useState } from 'react';
import chartPopoutSvg from '../../../../Components/Assets/chartPopout.svg';
import closeSvg from '../../../../Components/Assets/close.svg';
import DataSourceTag from '../../../../Components/DataSourceTag/DataSourceTag';
import useDynamicDimensions from '../../../Core/Hooks/useDynamicDimensionsHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import IconButton from '../../Form/Buttons/IconButton';
import ComponentHeader from '../../Headers/ComponentHeader';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import useWindowDimensions from '../../Responsive/Hooks/useWindowDimensionsHook';
import useChartReference from '../Hooks/useChartReferenceHook';
import ChartDisplayOptions from './ChartDisplayOptions';
import ChartDownload from './ChartDownload';
import type { ChartDimensions, IChartWrapperProps } from './ChartWrapper';
import styles from './ChartWrapper.module.scss';

export interface IChartInnerWrapperProps extends IChartWrapperProps {
    chartContainerDimensions: ChartDimensions;
    maxWidthHeightRatio?: number;
    maxPercentOfScreenHeight?: number;
    minimumChartHeight?: number;
    isInModal?: boolean;
    setShowModal: (showModal: boolean) => void;
    rightSide?: ReactNode;
    testId?: string | undefined;
}

const defaultMaxWidthHeightRatio = 2.7;
const defaultMaxPercentOfScreenHeight = 70;
const defaultMinimumChartHeight = 200;
const currentYear = new Date().getFullYear();

const ChartInnerWrapper: React.FC<IChartInnerWrapperProps> = (props: IChartInnerWrapperProps) => {
    const [chartReference, setChartReference] = useChartReference();
    const [chartHeaderReference, chartHeaderDimensions] = useDynamicDimensions();
    const [chartSubHeaderReference, chartSubHeaderDimensions] = useDynamicDimensions();
    const [chartFooterReference, chartFooterDimensions] = useDynamicDimensions();
    const [chartDataSourceTagReference, chartDataSourceTagDimensions] = useDynamicDimensions();
    const [translations] = useLanguage();

    const windowDimensions = useWindowDimensions();

    const maxWidthHeightRatio = useMemo(() => props.maxWidthHeightRatio ?? defaultMaxWidthHeightRatio, [props.maxWidthHeightRatio]);
    const maxPercentOfScreenHeight = useMemo(() => props.maxPercentOfScreenHeight ?? defaultMaxPercentOfScreenHeight, [props.maxPercentOfScreenHeight]);
    const minimumChartHeight = useMemo(() => props.minimumChartHeight ?? defaultMinimumChartHeight, [props.minimumChartHeight]);
    const [chartDimensions, setChartDimensions] = useState<ChartDimensions>();
    const [chartReady, setChartReady] = useState(false);

    useEffect(() => {
        if (!props.chartContainerDimensions.width || !props.chartContainerDimensions.height) {
            return;
        }

        const { width, height } = props.chartContainerDimensions;
        const headerHeight = (chartHeaderDimensions.height ?? 0) + (chartSubHeaderDimensions?.height ?? 0);
        const footerHeight = (chartFooterDimensions.height ?? 0) + (chartDataSourceTagDimensions.height ?? 0);
        const chartHeight = height - headerHeight - footerHeight;

        const newDimensions = {
            width,
            height: Math.max(chartHeight, minimumChartHeight),
        };

        // If the chart takes up over a certain percent (70%) of the screen height, we need to shrink it.
        if (chartHeight > (windowDimensions.height * maxPercentOfScreenHeight) / 100.0) {
            newDimensions.height = Math.max((windowDimensions.height * maxPercentOfScreenHeight) / 100.0, minimumChartHeight);
        }

        // If the height is greater than width, make the chart a square.
        // Yes, we are allows to go smaller than the minimum height here.
        // If the width is that small, you really can't see anything anyways.
        if (newDimensions.height > newDimensions.width) {
            newDimensions.height = newDimensions.width;
        }

        // We are going to apply chart dimension rules here.
        // We will not render the chart until we get container dimenions.
        if (width > newDimensions.height * maxWidthHeightRatio) {
            newDimensions.width = newDimensions.height * maxWidthHeightRatio;
        }

        setChartDimensions(newDimensions);

        // If the screen is resized, then hide the chart and re-render completely.
        if (newDimensions.width !== chartDimensions?.width || newDimensions.height !== chartDimensions?.height) {
            if (chartReference?.chart) {
                // Need to wait until the next tick to reflow because, the dimensions hasn't set yet.
                setTimeout(() => {
                    chartReference.chart?.reflow();
                });
            }

            setChartReady(false);
        }
    }, [props.chartContainerDimensions]);

    useEffect(() => {
        if (chartDimensions?.height && chartDimensions?.width) {
            setChartReady(true);
        }
    }, [chartDimensions]);

    return (
        <>
            <div
                ref={chartHeaderReference}
                className={styles.chart_wrapper_header}
                style={{ width: chartDimensions?.width ?? '100%', minWidth: chartDimensions?.width ?? '100%' }}
            >
                {!props.headerOptions?.hideTitle && <ComponentHeader title={props.title} subtitle={props.subtitle} />}
                <div className={styles.chart_wrapper_actions_container}>
                    {props.header && <div className={styles.chart_wrapper_header_actions_container}>{Children.only(props.header)}</div>}
                    {props.headerOptions?.chartDisplayTypes &&
                        props.headerOptions.chartDisplayTypes.length > 0 &&
                        props.headerOptions.handleChartDisplayTypeChange && (
                            <ChartDisplayOptions
                                chartDisplayTypes={props.headerOptions.chartDisplayTypes}
                                handleChartDisplayTypeChange={props.headerOptions.handleChartDisplayTypeChange}
                                testId={`${props.testId}DisplayOptions`}
                            />
                        )}
                    {!props.headerOptions?.hideDownload && (
                        <ChartDownload
                            chartReference={chartReference}
                            {...props}
                            isCsvDownloadAvailable={props.headerOptions?.isCsvDownloadAvailable}
                            testId={`${props.testId}Download`}
                        />
                    )}
                    {!props.isInModal && !props.headerOptions?.hidePopoutModal && (
                        <IconButton
                            className={styles.chart_wrapper_image_button}
                            handleClick={() => {
                                props.setShowModal(true);
                            }}
                            tooltip={translations.charts.buttons.showModal}
                            testId={`${props.testId}ShowModal`}
                        >
                            <img className={styles.chart_wrapper_image} src={chartPopoutSvg} alt="#" />
                        </IconButton>
                    )}
                    {props.isInModal && (
                        <IconButton
                            className={styles.chart_wrapper_image_button}
                            handleClick={() => {
                                props.setShowModal(false);
                            }}
                            testId={`${props.testId}CloseModal`}
                        >
                            <img data-testid="ModalClose" className={styles.chart_wrapper_image} src={closeSvg} alt="#" />
                        </IconButton>
                    )}
                </div>
            </div>
            {props.subHeader && (
                <div ref={chartSubHeaderReference} className={styles.chart_wrapper_sub_header}>
                    {Children.only(props.subHeader)}
                </div>
            )}
            <div className={styles.chart_wrapper_chart_container}>
                <div className={styles.chart_wrapper_chart} style={chartDimensions ?? {}}>
                    {!props.isLoading &&
                        chartReady &&
                        (props.rightSide ? (
                            <div className={styles.chart_wrapper_chart_with_right_side}>
                                {React.cloneElement(props.children, {
                                    chartReference: setChartReference,
                                })}
                                {props.rightSide}
                            </div>
                        ) : (
                            React.cloneElement(props.children, {
                                chartReference: setChartReference,
                            })
                        ))}
                    {(props.isLoading || !chartReady) && <LoadingSpinner />}
                </div>
            </div>
            {props.footer && (
                <div
                    ref={chartFooterReference}
                    className={styles.chart_wrapper_footer}
                    style={{ width: chartDimensions?.width ?? '100%', minWidth: chartDimensions?.width ?? '100%' }}
                >
                    {Children.only(props.footer)}
                </div>
            )}
            <div
                ref={chartDataSourceTagReference}
                className={styles.chart_wrapper_data_source_tag}
                style={{ width: chartDimensions?.width ?? '100%', minWidth: chartDimensions?.width ?? '100%' }}
            >
                <DataSourceTag value={props.dataSourceTag || ' '} />
                {props.isInModal && (
                    <p className={styles.chart_wrapper_disclaimer}>
                        {translations.pageFooterDisclaimer} &copy; {`${currentYear} ${translations.footer.text.allRightsReserved}`}
                    </p>
                )}
            </div>
        </>
    );
};

export default ChartInnerWrapper;
