import { useCallback, useEffect, useState } from 'react';
import { DemeterSymbolModel } from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { MarketPriceModel, selectMarketPricesBySymbolDebounced } from '../../../Redux/Slices/MarketPricesSlice';
import { selectStoreId } from '../../../Redux/Slices/SystemSlice';
import lightstreamerMarketPricesService from '../../Services/MarketPrices/LightstreamerMarketPricesService';

const useMarketPricesForwardCurve = (
    symbolModel?: DemeterSymbolModel,
): [marketPrices: MarketPriceModel[], refreshPrices: () => void, currencySpotPrice?: number] => {
    const marketPricesBySymbol = useApplicationSelector(selectMarketPricesBySymbolDebounced);
    const storeId = useApplicationSelector(selectStoreId);
    const [marketPricesInternal, setMarketPricesInternal] = useState<MarketPriceModel[]>([]);
    const [marketPricesExternal, setMarketPricesExternal] = useState<MarketPriceModel[]>([]);
    const [spotPriceInternal, setSpotPriceInternal] = useState<number>(1);
    const [spotPriceExternal, setSpotPriceExternal] = useState<number>(1);

    // Tell the lightstreamer service to get new data if anything changes.
    useEffect(() => {
        if (!symbolModel) {
            return;
        }

        let codes = symbolModel!.symbolContracts.map((x) => x.reutersInstrumentCode);
        if (symbolModel.reutersInstrumentCodeSpotPrice) {
            codes = [...codes, symbolModel.reutersInstrumentCodeSpotPrice];
        }

        lightstreamerMarketPricesService.start(storeId, codes, true);
        setMarketPricesInternal([]);
        setMarketPricesExternal([]);
        setSpotPriceInternal(1);
        setSpotPriceExternal(1);
    }, [symbolModel]);

    // Stop the lightstreamer service when we leave this component.
    useEffect(
        () => () => {
            lightstreamerMarketPricesService.stop(storeId);
        },
        [],
    );

    // Put the lightstreamer data into the array.
    useEffect(() => {
        if (!symbolModel) {
            return;
        }

        // If we don't have *most* of the prices, then we wait. Sometimes the last couple of contracts
        // don't come through yet, especially if they are new ones.
        const contractsToCount = symbolModel.symbolContracts.length - 3;

        if (symbolModel.symbolContracts.filter((x) => marketPricesBySymbol[x.reutersInstrumentCode]).length < contractsToCount) {
            return;
        }

        const marketPrices = symbolModel.symbolContracts.map((x) => marketPricesBySymbol[x.reutersInstrumentCode]).filter((x) => !!x);
        setMarketPricesInternal(marketPrices);

        const spotPriceCode = symbolModel.reutersInstrumentCodeSpotPrice;
        let spotPrice = 1;
        if (spotPriceCode && marketPricesBySymbol[spotPriceCode]) {
            // If we want to use the spot price we have to get the latest by using: (bid + ask) / 2.0.
            spotPrice = ((marketPricesBySymbol[spotPriceCode].bidPrice ?? 1.0) + (marketPricesBySymbol[spotPriceCode].askPrice ?? 1.0)) / 2.0;
            setSpotPriceInternal(spotPrice);
        }

        // If we don't have any external prices, then set it automatically, otherwise, wait.
        if (marketPricesExternal.length === 0) {
            setMarketPricesExternal(marketPrices);
            setSpotPriceExternal(spotPrice);
        }
    }, [marketPricesBySymbol]);

    const refreshPrices = useCallback((): void => {
        setMarketPricesExternal(marketPricesInternal);
        setSpotPriceExternal(spotPriceInternal);
    }, []);

    return [marketPricesExternal, refreshPrices, spotPriceExternal];
};

export default useMarketPricesForwardCurve;
