import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React, { useMemo } from 'react';
import { DemeterDataFrequency, UnitOfMeasure } from '../../Generated/Raven-Demeter';
import formattingService from '../../Refactor/Services/Formatting/FormattingService';
import languageService from '../../Refactor/Services/Language/LanguageService';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import styleService from '../../Refactor/Services/Styles/StyleService';
import styles from './Indicator.module.scss';
import { IIndicatorProps } from './InterfaceIndicator';

const Indicator: React.FC<IIndicatorProps> = ({
    indicatorTitle,
    indicatorNumber,
    isFuturesSettlement,
    currency,
    change,
    displayDecimalPlacesMinimum,
    displayDecimalPlacesMaximum,
    dataFrequency,
    asOfDate,
    unitOfMeasure,
    previousValue,
    tableDefinitionType,
}) => {
    const [translations] = useLanguage();

    const currentAndUnitDisplayName = useMemo(() => {
        const currencyDisplayName = translations.currency[currency];
        const unitOfMeasureDisplayName = translations.unitOfMeasure[`Short${unitOfMeasure}`];

        if (unitOfMeasure === UnitOfMeasure.Currency) {
            return '';
        }

        if (!currencyDisplayName) {
            return unitOfMeasureDisplayName;
        }

        return `${currencyDisplayName} / ${unitOfMeasureDisplayName}`;
    }, [currency, unitOfMeasure]);

    const percentChange = useMemo(() => {
        if (change === 0) {
            return formattingService.toPercentWithoutPlaceholder(change);
        }

        if (change > 0) {
            return `+${formattingService.toPercent(change)}`;
        }

        return formattingService.toPercent(change);
    }, [change]);

    return (
        <div
            className={`${styles.indicator} ${change <= -0.01 && styles.indcatorHoverRed} ${change >= 0.01 && styles.indcatorHoverGreen} ${
                change === 0 && styles.indcatorHoverBlue
            }`}
        >
            <div className={styles.indicator_info_container}>
                <p className={styles.indicator_title}>{languageService.multiWordTranslate(indicatorTitle)}</p>
                <p className={styles.indicator_currency}>{currentAndUnitDisplayName}</p>
                <div className={styles.indicator_info_top_container}>
                    <p className={styles.indicator_indicator_number}>
                        {indicatorNumber != null &&
                            formattingService.getDecimalDataWithAppropriateTrailingZeros(
                                displayDecimalPlacesMinimum,
                                displayDecimalPlacesMaximum,
                                indicatorNumber,
                            )}
                        {isFuturesSettlement && <span>s</span>}
                    </p>

                    {change !== null && (
                        <p className={`${styles.indicator_percent_change} ${styleService.getPositiveOrNegativeClass(+change)}`}>{percentChange}</p>
                    )}
                    <p className={styles.indicator_year_over_year}>
                        {dataFrequency === DemeterDataFrequency.Monthly && !tableDefinitionType.includes('Price') ? translations.words.shortYearOverYear : ''}
                    </p>
                </div>
                <div className={styles.indicator_bottom_row}>
                    <p>
                        {`${translations.words.previousShort} ${
                            previousValue
                                ? formattingService.getDecimalDataWithAppropriateTrailingZeros(
                                      displayDecimalPlacesMinimum,
                                      displayDecimalPlacesMaximum,
                                      previousValue,
                                  )
                                : ''
                        }`}
                    </p>
                    <div className={styles.indicator_date_and_frequency}>
                        <p className={styles.indicator_price_frequency}>{formattingService.getDataFrequencyShortDisplayName(dataFrequency)}</p>
                        <p className={styles.indicator_price_asOfDate}>{asOfDate === 'Invalid date' ? '' : asOfDate}</p>
                    </div>
                </div>
            </div>
            {change < 0 ? (
                <div className={styles.indicator_arrow_box_negative}>
                    <div className={styles.indicator_arrow_icon_negative}>
                        <ArrowDownwardIcon />
                    </div>
                </div>
            ) : null}
            {change > 0 ? (
                <div className={styles.indicator_arrow_box_positive}>
                    <div className={styles.indicator_arrow_icon_positive}>
                        <ArrowUpwardIcon />
                    </div>
                </div>
            ) : null}
            {change === 0 ? (
                <div className={styles.indicator_arrow_box_neutral}>
                    <div className={styles.indicator_arrow_icon_neutral}>
                        <ArrowForwardIcon />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Indicator;
