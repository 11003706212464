/* eslint-disable max-len */

import { Modal } from '@mui/material';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import styles from './SessionPage.module.scss';
import TermsAndConditions from './TermsAndConditions';

export interface ITrialAccessAgreementProps {
    handleAccept: (accept: boolean) => void;
}

const TrialAccessAgreement: React.FC<ITrialAccessAgreementProps> = (props) => (
    <Modal open>
        <div className={styles.acknowledgements_modal}>
            <div className={styles.acknowledgements_content_container}>
                <div className={styles.acknowledgements_content_container_header}>
                    <p>StoneX Financial Inc.</p>
                    <p>230 South LaSalle, Suite 10-500</p>
                    <p>Chicago, IL 60604</p>
                    <p>Phone: 312-780-6700</p>
                </div>
                <div className={styles.acknowledgements_content_container_title}>
                    <h1>We have updated our Terms and Conditions. Please review and Accept.</h1>
                </div>
                <TermsAndConditions />
            </div>
            <div className={styles.acknowledgements_button_container}>
                <LinkButton title="Decline" type={LinkButtonType.White} onClick={() => props.handleAccept(false)} />
                <LinkButton title="Accept" type={LinkButtonType.Blue} onClick={() => props.handleAccept(true)} />
            </div>
        </div>
    </Modal>
);

export default TrialAccessAgreement;
