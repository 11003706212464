/* eslint-disable class-methods-use-this */
import meanBy from 'lodash.meanby';
import moment from 'moment';
import { DemeterDataFrequency } from '../../../../../Generated/Raven-Demeter';
import formattingService from '../../../../Services/Formatting/FormattingService';
import { IChartAreaRangeData, IChartData } from '../../ChartDefinitions';
import marketIndicatorChartDateService from '../MarketIndicatorChartDateService';

interface SeasonalWeek {
    week: number;
    average: number;
    previousWeek: number;
    previousWeekAverage: number;
}

class MarketIndicatorSeasonalChartService {
    calculateThisYearData(data: IChartData[], dataFrequency: DemeterDataFrequency = DemeterDataFrequency.Weekly): IChartData[] {
        if (dataFrequency === DemeterDataFrequency.Monthly) {
            return data;
        }

        return marketIndicatorChartDateService.toWeeklyData(data);
    }

    calculateSeasonalAverageChange(data: IChartData[], yearsOfData: number, dataFrequency: DemeterDataFrequency = DemeterDataFrequency.Weekly): number {
        // This is a direct copy of the backend.
        const focusData = data[data.length - 1];
        const calculateAsOfDate = moment(focusData.asOfDate);

        if (dataFrequency === DemeterDataFrequency.Monthly) {
            const month = calculateAsOfDate.month();
            const currentDate = moment(new Date(calculateAsOfDate.year(), month, 1));
            const oldestDate = moment(currentDate).subtract(yearsOfData, 'years').toDate();
            const filteredData = data.filter((x) => {
                const asOfDate = moment(x.asOfDate);
                return asOfDate.isSameOrAfter(oldestDate) && asOfDate.isBefore(currentDate) && asOfDate.month() === month;
            });

            const previousMonthsData = filteredData.map((item) => {
                const previousData = data.filter((x) => moment(x.asOfDate).isBefore(item.asOfDate));
                const lastMonthData = previousData.reduce(
                    (maxItem, currentItem) => (moment(currentItem.asOfDate).isAfter(moment(maxItem.asOfDate)) ? currentItem : maxItem),
                    data[0] ?? null,
                );

                return lastMonthData ?? item;
            });

            const average = meanBy(filteredData, 'value');
            const previousMonthsAverage = meanBy(previousMonthsData, 'value');

            return average - previousMonthsAverage;
        }

        // This is a direct copy of the backend.
        const seasonalWeeks: SeasonalWeek[] = [];
        const week = formattingService.toWeekNumber(calculateAsOfDate.toDate());
        const previousWeek = formattingService.toWeekNumber(calculateAsOfDate.subtract(7, 'days').toDate());

        // Make sure we have all of the historical data.
        for (let i = 0; i <= yearsOfData; i += 1) {
            const focusAsOfDate = moment(focusData.asOfDate).subtract(i, 'years');
            const previousFocusAsOfDate = moment(focusAsOfDate).subtract(7, 'days');
            const filteredData = data.filter(
                (x) => Math.abs(moment(x.asOfDate).diff(focusAsOfDate, 'days')) < 14 && formattingService.toWeekNumber(x.asOfDate) === week,
            );
            const filteredDataPreviousWeek = data.filter(
                (x) => Math.abs(moment(x.asOfDate).diff(previousFocusAsOfDate, 'days')) < 14 && formattingService.toWeekNumber(x.asOfDate) === previousWeek,
            );

            const seasonalWeek: SeasonalWeek = {
                week,
                average: meanBy(filteredData, 'value'),
                previousWeek,
                previousWeekAverage: meanBy(filteredDataPreviousWeek, 'value'),
            };

            if (i !== 0) {
                seasonalWeeks.push(seasonalWeek);
            }
        }

        const average = meanBy(seasonalWeeks, 'average');
        const previousWeeksAverage = meanBy(seasonalWeeks, 'previousWeekAverage');
        return average - previousWeeksAverage;
    }

    calculateLastChange(data: IChartData[], dataFrequency: DemeterDataFrequency = DemeterDataFrequency.Weekly): number {
        if (dataFrequency === DemeterDataFrequency.Monthly) {
            return data[data.length - 1].value - data[data.length - 2].value;
        }

        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        return (weeklyData[weeklyData.length - 1]?.value ?? 0) - (weeklyData[weeklyData.length - 2]?.value ?? 0);
    }

    calculateLastYearData(data: IChartData[], dataFrequency: DemeterDataFrequency = DemeterDataFrequency.Weekly): IChartData[] {
        if (data.length === 0) {
            return [];
        }
        if (dataFrequency === DemeterDataFrequency.Monthly) {
            const focusData = data[data.length - 1];
            const lastYearStart = moment(focusData.asOfDate).subtract(2, 'years');
            const lastYearEnd = moment(focusData.asOfDate).subtract(1, 'years');

            return data
                .filter((x) => moment(x.asOfDate).isAfter(lastYearStart) && moment(x.asOfDate).isSameOrBefore(lastYearEnd))
                .map((x) => ({ ...x, asOfDate: moment(x.asOfDate).add(1, 'years').toDate() }));
        }

        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        if (weeklyData.length === 0) {
            return [];
        }

        const lastYearWeeksData = weeklyData.map((weekData) => {
            const previousYearWeekDate = marketIndicatorChartDateService.getPreviousYearWeek(weekData.asOfDate);
            const previousYearWeek = weeklyData.find((x) => x.asOfDate.getTime() >= previousYearWeekDate.getTime());
            return {
                value: previousYearWeek?.value,
                asOfDate: weekData.asOfDate,
                isActualValue: true,
            } as IChartData;
        });

        return lastYearWeeksData;
    }

    calculateSeasonalAverageData = (
        data: IChartData[],
        numberOfYears: number,
        dataFrequency: DemeterDataFrequency = DemeterDataFrequency.Weekly,
    ): IChartData[] => {
        const filteredData = dataFrequency === DemeterDataFrequency.Monthly ? data : marketIndicatorChartDateService.toWeeklyData(data);

        const weeklyDataWithAverages = filteredData.map((item) => {
            const previousYearsDates =
                dataFrequency === DemeterDataFrequency.Monthly
                    ? marketIndicatorChartDateService.getPreviousYearsMonths(item.asOfDate, numberOfYears)
                    : marketIndicatorChartDateService.getPreviousYearsWeeks(item.asOfDate, numberOfYears);
            const previousYearsData = previousYearsDates
                .map((weekDate) => filteredData.find((d) => d.asOfDate.getTime() === weekDate.getTime()))
                .filter((d) => d !== undefined);
            const averageValue =
                previousYearsData.length > 0 ? previousYearsData.reduce((sum, d) => (d ? sum + d.value : sum), 0) / previousYearsData.length : 0;

            return {
                value: averageValue,
                asOfDate: item.asOfDate,
                isActualValue: true,
            } as IChartData;
        });
        return weeklyDataWithAverages;
    };

    calculateRangeData = (
        data: IChartData[],
        numberOfYears: number,
        dataFrequency: DemeterDataFrequency = DemeterDataFrequency.Weekly,
    ): IChartAreaRangeData[] => {
        const filteredData = dataFrequency === DemeterDataFrequency.Monthly ? data : marketIndicatorChartDateService.toWeeklyData(data);

        return filteredData.flatMap((item) => {
            const previousYearsDates =
                dataFrequency === DemeterDataFrequency.Monthly
                    ? marketIndicatorChartDateService.getPreviousYearsMonths(item.asOfDate, numberOfYears)
                    : marketIndicatorChartDateService.getPreviousYearsWeeks(item.asOfDate, numberOfYears);
            const previousYearsData = previousYearsDates
                .map((weekDate) => filteredData.find((d) => d.asOfDate.getTime() === weekDate.getTime()))
                .filter((d) => d !== undefined);
            const minData =
                previousYearsData.length > 0
                    ? previousYearsData.reduce((min, d) => (d && min && d.value < min.value ? d : min), previousYearsData[0])
                    : undefined;
            const maxData =
                previousYearsData.length > 0
                    ? previousYearsData.reduce((max, d) => (d && max && d.value > max.value ? d : max), previousYearsData[0])
                    : undefined;

            if (!minData || !maxData) {
                return [];
            }

            return [
                {
                    minimumValue: minData ? minData.value : 0,
                    maximumValue: maxData ? maxData.value : 0,
                    asOfDate: item.asOfDate,
                    isActualValue: true,
                },
            ] as IChartAreaRangeData[];
        });
    };
}

const marketIndicatorSeasonalChartService = new MarketIndicatorSeasonalChartService();
export default marketIndicatorSeasonalChartService;
