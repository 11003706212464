import React, { memo, useMemo } from 'react';
import { DemeterDataSource, DemeterSymbolModel } from '../../../../Generated/Raven-Demeter';
import { IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import ProjectionChartRaw from '../../../Components/Charts/Projection/ProjectionChartRaw';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { MonthlyRiskCompositeModel, RiskRequest } from '../RiskDefinitions';

export interface IForwardCurveAndBasisChart {
    symbol: DemeterSymbolModel;
    risksRequest: RiskRequest;
    calculatedRisks: MonthlyRiskCompositeModel[];
    displayDecimalPlaces: number;
}

const ForwardCurveAndBasisChart: React.FC<IForwardCurveAndBasisChart> = (props: IForwardCurveAndBasisChart) => {
    const [translations] = useLanguage();

    const linesSeries = useMemo<IChartDataSeries[]>(
        () => [
            {
                label: translations.risk.text.price,
                data: props.calculatedRisks.map((x) => ({
                    value: x.price ?? 0,
                    asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                    isActualValue: true,
                })),
            },
            {
                label: translations.risk.text.markToMarketReference,
                data: props.calculatedRisks.map((x) => ({
                    value: x.priceConvertedWithBasis ?? 0,
                    asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                    isActualValue: true,
                })),
            },
        ],
        [props.calculatedRisks],
    );

    const barSeries = useMemo<IChartDataSeries>(
        () => ({
            label: translations.risk.text.basis,
            axisLabel: `${translations.risk.text.basis} ${translations.currency[props.risksRequest.currency ?? '']}`,
            data: props.calculatedRisks.map((x) => ({
                value: x.basis ?? 0,
                asOfDate: new Date(x.dataYear, x.dataMonth - 1, 1),
                isActualValue: true,
            })),
            format: 'currency',
        }),
        [props.calculatedRisks, props.risksRequest.currency, props.risksRequest.unitOfMeasure],
    );

    return (
        <ChartWrapper
            name="ForwardCurveAndBasisChart"
            title={translations.risk.headers.forwardCurveAndBasis}
            dataSourceTag={[translations.exchange[props.symbol.exchange], translations.dataSource[DemeterDataSource.StoneXCalculations]]}
            isLoading={props.calculatedRisks.length === 0}
        >
            <ProjectionChartRaw
                linesSeries={linesSeries}
                barSeries={barSeries}
                currency={props.risksRequest?.currency}
                displayDecimalPlacesMinimum={props.displayDecimalPlaces}
                displayDecimalPlacesMaximum={props.displayDecimalPlaces}
            />
        </ChartWrapper>
    );
};

export default memo(ForwardCurveAndBasisChart);
