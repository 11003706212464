import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import NavigationRoutes from '../../../../../Layouts/NavigationRoutes';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import plusSvg from '../../../../Assets/Icons/expand.svg';
import PageHeaderWithBackNavigation from '../../../../Components/Headers/PageHeaderWithBackNavigation';
import useSearchParameters from '../../../../Components/Navigation/Hooks/useSearchParametersHook';
import Tabs from '../../../../Components/Navigation/Tabs/Tabs';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { CombinationParameters, UpsertMarketIndicatorFactorRequest } from '../MarketIndicatorsManagementDefinitions';
import styles from './MarketIndicatorFactorPage.module.scss';
import MarketIndicatorFactorSelectionPage from './MarketIndicatorFactorSelectionPage';
import CombinationMultiplierColumn from './MarketIndicatorsFactorFields/Combination/CombinationMultiplierColumn';

const numberOfMultipliers = 12;
const numberOfSubFactorsForNonCombinationTemplate = 0;
const maximumNumberOfSubFactors = 4;
const minimumNumberOfSubFactors = 2;

const MarketIndicatorFactorPage: React.FC = () => {
    const [translations] = useLanguage();
    const [parentUpsertMarketIndicatorFactorRequest, setParentUpsertMarketIndicatorFactorRequest] = useState<UpsertMarketIndicatorFactorRequest>();
    const { marketIndicatorGuid, marketIndicatorFactorGuid } = useParams();
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [numberOfSubFactors, setNumberOfSubFactors] = useState(numberOfSubFactorsForNonCombinationTemplate);
    const navigate = useNavigate();

    const addColumnOfMultipliers = () => {
        const updatedMultipliers = parentUpsertMarketIndicatorFactorRequest?.parameters! as CombinationParameters;

        for (let index = 0; index < numberOfMultipliers; index += 1) {
            updatedMultipliers[`multipliers${index + 1}` as keyof CombinationParameters] += ',1';
        }

        setNumberOfSubFactors((current) => current + 1);
        setParentUpsertMarketIndicatorFactorRequest({ ...parentUpsertMarketIndicatorFactorRequest!, parameters: updatedMultipliers });
    };

    const [, refreshGetMarketIndicatorParentFactorResponse, getMarketIndicatorParentFactorResponse] = useApiWithoutAutoExecute(() => {
        if (!parentUpsertMarketIndicatorFactorRequest) {
            return null;
        }

        return demeterMarketIndicatorsApi.getMarketIndicatorFactor(marketIndicatorGuid!, parentUpsertMarketIndicatorFactorRequest.marketIndicatorFactorGuid);
    });

    useEffect(() => {
        const marketIndicatorFactorSubFactors = getMarketIndicatorParentFactorResponse?.marketIndicatorFactor?.subFactors ?? [];

        if (
            !getMarketIndicatorParentFactorResponse ||
            marketIndicatorFactorSubFactors?.length === parentUpsertMarketIndicatorFactorRequest?.subFactors?.length
        ) {
            return;
        }

        setParentUpsertMarketIndicatorFactorRequest({
            ...parentUpsertMarketIndicatorFactorRequest,
            subFactors: marketIndicatorFactorSubFactors,
        } as UpsertMarketIndicatorFactorRequest);
    }, [getMarketIndicatorParentFactorResponse]);

    useEffect(() => {
        if (!parentUpsertMarketIndicatorFactorRequest || searchParameters?.tab) {
            return;
        }

        setSearchParameters({ tab: 'parent' });
    }, [parentUpsertMarketIndicatorFactorRequest]);

    useEffect(() => {
        const totalNumberOfSavedSubfactors = getMarketIndicatorParentFactorResponse?.marketIndicatorFactor?.parameters.multipliers1?.split(',')?.length ?? 0;
        const minimumNumberOfTotalSubFactors = Math.max(totalNumberOfSavedSubfactors, minimumNumberOfSubFactors, numberOfSubFactors);

        if (totalNumberOfSavedSubfactors && numberOfSubFactors >= minimumNumberOfTotalSubFactors) {
            return;
        }

        setNumberOfSubFactors(minimumNumberOfTotalSubFactors);
    }, [getMarketIndicatorParentFactorResponse, marketIndicatorGuid]);

    useEffect(() => {
        if (!marketIndicatorGuid || !parentUpsertMarketIndicatorFactorRequest?.marketIndicatorFactorGuid || getMarketIndicatorParentFactorResponse) {
            return;
        }

        refreshGetMarketIndicatorParentFactorResponse();
    }, [marketIndicatorGuid, parentUpsertMarketIndicatorFactorRequest]);

    const marketIndicator = useMemo(() => getMarketIndicatorParentFactorResponse?.marketIndicatorFactor, [getMarketIndicatorParentFactorResponse]);

    const multiplierColumns = useMemo(() => {
        if (!parentUpsertMarketIndicatorFactorRequest || !getMarketIndicatorParentFactorResponse) {
            return null;
        }

        const lengthOfMultipliers = (parentUpsertMarketIndicatorFactorRequest.parameters as CombinationParameters)?.multipliers1?.split(',').length;
        const columns = [];

        const currentSubfactor = getMarketIndicatorParentFactorResponse.marketIndicatorFactor?.subFactors.find(
            (x) => x.marketIndicatorFactorGuid === searchParameters.tab,
        );

        for (let index = 0; index < lengthOfMultipliers; index += 1) {
            columns.push(
                <div key={`multiplierColumn_${index}`} className={styles.indicator_add_and_edit_position}>
                    <div className={styles.master_flex}>
                        <p className={styles.indicator_add_and_edit_subfactor_name}>{currentSubfactor?.displayName}</p>
                    </div>

                    <CombinationMultiplierColumn
                        parentUpsertMarketIndicatorFactorRequest={parentUpsertMarketIndicatorFactorRequest}
                        setParentUpsertMarketIndicatorFactorRequest={setParentUpsertMarketIndicatorFactorRequest}
                        columnNumber={index}
                    />
                </div>,
            );
        }

        return columns;
    }, [parentUpsertMarketIndicatorFactorRequest, getMarketIndicatorParentFactorResponse, numberOfSubFactors]);

    const marketIndicatorFactorSelectionPageWithProps = (
        <MarketIndicatorFactorSelectionPage
            parentUpsertMarketIndicatorFactorRequest={parentUpsertMarketIndicatorFactorRequest}
            setParentUpsertMarketIndicatorFactorRequest={setParentUpsertMarketIndicatorFactorRequest}
            getMarketIndicatorParentFactorResponse={getMarketIndicatorParentFactorResponse}
            refreshGetMarketIndicatorParentFactorResponse={refreshGetMarketIndicatorParentFactorResponse}
            multiplierColumns={multiplierColumns}
        />
    );

    const showParent = !!getMarketIndicatorParentFactorResponse;

    const tabOptions = [
        {
            title: translations.words.parent,
            key: 'parent',
            component: marketIndicatorFactorSelectionPageWithProps,
            showTab: showParent,
        },
        {
            title: marketIndicator?.subFactors[0]?.displayName ?? 'child1',
            key: marketIndicator?.subFactors[0]?.marketIndicatorFactorGuid ?? 'child1',
            component: marketIndicatorFactorSelectionPageWithProps,
            showTab: showParent && numberOfSubFactors > 0,
        },
        {
            title: marketIndicator?.subFactors[1]?.displayName ?? 'child2',
            key: marketIndicator?.subFactors[1]?.marketIndicatorFactorGuid ?? 'child2',
            component: marketIndicatorFactorSelectionPageWithProps,
            showTab: showParent && numberOfSubFactors > 1,
        },
        {
            title: marketIndicator?.subFactors[2]?.displayName ?? 'child3',
            key: marketIndicator?.subFactors[2]?.marketIndicatorFactorGuid ?? 'child3',
            component: marketIndicatorFactorSelectionPageWithProps,
            showTab: showParent && numberOfSubFactors > 2,
        },
        {
            title: marketIndicator?.subFactors[3]?.displayName ?? 'child4',
            key: marketIndicator?.subFactors[3]?.marketIndicatorFactorGuid ?? 'child4',
            component: marketIndicatorFactorSelectionPageWithProps,
            showTab: showParent && numberOfSubFactors > 3,
        },
    ];

    const rightSide = useMemo(() => {
        const numberOfSubFactorsIsMaximum = numberOfSubFactors >= maximumNumberOfSubFactors;
        if (searchParameters.tab !== 'parent' || numberOfSubFactorsIsMaximum) {
            return undefined;
        }

        return (
            <button onClick={addColumnOfMultipliers} className={styles.combination_tabs_plus_button} type="button">
                <img src={plusSvg} alt="#" />
            </button>
        );
    }, [searchParameters.tab, numberOfSubFactors, parentUpsertMarketIndicatorFactorRequest]);

    const navigateBackToMarketIndicators = () => navigate(`${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicatorGuid}/edit`);

    const parentLoading = (parentUpsertMarketIndicatorFactorRequest?.parameters as CombinationParameters)?.multipliers1 && !multiplierColumns;

    return (
        <div className={styles.indicator_add_and_edit_page_container}>
            <PageHeaderWithBackNavigation
                title={marketIndicatorFactorGuid ? translations.words.edit : translations.marketIndicatorsManagement.headers.addFactor}
                handleBackNavigation={navigateBackToMarketIndicators}
            />
            {showParent && !parentLoading && (
                <div className={styles.combination_tabs_container}>
                    <Tabs tabOptions={tabOptions} rightSideComponent={rightSide} />
                </div>
            )}
            {(!showParent || parentLoading) && (
                // Always need a setParent request for initial save (happens before I have tabs).
                <MarketIndicatorFactorSelectionPage
                    setParentUpsertMarketIndicatorFactorRequest={setParentUpsertMarketIndicatorFactorRequest}
                    refreshGetMarketIndicatorParentFactorResponse={refreshGetMarketIndicatorParentFactorResponse}
                    multiplierColumns={multiplierColumns}
                />
            )}
        </div>
    );
};

export default MarketIndicatorFactorPage;
