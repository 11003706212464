import { useEffect, useMemo, useState } from 'react';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import {
    AddMarketIndicatorRequest,
    CloneMarketIndicatorRequest,
    DemeterCommodity,
    DemeterFilterTimeSpan,
    DemeterPermissionType,
    DemeterRegion,
    DemeterSymbolModel,
    ListMarketIndicatorReviewsResponse,
    MarketIndicatorModel,
    MarketIndicatorReviewStatus,
    UpdateMarketIndicatorRequest,
} from '../../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import CheckboxInput from '../../../../Components/Form/Inputs/CheckboxInput';
import SelectInput from '../../../../Components/Form/Inputs/SelectInput';
import TextInput from '../../../../Components/Form/Inputs/TextInput';
import LoadingSpinner from '../../../../Components/LoadingSpinner/LoadingSpinner';
import ActionModal from '../../../../Components/Modals/ActionModal/ActionModal';
import ActionsCellButton from '../../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import RegexValidators from '../../../../Core/Validation/RegexValidators';
import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import usePermission from '../../../../Services/Permissions/usePermissionHook';
import { marketIndicatorsManagementContractOptions } from '../MarketIndicatorsManagementDefinitions';
import styles from './MarketIndicatorActions.module.scss';
import MarketIndicatorBasisLocation from './MarketIndicatorBasisLocation';

// Defaults.
const defaultRegion = DemeterRegion.UnitedStates;
const defaultCommodity = DemeterCommodity.Butter;
const defaultNumberOfYears = 4;
const defaultContractNumber = 1;

interface IMarketIndicatorActionsProps {
    marketIndicator: MarketIndicatorModel;
    symbols: DemeterSymbolModel[];
    symbolDropdownOptions: {
        label: string;
        value: DemeterSymbolModel;
    }[];
    handleUpdateMarketIndicator: () => void;
}

const MarketIndicatorActions: React.FC<IMarketIndicatorActionsProps> = (props: IMarketIndicatorActionsProps) => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Component states.
    const [showCloneIndicatorModal, setShowCloneIndicatorModal] = useState(false);
    const [showEditIndicatorModal, setShowEditIndicatorModal] = useState(false);
    const [symbol, setSymbol] = useState<DemeterSymbolModel>();
    const [showReviewHistoryModal, setShowReviewHistoryModal] = useState(false);

    // Form request.
    const [marketIndicatorRequest, setMarketIndicatorRequest] = useState<AddMarketIndicatorRequest & { marketIndicatorGuid: string; isPublished?: boolean }>({
        marketIndicatorGuid: '',
        region: defaultRegion,
        commodity: defaultCommodity,
        contractNumber: defaultContractNumber,
        displayName: '',
        numberOfYears: defaultNumberOfYears,
        isPublished: true,
    });

    // Api hooks.
    const [, handleCloneMarketIndicator, cloneMarketIndicatorResponse] = useApiWithoutAutoExecute(
        () =>
            demeterMarketIndicatorsApi.cloneMarketIndicator(marketIndicatorRequest!.marketIndicatorGuid, marketIndicatorRequest as CloneMarketIndicatorRequest),
        {
            successMessage: translations.marketIndicatorsManagement.messages.indicatorCloneSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.indicatorCloneFailed,
            conflictErrorMessage: translations.marketIndicatorsManagement.messages.indicatorCloneConflict,
        },
    );

    const [, handleUpdateMarketIndicator, updateMarketIndicatorResponse] = useApiWithoutAutoExecute(
        (marketIndicatorPublishUpdateRequest?: UpdateMarketIndicatorRequest) =>
            demeterMarketIndicatorsApi.updateMarketIndicator(
                marketIndicatorPublishUpdateRequest?.marketIndicatorGuid ?? marketIndicatorRequest!.marketIndicatorGuid,
                marketIndicatorPublishUpdateRequest ?? (marketIndicatorRequest as UpdateMarketIndicatorRequest),
            ),
        {
            successMessage: translations.marketIndicatorsManagement.messages.indicatorUpdateSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.indicatorUpdateFailed,
        },
    );

    const [isMarketIndicatorReviewHistoryLoading, getMarketIndicatorReviewHistory, getMarketIndicatorReviewHistoryResponse] = useApiWithoutAutoExecute<
        string,
        ListMarketIndicatorReviewsResponse
    >((marketIndicatorGuid) => demeterMarketIndicatorsApi.listMarketIndicatorReviews(marketIndicatorGuid!, DemeterFilterTimeSpan.TwoYears));

    useEffect(() => {
        props.handleUpdateMarketIndicator();
    }, [cloneMarketIndicatorResponse, updateMarketIndicatorResponse]);

    const setMarketIndicatorReqestByRow = (row: MarketIndicatorModel, isEditing?: boolean) => {
        setMarketIndicatorRequest({
            ...marketIndicatorRequest,
            marketIndicatorGuid: row.marketIndicatorGuid,
            region: row.region,
            commodity: row.commodity,
            contractNumber: row.contractNumber,
            displayName: row.displayName ?? '',
            numberOfYears: row.numberOfYears,
            isPublished: row.isPublished,
        });

        if (isEditing) {
            setSymbol(props.symbols?.find((x) => x.commodity === row.commodity && x.region === row.region));
        } else {
            setSymbol(props.symbolDropdownOptions[0].value);
        }
    };

    const handleSymbolChange = (newSymbolModel?: DemeterSymbolModel) => {
        if (!newSymbolModel) {
            return;
        }

        setSymbol(newSymbolModel);
        setMarketIndicatorRequest({
            ...marketIndicatorRequest,
            region: newSymbolModel.region,
            commodity: newSymbolModel.commodity,
        });
    };

    const handleShowHistory = () => {
        getMarketIndicatorReviewHistory(props.marketIndicator.marketIndicatorGuid);
        setShowReviewHistoryModal(true);
    };

    const modalReviewHistoryHeader = useMemo(
        () =>
            `${translations.marketIndicatorsManagement.reviewHistory.approvalHistory} ${props.marketIndicator.displayName} ${
                translations.marketIndicatorsManagement.reviewHistory.commodity
            } ${
                translations.commodity[props.marketIndicator.commodity]
                    ? translations.commodity[props.marketIndicator.commodity]
                    : props.marketIndicator.commodity
            }`,
        [translations.marketIndicatorsManagement.reviewHistory, props.marketIndicator],
    );

    const showEditButton = usePermission(DemeterPermissionType.MarketIndicatorDeveloper);
    const showCloneButton = usePermission(DemeterPermissionType.MarketIndicatorDeveloper);
    const showActivateButton = usePermission(DemeterPermissionType.MarketIndicatorDeveloper);
    const showHistoryButton =
        usePermission(DemeterPermissionType.MarketIndicatorAdministrator) ||
        usePermission(DemeterPermissionType.MarketIndicatorDeveloper) ||
        usePermission(DemeterPermissionType.MarketIndicatorCompliance) ||
        usePermission(DemeterPermissionType.MarketIndicatorSubjectMatterExpert);

    return (
        <>
            {showEditButton && (
                <ActionsCellButton
                    text={translations.marketIndicatorsManagement.actions.editDetails}
                    handleClick={() => {
                        setMarketIndicatorReqestByRow(props.marketIndicator, true);
                        setShowEditIndicatorModal(true);
                    }}
                />
            )}
            {showCloneButton && (
                <ActionsCellButton
                    text={translations.marketIndicatorsManagement.actions.clone}
                    handleClick={() => {
                        setMarketIndicatorReqestByRow(props.marketIndicator);
                        setShowCloneIndicatorModal(true);
                    }}
                />
            )}
            {showActivateButton && (
                <ActionsCellButton
                    text={
                        props.marketIndicator.isPublished
                            ? translations.marketIndicatorsManagement.actions.deactivate
                            : translations.marketIndicatorsManagement.actions.activate
                    }
                    handleClick={() =>
                        handleUpdateMarketIndicator({
                            marketIndicatorGuid: props.marketIndicator.marketIndicatorGuid,
                            displayName: props.marketIndicator.displayName ?? '',
                            numberOfYears: props.marketIndicator.numberOfYears,
                            isPublished: !props.marketIndicator.isPublished,
                            contractNumber: props.marketIndicator.contractNumber,
                        })
                    }
                />
            )}
            {showHistoryButton && <ActionsCellButton text={translations.marketIndicatorsManagement.actions.history} handleClick={handleShowHistory} />}
            <ActionModal
                header={translations.marketIndicatorsManagement.headers.cloneIndicators}
                showModal={showCloneIndicatorModal}
                actionButtonName={translations.actions.save}
                handleAction={() => {
                    handleCloneMarketIndicator();
                    setShowCloneIndicatorModal(false);
                }}
                handleCancel={() => setShowCloneIndicatorModal(false)}
                actionButtonDisabled={!RegexValidators.AlphaNumericMinimumLength2.test(marketIndicatorRequest.displayName)}
            >
                <>
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.indicatorName}
                            placeholder={translations.marketIndicatorsManagement.headers.indicatorName}
                            required
                            value={marketIndicatorRequest.displayName}
                            handleTextChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, displayName: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.marketIndicatorsManagement.messages.displayNameInvalid}
                        />
                    </div>
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <CheckboxInput
                            title={translations.marketIndicatorsManagement.fields.basisLocation}
                            isChecked={!!marketIndicatorRequest.usePrices}
                            handleIsChecked={(isChecked: boolean) =>
                                setMarketIndicatorRequest({
                                    ...marketIndicatorRequest,
                                    region: defaultRegion,
                                    commodity: defaultCommodity,
                                    usePrices: isChecked,
                                })
                            }
                        />
                    </div>
                    {!marketIndicatorRequest.usePrices && (
                        <>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    title={translations.marketIndicatorsManagement.headers.exchangeAndCommodity}
                                    options={props.symbolDropdownOptions}
                                    value={symbol}
                                    handleOptionChange={handleSymbolChange}
                                />
                            </div>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    title={translations.marketIndicatorsManagement.fields.contractNumber}
                                    value={marketIndicatorRequest.contractNumber}
                                    options={marketIndicatorsManagementContractOptions}
                                    handleOptionChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, contractNumber: +value! })}
                                />
                            </div>
                        </>
                    )}
                    {marketIndicatorRequest.usePrices && (
                        <MarketIndicatorBasisLocation
                            region={marketIndicatorRequest.region}
                            subRegion={marketIndicatorRequest.subRegion}
                            commodity={marketIndicatorRequest.commodity}
                            dataSource={marketIndicatorRequest.dataSource}
                            handleChange={(region, subRegion, commodity, dataSource) =>
                                setMarketIndicatorRequest({ ...marketIndicatorRequest, region, subRegion, commodity, dataSource })
                            }
                        />
                    )}
                </>
            </ActionModal>
            <ActionModal
                header={translations.marketIndicatorsManagement.actions.editDetails}
                showModal={showEditIndicatorModal}
                actionButtonName={translations.actions.save}
                handleAction={() => {
                    handleUpdateMarketIndicator();
                    setShowEditIndicatorModal(false);
                }}
                handleCancel={() => setShowEditIndicatorModal(false)}
                actionButtonDisabled={
                    !RegexValidators.AlphaNumericMinimumLength2.test(marketIndicatorRequest.displayName) ||
                    !RegexValidators.PositiveNumberZeroThroughTen.test(`${marketIndicatorRequest.numberOfYears}`)
                }
            >
                <>
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.indicatorName}
                            placeholder={translations.marketIndicatorsManagement.headers.indicatorName}
                            required
                            value={marketIndicatorRequest.displayName}
                            handleTextChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, displayName: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.marketIndicatorsManagement.messages.displayNameInvalid}
                        />
                    </div>
                    {!props.marketIndicator.usePrices && (
                        <>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    disabled
                                    title={translations.marketIndicatorsManagement.headers.exchangeAndCommodity}
                                    options={props.symbolDropdownOptions}
                                    value={symbol}
                                    handleOptionChange={() => {}}
                                />
                            </div>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    disabled
                                    title={translations.marketIndicatorsManagement.fields.contractNumber}
                                    value={marketIndicatorRequest.contractNumber}
                                    options={marketIndicatorsManagementContractOptions}
                                    handleOptionChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, contractNumber: +value! })}
                                />
                            </div>
                        </>
                    )}
                    {props.marketIndicator.usePrices && (
                        <div className={styles.market_indicator_actions_modal_input_wrapper}>
                            <SelectInput
                                disabled
                                title={translations.marketIndicatorsManagement.fields.product}
                                options={props.symbolDropdownOptions}
                                value={symbol}
                                handleOptionChange={() => {}}
                            />
                        </div>
                    )}
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.numberOfYears}
                            placeholder={translations.marketIndicatorsManagement.headers.numberOfYears}
                            required
                            value={`${marketIndicatorRequest.numberOfYears}`}
                            handleTextChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, numberOfYears: +value })}
                            validation={RegexValidators.PositiveNumberZeroThroughTen}
                            type="number"
                            errorMessage={translations.marketIndicatorsManagement.messages.numberOfYearsInvalid}
                        />
                    </div>
                </>
            </ActionModal>
            <ActionModal header={modalReviewHistoryHeader} showModal={showReviewHistoryModal} hideCancel handleCancel={() => setShowReviewHistoryModal(false)}>
                <div className={styles.market_indicator_actions_modal_review_history}>
                    {isMarketIndicatorReviewHistoryLoading && <LoadingSpinner />}
                    {!isMarketIndicatorReviewHistoryLoading && (
                        <>
                            {getMarketIndicatorReviewHistoryResponse?.reviewers && (
                                <div className={styles.market_indicator_actions_modal_review_history_reviewers}>
                                    <strong>{translations.marketIndicatorsManagement.reviewHistory.reviewers}:</strong>{' '}
                                    {getMarketIndicatorReviewHistoryResponse?.reviewers.map((x) => x.name).join(', ')}
                                </div>
                            )}
                            <div className={styles.market_indicator_actions_modal_review_history_table_wrapper}>
                                <div className={styles.market_indicator_actions_modal_review_history_table}>
                                    <div className={styles.market_indicator_actions_modal_review_history_table_row}>
                                        <div className={styles.market_indicator_actions_modal_review_history_table_header_cell}>
                                            {translations.marketIndicatorsManagement.headers.reviewDate}
                                        </div>
                                        <div className={styles.market_indicator_actions_modal_review_history_table_header_cell}>
                                            {translations.marketIndicatorsManagement.headers.status}
                                        </div>
                                        <div className={styles.market_indicator_actions_modal_review_history_table_header_cell}>
                                            {translations.marketIndicatorsManagement.headers.comments}
                                        </div>
                                        <div className={styles.market_indicator_actions_modal_review_history_table_header_cell}>
                                            {translations.marketIndicatorsManagement.headers.reviewedBy}
                                        </div>
                                    </div>
                                    {getMarketIndicatorReviewHistoryResponse?.rows?.map((review) => (
                                        <div key={review.reviewedAt} className={styles.market_indicator_actions_modal_review_history_table_row}>
                                            <div className={styles.market_indicator_actions_modal_review_history_table_cell}>
                                                {formattingService.toLongDateAndTimeFormat(new Date(review.reviewedAt))}
                                            </div>
                                            <div className={styles.market_indicator_actions_modal_review_history_table_cell}>
                                                {review.status === MarketIndicatorReviewStatus.Approved && (
                                                    <span className={styles.market_indicator_actions_modal_review_history_status_approved}>
                                                        {translations.words.approved}
                                                    </span>
                                                )}
                                                {review.status === MarketIndicatorReviewStatus.Rejected && (
                                                    <span className={styles.market_indicator_actions_modal_review_history_status_rejected}>
                                                        {translations.words.rejected}
                                                    </span>
                                                )}
                                            </div>
                                            <div className={styles.market_indicator_actions_modal_review_history_table_cell}>{review.comment}</div>
                                            <div className={styles.market_indicator_actions_modal_review_history_table_cell}>{review.reviewedBy}</div>
                                        </div>
                                    ))}
                                </div>
                                {getMarketIndicatorReviewHistoryResponse?.rows?.length === 0 && (
                                    <div className={styles.market_indicator_actions_modal_review_history_table_no_data}>No Data</div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </ActionModal>
        </>
    );
};

export default MarketIndicatorActions;
