import OverflowCellWithTooltip from '../../../Components/Tables/OverflowCellWithTooltip/OverflowCellWithTooltip';
import formattingService from '../../../Services/Formatting/FormattingService';
import styles from './ValueMatrixCalculator.module.scss';
import { FieldName, ValueMatrixTableResults } from './ValueMatrixDefinitions';

interface IValueMatrixResultsTableRowProps {
    valueMatrixResults: ValueMatrixTableResults[];
    fieldName: FieldName;
}

const ValueMatrixResultsTableRow: React.FC<IValueMatrixResultsTableRowProps> = (props: IValueMatrixResultsTableRowProps) => {
    const { minimumValue } = props.valueMatrixResults[props.valueMatrixResults.length - 1][props.fieldName];
    const decimalPlaces = formattingService.getDisplayDecimalPlacesMinimum(minimumValue);

    return (
        <div className={styles.value_matrix_results_table_decile_row}>
            {props.valueMatrixResults.map((x) => (
                <div key={x.title} className={styles.value_matrix_results_table_cell}>
                    <OverflowCellWithTooltip
                        value={`${formattingService.toNumberStringWithTrailingZeros(
                            x[props.fieldName].minimumValue,
                            decimalPlaces,
                            decimalPlaces,
                        )} - ${formattingService.toNumberStringWithTrailingZeros(x[props.fieldName].maximumValue, decimalPlaces, decimalPlaces)} `}
                    />
                </div>
            ))}
        </div>
    );
};

export default ValueMatrixResultsTableRow;
