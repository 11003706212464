import isequal from 'lodash.isequal';
import React, { memo, useEffect, useMemo, useState } from 'react';
import {
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterDataSource,
    DemeterFilterTimeSpan,
    DemeterRegion,
    DemeterSymbolModel,
    DemeterTableDefinitionType,
} from '../../../../Generated/Raven-Demeter';
import usePricesApi, { ListCommodityPricesResponse } from '../../../Apis/Hooks/usePricesApiHook';
import useTableDefinitionsApi from '../../../Apis/Hooks/useTableDefinitionsApiHook';
import { IChartBarDataSeries, IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import FilterTimeSpans from '../../../Components/Charts/FilterTimeSpans/FilterTimeSpans';
import ProjectionChartRaw from '../../../Components/Charts/Projection/ProjectionChartRaw';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { ForwardSpreadCalculatorRequest, ForwardSpreadRow } from './ForwardSpreadCalculatorDefinitions';

export interface IForwardSpreadCalculatorChartProps {
    title: string;
    forwardSpreadCalculatorRequest: ForwardSpreadCalculatorRequest;
    forwardSpreadRowData: ForwardSpreadRow[];
    testId?: string;
}

interface ICombinedData {
    value1?: number;
    isActualValue1?: boolean;
    value2?: number;
    isActualValue2?: boolean;
    spread?: number;
    asOfDate: Date;
}

const availableFilterTimeSpans = [
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.FiveYears,
    DemeterFilterTimeSpan.TenYears,
    DemeterFilterTimeSpan.TwentyYears,
];

const defaultFilterTimeSpan = DemeterFilterTimeSpan.FiveYears;

const ForwardSpreadCalculatorChart: React.FC<IForwardSpreadCalculatorChartProps> = (props: IForwardSpreadCalculatorChartProps) => {
    const [translations, translate] = useLanguage();

    const { symbol1, symbol2 } = props.forwardSpreadCalculatorRequest;
    const tableDefinitions = useTableDefinitionsApi(DemeterTableDefinitionType.CommodityPricesTable);
    const [combinedData, setCombinedData] = useState<ICombinedData[]>([]);
    const [filterTimeSpan, setFilterTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);
    const [oldestAsOfDate, setOldestAsOfDate] = useState<Date>();

    const regionCommoditySelection1 = {
        region: symbol1?.pricesRegion ?? DemeterRegion.All,
        commodity: symbol1?.pricesCommodity ?? DemeterCommodity.All,
        subRegion: '',
        extraParameters: symbol1?.pricesDataSource ?? '',
        dataFrequency: DemeterDataFrequency.Monthly,
    };

    const prices1Response = usePricesApi(
        regionCommoditySelection1,
        props.forwardSpreadCalculatorRequest.currency,
        props.forwardSpreadCalculatorRequest.unitOfMeasure,
        filterTimeSpan,
    );

    const regionCommoditySelection2 = {
        region: symbol2?.pricesRegion ?? DemeterRegion.All,
        commodity: symbol2?.pricesCommodity ?? DemeterCommodity.All,
        subRegion: '',
        extraParameters: symbol2?.pricesDataSource ?? '',
        dataFrequency: DemeterDataFrequency.Monthly,
    };

    const prices2Response = usePricesApi(
        regionCommoditySelection2,
        props.forwardSpreadCalculatorRequest.currency,
        props.forwardSpreadCalculatorRequest.unitOfMeasure,
        filterTimeSpan,
    );

    const hasMatchingData = (pricesResponse?: ListCommodityPricesResponse, symbol?: DemeterSymbolModel): boolean =>
        (pricesResponse?.rows &&
            pricesResponse.rows.length > 0 &&
            pricesResponse.rows[0].region === symbol?.pricesRegion &&
            pricesResponse.rows[0].commodity === symbol?.pricesCommodity &&
            pricesResponse.rows[0].dataSource === symbol?.pricesDataSource &&
            pricesResponse.currency === props.forwardSpreadCalculatorRequest.currency &&
            pricesResponse.unitOfMeasure === props.forwardSpreadCalculatorRequest.unitOfMeasure &&
            pricesResponse.filterTimeSpan === filterTimeSpan) ??
        false;

    useEffect(() => {
        // We want to make sure all data calls have returned before we render.
        if (
            !hasMatchingData(prices1Response, symbol1) ||
            !hasMatchingData(prices2Response, symbol2) ||
            !props.forwardSpreadRowData ||
            props.forwardSpreadRowData.length === 0
        ) {
            return;
        }

        // Get the filter timespans.
        const oldestAsOfDate1 = new Date(prices1Response!.oldestAsOfDate!);
        const oldestAsOfDate2 = new Date(prices2Response!.oldestAsOfDate!);
        const oldestAsOfDateAll = oldestAsOfDate2 > oldestAsOfDate1 ? oldestAsOfDate1 : oldestAsOfDate2;

        setOldestAsOfDate(oldestAsOfDateAll);

        // Combine the data.
        const startDate1 = new Date(prices1Response!.rows![0].asOfDate);
        const startDate2 = new Date(prices2Response!.rows![0].asOfDate);
        const startDateAll = startDate2 > startDate1 ? startDate1 : startDate2;
        let endDatePrices = new Date(props.forwardSpreadRowData[0].year, props.forwardSpreadRowData[0].month - 1, 1);
        endDatePrices = new Date(endDatePrices.getTime() - 86470000); // Move this back one day to avoid duplicates

        const newCombinedData = [] as ICombinedData[];
        let currentDate = startDateAll;
        while (currentDate < endDatePrices) {
            const dateToSearch = formattingService.toApiDate(currentDate);

            const data1 = prices1Response!.rows!.find((x) => formattingService.toApiDate(x.asOfDate) === dateToSearch);
            const data2 = prices2Response!.rows!.find((x) => formattingService.toApiDate(x.asOfDate) === dateToSearch);
            const combinedDatum = {
                value1: data1?.value != null ? data1?.value : undefined,
                isActualValue1: true,
                value2: data2?.value != null ? data2?.value : undefined,
                isActualValue2: true,
                asOfDate: new Date(currentDate),
            } as ICombinedData;

            if (combinedDatum.value1 !== undefined && combinedDatum.value2 !== undefined) {
                combinedDatum.spread = combinedDatum.value1 - combinedDatum.value2;
            }

            newCombinedData.push(combinedDatum);
            currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        }

        props.forwardSpreadRowData.forEach((x) => {
            const combinedDatum = {
                value1: x.convertedValue1,
                isActualValue1: false,
                value2: x.convertedValue2,
                isActualValue2: false,
                spread: x.spread,
                asOfDate: new Date(x.year, x.month - 1),
            } as ICombinedData;

            newCombinedData.push(combinedDatum);
        });

        // This updates the chart too much due to lightstreamer, need to stop the state update unless something has actually changed.
        if (!isequal(newCombinedData, combinedData)) {
            setCombinedData(newCombinedData);
        }
    }, [prices1Response, prices2Response, props.forwardSpreadRowData]);

    const getDisplayNameActual = (symbol?: DemeterSymbolModel): string => {
        if (!tableDefinitions || !symbol?.pricesRegion) {
            return '';
        }

        const regionDefinition = tableDefinitions.find((x) => x.region === symbol.pricesRegion);
        const commodityDefinition = regionDefinition?.demeterTableDefinitionGroups
            .flatMap((x) => x.demeterTableDefinitions)
            .find(
                (x) =>
                    x.commodity === symbol.pricesCommodity &&
                    (x.extraParameters === symbol.pricesDataSource || (!x.extraParameters && symbol.pricesDataSource === DemeterDataSource.All)),
            );

        return translate(commodityDefinition?.displayName!);
    };

    const isLoading = !tableDefinitions || !prices1Response || !prices2Response || combinedData.length === 0;
    const linesSeries = useMemo<IChartDataSeries[]>(
        () => [
            {
                label: getDisplayNameActual(symbol1),
                forecastLabel: formattingService.toDisplayName(symbol1),
                data: combinedData
                    .filter((x) => x.value1 !== undefined)
                    .map((x) => ({
                        value: x.value1!,
                        asOfDate: new Date(x.asOfDate),
                        isActualValue: x.isActualValue1!,
                    })),
            },
            {
                label: getDisplayNameActual(symbol2),
                forecastLabel: formattingService.toDisplayName(symbol2),
                data: combinedData
                    .filter((x) => x.value2 !== undefined)
                    .map((x) => ({
                        value: x.value2!,
                        asOfDate: new Date(x.asOfDate),
                        isActualValue: x.isActualValue2!,
                    })),
            },
        ],
        [combinedData],
    );

    const barSeries = useMemo<IChartBarDataSeries>(
        () => ({
            label: translations.calculators.fields.spread,
            forecastLabel: translations.calculators.text.spread,
            data: combinedData
                .filter((x) => x.spread !== undefined)
                .map((x) => ({
                    value: x.spread!,
                    asOfDate: new Date(x.asOfDate),
                    isActualValue: x.isActualValue2!,
                })),
            format: 'currency',
        }),
        [combinedData],
    );

    return (
        <ChartWrapper
            name="ForwardSpreadCalculatorChart"
            title={props.title}
            dataSourceTag={[
                translations.exchange[props.forwardSpreadCalculatorRequest.symbol1!.exchange!],
                translations.exchange[props.forwardSpreadCalculatorRequest.symbol2!.exchange!],
                ...(prices1Response?.dataSourceTag ?? '').split(', '),
                ...(prices2Response?.dataSourceTag ?? '').split(', '),
            ]}
            isLoading={isLoading}
            footer={
                <FilterTimeSpans
                    name="ForwardSpreadCalculatorChart"
                    filterTimeSpanOptions={availableFilterTimeSpans}
                    filterTimeSpan={filterTimeSpan}
                    oldestAsOfDate={oldestAsOfDate}
                    handleTimeSpanSelected={(timeSpan) => setFilterTimeSpan(timeSpan)}
                />
            }
            testId={props.testId}
        >
            <ProjectionChartRaw
                linesSeries={linesSeries}
                barSeries={barSeries}
                unitOfMeasure={props.forwardSpreadCalculatorRequest.unitOfMeasure}
                currency={props.forwardSpreadCalculatorRequest.currency}
                displayDecimalPlacesMaximum={4}
                displayDecimalPlacesMinimum={4}
            />
        </ChartWrapper>
    );
};

export default memo(ForwardSpreadCalculatorChart);
