import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Children } from 'react';
import ErrorBoundary from '../../../Core/ErrorHandling/ErrorBoundary';
import useLanguage from '../../../Services/Language/useLanguageHook';
import LinkButton, { LinkButtonType } from '../../Form/Buttons/LinkButton';
import ComponentSubHeader from '../../Headers/ComponentSubHeader';
import styles from './ProductsModal.module.scss';

interface IProductsModalWrapper {
    children: JSX.Element;
    title: string;
    subTitle?: string;
    open: boolean;
    isSetButtonDisabled: boolean;
    leftFooter?: JSX.Element | boolean;
    handleClose: () => void;
    handleSave: () => void;
}

const ProductsModalWrapper: React.FC<IProductsModalWrapper> = (props) => {
    const [translations] = useLanguage();

    return (
        <div>
            <Dialog disableEnforceFocus open={props.open} onClose={props.handleClose} sx={{ '& .MuiDialog-paper': { maxWidth: '740px' } }}>
                <DialogTitle>
                    <div className={styles.products_modal_header}>
                        <ComponentSubHeader title={props.title} />

                        <IconButton onClick={props.handleClose} size="small">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    {props.subTitle && <h4 className={styles.products_modal_sub_header}>{props.subTitle}</h4>}
                </DialogTitle>
                <ErrorBoundary>
                    <DialogContent>{Children.only(props.children)}</DialogContent>
                </ErrorBoundary>
                <DialogActions>
                    <div className={styles.products_modal_selector_actions}>
                        <div>{props.leftFooter && Children.only(props.leftFooter)}</div>
                        <div className={styles.products_modal_actions_right}>
                            <LinkButton
                                title={translations.calculators.basis.productSelector.actions.cancel}
                                type={LinkButtonType.White}
                                onClick={props.handleClose}
                            />
                            <LinkButton
                                title={translations.calculators.basis.productSelector.actions.set}
                                type={LinkButtonType.Blue}
                                disabled={props.isSetButtonDisabled}
                                onClick={props.handleSave}
                            />
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProductsModalWrapper;
