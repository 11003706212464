/* eslint-disable @typescript-eslint/indent */
import { useMemo } from 'react';
import { demeterApi, demeterDomesticsApi, demeterExportsApi, demeterImportsApi, demeterProductionApi, demeterStocksApi } from '../../../Apis/Apis';
import {
    Currency,
    DemeterCommodity,
    DemeterDataSource,
    DemeterFilterTimeSpan,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionType,
    UnitOfMeasure,
} from '../../../Generated/Raven-Demeter/api';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Components/Navigation/Hooks/useTableDefinitionHook';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';

export interface CommodityMonthlyProjectionModel {
    year: number;
    month: number;
    value?: number | null;
    normalizedValue: number;
    monthOverMonthChangePercent?: number | null;
    rollingYearChangePercent?: number | null;
    rollingYearValue?: number | null;
    unitOfMeasure?: UnitOfMeasure;
    alwaysShowActualValue: boolean;
    isActualValue: boolean;
    asOfDate: string;
}

export interface ListCommodityMonthlyProjectionResponse {
    rows?: CommodityMonthlyProjectionModel[] | null;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    dataSourceTag?: string | null;
    oldestAsOfDate?: string | null;
}

const useProjectionApi = (
    tableDefinitionType: DemeterTableDefinitionType,
    regionCommoditySelection: IRegionCommoditySelection,
    aggregateRegions?: DemeterRegion[],
    unitOfMeasure?: UnitOfMeasure,
    timeSpan?: DemeterFilterTimeSpan,
): ListCommodityMonthlyProjectionResponse | undefined => {
    const [, tableDefinitionCommodity] = useTableDefinition(tableDefinitionType, regionCommoditySelection);

    const cacheKey = useMemo(() => {
        const cacheKeyPrefix = `${CacheKeys.GetDemeterProjection}_${tableDefinitionType}`;
        if (!tableDefinitionCommodity || !regionCommoditySelection) {
            return cacheKeyPrefix;
        }

        const cacheKeySuffix = JSON.stringify({ ...regionCommoditySelection, timeSpan, unitOfMeasure });
        const aggregateRegionsKey = (aggregateRegions?.sort() ?? []).join();
        return `${cacheKeyPrefix}_${cacheKeySuffix}_${aggregateRegionsKey}`;
    }, [tableDefinitionCommodity, regionCommoditySelection, aggregateRegions, timeSpan, unitOfMeasure]);

    const [, , listDemeterProjectionResponse] = useCacheThenApi(cacheKey, () => {
        if (!tableDefinitionCommodity || !regionCommoditySelection) {
            return null;
        }

        const subRegion = regionCommoditySelection.subRegion ? (regionCommoditySelection.subRegion as DemeterSubRegion) : undefined;
        const aggregatedRequest =
            aggregateRegions && aggregateRegions.length > 0
                ? {
                      commoditySelections: aggregateRegions.map((region) => ({
                          region: region as DemeterRegion,
                          commodity: regionCommoditySelection.commodity as DemeterCommodity,
                      })),
                      unitOfMeasure,
                      filterTimeSpan: timeSpan,
                  }
                : undefined;

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyImportsTable) {
            if (aggregatedRequest) {
                return demeterImportsApi.listCommodityMonthlyImportsAggregated(aggregatedRequest);
            }
            return demeterImportsApi.listCommodityMonthlyImports(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyExportsTable) {
            if (aggregatedRequest) {
                return demeterExportsApi.listCommodityMonthlyExportsAggregated(aggregatedRequest);
            }
            return demeterExportsApi.listCommodityMonthlyExports(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }
        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyDomesticTable) {
            if (aggregatedRequest) {
                return demeterDomesticsApi.listCommodityMonthlyDomesticsAggregated(aggregatedRequest);
            }
            return demeterDomesticsApi.listCommodityMonthlyDomestics(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyMarginTable) {
            return demeterApi.listCommodityMonthlyMargins(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyProductionTable) {
            if (aggregatedRequest) {
                return demeterProductionApi.listCommodityMonthlyProductionAggregated(aggregatedRequest);
            }
            return demeterProductionApi.listCommodityMonthlyProduction(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyStockTable) {
            const dataSource = DemeterDataSource[regionCommoditySelection.extraParameters as keyof typeof DemeterDataSource];
            if (aggregatedRequest) {
                return demeterStocksApi.listCommodityMonthlyStocksAggregated({
                    commoditySelections: aggregatedRequest.commoditySelections.map((x) => ({ ...x, dataSource })),
                    unitOfMeasure,
                    filterTimeSpan: timeSpan,
                });
            }
            return demeterStocksApi.listCommodityMonthlyStocks(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                dataSource,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }

        return null;
    });

    return listDemeterProjectionResponse;
};

export default useProjectionApi;
