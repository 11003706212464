import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../../Apis/Apis';
import { DemeterPermissionType, DemeterRegion, ExchangeType, MarketIndicatorModel, MarketIndicatorReviewStatus } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import useApi from '../../../Apis/Hooks/useApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import Badge from '../../../Components/Badges/Badge/Badge';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import PageHeader from '../../../Components/Headers/PageHeader';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import usePermission from '../../../Services/Permissions/usePermissionHook';
import MarketIndicatorActions from './MarketIndicatorActions/MarketIndicatorActions';
import MarketIndicatorAddButton from './MarketIndicatorActions/MarketIndicatorAddButton';
import styles from './MarketIndicatorsManagement.module.scss';

// Defaults.
const defaultRegion = DemeterRegion.UnitedStates;
const defaultAllowedExchanges: ExchangeType[] = [ExchangeType.Cme, ExchangeType.Eex, ExchangeType.Ice, ExchangeType.Dce, ExchangeType.Euronext];
const reviewExpiringSoonDays = 25;
const reviewExpiredDays = 30;

const MarketIndicatorsMangementPage: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Api hooks.
    const symbols = useSymbolsApi();
    const [, refreshMarketIndicators, listMarketIndicatorsResponse] = useApi(() => demeterMarketIndicatorsApi.listMarketIndicators(false, true, true));

    // Dropdown options.
    const symbolDropdownOptions = useMemo(() => {
        if (!symbols || !listMarketIndicatorsResponse?.rows) {
            return [];
        }

        return symbols
            .filter((x) => x.region === defaultRegion || defaultAllowedExchanges.includes(x.exchange))
            .sort((a, b) => `${a.exchange}-${a.displayName}`.localeCompare(`${b.exchange}-${b.displayName}`))
            .map((x) => ({
                label: formattingService.toDisplayName(x),
                value: x,
            }));
    }, [symbols, listMarketIndicatorsResponse]);

    const displayReviewStatus = (marketIndicator: MarketIndicatorModel): JSX.Element => {
        if (marketIndicator.reviewStatus && marketIndicator.reviewStatus === MarketIndicatorReviewStatus.Rejected) {
            return <Badge title={translations.marketIndicatorsManagement.reviewStatus.rejected} icon="rejected" />;
        }

        if (marketIndicator.reviewStatus && marketIndicator.reviewStatus === MarketIndicatorReviewStatus.Approved) {
            const daysSinceLastApproval = formattingService.getDaysDifference(marketIndicator.reviewedAt);
            if (daysSinceLastApproval !== undefined && daysSinceLastApproval <= reviewExpiringSoonDays) {
                return <Badge title={translations.marketIndicatorsManagement.reviewStatus.approved} icon="approved" />;
            }
            if (daysSinceLastApproval !== undefined && daysSinceLastApproval <= reviewExpiredDays) {
                return <Badge title={translations.marketIndicatorsManagement.reviewStatus.expireSoon} icon="expireSoon" />;
            }
        }

        return <Badge title={translations.marketIndicatorsManagement.reviewStatus.expired} icon="expired" />;
    };

    const showAddButton = usePermission(DemeterPermissionType.MarketIndicatorDeveloper);

    return !symbols || !listMarketIndicatorsResponse || !symbolDropdownOptions ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.markets_management_page_container}>
            <PageHeader title={translations.marketIndicatorsManagement.title} />
            <div className={styles.markets_management_page_header_section}>
                <ComponentHeader title={translations.words.indicators} />
                {showAddButton && (
                    <MarketIndicatorAddButton
                        symbols={symbols}
                        symbolDropdownOptions={symbolDropdownOptions}
                        handleAddMarketIndicator={refreshMarketIndicators}
                    />
                )}
            </div>
            <div data-testid="MarketIndicatorsManagementTable" className={styles.markets_management_common_table}>
                <div className={styles.markets_management_common_table_header_row}>
                    <div className={styles.markets_management_common_table_header_large}>{translations.marketIndicatorsManagement.headers.commodityName}</div>
                    <div>{translations.marketIndicatorsManagement.headers.dateCreated}</div>
                    <div>{translations.marketIndicatorsManagement.headers.lastUpdated}</div>
                    <div>{translations.marketIndicatorsManagement.headers.status}</div>
                    <div>{translations.marketIndicatorsManagement.headers.reviewDate}</div>
                    <div>{translations.marketIndicatorsManagement.headers.reviewStatus}</div>
                    <div>{translations.marketIndicatorsManagement.headers.reviewExpert}</div>
                    <div className={styles.markets_management_common_table_header_large}>{translations.marketIndicatorsManagement.headers.actions}</div>
                </div>
                {listMarketIndicatorsResponse.rows &&
                    listMarketIndicatorsResponse.rows.map((marketIndicator) => (
                        <div key={`row_${marketIndicator.marketIndicatorGuid}`} className={styles.markets_management_common_table_row_group}>
                            <div className={styles.markets_management_common_table_row}>
                                <div className={styles.markets_management_common_table_cell_large}>
                                    <Link
                                        className={styles.markets_management_common_table_link}
                                        to={`${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicator.marketIndicatorGuid}/edit`}
                                    >
                                        {translate(marketIndicator.displayName!)}
                                    </Link>
                                </div>
                                <div className={styles.markets_management_common_table_cell}>
                                    {formattingService.toShortDayMonthYear(marketIndicator.modifiedAt ?? '')}
                                </div>
                                <div className={styles.markets_management_common_table_cell}>
                                    {formattingService.toShortDayMonthYear(marketIndicator.createdAt ?? '')}
                                </div>
                                <div className={styles.markets_management_common_table_cell}>
                                    <span className={marketIndicator.isPublished ? styles.markets_management_active : styles.markets_management_inactive}>
                                        {marketIndicator.isPublished ? translations.words.active : translations.words.inactive}
                                    </span>
                                </div>

                                <div className={styles.markets_management_common_table_cell}>
                                    {formattingService.toShortDayMonthYear(marketIndicator.reviewedAt ?? '')}
                                </div>
                                <div className={styles.markets_management_common_table_cell_centered}>{displayReviewStatus(marketIndicator)}</div>
                                <div className={styles.markets_management_common_table_cell}>{marketIndicator.reviewedBy}</div>
                                <div className={styles.markets_management_common_table_actions_container}>
                                    <MarketIndicatorActions
                                        marketIndicator={marketIndicator}
                                        symbols={symbols}
                                        symbolDropdownOptions={symbolDropdownOptions}
                                        handleUpdateMarketIndicator={refreshMarketIndicators}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className={styles.markets_management_common_table_footer}>
                <Badge text={translations.marketIndicatorsManagement.reviewStatus.approved} icon="approved" />
                <Badge text={translations.marketIndicatorsManagement.reviewStatus.rejected} icon="rejected" />
                <Badge text={translations.marketIndicatorsManagement.reviewStatus.expireSoon} icon="expireSoon" />
                <Badge text={translations.marketIndicatorsManagement.reviewStatus.expired} icon="expired" />
            </div>
        </div>
    );
};

export default MarketIndicatorsMangementPage;
