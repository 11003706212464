import { useMemo } from 'react';
import { demeterCompanyApi } from '../../../../Apis/Apis';
import { DemeterUserType } from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserType } from '../../../../Redux/Slices/UserSlice';
import useCacheThenApi from '../../../Apis/Hooks/useCacheThenApiHook';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './CompaniesPage.module.scss';

interface ICompanySubscriptionDetailsProps {
    companyGuid: string | null;
}

const CompanyDetails: React.FC<ICompanySubscriptionDetailsProps> = (props: ICompanySubscriptionDetailsProps) => {
    const [translations] = useLanguage();
    const currentUserType = useApplicationSelector(selectUserType);
    const isPremiumUser = useMemo(() => currentUserType === DemeterUserType.Premium, [currentUserType]);
    const [, , demeterCompanyResponse] = useCacheThenApi(CacheKeys.GetDemeterCompany, () => {
        if (!props?.companyGuid) {
            return null;
        }

        return demeterCompanyApi.getDemeterCompany(props.companyGuid);
    });

    if (!demeterCompanyResponse?.demeterCompany) {
        return null;
    }

    return (
        <div className={styles.company_details_container}>
            <div className={styles.company_details_item}>
                <div className={styles.company_details_label}>{translations.companies.fields.invoiceDate}</div>
                <div className={styles.company_details_value}>{formattingService.toShortDayMonthYear(demeterCompanyResponse.demeterCompany.invoiceDate)}</div>
            </div>
            <div className={styles.company_details_item}>
                <div className={styles.company_details_label}>{translations.companies.fields.subscriptionFrequency}</div>
                <div className={styles.company_details_value}>
                    {demeterCompanyResponse.demeterCompany.subscriptionFrequency
                        ? translations.subscriptionFrequency[demeterCompanyResponse.demeterCompany.subscriptionFrequency]
                        : null}
                </div>
            </div>
            <div className={styles.company_details_item}>
                <div className={styles.company_details_label}>{translations.companies.fields.subscriptionPrice}</div>
                <div className={styles.company_details_value}>{demeterCompanyResponse.demeterCompany.subscriptionPrice}</div>
            </div>
            <div className={styles.company_details_item}>
                <div className={styles.company_details_label}>{translations.companies.fields.billingCurrency}</div>
                <div className={styles.company_details_value}>{demeterCompanyResponse.demeterCompany.billingCurrency}</div>
            </div>
            <div className={styles.company_details_item}>
                <div className={styles.company_details_label}>{translations.companies.fields.billingRegion}</div>
                <div className={styles.company_details_value}>
                    {demeterCompanyResponse.demeterCompany.billingRegion ? translations.region[demeterCompanyResponse.demeterCompany.billingRegion] : null}
                </div>
            </div>
            <span className={styles.company_details_licenses}>
                <div className={styles.company_details_item}>
                    <div className={styles.company_details_label}>{translations.words.licenses}:</div>
                </div>
                {demeterCompanyResponse.demeterCompany?.marketLicenses &&
                    demeterCompanyResponse.demeterCompany.marketLicenses
                        .filter((x) => (isPremiumUser && x.numberOfLicenses > 0) || !isPremiumUser)
                        .map((market) => (
                            <div key={market.market} className={styles.company_details_item}>
                                <div className={styles.company_details_label}>{translations.market[market.market]}</div>
                                <div className={styles.company_details_value}>{market.numberOfLicenses}</div>
                            </div>
                        ))}
            </span>
        </div>
    );
};

export default CompanyDetails;
