import React, { useEffect, useMemo, useState } from 'react';
import { CommodityWeeklyPriceSummaryModel, DemeterRegion } from '../../../../Generated/Raven-Demeter/api';
import useWeeklyPricesSummaryApi from '../../../Apis/Hooks/useWeeklyPricesSummaryApiHook';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styleService from '../../../Services/Styles/StyleService';
import PricesSummaryTableWrapper from '../PricesSummaryTableWrapper';
import styles from '../PriceSummaries.module.scss';

const EuropeanUnionWeeklySummarySmall: React.FC = () => {
    const summaryData = useWeeklyPricesSummaryApi(DemeterRegion.EuropeanUnion);
    const [translations] = useLanguage();

    const columnHeaders = useMemo(
        () => [translations.words.product, translations.words.price, translations.words.change, translations.fields.percentChange],
        [translations],
    );
    const title = useMemo(
        () =>
            `${translations.dashboard.priceSummary.europeanUnion.headers.quotationsSummary}  ${formattingService.toShortDayMonthYear(summaryData?.asOfDate)}` ??
            '',
        [summaryData, translations],
    );
    const subtitle = useMemo(
        () => (summaryData?.rows ? `${summaryData.rows[0].currency} / ${translations.unitOfMeasure[summaryData.rows[0].unitOfMeasure!]}` : ''),
        [summaryData, translations],
    );

    const [summaryDataFiltered, setSummaryDataFiltered] = useState<CommodityWeeklyPriceSummaryModel[]>([]);

    useEffect(() => {
        if (!summaryData?.rows) {
            return;
        }
        setSummaryDataFiltered(summaryData.rows.filter((row) => row.subRegion === null));
    }, [summaryData]);

    return (
        <>
            <ComponentHeader title={title} subtitle={subtitle} />
            <PricesSummaryTableWrapper
                className={styles.price_summary_table_european_union_quotations_small}
                loading={!summaryData?.rows || summaryData.rows.length === 0}
                columnHeaders={columnHeaders}
            >
                <>
                    {summaryDataFiltered.map((row: CommodityWeeklyPriceSummaryModel) => {
                        const { currentWeekPrice, commodity } = row;
                        const changeClass = styleService.getPositiveOrNegativeClass(currentWeekPrice.netChange!);
                        return (
                            <>
                                <p>{translations.commodity[commodity]}</p>
                                <p>{formattingService.toPriceString(currentWeekPrice.value)}</p>
                                <p className={changeClass}>{formattingService.toPriceString(row.currentWeekPrice.netChange)}</p>
                                <p className={changeClass}>{formattingService.toPercent(currentWeekPrice.percentChange)}</p>
                            </>
                        );
                    })}
                </>
            </PricesSummaryTableWrapper>
        </>
    );
};

export default EuropeanUnionWeeklySummarySmall;
