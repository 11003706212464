import React from 'react';
import InputContainer from './InputContainer';
import styles from './Inputs.module.scss';

interface ITextAreaProps {
    className?: string;
    title?: string;
    placeholder?: string;
    value?: string | null;
    disabled?: boolean;
    error?: string;
    handleTextChange: (value: string) => void;
}

const TextArea: React.FC<ITextAreaProps> = (props: ITextAreaProps) => (
    <>
        <InputContainer title={props.title}>
            <textarea
                className={props.className}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value ?? ''}
                onChange={(event) => {
                    props.handleTextChange(event.target.value ?? '');
                }}
            />
        </InputContainer>
        {props.error && <div className={styles.input_error_message}>{props.error}</div>}
    </>
);

export default TextArea;
