// https://github.com/1904labs/dom-to-image-more/pull/176 issue thread discussing types and module in declares.d.ts
// eslint-disable-next-line import/no-extraneous-dependencies
import { Buffer } from 'buffer';
import domToImage from 'dom-to-image-more';
import { useEffect, useMemo } from 'react';
import { demeterCalculatorsApi } from '../../../../Apis/Apis';
import utilitySvg from '../../../../Components/Assets/utility.svg';
import ContentType from '../../../../Core/Common/ContentTypes';
import { PriceType } from '../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import IconMenuDropdown from '../../../Components/Form/Inputs/IconMenuDropdown';
import browserService from '../../../Services/BrowserService';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValueMatrixCalculator.module.scss';
import { defaultNumberOfYearsToLookBackGeneral, ValueMatrixTableRequest } from './ValueMatrixDefinitions';

type ValueMatrixDownloadType = 'jpeg' | 'pdf' | 'svg' | 'png' | 'xlsx';

const padding = 32; // Prevent element screenshot from cropping table.

interface IValueMatrixDownloadProps {
    resultsReference: React.RefObject<HTMLElement>;
    valueMatrixTableInputs: ValueMatrixTableRequest;
    priceType: PriceType;
    contractNumberStart: number;
    contractNumberEnd?: number | null | undefined;
    useWeightedCalculation: boolean;
}

const ValueMatrixDownload: React.FC<IValueMatrixDownloadProps> = (props) => {
    const [translations] = useLanguage();

    const [, downloadValueMatrixCalculator, downloadValueMatrixCalculatorResponse] = useApiWithoutAutoExecute(() =>
        demeterCalculatorsApi.downloadValueMatrixCalculator(props.valueMatrixTableInputs.product.region, props.valueMatrixTableInputs.product.commodity, {
            region: props.valueMatrixTableInputs.product.region,
            commodity: props.valueMatrixTableInputs.product.commodity,
            subRegion: undefined,
            dataSource: props.valueMatrixTableInputs.product.pricesDataSource,
            stripType: props.valueMatrixTableInputs.stripType,
            priceType: props.priceType,
            contractNumberStart: props.contractNumberStart,
            contractNumberEnd: props.contractNumberEnd,
            useProducerPriceIndex: props.valueMatrixTableInputs.applyProducerPriceIndex,
            useWeightedCalculation: props.useWeightedCalculation,
            innerYears: props.valueMatrixTableInputs.weighted?.innerRange,
            outerYears: props.valueMatrixTableInputs.weighted?.outerRange ?? defaultNumberOfYearsToLookBackGeneral,
            innerWeightPercent: props.valueMatrixTableInputs.weighted?.innerWeight,
            outerWeightPercent: props.valueMatrixTableInputs.weighted?.outerWeight,
            termClassifications: props.valueMatrixTableInputs.termClassification.map((x) => x.contractGroupName),
            monthSets: [],
            currentValuesPerMonthSet: [],
            asOfDate: formattingService.toApiDate(props.valueMatrixTableInputs.asOfDate!),
        }),
    );

    const downloadOptions = useMemo<{ label: string; value: ValueMatrixDownloadType }[]>(
        () => [
            {
                label: translations.dropdown.downloads.jpeg,
                value: 'jpeg',
            },
            {
                label: translations.dropdown.downloads.png,
                value: 'png',
            },
            // {
            //     label: translations.dropdown.downloads.xlsx,
            //     value: 'xlsx',
            // },
        ],
        [translations],
    );

    useEffect(() => {
        if (!downloadValueMatrixCalculatorResponse) {
            return;
        }
        const byteArray = Buffer.from(downloadValueMatrixCalculatorResponse.fileContent ?? '', 'base64');
        const blob = new Blob([byteArray], {
            type: downloadValueMatrixCalculatorResponse.contentType || ContentType.Excel,
        });
        browserService.downloadBlob(blob, 'ValueMatrix.xlsx');
    }, [downloadValueMatrixCalculatorResponse]);

    const screenshotDefaultOptions = (element: HTMLElement) => ({
        width: element.getBoundingClientRect().width + padding,
        height: element.getBoundingClientRect().height,
    });

    const handleExport = (type: ValueMatrixDownloadType) => {
        if (props.resultsReference === null || props.resultsReference.current === null) {
            return;
        }

        switch (type) {
            case 'jpeg':
                domToImage
                    .toBlob(props.resultsReference.current, screenshotDefaultOptions(props.resultsReference.current))
                    .then((blob: Blob) => browserService.downloadBlob(blob, 'ValueMatrix.jpeg'));
                break;
            case 'png':
                domToImage
                    .toBlob(props.resultsReference.current, screenshotDefaultOptions(props.resultsReference.current))
                    .then((blob: Blob) => browserService.downloadBlob(blob, 'ValueMatrix.png'));
                break;
            case 'xlsx':
                downloadValueMatrixCalculator();
                break;
            default:
                break;
        }
    };

    return (
        <IconMenuDropdown
            iconButton={<img className={styles.value_matrix_download_image} src={utilitySvg} alt="#" />}
            options={downloadOptions}
            handleSelection={(value: ValueMatrixDownloadType) => handleExport(value)}
            buttonClassName={styles.value_matrix_download_image_button}
            tooltip={translations.actions.clickToDownload}
        />
    );
};

export default ValueMatrixDownload;
