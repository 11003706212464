import { useEffect, useMemo } from 'react';
import { DemeterMarket } from '../../../../../Generated/Raven-Demeter';
import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import LabelWithTooltip from '../../../../Components/Form/Inputs/LabelWithTooltip';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from '../ValueMatrixCalculator.module.scss';
import { ValueMatrixStripType, ValueMatrixTableRequest } from '../ValueMatrixDefinitions';

interface IValueMatrixStripTypeDropdownProps {
    valueMatrixInputs: ValueMatrixTableRequest;
    setValueMatrixInputs: (value: ValueMatrixTableRequest) => void;
}

const minimumNumberOfContractMonthsForTwelveMonthStrip = 2;

const ValueMatrixStripTypeDropdown: React.FC<IValueMatrixStripTypeDropdownProps> = (props: IValueMatrixStripTypeDropdownProps) => {
    const [translations] = useLanguage();

    const stripTypeOptions = useMemo(() => {
        if (!props.valueMatrixInputs.product) {
            return [];
        }

        const options = [
            {
                label: translations.calculators.valueMatrix.text.contract1,
                value: 'firstContractMonth' as ValueMatrixStripType,
            },
            {
                label: translations.calculators.valueMatrix.text.contract2,
                value: 'secondContractMonth' as ValueMatrixStripType,
            },
        ];

        if (props.valueMatrixInputs.market === DemeterMarket.Energy) {
            options.push({
                label: translations.calculators.valueMatrix.text.forwardContract,
                value: 'forwardContract' as ValueMatrixStripType,
            });
        }

        if ((props.valueMatrixInputs.product.contractMonths ?? []).length >= minimumNumberOfContractMonthsForTwelveMonthStrip) {
            options.push({
                label: translations.calculators.valueMatrix.text.twelveMonthStrip,
                value: 'twelveMonthStrip' as ValueMatrixStripType,
            });
        }

        // Per discussions, for now this will only be availbale for dairy markets.
        if (props.valueMatrixInputs.market === DemeterMarket.Dairy) {
            options.unshift({
                label: translations.words.spot,
                value: 'spot' as ValueMatrixStripType,
            });
        }

        return options;
    }, [translations, props.valueMatrixInputs?.product]);

    // If we change to a product that doesn't have enough contracts for the currently selected strip type, we change it.
    useEffect(() => {
        if (stripTypeOptions.length === 0 || stripTypeOptions.map((x) => x.value).includes(props.valueMatrixInputs.stripType)) {
            if (props.valueMatrixInputs?.isCascadeUpdating) {
                props.setValueMatrixInputs({ ...props.valueMatrixInputs, isCascadeUpdating: false });
            }

            return;
        }

        props.setValueMatrixInputs({ ...props.valueMatrixInputs, stripType: stripTypeOptions[0].value, isCascadeUpdating: false });
    }, [stripTypeOptions]);

    return (
        <div className={styles.value_matrix_dropdown}>
            <Dropdown
                testId="ValueMatrixStripTypeDropdown"
                value={props.valueMatrixInputs.stripType}
                options={stripTypeOptions}
                handleOptionChange={(value) => {
                    props.setValueMatrixInputs({
                        ...props.valueMatrixInputs,
                        stripType: value as ValueMatrixStripType,
                        updateTriggeredBy: 'stripTypeSelection',
                    });
                }}
                label={
                    <LabelWithTooltip
                        title={translations.calculators.valueMatrix.fields.stripType}
                        tooltip={
                            <>
                                <p>{translations.calculators.valueMatrix.text.stripTypeTooltipInformationFirstContract}</p>
                                <p>{translations.calculators.valueMatrix.text.stripTypeTooltipInformationSecondContract}</p>
                                <p>{translations.calculators.valueMatrix.text.stripTypeTooltipInformationForwardContract}</p>
                                <p>{translations.calculators.valueMatrix.text.stripTypeTooltipInformationTwelveMonth}</p>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default ValueMatrixStripTypeDropdown;
