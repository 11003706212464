import React from 'react';
import styles from './LinkButton.module.scss';

interface ITextButtonProps {
    title: string;
    onClick?: () => void;
    testId?: string;
}

const TextButton: React.FC<ITextButtonProps> = (props: ITextButtonProps) => (
    <span className={styles.text_button} onClick={props.onClick} data-testid={props.testId} role="button" onKeyPress={() => {}} tabIndex={0}>
        {props.title}
    </span>
);

export default TextButton;
