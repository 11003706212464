import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import useLanguage from '../../../Services/Language/useLanguageHook';
import SwitchWithLabel from '../../Form/Inputs/SwitchWithLabel';
import TextInput from '../../Form/Inputs/TextInput';
import { ITreeNode } from '../../TreeView/TreeItem';
import TreeView from '../../TreeView/TreeView';
import styles from './ProductsModal.module.scss';
import productsModalService from './ProductsModalService';

interface ISelectProduct {
    title: string;
    productTreeNodes: ITreeNode[];
    userProductTreeNodes: ITreeNode[] | undefined;
    expandedDepth: number;
    setProduct: (product: ITreeNode) => void;
    productPairProductId: string | undefined;
    enableUserData: boolean;
}

const SelectProduct: React.FC<ISelectProduct> = (props) => {
    const [translations] = useLanguage();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [useMyData, setUseMyData] = useState(false);
    const [treeNodes, setTreeNodes] = useState<ITreeNode[]>([]);

    const handleSelected = (event: React.MouseEvent<HTMLElement>) => {
        const productNodes = useMyData ? props.userProductTreeNodes : props.productTreeNodes;
        if (!productNodes?.length) {
            return;
        }

        const product = productNodes.find((x) => x.id === event.currentTarget.id);
        if (!product) {
            return;
        }

        const rawNodes = productNodes.map((node) => {
            if (product.id === node.id) {
                const newNode = { ...node };
                newNode.selectedProduct = true;
                return newNode;
            }

            return node;
        });

        setTreeNodes(useMyData ? rawNodes : productsModalService.buildTree(rawNodes, props.expandedDepth).children);
        props.setProduct(product);
    };

    const handleSearch = (search: string) => {
        setSearchTerm(search);
    };

    const handleSwitchChange = (value: boolean) => {
        setUseMyData(value);
    };

    useEffect(() => {
        const productNodes = useMyData ? props.userProductTreeNodes : props.productTreeNodes;
        if (!productNodes?.length) {
            setTreeNodes([]);
            return;
        }

        const rawNodes = productNodes.map((node) => {
            if (props.productPairProductId === node.id) {
                const newNode = { ...node };
                newNode.selectedProduct = true;
                return newNode;
            }

            return node;
        });

        setTreeNodes(useMyData ? rawNodes : productsModalService.buildTree(rawNodes, props.expandedDepth).children);
    }, [props.productTreeNodes, props.userProductTreeNodes, useMyData]);

    return (
        <div className={styles.products_modal_product_column}>
            <div className={styles.products_modal_product_selection_column_subheader}>
                <div className={styles.products_modal_product_column_subheader_text}>{props.title}</div>
                {props.enableUserData && (
                    <div>
                        <SwitchWithLabel
                            checked={useMyData}
                            onChange={handleSwitchChange}
                            label={translations.calculators.basis.productSelector.tabs.productsTab.useMyData}
                        />
                    </div>
                )}
            </div>
            <TextInput
                value={searchTerm}
                placeholder={translations.calculators.basis.productSelector.tabs.productsTab.search}
                handleTextChange={handleSearch}
            />
            <div className={styles.products_modal_tree_container}>
                {treeNodes && (
                    <TreeView
                        data={treeNodes}
                        searchTerm={searchTerm}
                        handleSelect={handleSelected}
                        noData={
                            useMyData ? (
                                <Link className={styles.basis_calculator_link} to={NavigationRoutes.UserData}>
                                    {translations.calculators.basis.productSelector.actions.uploadMyData}
                                </Link>
                            ) : (
                                <div>{translations.calculators.basis.productSelector.tabs.productsTab.noData}</div>
                            )
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default SelectProduct;
