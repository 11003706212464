import React, { useEffect, useMemo } from 'react';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import scssVariables from '../../../../../Config.module.scss';
import {
    Currency,
    GetMarketIndicatorFactorDataResponse,
    GetMarketIndicatorFactorResponse,
    GetMarketIndicatorResponse,
    GetTestMarketIndicatorFactorDataRequest,
    MarketIndicatorFactorModel,
    RunMarketIndicatorFactorResponse,
    UnitOfMeasure,
} from '../../../../../Generated/Raven-Demeter';
import useMultipleApis from '../../../../Apis/Hooks/useMultipleApisHook';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { IChartData, IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { defaultChartPalette, IMarketIndicatorChartColorPalette, IMarketIndicatorUserChartBaseProps } from '../MarketIndicatorUserChartDefinitions';
import MarketIndicatorUserChartRaw from '../MarketIndicatorUserChartRaw';

export const combinationChartPalette: IMarketIndicatorChartColorPalette = {
    lineChartColorsRuleSetTwo: [scssVariables.ruleSetTwoLineColor1, scssVariables.ruleSetTwoLineColor2, scssVariables.ruleSetTwoLineColor3],
};

export interface IMarketIndicatorCombinationChartProps extends IMarketIndicatorUserChartBaseProps {
    runMarketIndicatorResponse?: GetMarketIndicatorResponse;
    getMarketIndicatorSubFactorResponses?: GetMarketIndicatorFactorResponse[];
}

const MarketIndicatorCombinationChart: React.FC<IMarketIndicatorCombinationChartProps> = (props: IMarketIndicatorCombinationChartProps) => {
    const [translations, translate] = useLanguage();
    const getMarketSubFactorDataRows = (props.getMarketIndicatorSubFactorResponses as GetMarketIndicatorFactorDataResponse[])?.every((x) => x.rows);

    const [, refreshRunTestMarketIndicatorFactor, runTestMarketIndicatorFactorResponse] = useMultipleApis(
        () => {
            if (!props.getMarketIndicatorSubFactorResponses || getMarketSubFactorDataRows) {
                return null;
            }

            return props.getMarketIndicatorSubFactorResponses?.map((x) => {
                const {
                    leadingIndicatorType,
                    region,
                    subRegion,
                    commodity,
                    extraParameters,
                    dataFrequency,
                    templateType,
                    parameters,
                    correlation,
                    dataCombinationType,
                    secondaryExtraParameters,
                    secondaryRegion,
                    secondarySubRegion,
                    secondaryCommodity,
                    secondaryDataFrequency,
                    secondaryLeadingIndicatorType,
                } = x.marketIndicatorFactor ?? (x as MarketIndicatorFactorModel);

                return demeterMarketIndicatorsApi.runTestMarketIndicatorFactor({
                    leadingIndicatorType: leadingIndicatorType!,
                    region,
                    subRegion,
                    commodity,
                    extraParameters,
                    dataFrequency,
                    templateType: templateType!,
                    parameters: parameters ?? { yearsOfData: props.runMarketIndicatorResponse?.marketIndicator?.numberOfYears },
                    correlation,
                    numberOfYears: props.runMarketIndicatorResponse?.marketIndicator?.numberOfYears!,
                    asOfDate: props.runMarketIndicatorResponse?.marketIndicator?.asOfDate!,
                    dataCombinationType,
                    secondaryExtraParameters,
                    secondaryRegion,
                    secondarySubRegion,
                    secondaryCommodity,
                    secondaryDataFrequency,
                    secondaryLeadingIndicatorType,
                });
            });
        },
        { errorMessage: translations.marketIndicators.multipleFactorsErrorMessage },
    );

    const [, refreshGetTestMarketIndicatorFactor, getTestMarketIndicatorFactorResponse] = useMultipleApis(
        () => {
            if (!props.getMarketIndicatorSubFactorResponses || getMarketSubFactorDataRows) {
                return [];
            }

            return props.getMarketIndicatorSubFactorResponses.map((x) =>
                demeterMarketIndicatorsApi.getTestMarketIndicatorFactorData(x as GetTestMarketIndicatorFactorDataRequest),
            );
        },
        { errorMessage: translations.marketIndicators.multipleFactorsErrorMessage },
    );

    useEffect(() => {
        refreshGetTestMarketIndicatorFactor();
    }, [props.getMarketIndicatorSubFactorResponses]);

    useEffect(() => {
        if (props.getMarketIndicatorSubFactorResponses?.length === 0) {
            return;
        }

        refreshRunTestMarketIndicatorFactor();
    }, [props.getMarketIndicatorSubFactorResponses, props.runMarketIndicatorResponse?.marketIndicator]);

    const unitOfMeasures = useMemo(() => {
        if (getTestMarketIndicatorFactorResponse && getTestMarketIndicatorFactorResponse.length > 0) {
            return getTestMarketIndicatorFactorResponse.map((x) => x.unitOfMeasure ?? ('' as UnitOfMeasure));
        }

        if (props.getMarketIndicatorSubFactorResponses) {
            return (props.getMarketIndicatorSubFactorResponses as GetMarketIndicatorFactorDataResponse[]).map((x) => x.unitOfMeasure ?? ('' as UnitOfMeasure));
        }

        return [] as UnitOfMeasure[];
    }, [props.getMarketIndicatorSubFactorResponses, getTestMarketIndicatorFactorResponse]);

    const currencies = useMemo(() => {
        if (getTestMarketIndicatorFactorResponse && getTestMarketIndicatorFactorResponse.length > 0) {
            return getTestMarketIndicatorFactorResponse.map((x) => x.currency ?? ('' as Currency));
        }

        if (props.getMarketIndicatorSubFactorResponses) {
            return (props.getMarketIndicatorSubFactorResponses as GetMarketIndicatorFactorDataResponse[]).map((x) => x.currency ?? ('' as Currency));
        }

        return [] as Currency[];
    }, [props.getMarketIndicatorSubFactorResponses, getTestMarketIndicatorFactorResponse]);

    const lineSeries = useMemo(() => {
        if (!runTestMarketIndicatorFactorResponse && (!props.getMarketIndicatorSubFactorResponses || !getMarketSubFactorDataRows)) {
            return [];
        }

        const newLinesSeries: IChartDataSeries[] = [];

        (runTestMarketIndicatorFactorResponse ?? props.getMarketIndicatorSubFactorResponses!).forEach((x, index) => {
            const lineSeriesData: IChartData[] = [];

            (x as RunMarketIndicatorFactorResponse).rows
                ?.filter((row) => row.value)
                .forEach((row) => {
                    lineSeriesData.push({
                        value: row.value!,
                        asOfDate: new Date(row.asOfDate),
                        isActualValue: true,
                    });
                });

            const currentSubFactorResponse = props.getMarketIndicatorSubFactorResponses![index];
            const currentSubFactorDisplayName =
                (currentSubFactorResponse as MarketIndicatorFactorModel)?.displayName ??
                (currentSubFactorResponse as GetMarketIndicatorFactorDataResponse)?.factorDisplayName ??
                '';

            newLinesSeries.push({
                yAxis: index,
                label: `${translate(currentSubFactorDisplayName)}`,
                data: lineSeriesData,
            });
        });

        return newLinesSeries;
    }, [
        runTestMarketIndicatorFactorResponse,
        props.getMarketIndicatorSubFactorResponses,
        props.runTestMarketIndicatorFactorResponse,
        unitOfMeasures,
        currencies,
    ]);

    const dataSourceTag = useMemo(() => {
        if (
            props.runMarketIndicatorResponse?.marketIndicator &&
            props.runMarketIndicatorResponse?.marketIndicator.dataSource &&
            props.runMarketIndicatorResponse?.marketIndicator.dataSource.length > 0
        ) {
            return `${props.runMarketIndicatorResponse?.marketIndicator.dataSource[0]}, ${translations.dataSource.StoneXCalculations}`;
        }
        return translations.dataSource.StoneXCalculations;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const titleString = useMemo(() => translate(props.title ?? ''), [props.title]);

    const isLoading = props.isLoading || lineSeries.length === 0;

    return (
        <ChartWrapper
            name="MarketIndicatorCombinationChart"
            title={titleString}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
            dataSourceTag={dataSourceTag}
            isLoading={isLoading}
        >
            <MarketIndicatorUserChartRaw
                lineSeries={lineSeries}
                unitOfMeasures={unitOfMeasures}
                currencies={currencies}
                colorPalette={defaultChartPalette}
                hideAreaRangeSeriesInLegend
            />
        </ChartWrapper>
    );
};

export default MarketIndicatorCombinationChart;
