import React, { Children, ReactNode, useEffect, useState } from 'react';
import useDynamicDimensions from '../../../Core/Hooks/useDynamicDimensionsHook';
import { ChartDisplayType } from '../ChartDefinitions';
import ChartInnerWrapper from './ChartInnerWrapper';
import ChartModalWrapper from './ChartModalWrapper';
import styles from './ChartWrapper.module.scss';

export interface IChartHeaderOptions {
    hideTitle?: boolean;
    hidePopoutModal?: boolean;
    showOnlyAsPopout?: boolean;
    hideDownload?: boolean;
    isCsvDownloadAvailable?: boolean;
    chartDisplayTypes?: ChartDisplayType[];
    handleChartDisplayTypeChange?: (displayType: ChartDisplayType) => void;
}

export interface IChartWrapperProps {
    children: JSX.Element;
    name: string; // Internal name of the component.
    title?: string; // Title of the chart.
    subtitle?: string; // Sub-title of chart.
    dataSourceTag: string | string[];
    isLoading: boolean; // Set to true if we are loading the chart.
    showPopout?: boolean;
    setShowPopout?: (showPopout: boolean) => void;
    headerOptions?: IChartHeaderOptions;
    header?: ReactNode;
    subHeader?: ReactNode;
    rightSide?: ReactNode;
    footer?: ReactNode;
    testId?: string;
}

export interface ChartDimensions {
    width: number;
    height: number;
}

const ChartWrapper: React.FC<IChartWrapperProps> = (props: IChartWrapperProps) => {
    const [chartContainerReference, chartContainerDimensions] = useDynamicDimensions();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(props.showPopout ?? false);
    }, [props.showPopout]);

    useEffect(() => {
        if (!props.setShowPopout || showModal === props.showPopout) {
            return;
        }

        props.setShowPopout(showModal);
    }, [showModal]);

    if (props.headerOptions?.showOnlyAsPopout && !showModal) {
        return null;
    }

    return (
        <div data-testid={props.testId} ref={chartContainerReference} className={styles.chart_wrapper}>
            <ChartModalWrapper
                name={props.name}
                showModal={showModal}
                setShowModal={(show: boolean) => {
                    setShowModal(show);
                }}
            >
                <ChartInnerWrapper
                    {...props}
                    chartContainerDimensions={chartContainerDimensions}
                    setShowModal={(show: boolean) => {
                        setShowModal(show);
                    }}
                >
                    {Children.only(props.children)}
                </ChartInnerWrapper>
            </ChartModalWrapper>
        </div>
    );
};

export default ChartWrapper;
