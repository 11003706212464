import { demeterApi } from '../../../Apis/Apis';
import { DemeterSymbolModel } from '../../../Generated/Raven-Demeter';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheOrApi from './useCacheOrApiHook';

const useSymbolsApi = (): DemeterSymbolModel[] | undefined => {
    const [, , listDemeterSymbolsResponse] = useCacheOrApi(CacheKeys.ListDemeterSymbols, () => demeterApi.listDemeterSymbols());

    return listDemeterSymbolsResponse?.rows ?? undefined;
};

export default useSymbolsApi;
