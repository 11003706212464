import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React, { useMemo } from 'react';
import { createSearchParams, Link } from 'react-router-dom';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import { MarketIndicatorModel, MarketIndicatorOutlook } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import bearishSvg from '../../Assets/Icons/HedgeMonitorPro/bearishCard.svg';
import bearishMiniatureSvg from '../../Assets/Icons/HedgeMonitorPro/bearishCardMiniature.svg';
import bullishSvg from '../../Assets/Icons/HedgeMonitorPro/bullishCard.svg';
import bullishMiniatureSvg from '../../Assets/Icons/HedgeMonitorPro/bullishCardMiniature.svg';
import BrandCard from '../../Components/BrandCard/BrandCard';
import chartService from '../../Components/Charts/ChartService';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './HedgeMonitorProPage.module.scss';

interface IHedgeMonitorProSummaryProps {
    marketIndicator?: MarketIndicatorModel;
    useNavigationCard?: boolean;
}

const HedgeMonitorProSummary: React.FC<IHedgeMonitorProSummaryProps> = (props: IHedgeMonitorProSummaryProps) => {
    // Application hooks and application constants.
    const [translations, translate] = useLanguage();
    const [setSearchParameters] = useSearchParameters();

    // Text hooks.
    const titleString = useMemo(() => translate(props.marketIndicator?.displayName ?? ''), [translations, props.marketIndicator]);
    const brandingTitleString = useMemo(() => translations.hedgeMonitorPro.brandingTitleString, [translations]);
    const hedgeMonitorProOutlookString = useMemo(() => {
        if (!props.marketIndicator?.hedgeMonitorProOutlook) {
            return '';
        }

        return translations.marketIndicatorOutlook[props.marketIndicator.hedgeMonitorProOutlook as MarketIndicatorOutlook];
    }, [translations, props.marketIndicator]);

    const precipiceOutlookString = useMemo(() => {
        if (!props.marketIndicator?.precipiceOutlook) {
            return '';
        }

        return translations.marketIndicatorOutlook[props.marketIndicator.precipiceOutlook as MarketIndicatorOutlook];
    }, [translations, props.marketIndicator]);

    const marketIndicatorsOutlookString = useMemo(() => {
        if (!props.marketIndicator?.outlook) {
            return '';
        }

        return translations.marketIndicatorOutlook[props.marketIndicator.outlook as MarketIndicatorOutlook];
    }, [translations, props.marketIndicator]);

    // Page hooks.
    const precipiceOutlookColor = useMemo(() => chartService.getOutlookColor(props.marketIndicator?.precipiceOutlook!), [props.marketIndicator]);
    const marketIndicatorOutlookColor = useMemo(() => chartService.getOutlookColor(props.marketIndicator?.outlook!), [props.marketIndicator]);

    const gradientLineStyle = useMemo(() => {
        if (!props.marketIndicator?.hedgeMonitorProOutlook) {
            return null;
        }

        const hedgeMonitorProDecileColor = chartService.getOutlookColor(props.marketIndicator?.hedgeMonitorProOutlook!);

        return `linear-gradient(to right, white, ${hedgeMonitorProDecileColor}, white) 1`;
    }, [props.marketIndicator]);

    const bullishBearishImageSource = useMemo(() => {
        if (props.useNavigationCard) {
            if (props.marketIndicator?.hedgeMonitorProOutlook === MarketIndicatorOutlook.Bearish) {
                return bearishMiniatureSvg;
            }

            if (props.marketIndicator?.hedgeMonitorProOutlook === MarketIndicatorOutlook.Bullish) {
                return bullishMiniatureSvg;
            }
        }

        if (props.marketIndicator?.hedgeMonitorProOutlook === MarketIndicatorOutlook.Bearish) {
            return bearishSvg;
        }

        if (props.marketIndicator?.hedgeMonitorProOutlook === MarketIndicatorOutlook.Bullish) {
            return bullishSvg;
        }

        return '';
    }, [props.marketIndicator?.hedgeMonitorProOutlook]);

    const loading = !props.marketIndicator || !gradientLineStyle;

    return loading ? (
        <LoadingSpinner />
    ) : (
        <BrandCard
            brandingTitleString={brandingTitleString}
            title={titleString}
            navigateTo={{
                pathName: NavigationRoutes.HedgeMonitorPro,
                search: createSearchParams({ marketIndicatorGuid: props.marketIndicator!.marketIndicatorGuid }).toString(),
            }}
            useNavigationCard={props.useNavigationCard}
        >
            <>
                <div className={styles.hedge_monitor_pro_summary_outlook} style={{ borderImage: gradientLineStyle }}>
                    {bullishBearishImageSource && <img src={bullishBearishImageSource} alt="#" />}
                    <div className={styles.hedge_monitor_pro_summary_outlook_center_text}>{hedgeMonitorProOutlookString}</div>
                </div>
                {!props.useNavigationCard && (
                    <div className={styles.hedge_monitor_pro_halves}>
                        <div className={styles.hedge_monitor_pro_summary_outlook_wrapper}>
                            <div className={styles.hedge_monitor_pro_summary_outlook_box} style={{ backgroundColor: precipiceOutlookColor }}>
                                {precipiceOutlookString}
                            </div>
                            <div className={styles.hedge_monitor_pro_summary_outlook_text}>
                                {translations.text.signalFrom}{' '}
                                <a href={applicationSettings.links.precipiceUri!}>
                                    {translations.hedgeMonitorPro.text.precipiceMomentum} <OpenInNewIcon />
                                </a>
                            </div>
                        </div>
                        <div className={styles.hedge_monitor_pro_summary_outlook_wrapper}>
                            <div className={styles.hedge_monitor_pro_summary_outlook_box} style={{ backgroundColor: marketIndicatorOutlookColor }}>
                                {marketIndicatorsOutlookString}
                            </div>
                            <div className={styles.hedge_monitor_pro_summary_outlook_text}>
                                {translations.text.signalFrom}{' '}
                                <Link to={`${NavigationRoutes.MarketIndicators}?marketIndicatorGuid=${setSearchParameters.marketIndicatorGuid}`}>
                                    {translations.hedgeMonitorPro.text.marketIndicator}
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </BrandCard>
    );
};

export default HedgeMonitorProSummary;
