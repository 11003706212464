import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterCompanyApi } from '../../../../Apis/Apis';
import { DemeterUserType } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectCompanyGuid, selectUserType } from '../../../../Redux/Slices/UserSlice';
import useCacheThenApi from '../../../Apis/Hooks/useCacheThenApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import PageHeader from '../../../Components/Headers/PageHeader';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import Tabs from '../../../Components/Navigation/Tabs/Tabs';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import useLanguage from '../../../Services/Language/useLanguageHook';
import LicensesPage from '../LicenseManagement/LicensesPage';
import UsersPage from '../Users/UsersPage';
import styles from './CompaniesPage.module.scss';
import CompanyDetails from './CompanyDetails';

const CompanyUsersPage: React.FC = () => {
    const [translations] = useLanguage();
    const pageTitle = translations.companies.title.companyUsers;
    const routeParameters = useParams();
    const navigate = useNavigate();
    const [, setSearchParameters] = useSearchParameters();
    const currentUserType = useApplicationSelector(selectUserType);
    const companyGuidFromRoute = routeParameters.demeterCompanyGuid;
    const companyGuidFromRedux = useApplicationSelector(selectCompanyGuid);
    const isPremiumUser = useMemo(() => currentUserType === DemeterUserType.Premium, [currentUserType]);

    const selectedCompanyGuid = useMemo(() => {
        if (isPremiumUser && companyGuidFromRedux) {
            return companyGuidFromRedux;
        }

        if (companyGuidFromRoute) {
            return companyGuidFromRoute;
        }

        return null;
    }, [companyGuidFromRedux, companyGuidFromRoute]);

    const [, , getCompanyResponse] = useCacheThenApi(CacheKeys.GetDemeterCompany, () => {
        if (!selectedCompanyGuid) {
            return null;
        }

        return demeterCompanyApi.getDemeterCompany(selectedCompanyGuid);
    });

    const navigateBackToCompanies = () => {
        setSearchParameters({ tab: 'Companies' });
        navigate(NavigationRoutes.Administration);
    };

    const tabOptions = [
        {
            title: translations.users.title.list,
            key: 'Users',
            component: <UsersPage />,
        },
        {
            title: translations.words.licenses,
            key: 'Licenses',
            component: <LicensesPage />,
        },
    ];

    return (
        <div className={styles.master_page_container}>
            {companyGuidFromRoute && (
                <div className={styles.company_users_header_container}>
                    <LinkButton title={translations.actions.back} onClick={navigateBackToCompanies} type={LinkButtonType.White} icon={<ArrowBackIcon />} />
                    <h2>{getCompanyResponse?.demeterCompany?.name}</h2>
                </div>
            )}
            <PageHeader title={pageTitle} testId="CompanyUsersPageHeader" />
            <CompanyDetails companyGuid={selectedCompanyGuid} />
            <Tabs tabOptions={tabOptions} />
        </div>
    );
};

export default CompanyUsersPage;
