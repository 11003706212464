import { IconButton as MuiIconButton, Tooltip } from '@mui/material';
import React, { ReactNode, Ref } from 'react';

interface IIconButtonProps {
    className?: string;
    handleClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | (() => void);
    children: ReactNode;
    reference?: Ref<HTMLButtonElement>;
    size?: 'small' | 'medium' | 'large';
    color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    tooltip?: string;
    testId?: string | undefined;
}

const IconButton: React.FC<IIconButtonProps> = (props: IIconButtonProps) => (
    <Tooltip title={props.tooltip}>
        <MuiIconButton
            className={props.className}
            size={props.size}
            color={props.color ?? 'primary'}
            style={{ backgroundColor: 'transparent' }}
            disableRipple
            disableFocusRipple
            ref={props.reference}
            onClick={props.handleClick}
            data-testId={props.testId}
        >
            {props.children}
        </MuiIconButton>
    </Tooltip>
);

export default IconButton;
