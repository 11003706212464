import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi, futuresApi } from '../../../../Apis/Apis';
import { Currency, DemeterFilterTimeSpan, DemeterSymbolModel, MarketPricesTimeSpan, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import useCacheOrApi from '../../../Apis/Hooks/useCacheOrApiHook';
import useCacheThenApi from '../../../Apis/Hooks/useCacheThenApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import { IChartBarDataSeries, IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import FilterTimeSpans from '../../../Components/Charts/FilterTimeSpans/FilterTimeSpans';
import ProjectionChartRaw from '../../../Components/Charts/Projection/ProjectionChartRaw';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import { IExchangeCommoditySelection } from '../../../Components/Navigation/Hooks/useExchangeCommodityNavigationHook';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './FuturesPricesTable.module.scss';

export interface IFuturesPriesComparisonChartProps {
    exchangeCommoditySelection: IExchangeCommoditySelection;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    testId?: string;
}

const availableFilterTimeSpans = [
    DemeterFilterTimeSpan.SixMonths,
    DemeterFilterTimeSpan.NineMonths,
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.TwoYears,
];

const defaultFilterTimeSpan = DemeterFilterTimeSpan.OneYear;

const FuturesPriesComparisonChart: React.FC<IFuturesPriesComparisonChartProps> = (props: IFuturesPriesComparisonChartProps) => {
    // Text hooks.
    const [translations] = useLanguage();

    // Symbols hooks.
    const symbols = useSymbolsApi();
    const [symbolModel1, setSymbolModel1] = useState<DemeterSymbolModel>();
    const [symbolModel2, setSymbolModel2] = useState<DemeterSymbolModel>();
    const [reutersInstrumentCodePrefix2, setReutersInstrumentCodePrefix2] = useState<string>();

    // Data hooks.
    const [filterTimeSpan, setFilterTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);
    const [asOfDate, setAsOfDate] = useState<Date>(new Date());
    const [linesSeries, setLinesSeries] = useState<IChartDataSeries[]>([]);
    const [barSeries, setBarSeries] = useState<IChartBarDataSeries>({ label: translations.words.spread, data: [], format: 'currency' });
    const [, , comparisonChartData] = useCacheOrApi(`${CacheKeys.ListMarketPricesComparisonChart}_${symbolModel1?.reutersInstrumentCodePrefix}`, () => {
        if (!symbolModel1) {
            return null;
        }

        return futuresApi.listMarketPricesComparisonChart(symbolModel1.reutersInstrumentCodePrefix);
    });

    const [marketPricesComparisonLoading, , marketPricesComparisonData] = useCacheThenApi(
        // eslint-disable-next-line max-len
        `${CacheKeys.ListMarketPricesComparisons}_${symbolModel1?.reutersInstrumentCodePrefix}_${reutersInstrumentCodePrefix2}_${props.currency}__${props.unitOfMeasure}_${asOfDate}_${filterTimeSpan}`,
        () => {
            if (!symbolModel1 || !reutersInstrumentCodePrefix2) {
                return null;
            }

            return demeterApi.listMarketPricesComparisonWithConversions(
                symbolModel1.reutersInstrumentCodePrefix,
                reutersInstrumentCodePrefix2,
                props.currency,
                props.unitOfMeasure,
                formattingService.toApiDate(asOfDate),
                filterTimeSpan as MarketPricesTimeSpan,
            );
        },
    );

    const title = `${formattingService.toDisplayName(symbolModel1)} ${translations.futures.headers.forwardCurve}`;

    const comparisonOptions = useMemo<{ label: string; value: string }[]>(() => {
        if (!comparisonChartData || !symbolModel1) {
            return [];
        }

        const comparisonMapForSymbol = comparisonChartData.reutersInstrumentCodePrefixMap![symbolModel1.reutersInstrumentCodePrefix.toLowerCase()] ?? [];
        if (!symbols || comparisonMapForSymbol.length === 0) {
            return [];
        }

        const newComparisonOptions = comparisonMapForSymbol.map((x) => {
            const selectedSymbol = symbols.find((symbol) => symbol.reutersInstrumentCodePrefix === x);

            return {
                label: formattingService.toDisplayName(selectedSymbol),
                value: x,
            };
        });

        return newComparisonOptions.sort((a, b) => a.label.localeCompare(b.label));
    }, [comparisonChartData]);

    useEffect(() => {
        if (!symbols) {
            return;
        }

        const selectedSymbol = symbols.find(
            (x) => x.exchange === props.exchangeCommoditySelection.exchange && x.commodity === props.exchangeCommoditySelection.commodity,
        );

        if (selectedSymbol) {
            setSymbolModel1(selectedSymbol);
            setAsOfDate(new Date());
        }
    }, [symbols, props.exchangeCommoditySelection]);

    useEffect(() => {
        if (!symbols || !reutersInstrumentCodePrefix2) {
            return;
        }

        const selectedSymbol = symbols.find((x) => x.reutersInstrumentCodePrefix === reutersInstrumentCodePrefix2);

        if (selectedSymbol) {
            setSymbolModel2(selectedSymbol);
        }
    }, [symbols, reutersInstrumentCodePrefix2]);

    useEffect(() => {
        if (!comparisonChartData || !symbolModel1) {
            return;
        }

        const comparisonMapForSymbol = comparisonChartData.reutersInstrumentCodePrefixMap![symbolModel1.reutersInstrumentCodePrefix.toLowerCase()] ?? [];

        if (comparisonMapForSymbol.length > 0) {
            setReutersInstrumentCodePrefix2(comparisonMapForSymbol[0]);
        } else {
            setReutersInstrumentCodePrefix2(symbolModel1?.reutersInstrumentCodePrefix);
        }
    }, [comparisonChartData, symbolModel1]);

    useEffect(() => {
        if (!marketPricesComparisonData || !marketPricesComparisonData.rows || marketPricesComparisonData.rows?.length === 0) {
            setLinesSeries([]);
            return;
        }

        const rows = marketPricesComparisonData.rows.filter((row) => (row.settlementPrice1 ?? 0) > 0 && (row.settlementPrice2 ?? 0) > 0);

        if (rows.length === 0) {
            setLinesSeries([]);
            return;
        }

        setLinesSeries([
            {
                label: formattingService.toDisplayName(symbolModel1),
                data: rows.map((row) => ({
                    value: row.settlementPrice1 ?? 0,
                    asOfDate: new Date(row.contractYear, row.contractMonth - 1, 1),
                    isActualValue: true,
                })),
            },
            {
                label: formattingService.toDisplayName(symbolModel2),
                data: rows.map((row) => ({
                    value: row.settlementPrice2 ?? 0,
                    asOfDate: new Date(row.contractYear, row.contractMonth - 1, 1),
                    isActualValue: true,
                })),
            },
        ]);

        setBarSeries({
            ...barSeries,
            data: rows.map((row) => ({
                value: row.difference ?? 0,
                asOfDate: new Date(row.contractYear, row.contractMonth - 1, 1),
                isActualValue: true,
            })),
        });
    }, [marketPricesComparisonData]);

    if (!comparisonOptions || comparisonOptions.length === 0) {
        return null;
    }

    return (
        <div className={styles.futures_prices_chart_container_small}>
            <ChartWrapper
                name="FuturesPricesComparisonChart"
                title={title}
                dataSourceTag={marketPricesComparisonData?.dataSourceTag ?? ''}
                isLoading={marketPricesComparisonLoading || linesSeries.length === 0}
                header={
                    <DatePickerInput
                        value={asOfDate}
                        handleDateChange={(newDate: Date | undefined) => {
                            if (newDate) {
                                setAsOfDate(newDate);
                            }
                        }}
                    />
                }
                subHeader={
                    <div className={styles.futures_prices_comparison_chart_sub_header}>
                        <div className={styles.futures_prices_comparison_chart_selection1}>{formattingService.toDisplayName(symbolModel1)} </div>
                        {translations.words.vs}
                        <div className={styles.futures_prices_comparison_chart_selection2}>
                            <Dropdown
                                value={reutersInstrumentCodePrefix2}
                                options={comparisonOptions}
                                handleOptionChange={(reutersInstrumentCodePrefix: string) => {
                                    setReutersInstrumentCodePrefix2(reutersInstrumentCodePrefix);
                                }}
                                customStyles={{
                                    border: 'none !important',
                                    boxShadow: 'none',
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                }}
                            />
                        </div>
                    </div>
                }
                footer={
                    <FilterTimeSpans
                        name="FuturesPricesComparisonChart"
                        filterTimeSpanOptions={availableFilterTimeSpans}
                        filterTimeSpan={filterTimeSpan}
                        handleTimeSpanSelected={(timeSpan) => setFilterTimeSpan(timeSpan)}
                    />
                }
                testId={props.testId}
            >
                <ProjectionChartRaw
                    linesSeries={linesSeries}
                    barSeries={barSeries}
                    currency={marketPricesComparisonData?.currency}
                    unitOfMeasure={marketPricesComparisonData?.unitOfMeasure ?? UnitOfMeasure.MetricTon}
                    displayDecimalPlacesMinimum={0} // TODO: Make these futures constants somewhere.
                    displayDecimalPlacesMaximum={4} // TODO: Use the symbols to determine these.
                />
            </ChartWrapper>
        </div>
    );
};

export default FuturesPriesComparisonChart;
