import React, { useMemo } from 'react';
import Switch from '../../../Components/Form/Buttons/Switch';
import CheckboxDropdown from '../../../Components/Form/Inputs/CheckboxDropdown';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValueMatrixCalculator.module.scss';
import { ChartDisplayVisability, DecileChartOption, ValueMatrixChartRequest, ValueMatrixTableRequest } from './ValueMatrixDefinitions';

export interface IValueMatrixChartControlProps {
    valueMatrixTableInputs: ValueMatrixTableRequest;
    valueMatrixChartRequest: ValueMatrixChartRequest;
    setValueMatrixChartInputs: (valueMatrixChartRequest: ValueMatrixChartRequest) => void;
    decileChartOptions: DecileChartOption[];
    chartDisplayVisibility: ChartDisplayVisability;
    setChartDisplayVisibility: (displayVisability: ChartDisplayVisability) => void;
    disabled?: boolean;
}

const minimumNumberOfSelectionsForDeciles = 1;
const maximumNumberOfSelectionsForDeciles = 10;
const minimumNumberOfSelectionsForYears = 0;
const maximumNumberOfSelectionsForYears = 5;

const ValueMatrixChartControls: React.FC<IValueMatrixChartControlProps> = (props: IValueMatrixChartControlProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const valueMatrixStripTypeIsNotSpot = props.valueMatrixTableInputs?.stripType !== 'spot';

    const decileYearOptions = useMemo(() => {
        if (!props.valueMatrixChartRequest.chartEndDate || !props.valueMatrixChartRequest.chartStartDate) {
            return [];
        }

        const currentYear = props.valueMatrixChartRequest.chartStartDate.getFullYear();
        const endYear = props.valueMatrixChartRequest.chartEndDate.getFullYear();

        let options = [{ label: formattingService.toDisplayName(props.valueMatrixTableInputs.product), value: endYear }];

        if (valueMatrixStripTypeIsNotSpot) {
            options = [
                { label: `${currentYear}`, value: currentYear },
                { label: `${currentYear + 1}`, value: currentYear + 1 },
                { label: `${currentYear + 2}`, value: currentYear + 2 },
                { label: `${currentYear + 3}`, value: currentYear + 3 },
            ];
        }

        if (!options.some((x) => props.valueMatrixChartRequest.chartTerms.includes(x.value))) {
            props.setValueMatrixChartInputs({ ...props.valueMatrixChartRequest, chartTerms: [options[0].value] });
        }

        return options;
    }, [props.valueMatrixTableInputs.stripType, props.valueMatrixChartRequest.chartStartDate, props.valueMatrixChartRequest.chartEndDate]);

    return (
        <>
            <p className={styles.value_matrix_checkbox_dropdown_label}>
                <Switch
                    checked={props.chartDisplayVisibility.chartControlsActive}
                    handleChange={() => {
                        if (props.chartDisplayVisibility.chartControlsActive) {
                            props.setChartDisplayVisibility({ chartIsVisible: false, chartControlsActive: false });
                        } else {
                            props.setChartDisplayVisibility({ chartIsVisible: false, chartControlsActive: true });
                        }
                    }}
                    disabled={props.disabled}
                />
                {translations.calculators.valueMatrix.text.chartSettings}
            </p>
            {props.chartDisplayVisibility.chartControlsActive && (
                <>
                    <div className={styles.value_matrix_inputs_row}>
                        <div className={styles.value_matrix_inputs_datepicker}>
                            <DatePickerInput
                                title={translations.text.startDate}
                                value={props.valueMatrixChartRequest.chartStartDate}
                                handleDateChange={(value) => props.setValueMatrixChartInputs({ ...props.valueMatrixChartRequest, chartStartDate: value })}
                            />
                        </div>
                        <div className={styles.value_matrix_inputs_datepicker}>
                            <DatePickerInput
                                title={translations.text.endDate}
                                value={props.valueMatrixChartRequest.chartEndDate}
                                handleDateChange={(value) => props.setValueMatrixChartInputs({ ...props.valueMatrixChartRequest, chartEndDate: value })}
                            />
                        </div>
                    </div>
                    <div className={styles.value_matrix_inputs_row}>
                        <div className={styles.value_matrix_chart_dropdown}>
                            <CheckboxDropdown
                                label={translations.calculators.valueMatrix.fields.deciles}
                                options={props.decileChartOptions}
                                placeholder={props.decileChartOptions
                                    .filter((x) => props.valueMatrixChartRequest.chartDeciles.includes(x.value))
                                    .map((x) => x.label)
                                    .join(', ')}
                                values={props.valueMatrixChartRequest.chartDeciles.sort((a, b) => b - a)}
                                handleOptionSelect={(value) => props.setValueMatrixChartInputs({ ...props.valueMatrixChartRequest, chartDeciles: value })}
                                minimumSelectionLimit={minimumNumberOfSelectionsForDeciles}
                                maximumSelectionLimit={maximumNumberOfSelectionsForDeciles}
                                limitMessage={translations.calculators.valueMatrix.text.numberOfSelectedDecileslimitMessage}
                            />
                        </div>
                        {valueMatrixStripTypeIsNotSpot && (
                            <div className={styles.value_matrix_chart_dropdown}>
                                <CheckboxDropdown
                                    label={translations.calculators.valueMatrix.fields.terms}
                                    options={decileYearOptions}
                                    placeholder={decileYearOptions
                                        .filter((x) => props.valueMatrixChartRequest.chartTerms.includes(x.value))
                                        .map((x) => x.label)
                                        .join(', ')}
                                    values={props.valueMatrixChartRequest.chartTerms}
                                    handleOptionSelect={(value) => props.setValueMatrixChartInputs({ ...props.valueMatrixChartRequest, chartTerms: value })}
                                    minimumSelectionLimit={minimumNumberOfSelectionsForYears}
                                    maximumSelectionLimit={maximumNumberOfSelectionsForYears}
                                    limitMessage={translations.calculators.valueMatrix.text.numberOfSelectedYearslimitMessage}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.value_matrix_chart_section_controls_active} />
                </>
            )}
        </>
    );
};

export default ValueMatrixChartControls;
