/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import { DemeterNewsModel } from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import IconButton from '../../../Components/Form/Buttons/IconButton';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import browserService from '../../../Services/BrowserService';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './LatestNews.module.scss';

export interface ILatestNewsModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (modalStatus: boolean) => void;
    headlineNewsPreviewData: { data: DemeterNewsModel; index: number; finalArticleIndex: number };
    handleArticleChange: (isNext: boolean) => void;
}

const LatestNewsModal: React.FC<ILatestNewsModalProps> = (props: ILatestNewsModalProps) => {
    const [translations] = useLanguage();

    const [loading, refreshApi, shortFormData] = useApi(() => {
        if (props.headlineNewsPreviewData.data.isShortForm) {
            return demeterApi.getDemeterHeadlineNews(props.headlineNewsPreviewData.data.articleId);
        }

        return null;
    });

    useEffect(() => {
        refreshApi();
    }, [props.headlineNewsPreviewData]);

    const handleClose = () => {
        props.setIsModalOpen(false);
    };

    return (
        <Modal open={props.isModalOpen} onClose={handleClose} sx={{ padding: 0 }}>
            <div className={styles.latest_news_modal}>
                <div className={styles.latest_news_modal_header_container}>
                    <div className={styles.latest_news_modal_title_container}>
                        <h3>{props.headlineNewsPreviewData.data.title}</h3>
                        <div className={styles.latest_news_modal_title_bottom}>
                            <h4>{props.headlineNewsPreviewData.data.authorName}</h4>
                            <h5>{formattingService.toDayOfWeekWithDayMonthYear(props.headlineNewsPreviewData.data.publishedDate)}</h5>
                        </div>
                    </div>
                    <div className={styles.latest_news_modal_close_icon_container}>
                        <IconButton handleClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                {props.headlineNewsPreviewData.data.isShortForm ? (
                    !loading && shortFormData ? (
                        <div className={styles.latest_news_modal_body_container}>
                            <td dangerouslySetInnerHTML={{ __html: shortFormData.content ?? '' }} />
                        </div>
                    ) : (
                        <LoadingSpinner />
                    )
                ) : (
                    <>
                        {props.headlineNewsPreviewData.data.heroImageUrl !== null && (
                            <img alt="Article Hero" src={props.headlineNewsPreviewData.data.heroImageUrl} className={styles.latest_news_modal_header_image} />
                        )}
                        <div className={styles.latest_news_modal_body_container}>
                            <p>{props.headlineNewsPreviewData.data.abstract}</p>
                        </div>
                        <div className={styles.latest_news_modal_article_link_container}>
                            <button
                                className={styles.latest_news_modal_article_link}
                                type="button"
                                onClick={() =>
                                    browserService.openMarketIntelligenceArticle({
                                        language: props.headlineNewsPreviewData.data.languageCode,
                                        articleId: props.headlineNewsPreviewData.data.articleId,
                                    })
                                }
                            >
                                <p>{translations.dashboard.latestNews.readFullArticle}</p>
                                <LaunchIcon />
                            </button>
                        </div>
                    </>
                )}
                <div className={styles.latest_news_modal_bottom_row}>
                    <LinkButton
                        onClick={() => {
                            props.handleArticleChange(false);
                        }}
                        title="Previous"
                        type={LinkButtonType.White}
                        disabled={props.headlineNewsPreviewData.index === 0}
                    />
                    <LinkButton
                        onClick={() => props.handleArticleChange(true)}
                        title="Next"
                        type={LinkButtonType.Blue}
                        disabled={props.headlineNewsPreviewData.finalArticleIndex === props.headlineNewsPreviewData.index}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default LatestNewsModal;
