import { useMemo } from 'react';
import { MarketIndicatorTemplateType } from '../../../../../../../Generated/Raven-Demeter/api';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useSearchParameters from '../../../../../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorTemplateTypeDropdownProps {
    value: MarketIndicatorTemplateType;
    handleOptionChange: (value: MarketIndicatorTemplateType) => void;
}

const MarketFactorTemplateTypeDropdown: React.FC<IMarketFactorTemplateTypeDropdownProps> = (props: IMarketFactorTemplateTypeDropdownProps) => {
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();

    const templateTypeOptions = useMemo(() => {
        const options: { label: string; value: MarketIndicatorTemplateType }[] = [
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.AverageSeasonal],
                value: MarketIndicatorTemplateType.AverageSeasonal,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.Decile],
                value: MarketIndicatorTemplateType.Decile,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.Forecast],
                value: MarketIndicatorTemplateType.Forecast,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.ForwardCurve],
                value: MarketIndicatorTemplateType.ForwardCurve,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.Historical],
                value: MarketIndicatorTemplateType.Historical,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.ManualEntry],
                value: MarketIndicatorTemplateType.ManualEntry,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.Seasonal],
                value: MarketIndicatorTemplateType.Seasonal,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.SlidingWindowSeasonal],
                value: MarketIndicatorTemplateType.SlidingWindowSeasonal,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.Speculative],
                value: MarketIndicatorTemplateType.Speculative,
            },
            {
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.Technical],
                value: MarketIndicatorTemplateType.Technical,
            },
        ];

        if (!searchParameters.tab || searchParameters.tab === 'parent') {
            options.push({
                label: translations.marketIndicatorTemplateType[MarketIndicatorTemplateType.Combination],
                value: MarketIndicatorTemplateType.Combination,
            });
        }

        return options;
    }, [translations, searchParameters.tab]);

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={props.value}
                options={templateTypeOptions}
                handleOptionChange={props.handleOptionChange}
                label={translations.marketIndicatorsManagement.fields.templateType}
                disabled={searchParameters.tab === 'parent'}
            />
        </div>
    );
};

export default MarketFactorTemplateTypeDropdown;
