import { useMemo } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import { ListCommodityYearlyBalancesResponse, SortDirection, UnitOfMeasure } from '../../../Generated/Raven-Demeter/api';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';

const useYearlyBalancesApi = (
    regionCommoditySelection: IRegionCommoditySelection,
    years: number,
    unitOfMeasure?: UnitOfMeasure,
): ListCommodityYearlyBalancesResponse | undefined => {
    const cacheKey = useMemo(() => {
        const cacheKeyPrefix = `${CacheKeys.ListCommodityYearlyBalances}`;
        if (!regionCommoditySelection) {
            return cacheKeyPrefix;
        }

        const cacheKeySuffix = JSON.stringify({ ...regionCommoditySelection, years, unitOfMeasure });
        return `${cacheKeyPrefix}_${cacheKeySuffix}`;
    }, [regionCommoditySelection, years, unitOfMeasure]);

    const [, , listYearlyBalancesResponse] = useCacheThenApi(cacheKey, () => {
        if (!regionCommoditySelection) {
            return null;
        }

        return demeterApi.listCommodityYearlyBalances(
            regionCommoditySelection.region,
            regionCommoditySelection.commodity,
            unitOfMeasure,
            undefined,
            SortDirection.Desc,
            years + 2, // Look x years back, but get additional years for forecast data.
            0,
        );
    });

    return listYearlyBalancesResponse;
};

export default useYearlyBalancesApi;
