import { Options, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Currency, DemeterDataFrequency, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import {
    areaRangeChartType,
    chartColors,
    ChartContext,
    defalutLineWidth,
    defaultChartAxisTickLabelStyle,
    defaultChartAxisTitleStyle,
    defaultChartOptions,
    defaultZoneAxis,
    featuredLineWidth,
    HighchartsPlot,
    IChartAreaRangeData,
    IChartData,
    IChartProps,
    IChartSeasonalDataSeries,
    lineChartType,
    shortDashStyle,
    solidStyle,
} from '../ChartDefinitions';
import chartService from '../ChartService';

export interface ISeasonalChartRawProps extends IChartProps {
    linesSeries: IChartSeasonalDataSeries[];
    dataFrequency: DemeterDataFrequency;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    disableTooltip?: boolean;
}

const dayInMilliseconds = 24 * 3600 * 1000;

const SeasonalChartRaw: React.FC<ISeasonalChartRawProps> = (props: ISeasonalChartRawProps) => {
    const [translations] = useLanguage();

    const SeasonalChartDefaultOptions = useMemo<Options>(
        () => ({
            ...defaultChartOptions,
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter() {
                        const context = this as unknown as { value: number };
                        return formattingService.toMonthFromUtc(context.value);
                    },
                    style: defaultChartAxisTickLabelStyle,
                },
                tickInterval: 30 * dayInMilliseconds,
            },
            yAxis: [
                {
                    title: {
                        text: '',
                        style: defaultChartAxisTitleStyle,
                    },
                    labels: {
                        format: '{value:{point.y: , .0f}',
                        style: defaultChartAxisTickLabelStyle,
                    },
                },
            ],
            series: [],
        }),
        [translations],
    );

    const [highchartOptions, setHighchartOptions] = useState<Options>(SeasonalChartDefaultOptions);

    const getDataSeriesDefinitions = useCallback(() => {
        let colorIndex = -1;
        const yearToColorMap = {} as { [key: string]: number };
        const linesDataSeries = props.linesSeries.map((series) => {
            // Match the same color of the regular year with the forecast year.
            if (series.lineDataType !== 'forecast') {
                colorIndex += 1;
                yearToColorMap[`${series.yearsOrNumberOfYears}`] = colorIndex;
            } else {
                const colorIndexToUse = yearToColorMap[`${series.yearsOrNumberOfYears}`];

                if (colorIndexToUse === undefined) {
                    colorIndex += 1;
                    yearToColorMap[`${series.yearsOrNumberOfYears}`] = colorIndex;
                }
            }

            const color =
                series.lineDataType === 'range'
                    ? chartColors.areaChartColorsRuleSetTwo[0]
                    : chartColors.lineChartColorsRuleSetTwo[colorIndex % chartColors.lineChartColorsRuleSetTwo.length];

            const type = series.lineDataType === 'range' ? areaRangeChartType : lineChartType;

            return {
                ...chartService.getDataSeriesBase(series, type, color),
                yAxis: 0,
                events: {
                    legendItemClick() {},
                },
                zoneAxis: defaultZoneAxis,
                dashStyle: series.lineDataType === 'forecast' ? shortDashStyle : solidStyle,
                zIndex: series.lineDataType === 'range' ? 1 : 2,
                lineWidth: series.isPrimaryLine ? featuredLineWidth : defalutLineWidth,
            };
        });

        return [...linesDataSeries];
    }, [props.linesSeries]);

    useEffect(() => {
        const dataSeries = getDataSeriesDefinitions();
        const yAxis = [...(SeasonalChartDefaultOptions.yAxis as YAxisOptions[])!];
        let maximumValue = 0;

        props.linesSeries.forEach((series, index) => {
            dataSeries[index].data = series.data.map((item: IChartData) => {
                const chartPoint = {
                    // We want all of the x values to line up, therefore we start at the same year.
                    x: new Date(2000, item.asOfDate.getMonth(), item.asOfDate.getDate()).getTime(),
                    lineDataType: series.lineDataType,
                    asOfDate: item.asOfDate,
                    isActualValue: item.isActualValue,
                } as HighchartsPlot;

                if (series.lineDataType === 'range') {
                    const areaRangeData = item as IChartAreaRangeData;
                    chartPoint.high = areaRangeData.maximumValue;
                    chartPoint.low = areaRangeData.minimumValue;
                    chartPoint.lineDataType = 'range';
                    maximumValue = Math.max(maximumValue, Math.abs(areaRangeData.maximumValue));
                } else {
                    chartPoint.y = item.value;
                    maximumValue = Math.max(maximumValue, Math.abs(item.value));
                }

                return chartPoint;
            });
        });

        const newOptions = {
            ...SeasonalChartDefaultOptions,
            ...{ series: dataSeries },
            ...{ yAxis },
            ...{
                tooltip: {
                    enabled: props.disableTooltip !== true,
                    formatter() {
                        const context = this as unknown as ChartContext;

                        // We have to put in the first actual value into the forecast line to render it correctly, this allows us to name it properly.
                        let nameOverride = context.point.isActualValue ? `${context.point.asOfDate?.getFullYear()}` ?? '' : context.series.name!;

                        // Get the correct name for the range and average.
                        if (context.point.lineDataType === 'average') {
                            nameOverride = translations.words.average;
                        } else if (context.point.lineDataType === 'range') {
                            nameOverride = translations.words.range;
                        }

                        return chartService.getTooltipText(context, {
                            nameOverride,
                            dataFrequency: props.dataFrequency,
                            displayDecimalPlacesMinimum: props.displayDecimalPlacesMinimum ?? 0,
                            displayDecimalPlacesMaximum: props.displayDecimalPlacesMaximum ?? 0,
                        });
                    },
                },
            },
            downloadData: chartService.getDownloadDataForSeasonalChart(props.linesSeries, props.dataFrequency, props.displayDecimalPlacesMinimum),
        };

        newOptions.yAxis[0].labels!.format = `{value:{point.y: , .${formattingService.getDisplayDecimalPlacesMinimumForCharts(maximumValue)}f}`;
        newOptions.yAxis[0]!.title!.text = chartService.getCurrencyAndUnitOfMeasureText(props.unitOfMeasure, props.currency);

        setHighchartOptions(newOptions as Options);
    }, [props.linesSeries, translations]);

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default memo(SeasonalChartRaw);
