import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { ApiError } from '../../../Apis/Apis';
import useNotificationHook from '../../Components/Notifications/useNotificationHook';
import cacheService from '../../Services/Cache/CacheService';
import useLanguage from '../../Services/Language/useLanguageHook';
import { UseApiOptions } from './useApiHook';

// This states that we are going to use cache if the items exists and only call the api if the item does not exist in cache.
const useCacheOrApi = <T>(
    key: string,
    apiCallback: () => Promise<AxiosResponse<T, any>> | null | undefined,
    options?: UseApiOptions,
): [loading: boolean, refreshApi: () => void, data?: T, error?: string] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T | undefined>();
    const [error, setError] = useState<string>();
    const [refreshCounter, setRefreshCounter] = useState(0);
    const counterReference = useRef(0);
    const keyReference = useRef<string>('');
    const [displayError] = useNotificationHook();
    const [translations] = useLanguage();

    const refreshApi = async () => {
        counterReference.current += 1;
        await cacheService.delete(key);
        setRefreshCounter(counterReference.current);
    };

    useEffect(() => {
        let cancelRequest = false;
        const keyUsedForApi = key;
        keyReference.current = keyUsedForApi;

        const fetchData = async () => {
            const cachedValue = await cacheService.get<T>(keyUsedForApi);
            if (cachedValue) {
                if (keyReference.current !== keyUsedForApi) {
                    return;
                }

                setData(cachedValue);
            } else {
                try {
                    const apiToCall = apiCallback();
                    if (!apiToCall) {
                        return;
                    }

                    setLoading(true);
                    const response = await apiToCall;
                    const responseData = response.data;
                    cacheService.set(keyUsedForApi, responseData);

                    // This prevent this async method from updating state on an unmounted component.
                    if (keyReference.current !== keyUsedForApi || cancelRequest) {
                        return;
                    }

                    setData(responseData);
                    setLoading(false);
                } catch (exception) {
                    if (cancelRequest) {
                        return;
                    }

                    const problemMessage = (exception as ApiError).response.data?.detail ?? '';
                    const errorMessage = (exception as ApiError).response.data?.errors ?? [];

                    setError(problemMessage || errorMessage.join(', '));
                    setLoading(false);

                    if (!options?.stopGlobalErrorHandling) {
                        displayError(translations.errors.apiError);
                    }
                }
            }
        };

        fetchData();

        return (): void => {
            cancelRequest = true;
        };
    }, [key, refreshCounter]);

    return [loading, refreshApi, data, error];
};

export default useCacheOrApi;
