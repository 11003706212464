import { ChangeEvent, FC, useEffect, useState } from 'react';
import { demeterUserDataApi } from '../../../Apis/Apis';
import arrowLeft from '../../../Components/Assets/arrowLeft.svg';
import arrowRight from '../../../Components/Assets/arrowRight.svg';
import { Data, DataSeries, UpdateDemeterUserDataRequest } from '../../../Generated/Raven-Demeter';
import useApi from '../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import ComponentSubHeader from '../../Components/Headers/ComponentSubHeader';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import ConfirmModal from '../../Components/Modals/ConfirmModal/ConfirmModal';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import downloadService from '../../Services/Download/DownloadService';
import useLanguage from '../../Services/Language/useLanguageHook';
import { IUserData } from './UserDataDefinitions';
import UserDataForm from './UserDataForm';
import styles from './UserDataPage.module.scss';
import UserDataStartup from './UserDataStartup';

const UserDataManagement: FC = () => {
    const [translations] = useLanguage();
    const [data, setData] = useState<IUserData[]>();
    const [value, setValue] = useState<IUserData>();
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showLoading, setShowLoading] = useState(true);
    const [, setSearchParameters] = useSearchParameters();
    const totalPages = (data && Math.ceil(data.length / rowsPerPage)) ?? 0;

    const [, listDemeterUserData, listDemeterUserDataResponse] = useApi(() => demeterUserDataApi.listDemeterUserData());
    const [, getDemeterUserData, getDemeterUserDataResponse] = useApi(() => {
        if (!value) {
            return null;
        }

        return demeterUserDataApi.getDemeterUserData(value.id);
    });

    const [, updateDemeterUserData, updateDemeterUserDataResponse] = useApi(() => {
        if (!value) {
            return null;
        }

        return demeterUserDataApi.updateDemeterUserData(value.id, {
            name: value.name,
            fileName: value.fileName,
            currency: value.currency,
            unitOfMeasure: value.unitOfMeasure,
        } as UpdateDemeterUserDataRequest);
    });

    const [, deleteDemeterUserData, deleteDemeterUserDataResponse] = useApiWithoutAutoExecute(() => {
        if (!value) {
            return null;
        }
        return demeterUserDataApi.deleteDemeterUserData(value.id!);
    });

    useEffect(() => {
        if (!listDemeterUserDataResponse) {
            return;
        }
        const userData = listDemeterUserDataResponse.rows?.map(
            (x) =>
                ({
                    id: x.demeterUserDataGuid,
                    name: x.name,
                    fileName: x.fileName,
                    currency: x.currency,
                    unitOfMeasure: x.unitOfMeasure,
                    data: [],
                } as IUserData),
        );
        setData(userData);
        setShowLoading(false);
    }, [listDemeterUserDataResponse]);

    useEffect(() => {
        if (!getDemeterUserDataResponse) {
            return;
        }
        const dataSeries: DataSeries[] = [];
        dataSeries.push({
            label: 'Value',
            data: getDemeterUserDataResponse.demeterUserData?.rows.map(
                (x) => ({ value: x.value, date: x.asOfDate, dateRaw: x.asOfDate, isActualValue: true } as unknown as Data),
            ),
        });
        downloadService.downloadCsv(dataSeries, getDemeterUserDataResponse.demeterUserData?.fileName!);
        setValue(undefined);
    }, [getDemeterUserDataResponse]);

    useEffect(() => {
        if (!updateDemeterUserDataResponse) {
            return;
        }
        listDemeterUserData();
        setFormDialogOpen(false);
        setValue(undefined);
    }, [updateDemeterUserDataResponse]);

    useEffect(() => {
        if (!deleteDemeterUserDataResponse) {
            return;
        }
        setShowLoading(false);
        setValue(undefined);
        listDemeterUserData();
    }, [deleteDemeterUserDataResponse]);

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (dataItem: IUserData) => {
        setValue(dataItem);
        updateDemeterUserData();
    };

    const handleEdit = (dataItem: IUserData) => {
        setValue(dataItem);
        setFormDialogOpen(true);
    };

    const handleClose = () => {
        setFormDialogOpen(false);
        setValue(undefined);
    };

    const handleDelete = (dataItem: IUserData) => {
        setValue(dataItem);
        setConfirmDeleteModalOpen(true);
    };

    const handleDeleteConfirmed = () => {
        setConfirmDeleteModalOpen(false);
        deleteDemeterUserData();
    };

    const handleDownload = (dataItem: IUserData) => {
        setValue(dataItem);
        getDemeterUserData();
    };

    const handleUpload = () => {
        setSearchParameters({ show: 'Upload' });
    };

    const showStartup = !showLoading && (!data || data.length === 0);
    const showManagement = !showLoading && !showStartup;

    return (
        <>
            {showLoading && <PageLoadingSpinner />}
            {showStartup && <UserDataStartup />}
            {showManagement && (
                <div className={styles.master_page_container}>
                    <div className={styles.user_data_management}>
                        <div className={styles.user_data_management_header_container}>
                            <h2>{translations.userData.management.header}</h2>
                            <LinkButton
                                title={translations.userData.management.upload}
                                onClick={handleUpload}
                                type={LinkButtonType.White}
                                disabled={showLoading}
                            />
                        </div>
                        <div className={styles.user_data_management_container}>
                            <div className={styles.user_data_management_description}>{translations.userData.management.description}</div>
                            <div>
                                <ComponentSubHeader title={translations.userData.management.subheader} />
                                <div className={styles.user_data_management_table}>
                                    <div className={styles.user_data_management_table_row}>
                                        <div className={styles.user_data_management_table_header_cell}>{translations.userData.management.table.nameHeader}</div>
                                        <div className={styles.user_data_management_table_header_cell}>
                                            {translations.userData.management.table.fileNameHeader}
                                        </div>
                                        <div className={styles.user_data_management_table_header_cell}>{translations.userData.management.table.unitHeader}</div>
                                        <div className={styles.user_data_management_table_header_cell}>
                                            {translations.userData.management.table.currencyHeader}
                                        </div>
                                        <div className={styles.user_data_management_table_header_cell}>
                                            {translations.userData.management.table.actionsHeader}
                                        </div>
                                    </div>
                                    {data &&
                                        data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((dataItem) => (
                                            <div className={styles.user_data_management_table_row}>
                                                <div className={styles.user_data_management_table_cell}>{dataItem.name}</div>
                                                <div className={styles.user_data_management_table_cell}>{dataItem.fileName}</div>
                                                <div className={styles.user_data_management_table_cell}>
                                                    {translations.unitOfMeasure[dataItem.unitOfMeasure]}
                                                </div>
                                                <div className={styles.user_data_management_table_cell}>{translations.currency[dataItem.currency]}</div>
                                                <div className={styles.user_data_management_table_cell}>
                                                    <div className={styles.user_data_management_table_actions}>
                                                        <button type="button" onClick={() => handleDelete(dataItem)}>
                                                            {translations.actions.delete}
                                                        </button>
                                                        {' | '}
                                                        <button type="button" onClick={() => handleEdit(dataItem)}>
                                                            {translations.actions.edit}
                                                        </button>
                                                        {' | '}
                                                        <button type="button" onClick={() => handleDownload(dataItem)}>
                                                            {translations.actions.download}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <div className={styles.user_data_management_table_footer}>
                                    <div>{translations.userData.management.table.itemsPerPage}:</div>
                                    <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                        {[5, 10, 25].map((rowsPerPageOption) => (
                                            <option key={rowsPerPageOption} value={rowsPerPageOption}>
                                                {rowsPerPageOption}
                                            </option>
                                        ))}
                                    </select>
                                    <div>
                                        {page + 1} {translations.userData.management.table.pageOf} {totalPages}
                                    </div>
                                    <button type="button" onClick={() => handleChangePage(Math.max(0, page - 1))} disabled={page === 0}>
                                        <img src={arrowLeft} alt="" />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleChangePage(Math.min(totalPages - 1, page + 1))}
                                        disabled={page >= totalPages - 1}
                                    >
                                        <img src={arrowRight} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <UserDataForm open={formDialogOpen} value={value} onClose={handleClose} onChange={handleChange} />
            <ConfirmModal
                header={translations.userData.management.confirmDelete.header}
                message={translations.userData.management.confirmDelete.message}
                showModal={confirmDeleteModalOpen}
                handleConfirm={handleDeleteConfirmed}
                handleCancel={() => setConfirmDeleteModalOpen(false)}
            />
        </>
    );
};
export default UserDataManagement;
