import { demeterApi } from '../../../Apis/Apis';
import { Currency, GetCurrencyConversionChartResponseCurrencyConversionMap } from '../../../Generated/Raven-Demeter';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';

const useCurrencyConversionApi = (
    reutersInstrumentCodePrefix?: string,
): [
    getCurrencyConversionRate: (currency: Currency | undefined, year: number, month: number) => number,
    conversionMap: GetCurrencyConversionChartResponseCurrencyConversionMap | undefined,
] => {
    const [, , response] = useCacheThenApi(`${CacheKeys.GetCurrencyConversionChart}_${reutersInstrumentCodePrefix}`, () => {
        if (!reutersInstrumentCodePrefix) {
            return undefined;
        }

        return demeterApi.getCurrencyFuturesConversionChart(reutersInstrumentCodePrefix!);
    });

    const getCurrencyConversionRate = (currency: Currency | undefined, year: number, month: number): number => {
        if (currency === undefined) {
            return 1.0;
        }

        const conversionMap = response?.currencyConversionMap
            ? response.currencyConversionMap[currency.toLowerCase() as keyof GetCurrencyConversionChartResponseCurrencyConversionMap]
            : undefined;

        if (!conversionMap) {
            return 1.0;
        }

        const conversion = conversionMap.find((x) => x.month === month && x.year === year);

        if (!conversion?.conversionRate) {
            return 1.0;
        }

        return conversion.conversionRate;
    };

    return [getCurrencyConversionRate, response?.currencyConversionMap ?? undefined];
};

export default useCurrencyConversionApi;
