import React, { useEffect, useState } from 'react';
import { demeterApi, demeterUsersApi } from '../../Apis/Apis';
import Indicator from '../../Components/Indicator/Indicator';
import { getUserDataPreferences } from '../../Components/UserPreference/UserPreference';
import promiseHandler from '../../Core/Handlers/PromiseHandler';
import {
    DemeterCommodity,
    DemeterDashboardPresetChartModel,
    DemeterDataFrequency,
    DemeterLeadingIndicatorModel,
    DemeterMarket,
    DemeterRegion,
    DemeterUserStoreType,
    DemeterWorkerType,
} from '../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../Redux/ReduxStore';
import { MarketPriceModelBySymbol, selectMarketPricesBySymbol } from '../../Redux/Slices/MarketPricesSlice';
import { selectLocation, selectStoreId } from '../../Redux/Slices/SystemSlice';
import useApi from '../../Refactor/Apis/Hooks/useApiHook';
import IndicatorsManagementButton from '../../Refactor/Components/Form/Buttons/IndicatorsManagementButton/IndicatorsManagementButton';
import ManageIndicatorsModal from '../../Refactor/Components/Modals/ProductsModal/ManageIndicatorsModal';
import IndicatorFrequencyNote from '../../Refactor/Pages/Dashboard/Indicators/IndicatorFrequencyNote';
import formattingService from '../../Refactor/Services/Formatting/FormattingService';
import { Language } from '../../Refactor/Services/Language/LanguageService';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import lightstreamerMarketPricesService from '../../Refactor/Services/MarketPrices/LightstreamerMarketPricesService';
import styles from './DashboardPage.module.scss';
import IndicatorModal from './IndicatorModal';

export type UserProfileOption = { label: string; value: string; workerType: DemeterWorkerType };

export interface IndicatorsWidgetInfoInterface {
    title: string;
    change: number;
    currency: string;
    value?: number | null;
    asOfDate: string | null | undefined;
    latestPriceAsOfDate: string | null | undefined;
    settlementPriceAsOfDate: string | null | undefined;
    reutersInstrumentCode: string | null | undefined;
    commodity?: DemeterCommodity;
    region?: DemeterRegion;
    isFuturesSettlement: boolean;
    indicatorModel: DemeterLeadingIndicatorModel;
    displayDecimalPlacesMinimum: number;
    displayDecimalPlacesMaximum: number;
}

interface IndicatorsProps {
    market: DemeterMarket;
    setDashboardPresetChart: (chartPresets: DemeterDashboardPresetChartModel[]) => void;
    userPreferenceIndicatorGuids: string[];
    updateIndicatorsPricesAndAsOfDate: (data: DemeterLeadingIndicatorModel[], marketPrices?: MarketPriceModelBySymbol) => void;
    indicatorsWidgetInfo: IndicatorsWidgetInfoInterface[] | undefined;
    indicatorInfo: DemeterLeadingIndicatorModel[] | null | undefined;
    setIndicatorInfo: (indicatorsWidgetInfo: DemeterLeadingIndicatorModel[] | null | undefined) => void;
}

const defaultWorkerType = DemeterWorkerType.Farmer;

const Indicators: React.FC<IndicatorsProps> = (props: IndicatorsProps) => {
    // Application level definitions.
    const storeId = useApplicationSelector(selectStoreId);
    const marketPricesBySymbol = useApplicationSelector(selectMarketPricesBySymbol);

    // Custom hooks.
    const [translations, , , setLanguage] = useLanguage();
    const location = useApplicationSelector(selectLocation);

    // Page states.
    const [showPopout, setShowPopout] = useState(false);
    const [selectedIndicators, setSelectedIndicators] = useState<DemeterLeadingIndicatorModel[]>([]);
    const [simpleIndicators, setSimpleIndicators] = useState<DemeterLeadingIndicatorModel[]>([]);
    const [modalSelectedIndicatorModel, setModalSelectedIndicatorModel] = useState<DemeterLeadingIndicatorModel>();
    const [productsModalOpen, setProductsModalOpen] = useState(false);

    const [loadingIndicators, , listDemeterLeadingIndicatorsResponse] = useApi(() => demeterApi.listDemeterLeadingIndicators(props.market, true, false));

    useEffect(() => {
        (async () => {
            if (listDemeterLeadingIndicatorsResponse?.rows) {
                setSimpleIndicators(listDemeterLeadingIndicatorsResponse.rows);
            }
            const userWorkerType = ((await getUserDataPreferences('workerType')) as DemeterWorkerType) ?? defaultWorkerType;
            await displayIndicatorsOnProfileChange(userWorkerType);
        })();
    }, [translations, props.market, listDemeterLeadingIndicatorsResponse]);

    useEffect(() => {
        checkLanguageForUserExists();
    }, []);

    useEffect(() => {
        updateUserSelectedIndicators();
    }, [simpleIndicators, props.userPreferenceIndicatorGuids]);

    useEffect(() => {
        props.updateIndicatorsPricesAndAsOfDate(props.indicatorInfo ?? [], marketPricesBySymbol);
    }, [marketPricesBySymbol]);

    useEffect(() => {
        if (props.indicatorInfo && props.indicatorInfo.length > 0) {
            const codes = props.indicatorInfo.map((element) => element.reutersInstrumentCode); // shouldnt be null...
            lightstreamerMarketPricesService.start(storeId, codes as string[]);
        }

        return (): void => {
            lightstreamerMarketPricesService.stop(storeId);
        };
    }, [props.indicatorInfo]);

    const setManageModal = async (indicators: Array<DemeterLeadingIndicatorModel>) => {
        updateUserSelectedIndicators(indicators);
        await promiseHandler(
            demeterApi.batchUpdateDemeterLeadingIndicators({ demeterLeadingIndicatorGuids: indicators.map((x) => x.demeterLeadingIndicatorGuid) }),
        );
        await displayUserPreferenceIndicators();
    };

    const displayUserPreferenceIndicators = async (indicators?: DemeterLeadingIndicatorModel[]) => {
        const userPreferenceIndicators = indicators || (await promiseHandler(demeterApi.listDemeterLeadingIndicators(props.market, false, true)))?.data.rows;
        props.updateIndicatorsPricesAndAsOfDate(userPreferenceIndicators ?? []);
        props.setIndicatorInfo(userPreferenceIndicators);
    };

    const updateUserSelectedIndicators = async (userIndicatorSelections?: DemeterLeadingIndicatorModel[], priceChangeOnly?: boolean) => {
        if (userIndicatorSelections != null) {
            setSelectedIndicators(userIndicatorSelections ?? []);
            return;
        }

        // map user stored preferences as selections for manageModal
        if (userIndicatorSelections == null && simpleIndicators?.length > 0 && props.userPreferenceIndicatorGuids?.length > 0) {
            if (userIndicatorSelections == null && simpleIndicators?.length > 0 && props.userPreferenceIndicatorGuids?.length > 0) {
                const userPreferenceSelectedIndicators: DemeterLeadingIndicatorModel[] = [];
                props.userPreferenceIndicatorGuids?.forEach((guid) => {
                    const selectedPreference = simpleIndicators?.find((availableInd) => availableInd.demeterLeadingIndicatorGuid === guid);
                    if (selectedPreference) {
                        userPreferenceSelectedIndicators.push({ ...selectedPreference });
                    }
                });
                if (!priceChangeOnly) {
                    setSelectedIndicators(userPreferenceSelectedIndicators);
                }
            }
        }
    };

    const checkLanguageForUserExists = async () => {
        const getLanguageData = await promiseHandler(demeterUsersApi.getUserStore(DemeterUserStoreType.LanguageSelected));
        const languageStored = getLanguageData?.data.userStore?.value.language ?? window?.navigator?.language;
        handleLanguageChange(languageStored as Language, true);
        return getLanguageData?.data.userStore?.value.language;
    };

    const handleLanguageChange = async (newLanguage: Language, isOnLoad: boolean = false) => {
        await setLanguage(newLanguage);

        if (!isOnLoad) {
            await promiseHandler(
                demeterUsersApi.updateUserStore(DemeterUserStoreType.LanguageSelected, {
                    userStoreType: DemeterUserStoreType.LocationData,
                    value: { language: newLanguage as string },
                }),
            );
        }
    };

    const displayIndicatorsOnProfileChange = async (workerType: DemeterWorkerType) => {
        const presets = await promiseHandler(demeterApi.getDashboardPresets(props.market, location, undefined, workerType));
        const currentUserPreferenceIndicators = await demeterApi.listDemeterLeadingIndicators(props.market, false, true);
        props.setDashboardPresetChart(presets?.data.dashboardPreset?.dashboardPresetsCharts ?? []);

        if (currentUserPreferenceIndicators.data.rows) {
            await displayUserPreferenceIndicators();
        }
    };

    const openModalAndSetProps = (indicator: DemeterLeadingIndicatorModel) => {
        setShowPopout(true);
        setModalSelectedIndicatorModel(indicator);
    };

    const getAsOfDateForIndicator = (indicator: IndicatorsWidgetInfoInterface) => {
        if (indicator.indicatorModel.dataFrequency === DemeterDataFrequency.Monthly) {
            return formattingService.toMonthYear(new Date(indicator.indicatorModel.asOfDate!));
        }

        if (!indicator.reutersInstrumentCode) {
            return formattingService.toLongDayMonthYear(new Date(indicator.indicatorModel.asOfDate!));
        }

        if (!indicator.isFuturesSettlement && indicator.latestPriceAsOfDate) {
            return formattingService.toTimestamp(new Date(indicator.latestPriceAsOfDate));
        }

        if (indicator.isFuturesSettlement && indicator.settlementPriceAsOfDate) {
            return formattingService.toLongDayMonthYear(new Date(indicator.settlementPriceAsOfDate));
        }

        return '';
    };

    return (
        <div className={styles.indicators}>
            <div data-testid="DashboardPageIndicators" className={styles.master_flex}>
                <div className={styles.indicatorsContainer}>
                    {props.indicatorsWidgetInfo &&
                        props.indicatorsWidgetInfo.map((indicator) => (
                            <div
                                key={indicator.title}
                                className={styles.indicators_indicators_area}
                                onClick={() => openModalAndSetProps(indicator?.indicatorModel)}
                                aria-hidden="true"
                            >
                                <Indicator
                                    change={indicator.change !== null ? Math.round((indicator.change + Number.EPSILON) * 100) / 100 : indicator.change}
                                    currency={indicator.currency}
                                    indicatorNumber={indicator.value!}
                                    isFuturesSettlement={indicator.isFuturesSettlement}
                                    indicatorTitle={indicator.title}
                                    displayDecimalPlacesMinimum={indicator.displayDecimalPlacesMinimum}
                                    displayDecimalPlacesMaximum={indicator.displayDecimalPlacesMaximum}
                                    dataFrequency={indicator.indicatorModel.dataFrequency}
                                    asOfDate={getAsOfDateForIndicator(indicator)}
                                    unitOfMeasure={indicator.indicatorModel.unitOfMeasure!}
                                    previousValue={indicator.indicatorModel.previousValue!}
                                    tableDefinitionType={indicator.indicatorModel.tableDefinitionType}
                                />
                            </div>
                        ))}
                    {!loadingIndicators && props.indicatorsWidgetInfo && (
                        <div className={styles.indicators_manage_indicators}>
                            <IndicatorsManagementButton
                                handleClick={() => setProductsModalOpen(true)}
                                tooltipText={translations.dashboard.indicators.addOrRemoveLeadingIndicators}
                            />
                        </div>
                    )}
                    <ManageIndicatorsModal
                        open={productsModalOpen}
                        title={`${translations.dashboard.indicators.manage} ${translations.dashboard.indicators.title}`}
                        selected={selectedIndicators}
                        indicators={simpleIndicators}
                        handleClose={() => setProductsModalOpen(false)}
                        handleChange={setManageModal}
                    />
                    <div>
                        <IndicatorModal indicatorModel={modalSelectedIndicatorModel} showPopout={showPopout} setShowPopout={setShowPopout} />
                    </div>
                </div>
            </div>
            <IndicatorFrequencyNote />
        </div>
    );
};

export default Indicators;
