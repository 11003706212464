/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import {
    Currency,
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterFilterTimeSpan,
    DemeterRegion,
    ListCommodityOtcPricesForwardCurveResponse,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter';
import useCacheThenApi from '../../../Apis/Hooks/useCacheThenApiHook';
import { ChartDisplayType, IChartPriceDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import FilterTimeSpans from '../../../Components/Charts/FilterTimeSpans/FilterTimeSpans';
import PriceChartRaw from '../../../Components/Charts/Price/PriceChartRaw';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './OtcPricesTable.module.scss';

export interface OtcPricesForwardCurveChartProps {
    title: string;
    region: DemeterRegion;
    commodity: DemeterCommodity;
    currency: Currency;
    unitOfMeasure: UnitOfMeasure;
    displayDecimalPlacesMinimum: number;
    displayDecimalPlacesMaximum: number;
}

type OtcPricesForwardCurveRequest = {
    region: DemeterRegion;
    commodity: DemeterCommodity;
    asOfDate1: Date;
    asOfDate2: Date;
    filterTimeSpan: DemeterFilterTimeSpan;
    unitOfMeasure: UnitOfMeasure;
    currency: Currency;
};

const availableFilterTimeSpans = [
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.ThreeYears,
    DemeterFilterTimeSpan.FiveYears,
    DemeterFilterTimeSpan.TenYears,
];

const defaultFilterTimeSpan = DemeterFilterTimeSpan.FiveYears;

const oneMonthAgo = moment().subtract(1, 'M');
const twoMonthsAgo = moment().subtract(2, 'M');

const OtcPricesForwardCurveChart: React.FC<OtcPricesForwardCurveChartProps> = (props: OtcPricesForwardCurveChartProps) => {
    // Text hooks.
    const [translations] = useLanguage();
    const title = useMemo(() => `${props.title} ${translations.futures.headers.priceCurve}`, [props.title, translations]);

    // Data hooks.
    const [linesSeries, setLinesSeries] = useState<IChartPriceDataSeries[]>([]);
    const [filterTimeSpan, setFilterTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);
    const [oldestAllowedDate, setOldestAllowedDate] = useState<Date>(new Date(0));
    const [otcPricesForwardCurveRequest, setOtcPricesForwardCurveRequest] = useState<OtcPricesForwardCurveRequest>({
        region: props.region,
        commodity: props.commodity,
        filterTimeSpan,
        asOfDate1: oneMonthAgo.toDate(),
        asOfDate2: twoMonthsAgo.toDate(),
        unitOfMeasure: props.unitOfMeasure,
        currency: props.currency,
    });

    const [otcPricesForwardCurveLoading1, , otcPricesForwardCurveData1] = useCacheThenApi(
        `${CacheKeys.ListOtcPricesForwardCurves}1_${otcPricesForwardCurveRequest.region}_${otcPricesForwardCurveRequest.commodity}_${otcPricesForwardCurveRequest?.asOfDate1}_${otcPricesForwardCurveRequest.currency}__${otcPricesForwardCurveRequest.unitOfMeasure}_${filterTimeSpan}`,
        () =>
            demeterApi.listCommodityOtcPricesForwardCurve(
                otcPricesForwardCurveRequest.region,
                otcPricesForwardCurveRequest.commodity,
                formattingService.toApiDate(otcPricesForwardCurveRequest.asOfDate1),
                otcPricesForwardCurveRequest.filterTimeSpan,
                otcPricesForwardCurveRequest.unitOfMeasure,
                otcPricesForwardCurveRequest.currency,
            ),
    );

    const [otcPricesForwardCurveLoading2, , otcPricesForwardCurveData2] = useCacheThenApi(
        `${CacheKeys.ListOtcPricesForwardCurves}2_${otcPricesForwardCurveRequest.region}_${otcPricesForwardCurveRequest.commodity}_${otcPricesForwardCurveRequest?.asOfDate2}_${otcPricesForwardCurveRequest.currency}__${otcPricesForwardCurveRequest.unitOfMeasure}_${filterTimeSpan}`,
        () =>
            demeterApi.listCommodityOtcPricesForwardCurve(
                otcPricesForwardCurveRequest.region,
                otcPricesForwardCurveRequest.commodity,
                formattingService.toApiDate(otcPricesForwardCurveRequest.asOfDate2),
                otcPricesForwardCurveRequest.filterTimeSpan,
                otcPricesForwardCurveRequest.unitOfMeasure,
                otcPricesForwardCurveRequest.currency,
            ),
    );

    const getForwardCurveValues = (otcPricesForwardCurveData: ListCommodityOtcPricesForwardCurveResponse) =>
        otcPricesForwardCurveData.asOfDateOtcPrices?.map((row) => {
            const rowAsOfDate = new Date(row.asOfDate);

            return {
                value: row.bidAskAveragePrice ?? 0,
                asOfDate:
                    row.contractYear === rowAsOfDate.getFullYear() && row.contractMonth === rowAsOfDate.getMonth() + 1
                        ? moment(rowAsOfDate).add(1, 'day').toDate()
                        : new Date(row.contractYear, row.contractMonth - 1, 1),
                isActualValue: false,
            };
        });

    // Using a buffer object (otcPricesForwardCurveRequest) we can reset the dates when we changed commodities,
    // region, etc. This prevents calling the API with a date older than the oldestAsOfDate thus preventing the error.
    useEffect(() => {
        setOtcPricesForwardCurveRequest({
            region: props.region,
            commodity: props.commodity,
            filterTimeSpan,
            unitOfMeasure: props.unitOfMeasure,
            currency: props.currency,
            asOfDate1: oneMonthAgo.toDate(),
            asOfDate2: twoMonthsAgo.toDate(),
        });
    }, [props.region, props.commodity, filterTimeSpan, props.unitOfMeasure, props.currency]);

    useEffect(() => {
        if (
            otcPricesForwardCurveLoading1 ||
            otcPricesForwardCurveLoading2 ||
            !otcPricesForwardCurveData1 ||
            !otcPricesForwardCurveData2 ||
            !otcPricesForwardCurveData1.rows ||
            !otcPricesForwardCurveData2.rows ||
            otcPricesForwardCurveData1.rows?.length === 0 ||
            otcPricesForwardCurveData2.rows?.length === 0
        ) {
            setLinesSeries([]);
            return;
        }

        // From the backend, the oldest dates allowed are off by one. Will need to look into this in the future.
        const oldestOtcAsOfDate = moment(otcPricesForwardCurveData1.oldestAsOfDate).add(1, 'day').toDate();

        setOldestAllowedDate(oldestOtcAsOfDate);

        const actualValues = otcPricesForwardCurveData1.rows!.map((row) => ({
            value: row.bidAskAveragePrice ?? 0,
            asOfDate: new Date(row.asOfDate),
            isActualValue: true,
        }));

        const currentDayValues = (otcPricesForwardCurveData1?.currentOtcPrices ?? []).map((row) => ({
            value: row.bidAskAveragePrice ?? 0,
            asOfDate: new Date(row.contractYear, row.contractMonth - 1, 1),
            isActualValue: false,
        }));

        const forwardCurveValues1 = getForwardCurveValues(otcPricesForwardCurveData1);
        const forwardCurveValues2 = getForwardCurveValues(otcPricesForwardCurveData2);
        const currentOtcPrices = [...actualValues, ...currentDayValues];

        setLinesSeries([
            {
                label: title,
                forecastLabel: translations.charts.legend.lastSettlement,
                data: currentOtcPrices,
            },
            {
                label: formattingService.toShortDayMonthYear(otcPricesForwardCurveRequest.asOfDate1),
                data: [],
                forwardCurveLabel: formattingService.toShortDayMonthYear(otcPricesForwardCurveRequest.asOfDate1),
                forwardCurveData: forwardCurveValues1,
                forwardCurveLineStyle: 'ShortDash',
            },
            {
                label: formattingService.toShortDayMonthYear(otcPricesForwardCurveRequest.asOfDate2),
                data: [],
                forwardCurveLabel: formattingService.toShortDayMonthYear(otcPricesForwardCurveRequest.asOfDate2),
                forwardCurveData: forwardCurveValues2,
                forwardCurveLineStyle: 'ShortDash',
            },
        ]);
    }, [otcPricesForwardCurveData1, otcPricesForwardCurveData2]);

    const currency = useMemo<Currency>(
        () => (otcPricesForwardCurveData1 && otcPricesForwardCurveData1.currency) ?? props.currency,
        [otcPricesForwardCurveData1, props.currency],
    );
    const unitOfMeasure = useMemo<UnitOfMeasure>(
        () => (otcPricesForwardCurveData1 && otcPricesForwardCurveData1.unitOfMeasure) ?? props.unitOfMeasure,
        [otcPricesForwardCurveData1, props.unitOfMeasure],
    );

    return (
        <ChartWrapper
            name="OtcPricesForwardCurveChart"
            title={title}
            dataSourceTag={otcPricesForwardCurveData1?.dataSourceTag ?? ''}
            isLoading={otcPricesForwardCurveLoading1 && otcPricesForwardCurveLoading2 && linesSeries.length === 0}
            header={
                <div className={styles.otc_prices_multiple_header_components_container}>
                    <DatePickerInput
                        value={otcPricesForwardCurveRequest?.asOfDate1}
                        handleDateChange={(newDate: Date | undefined) => {
                            if (newDate && moment(oldestAllowedDate).isSameOrBefore(newDate)) {
                                setOtcPricesForwardCurveRequest({ ...otcPricesForwardCurveRequest, asOfDate1: newDate });
                            }
                        }}
                        oldestAllowedDate={oldestAllowedDate}
                    />
                    <DatePickerInput
                        value={otcPricesForwardCurveRequest?.asOfDate2}
                        handleDateChange={(newDate: Date | undefined) => {
                            if (newDate && moment(oldestAllowedDate).isSameOrBefore(newDate)) {
                                setOtcPricesForwardCurveRequest({ ...otcPricesForwardCurveRequest, asOfDate2: newDate });
                            }
                        }}
                        oldestAllowedDate={oldestAllowedDate}
                    />
                </div>
            }
            footer={
                <FilterTimeSpans
                    name="OtcPricesForwardCurveChart"
                    filterTimeSpanOptions={availableFilterTimeSpans}
                    filterTimeSpan={filterTimeSpan}
                    handleTimeSpanSelected={(timeSpan) => setFilterTimeSpan(timeSpan)}
                />
            }
        >
            <PriceChartRaw
                displayType={ChartDisplayType.Line}
                linesSeries={linesSeries}
                dataFrequency={DemeterDataFrequency.Daily}
                hidePriceNavigator
                currency={currency}
                unitOfMeasure={unitOfMeasure}
                displayDecimalPlacesMinimum={props.displayDecimalPlacesMinimum}
                displayDecimalPlacesMaximum={props.displayDecimalPlacesMaximum}
            />
        </ChartWrapper>
    );
};

export default OtcPricesForwardCurveChart;
