import { useState } from 'react';
import { demeterCompanyApi } from '../../../Apis/Apis';
import { AgreeToCompanyTermsAndConditionsRequest, DemeterUserModel } from '../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import TextButton from '../../Components/Form/Buttons/TextButton';
import CheckboxInput from '../../Components/Form/Inputs/CheckboxInput';
import TextInput from '../../Components/Form/Inputs/TextInput';
import PageHeader from '../../Components/Headers/PageHeader';
import ActionModal from '../../Components/Modals/ActionModal/ActionModal';
import RegexValidators from '../../Core/Validation/RegexValidators';
import useLanguage from '../../Services/Language/useLanguageHook';
import CompanyRegistrationAgreement from './CompanyRegistrationAgreement';
import styles from './SessionPage.module.scss';

export interface ICompanyRegistrationProps {
    user: DemeterUserModel | undefined;
    handleSubmit: () => void;
    handleCancel: () => void;
}

const CompanyRegistration: React.FC<ICompanyRegistrationProps> = (props: ICompanyRegistrationProps) => {
    const [translations] = useLanguage();
    const [request, setRequest] = useState<AgreeToCompanyTermsAndConditionsRequest>({
        demeterCompanyGuid: props.user?.demeterCompanyGuid!,
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phone: '',
        contactPerson: '',
        termsAndConditionsAccepted: false,
    });
    const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState<boolean>(false);

    const isValid =
        request &&
        !!request.demeterCompanyGuid &&
        !!request.address1 &&
        !!request.address2 &&
        !!request.city &&
        !!request.state &&
        !!request.country &&
        !!request.postalCode &&
        !!request.phone &&
        !!request.contactPerson &&
        request.termsAndConditionsAccepted;

    const [isSaving, handleSubmit] = useApiWithoutAutoExecute(() => {
        if (isValid) {
            props.handleSubmit();
            return demeterCompanyApi.agreeToCompanyTermsAndConditions(request?.demeterCompanyGuid!, request);
        }

        return null;
    });

    return (
        <>
            <div className={styles.master_page_container}>
                <PageHeader title={props.user?.companyName || ''} />
                <div className={styles.company_registration}>
                    <div className={styles.company_registration_description}>{translations.companyRegistration.description}</div>

                    <div className={styles.company_registration_form}>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.address1}
                                value={request.address1}
                                required
                                handleTextChange={(value) => setRequest({ ...request, address1: value })}
                                errorMessage={translations.companies.errors.nameRequired}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.address2}
                                value={request.address2}
                                required
                                handleTextChange={(value) => setRequest({ ...request, address2: value })}
                                errorMessage={translations.companies.errors.nameRequired}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.city}
                                value={request.city}
                                required
                                handleTextChange={(value) => setRequest({ ...request, city: value })}
                                errorMessage={translations.companies.errors.nameRequired}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.state}
                                value={request.state}
                                required
                                handleTextChange={(value) => setRequest({ ...request, state: value })}
                                errorMessage={translations.companies.errors.nameRequired}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.country}
                                value={request.country}
                                required
                                handleTextChange={(value) => setRequest({ ...request, country: value })}
                                errorMessage={translations.companies.errors.nameRequired}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.postalCode}
                                value={request.postalCode}
                                required
                                handleTextChange={(value) => setRequest({ ...request, postalCode: value })}
                                errorMessage={translations.companies.errors.nameRequired}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.phone}
                                value={request.phone}
                                required
                                handleTextChange={(value) => setRequest({ ...request, phone: value })}
                                errorMessage={translations.companies.errors.nameRequired}
                                validation={RegexValidators.Phone}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <TextInput
                                title={translations.companyRegistration.contactPerson}
                                value={request.contactPerson}
                                required
                                handleTextChange={(value) => setRequest({ ...request, contactPerson: value })}
                                errorMessage={translations.companies.errors.nameRequired}
                                validation={RegexValidators.AlphaNumericMinimumLength2}
                            />
                        </div>
                        <div className={styles.company_registration_form_field}>
                            <div className={styles.company_registration_form_field_checkbox_container}>
                                <CheckboxInput
                                    isChecked={request.termsAndConditionsAccepted ?? false}
                                    handleIsChecked={(value) => setRequest({ ...request, termsAndConditionsAccepted: value })}
                                />
                                <span>
                                    {translations.companyRegistration.agreeTo}{' '}
                                    <TextButton title={translations.words.termsAndConditions} onClick={() => setShowTermsAndConditionsModal(true)} />
                                </span>
                            </div>
                        </div>
                        <div className={styles.company_registration_form_buttons}>
                            <LinkButton title={translations.actions.cancel} type={LinkButtonType.White} onClick={props.handleCancel} disabled={isSaving} />
                            <LinkButton title={translations.actions.submit} type={LinkButtonType.Blue} onClick={handleSubmit} disabled={isSaving || !isValid} />
                        </div>
                    </div>
                </div>
            </div>
            <ActionModal
                showModal={showTermsAndConditionsModal}
                header={translations.companyRegistration.termsAndConditionsHeader}
                handleCancel={() => setShowTermsAndConditionsModal(false)}
            >
                <CompanyRegistrationAgreement
                    handleCancel={() => setShowTermsAndConditionsModal(false)}
                    handleAccept={() => {
                        setRequest({ ...request!, termsAndConditionsAccepted: true });
                        setShowTermsAndConditionsModal(false);
                    }}
                />
            </ActionModal>
        </>
    );
};

export default CompanyRegistration;
