import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ApiError } from '../../../Apis/Apis';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import useNotificationHook from '../../Components/Notifications/useNotificationHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import { UseApiOptions } from './useApiHook';

const useApiWithForce = <TRequest, TResponse>(
    apiCallback: (request?: TRequest) => Promise<AxiosResponse<TResponse, any>> | null | undefined,
    options?: UseApiOptions,
): [loading: boolean, refreshApi: (request?: TRequest) => void, data?: TResponse, error?: string] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TResponse | undefined>();
    const [error, setError] = useState<string>();
    const [displayError, displaySuccess] = useNotificationHook();

    const [translations] = useLanguage();

    const refreshApi = async (request?: TRequest) => {
        try {
            const apiToCall = apiCallback(request);
            if (!apiToCall) {
                return;
            }

            setLoading(true);
            const response = await apiToCall;
            const responseData = response.data;

            setData(responseData);
            setError(undefined);
            setLoading(false);

            if (options?.successMessage) {
                displaySuccess(options.successMessage);
            }
        } catch (exception) {
            const problemMessage = (exception as ApiError).response.data?.detail ?? '';
            const errorMessage = (exception as ApiError).response.data?.errors ?? [];

            setError(problemMessage || errorMessage.join(', '));
            setLoading(false);

            if (options?.conflictErrorMessage && (exception as ApiError).response.status === applicationConstants.HttpStatus.Conflict) {
                displayError(options.conflictErrorMessage);
            } else if (options?.errorMessage) {
                displayError(options.errorMessage);
            } else if (!options?.stopGlobalErrorHandling) {
                displayError(translations.errors.apiError);
            }
        }
    };

    useEffect(() => {
        if (!options?.stopAutoExecute) {
            refreshApi();
        }
    }, []);

    return [loading, refreshApi, data, error];
};

export default useApiWithForce;
