/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable react/no-unused-prop-types */
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import IconButton from '../Buttons/IconButton';

interface IIconMenuDropdownProps<T> {
    iconButton: React.ReactElement;
    options: { label: JSX.Element | string; value: T }[];
    handleSelection: (value: T) => void;
    messageToDisplayAtTop?: string;
    buttonClassName?: string;
    tooltip?: string;
    testId?: string | undefined;
}

const IconMenuDropdown = <T,>(props: IIconMenuDropdownProps<T>): JSX.Element => {
    const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorElement);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const handleOptionChange = (label: JSX.Element | string): void => {
        const option = props.options.find((x) => x.label === label);

        if (option) {
            props.handleSelection(option.value);
        }

        handleClose();
    };

    return (
        <div>
            <IconButton className={props.buttonClassName} handleClick={handleClick} tooltip={props.tooltip} testId={props.testId}>
                {props.iconButton}
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorElement}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {props.messageToDisplayAtTop && (
                    <MenuItem value="" disabled disableRipple disableTouchRipple sx={{ color: 'black' }} data-testId={`${props.testId}OptionDefault`}>
                        <em>{props.messageToDisplayAtTop}</em>
                    </MenuItem>
                )}
                {props.options.map((option) => (
                    <MenuItem
                        key={`${option.value}`}
                        value={`${option.value}`}
                        onClick={() => handleOptionChange(option.label)}
                        data-testId={`${props.testId}Option${option.value}`}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default IconMenuDropdown;
